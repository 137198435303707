<template>
  <div
    class="printLabel-box"
    @click="handleClick('1')"
  >
    <div
      class="print-content"
      v-loading="printLoading"
      element-loading-background="rgba(0, 0, 0, 0.2)"
      @click.stop=""
    >
      <CloseOutlined
        class="print-close-btn"
        @click="handleClick('1')"
      />
      <p class="title">{{ $t(`label.label19`) }}</p>
      <a-row class="print-box">
        <a-col :span="14">
          <div class="print-left">
            <div class="print-left-header">
              <div class="print-left-header-item">
                <span class="item-title">{{ $t(`label.label16`) }}</span>
                <span class="title-span">{{ labelData.name }}</span>
              </div>
              <div class="print-left-header-item">
                <span class="item-title">{{ $t(`label.label20`) }}</span>
                <span
                  class="title-span"
                  v-if="labelInfo"
                >
                  {{
                    Number(labelInfo.width) * Number(labelInfo.cloumn) +
                    Number(labelInfo.paddingLeft) +
                    Number(labelInfo.paddingRight) +
                    (Number(labelInfo.cloumn) - 1) *
                      Number(labelInfo.ColumnSpacing)
                  }}
                  <span>*</span>
                  {{
                    Number(labelInfo.height) * Number(labelInfo.row) +
                    Number(labelInfo.paddingTop) +
                    Number(labelInfo.paddingBottom) +
                    (Number(labelInfo.row) - 1) * Number(labelInfo.RowSpacing)
                  }}{{ MeasureUnit }}
                </span>
              </div>
            </div>
            <div
              class="left-nav"
              v-loading="loading"
            >
              <img
                style="max-width: 100%; max-height: 100%"
                v-if="printImg.length > 0"
                :src="printImg[pageNum - 1].src"
              />
            </div>
            <div class="left-btn">
              <DoubleLeftOutlined
                @click="pageChange(1)"
                class="pagebtn"
              />
              <LeftOutlined
                class="pagebtn"
                @click="pageChange(2)"
              />
              <div>
                <span v-if="printImg.length > 0">{{ pageNum }}/{{ totalPage }}</span>
              </div>
              <RightOutlined
                class="pagebtn"
                @click="pageChange(3)"
              />
              <DoubleRightOutlined
                class="pagebtn"
                @click="pageChange(4)"
              />
            </div>
          </div>
        </a-col>
        <a-col :span="10">
          <div class="print-right">
            <div class="right-item">
              <span
                class="item-title"
                style="display: block"
              >{{
                $t(`editAttr.attrText98`)
              }}</span>
              <div class="select-print">
                <div>
                  <a-input
                    disabled
                    v-model:value="selectKeysPage"
                  ></a-input>
                </div>
                <div
                  class="set-print"
                  @click="selectData()"
                >
                  {{ $t(`button.choose`) }}
                </div>
              </div>
            </div>
            <div class="right-item">
              <a-checkbox
                size="large"
                class="item-title"
                :disabled="!canSetBackground"
                v-model:checked="printBackground"
                @change="printBackgroundChange"
              >{{ $t(`editAttr.attrText99`) }}</a-checkbox>
            </div>
            <div v-if="system == 'Windows'">
              <div v-if="showPrintDevice">
                <div class="right-item">
                  <div
                    class="item-title"
                    style="display: block"
                  >
                    {{ $t(`label.label64`) }}
                    <span
                      @click="handleOpenHelp()"
                      class="iconfont icon-bangzhu"
                    ></span>
                  </div>
                  <div class="print-device">
                    <a-select
                      allowClear
                      @change="printDeviceChange"
                      v-if="printDeviceList.length > 0"
                      v-model:value="printDevice"
                      :placeholder="$t(`tips.tip84`)"
                      :options="printDeviceList"
                    ></a-select>
                  </div>
                </div>
                <div class="right-item">
                  <div class="item-title">
                    {{ $t(`label.label65`) }}
                  </div>
                  <div class="print-device">
                    <el-input-number
                      v-model="printNum"
                      controls-position="right"
                      :step="1"
                      :precision="0"
                      :min="1"
                      @blur="printNum = printNum ? printNum : 1"
                    >
                    </el-input-number>
                  </div>
                </div>
              </div>

              <div
                class="right-item"
                v-else
              >
                <div
                  class="item-title"
                  style="height: auto"
                >
                  {{ $t(`tips.tip85`) }}
                  <span
                    @click="handleDownLoad()"
                    style="
                      text-decoration: underline;
                      color: #003591;
                      cursor: pointer;
                    "
                  >{{ $t(`tips.tip86`) }}</span>
                  {{ $t(`tips.tip87`) }}
                  <span
                    @click="handleOpenHelp()"
                    class="iconfont icon-bangzhu"
                  ></span>
                </div>
                <div class="item-title printer-tip">
                  ({{ $t(`tips.tip88`)
                  }}<span
                    style="
                      color: #003591;
                      cursor: pointer;
                      text-decoration: underline;
                    "
                    @click="getWebSocket('Manual_link')"
                  >{{ $t(`tips.tip89`) }}</span>)
                </div>
              </div>
            </div>
            <div class="btn-group">
              <a-button @click="handleClick('1')">{{
                $t(`button.cancel`)
              }}</a-button>
              <a-button
                type="primary"
                @click="handleClick('2')"
              >{{
                $t(`button.confirm`)
              }}</a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div style="position: absolute; top: -200%; left: -200%;">
      <div
        id="printDemo"
        style="
          position: absolute;
          margin: 0;
          padding: 0;
          left: 0;
          top: 0;
          pointer-events: none;
        "
      >
        <!-- 预览 type==0 立即打印预览  type==2 编辑预览 -->
        <ImagePreview
          style="padding: 0"
          :data="labelData"
          :printBackground="printBackground"
          :type="fromEdit ? 2 : 0"
          @get="getImage"
          @change="changeImg"
          @info="getInfo"
          @return="getDataList"
          ref="ImagePreview"
        />
      </div>
    </div>
    <SelectData
      ref="selectDataRef"
      v-show="selectDia"
      @confirm="confirmSelect"
    />
  </div>
</template>
<script>
import { getExplorer, getSystem } from "../utils/device";
import { dataTypeChange } from "../views/editLabel/js/propertyType.js"; //设置数据类型
import ImagePreview from "./ImagePreview.vue";
import SelectData from "./SelectData.vue";
import { message } from "ant-design-vue";
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  nextTick,
  toRefs,
  getCurrentInstance,
  onBeforeMount,
} from "vue";
export default defineComponent({
  props: {
    labelData: {
      type: Object,
      default: () => { },
    },
    fromEdit: {
      type: Boolean,
      default: false,
    },
    labelDetail: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    SelectData,
    DoubleLeftOutlined,
    LeftOutlined,
    RightOutlined,
    DoubleRightOutlined,
    ImagePreview,
    CloseOutlined,
  },
  setup (props, context) {
    const { proxy } = getCurrentInstance();
    let globalProperties =
      getCurrentInstance().appContext.config.globalProperties;
    const Version = globalProperties.$Version;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const axios = globalProperties.$axioshanma;
    const axiosHanma = globalProperties.$axioshanma;

    const state = reactive({
      printLoading: false,
      showPrintDevice: false,
      printNum: 1,
      printImg: [],
      pageNum: 1,
      divNum: 1,
      cloumn: 1,
      totalPage: 1,
      loading: true,
      ImagePreview: null,
      MeasureUnit: "",
      row: "",
      printBackground: false,
      Background: "",
      labelList: [],
      rebuild: false,
      canSetBackground: true,
      isEditPreview: false,
      selectDia: false,
      selectDataRef: null,
      newList: [],
      selectKeys: [],
      selectKeysPage: "",
      explorer: "",
      system: "",
      conversion: 3.96, // 1mm约等于4点
      UnitConversion: 1, //单位毫米时为1，1英寸等于25.4毫米，英寸时为25.4, 将英寸换算成毫米
      selectDataList: [],
      printDevice: null, //打印机
      printDeviceList: [],
      printNum: 1, //打印份数
      webSocket: null,
      labelInfo: "", //标签信息
      origionGroup: [], //初始标签数组
    });
    onBeforeMount(() => {
      state.system = getSystem(); //获取操作系统
      if (state.system == "Windows") {
        getWebSocket();
      }
    });
    onMounted(() => {
      state.printDevice = localStorage.getItem("printDevice");
      state.explorer = getExplorer();
      state.labelList = JSON.parse(JSON.stringify(props.labelData));
      state.isEditPreview = props.fromEdit;
      state.divNum = state.labelList.cloumn * state.labelList.row;
      state.cloumn = state.labelList.cloumn;
      state.row = state.labelList.row;
      state.printBackground = state.labelList.Background ? true : false;
      state.canSetBackground = state.labelList.Background ? true : false;
      if (state.isEditPreview) {
        state.origionGroup = JSON.parse(
          JSON.stringify(state.labelList.newgroup)
        );
        state.MeasureUnit = state.labelList.MeasureUnit.toLowerCase();
        state.labelInfo = JSON.parse(JSON.stringify(props.labelData));
        delete state.labelInfo.newgroup;
      }
      state.selectDataList = state.labelList.newgroup;
    });

    //webSocket通信
    const getWebSocket = (val) => {
      state.webSocket = new WebSocket("ws://127.0.0.1:9004");
      if (val == "Manual_link") {
        state.printLoading = true;
      }
      state.webSocket.onopen = () => {
        // console.log("webSocket已连接");
        state.printLoading = false;
        state.showPrintDevice = true;
        //获取打印机列表
        state.webSocket.send("hmarkwebclient {fun:'getComputerPrinters'}");
      };
      state.webSocket.onmessage = (evt) => {
        //数据接收
        var received_msg = JSON.parse(evt.data);
        // console.log(received_msg);

        switch (received_msg.fun) {
          case "getComputerPrinters":
            //打印机列表
            if (received_msg.code == 200) {
              let list = JSON.parse(received_msg.data);
              state.printDeviceList = [];
              let isDevice = list.find((item) => item == state.printDevice);
              if (!isDevice) {
                state.printDevice = null;
                localStorage.removeItem("printDevice");
              }
              list.forEach((item) => {
                state.printDeviceList.push({
                  value: item,
                  label: item,
                });
              });
            } else {
              message.error(proxy.$t(`tips.tip90`));
              state.showPrintDevice = true;
            }
            break;
          case "outputPrintting":
            //打印下发回调
            if (received_msg.code == 200) {
              message.success(proxy.$t(`tips.tip91`));
              state.webSocket.close();
              state.showPrintDevice = false;
              context.emit("cancel");
            } else {
              message.success(proxy.$t(`tips.tip92`));
            }
        }
      };

      state.webSocket.onclose = () => {
        // 关闭 websocket
        // console.log("webSocket关闭");
        state.showPrintDevice = false;
      };

      state.webSocket.onerror = () => {
        //通信失败
        // console.log("webSocket通信失败");
        state.showPrintDevice = false;
        if (val == "Manual_link") {
          state.printLoading = false;
          message.error(proxy.$t(`tips.tip93`));
        }
      };
    };
    //获取单位
    const getInfo = (unit, printBackground, Background) => {
      state.MeasureUnit = unit.toLowerCase();
      state.Background = Background;
      state.printBackground = printBackground;
      state.canSetBackground = printBackground ? true : false;
    };
    //初始化获取图片列表
    const getImage = (value, count) => {
      state.printImg = value;
      state.totalPage = count;
      state.loading = false;
    };
    //切换页码图片不存在时重新获取图片列表
    const changeImg = (value) => {
      state.loading = false;
      state.printImg = value;
    };
    // 控制是否打印背景图片
    const printBackgroundChange = () => {
      state.loading = true;
      state.pageNum = 1;
      state.ImagePreview.rebuildImgList();
    };
    //切换页码
    const pageChange = (e) => {
      switch (e) {
        case 1:
          if (state.totalPage > 1) {
            state.pageNum = 1;
          }
          break;
        case 2:
          if (state.pageNum > 1) {
            state.pageNum--;
          }
          break;
        case 3:
          if (state.pageNum < state.totalPage) {
            state.pageNum++;
          }
          break;
        case 4:
          if (state.pageNum != state.totalPage) {
            state.pageNum = state.totalPage;
          }

          break;
      }
      if (state.printImg[state.pageNum - 1].src) {
        state.loading = false;
      } else {
        state.loading = true;
        state.ImagePreview.changePage(state.pageNum);
      }
    };

    //获取标签数据
    const getDataList = (list, labelInfo) => {
      if (!state.isEditPreview) {
        state.labelInfo = labelInfo;
        state.labelInfo.MeasureUnit = state.MeasureUnit;
        state.labelInfo.Background = state.Background;
        state.labelInfo.PrintBackground = state.printBackground;
        // console.log(state.labelInfo);
      }
      state.origionGroup = JSON.parse(JSON.stringify(list));
      state.selectDataList = JSON.parse(JSON.stringify(list));
      state.newList = list;
      for (let i = 0; i < state.newList.length; i++) {
        state.selectKeys.push(i + 1);
      }
      selctPageNumber();
    };

    const selctPageNumber = () => {
      let tempArr = [];
      const pageNum = (i) => {
        if (state.selectKeys[i] + 1 == state.selectKeys[i + 1]) {
          if (i + 1 < state.selectKeys.length) {
            pageNum(i + 1);
          }
        } else {
          let endVal = state.selectKeys[i];
          let str = "";
          if (endVal == startVal) {
            str = startVal;
          } else {
            str = startVal + "-" + endVal;
          }
          tempArr.push(str);
          if (i + 1 < state.selectKeys.length) {
            startVal = state.selectKeys[i + 1];
            pageNum(i + 1);
          }
        }
      };
      let startVal = state.selectKeys[0];
      pageNum(0);
      state.selectKeysPage = tempArr.join(",");
    };

    const selectData = () => {
      state.selectDia = true;
      state["selectDataRef"].show(state.newList, state.selectKeys);
    };

    const confirmSelect = (list, keys) => {
      state.loading = true;
      state.selectKeys = keys;
      selctPageNumber();
      state.pageNum = 1;
      // console.log(list);
      state.ImagePreview.resetPrintData(list);
      state.selectDataList = list;
    };

    //切换打印设备
    const printDeviceChange = () => {
      localStorage.setItem("printDevice", state.printDevice);
    };

    const handleDownLoad = () => {
      axios(
        "/api/upgrade.download/appSoftwareDownload?language=ul&app_type=1&app_package_name=HPRT_HMark_Service_exe&type=1",
        {},
        "get"
      ).then((res) => {
        let a = document.createElement("a");
        a.href = res.url;
        a.click();
      });
    };

    // 打印
    const handleClick = (e) => {
      if (e == 1) {
        //取消
        if (state.showPrintDevice) {
          state.webSocket.close();
        }
        context.emit("cancel");
      } else if (e == 2) {
        if (state.showPrintDevice) {
          if (state.printDevice) {
            dealCloudData();
          } else {
            message.error(proxy.$t(`tips.tip84`));
          }
          recordUseTemp();
        } else {
          //确定
          nextTick(() => {
            $("#printDemo").jqprint({
              debug: false, //如果是true则可以显示iframe查看效果（iframe默认高和宽都很小，可以再源码中调大），默认是false
              importCSS: true, //true表示引进原来的页面的css，默认是true。（如果是true，先会找$("link[media=print]")，若没有会去找$("link")中的css文件）
              printContainer: true, //表示如果原来选择的对象必须被纳入打印（注意：设置为false可能会打破你的CSS规则）。
              operaSupport: true, //表示如果插件也必须支持歌opera浏览器，在这种情况下，它提供了建立一个临时的打印选项卡。默认是true
            });
          });
          recordUseTemp();
          setTimeout(() => {
            context.emit("cancel");
          }, 1000);
        }
      }
    };

    //记录模板使用情况
    const recordUseTemp = () => {
      let info = "";
      if (props.fromEdit) {
        info = props.labelDetail;
      } else {
        info = state.labelList;
      }
      if (info.hasOwnProperty("shareTemp") && info.shareTemp) {
        //公共模板下载记录添加
        axiosHanma("/api/tem.app_public/addDowTem", {
          id: info.id,
        }).then((res) => { });
        //公共模板使用记录添加
        axiosHanma("/api/tem.app_public/userUseTem", {
          id: info.id,
          user_id: userInfo.userid,
        }).then((res) => { });
      } else if (info.hasOwnProperty("id")) {
        //云端模板使用情况记录
        axiosHanma("/api/tem.app_public/userPrivateUserTem", {
          id: info.id,
          user_id: userInfo.userid,
        }).then((res) => {
          //首页刷新最近使用列表
          context.emit("refresh");
        });
      }
    };

    //处理云打印数据
    const dealCloudData = () => {
      if (state.labelInfo.MeasureUnit.toLowerCase() == "inch") {
        state.UnitConversion = 25.4;
      } else {
        state.UnitConversion = 1;
      }
      let newgroup = JSON.parse(JSON.stringify(state.selectDataList));
      let cloudData = [];
      //origionGroupLength  原始数组长度  如果长度为1则不包含数据库或者序列化数据
      let origionGroupLength = state.origionGroup.length;
      //pageCount  一页包含了几个标签
      let pageCount = state.labelInfo.row * state.labelInfo.cloumn;

      let currentRow = 1;
      let currentCloumn = 1;

      for (let j = 0; j < newgroup.length; j++) {
        let group = newgroup[j].group;
        let page = Math.ceil((newgroup[j].groupIndex + 1) / pageCount);
        let row = currentRow;
        let cloumn = currentCloumn;
        if ((j + 1) % pageCount == 0) {
          currentRow = 1;
          currentCloumn = 1;
        } else {
          if (currentCloumn == state.labelInfo.cloumn) {
            currentRow += 1;
            currentCloumn = 1;
          } else {
            currentCloumn += 1;
          }
        }

        for (let i = 0; i < group.length; i++) {
          if (
            group[i].Type == "LineVertical" ||
            group[i].Type == "LineHorizontal" ||
            group[i].Type == "Line"
          ) {
            switch (group[i].Type) {
              case "LineVertical":
                group[i].EndY =
                  (group[i].Height + group[i].StartY) * state.conversion;
                group[i].EndX = group[i].StartX * state.conversion;
                break;
              case "LineHorizontal":
                group[i].EndX =
                  (group[i].Width + group[i].StartX) * state.conversion;
                group[i].EndY = group[i].StartY * state.conversion;
                break;
              default:
                group[i].EndX = group[i].EndX * state.conversion;
                group[i].EndY = group[i].EndY * state.conversion;
                break;
            }
            delete group[i].Width;
            delete group[i].Height;
          } else {
            let w = group[i].Width * state.conversion;
            let h = group[i].Height * state.conversion;
            group[i].Width = w;
            group[i].Height = h;
          }
          if (group[i].Type == "Image") {
            group[i].Image = group[i].OriginalImage;
          }
          if (group[i].hasOwnProperty("BoxWidth")) {
            group[i].BoxWidth = group[i].BoxWidth * state.conversion;
          }
          group[i].StartX = group[i].StartX * state.conversion;
          group[i].StartY = group[i].StartY * state.conversion;
          if (group[i].AngleRound) {
            switch (group[i].AngleRound) {
              case 90:
                group[i].StartX = group[i].StartX - group[i].Height;
                break;
              case 180:
                group[i].StartX = group[i].StartX - group[i].Width;
                group[i].StartY = group[i].StartY - group[i].Height;
                break;
              case 270:
                group[i].StartY = group[i].StartY - group[i].Width;
                break;
            }
          }

          if (group[i].Font) {
            group[i].Font["@FontSize"] = group[i].Font["@FontSize"].toString();
          }

          if (group[i].Type == "Diamond") {
            let Vertice = group[i].Vertice;
            for (let k in Vertice) {
              Vertice[k]["@X"] = Math.round(Vertice[k]["@X"]);
              Vertice[k]["@Y"] = Math.round(Vertice[k]["@Y"]);
            }
            group.Vertice = Vertice;
          } else if (group[i].Type == "Triangle") {
            let Vertice = group[i].Vertice;
            for (let h in Vertice) {
              Vertice[h]["@X"] = Math.round(Vertice[h]["@X"]);
              Vertice[h]["@Y"] = Math.round(Vertice[h]["@Y"]);
            }
            group.Vertice = Vertice;
          } else if (group[i].Type == "Table") {
            group[i].PenWidth = group[i].PenWidth * state.conversion;

            for (let line = 0; line < group[i].DicLineLocW.length; line++) {
              group[i].DicLineLocW[line] = (
                Math.round(
                  group[i].DicLineLocW[line] * state.conversion * 100000
                ) / 100000
              ).toFixed(5);
            }
            group[i].DicLineLocW = group[i].DicLineLocW.join(",");

            for (let line = 0; line < group[i].DicLineLocH.length; line++) {
              group[i].DicLineLocH[line] = (
                Math.round(
                  group[i].DicLineLocH[line] * state.conversion * 100000
                ) / 100000
              ).toFixed(5);
            }
            group[i].DicLineLocH = group[i].DicLineLocH.join(",");

            group[i].DataSourceList.map((data) => {
              data.StartX = data.StartX * state.conversion;
              data.StartY = data.StartY * state.conversion;
              data.Width = data.Width * state.conversion;
              data.Height = data.Height * state.conversion;
              data = dataTypeChange(data, 5);
              data.Key = data.Key.join(",");
              if (data.hasOwnProperty("children")) {
                data.Key = data.children.join("|");
              }
              delete data.children;
              delete data.ConnectionData;
              delete data.OriginalConnectionData;
            });
            delete group[i].Row;
            delete group[i].Cloumn;
          }
          if (group[i].Type != "Table") {
            delete group[i].DataSourceList;
          }
          delete group[i].divL;
          delete group[i].divT;
          delete group[i].badBarcode;
          delete group[i].RightBarcodeImage;
          delete group[i].ImageFilePath;
          delete group[i].OriginalImage;
          delete group[i].index;
          group[i] = dataTypeChange(group[i], 5);
        }
        cloudData.push({
          "@Count": group.length, //元素长度
          page, //页码
          row, //所在行
          cloumn, //所在列
          DrawObject: group, //元素数组
        });
      }
      if (pageCount > origionGroupLength) {
        let copyData = JSON.parse(JSON.stringify(cloudData[0]));
        cloudData = [];
        let currentRow = 1;
        let currentCloumn = 1;
        for (let z = 0; z < pageCount; z++) {
          let data = JSON.parse(JSON.stringify(copyData));
          data.row = currentRow;
          data.cloumn = currentCloumn;
          if ((z + 1) % pageCount == 0) {
            currentRow = 1;
            currentCloumn = 1;
          } else {
            if (currentCloumn == state.labelInfo.cloumn) {
              currentRow += 1;
              currentCloumn = 1;
            } else {
              currentCloumn += 1;
            }
          }

          cloudData.push(data);
        }
      }

      // console.log(cloudData);

      var cloudPrintJson = {
        "?xml": {
          "@version": "1.0",
          "@encoding": "utf-8",
        },
        PrtLable: {
          "#comment": [],
          FileInfo: {
            Creator: {
              "@Platform": "Web",
              "@Version": Version,
            },
          },
          PictureArea: {
            AreaSize: {
              "@Height": Math.round(
                state.labelInfo.height * (31.7 / 8) * state.UnitConversion
              ).toString(),
              "@Width": Math.round(
                state.labelInfo.width * (31.7 / 8) * state.UnitConversion
              ).toString(),
            },
            LabelPage: {
              "@MeasureUnit": state.labelInfo.MeasureUnit,
              "@LabelShape": "Rectangle",
              "@Height": state.labelInfo.height,
              "@Width": state.labelInfo.width,
              Rows: state.labelInfo.row,
              Columns: state.labelInfo.cloumn,
              RowSpacing: state.labelInfo.RowSpacing,
              ColumnSpacing: state.labelInfo.ColumnSpacing,
              LeftMargin: state.labelInfo.paddingLeft,
              RightMargin: state.labelInfo.paddingRight,
              UpperMargin: state.labelInfo.paddingTop,
              LowerMargin: state.labelInfo.paddingBottom,
              LabelWidth: state.labelInfo.width,
              LabelHeight: state.labelInfo.height,
              Background: state.labelInfo.Background,
              PrintBackground: state.printBackground ? "True" : "False",
              PrinterName: state.printDevice, //选择的打印机
              PrintNum: state.printNum, //打印份数
            },
          },
          ObjectList: cloudData,
        },
      };
      // console.log(cloudPrintJson);
      if (state.showPrintDevice) {
        //下发打印命令
        state.webSocket.send(
          'hmarkwebclient {fun:"outputPrintting",data:' +
          JSON.stringify(cloudPrintJson) +
          "}"
        );
      }
    };

    //跳转帮助中心
    const handleOpenHelp = () => {
      if (process.env.VUE_APP_VERSION == "hprt") {
        // hrpt 版本
        if (proxy.$i18n.locale == "zh") {
          window.open("/help/124/", "_blank");
        } else {
          window.open("/en/help/125/", "_blank");
        }
      }else if(process.env.VUE_APP_VERSION == "hereLabelTop"){
        window.open("/help/125", "_blank");
      } else {
        // HereLabel 版本
        if (proxy.$i18n.locale == "zh") {
          window.open("/helpDetail?id=124", "_blank");
        } else {
          window.open("/helpDetail?id=125", "_blank");
        }
      }
    };

    return {
      ...toRefs(state),
      selectData,
      handleClick,
      getImage,
      pageChange,
      changeImg,
      getInfo,
      printBackgroundChange,
      getDataList,
      confirmSelect,
      getWebSocket,
      printDeviceChange,
      handleDownLoad,
      handleOpenHelp,
    };
  },
});
</script>
<style scoped>
.printLabel-box .icon-bangzhu {
  font-size: 18px;
  color: #7c8ca4;
  cursor: pointer;
}
.printLabel-box .printer-tip {
  font-size: 14px;
  height: auto;
}
.printLabel-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.print-content {
  width: 888px;
  height: 582px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
}
.print-close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
}
.print-content .title {
  width: 100%;
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 28px;
  margin-top: 30px;
  margin-left: 32px;
}
.print-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 24px;
  position: relative;
}
.print-box .print-left {
  width: 480px;
  display: flex;
  align-content: center;
  flex-direction: column;
  margin-left: 32px;
}
.print-box .print-left-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.print-box .print-left-header-item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 46%;
}
.print-left-header .item-title {
  height: 24px;
  font-size: 16px;
  color: #535d6c;
  line-height: 24px;
  margin-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.print-left-header .title-span {
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 24px;
  max-width: 60%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  flex-shrink: 0;
}
.left-nav {
  width: 480px;
  height: 360px;
  background: #f5f8fc;
  border-radius: 4px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
}
.left-nav > img {
  object-fit: scale-down;
}
.left-btn {
  margin-top: 24px;
  margin-left: 110px;
  display: flex;
  align-items: center;
}
.left-btn ::v-deep(.anticon) {
  font-size: 16px;
  margin: 13px 30px 13px 0px;
}
.left-btn ::v-deep(.anticon:nth-child(2)) {
  margin-right: 19px;
}
.left-btn .pagebtn {
  cursor: pointer;
}
.left-btn ::v-deep(.anticon:hover) {
  color: #003591;
}

.left-btn > div {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 24px;
  width: 80px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d6dce6;
  text-align: center;
  padding-top: 8px;
  margin-right: 19px;
}

.print-box .print-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 32px;
}

.print-right .right-item {
  margin-bottom: 24px;
}
.print-right .item-title {
  height: 24px;
  font-size: 16px;
  color: #535d6c;
  line-height: 24px;
  margin-right: 16px;
}
.print-right .title-span {
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 24px;
}
.select-print {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.select-print ::v-deep(.ant-input) {
  width: 240px;
  height: 40px;
}
.print-device {
  margin-top: 16px;
}
.print-device ::v-deep(.ant-select) {
  width: 240px;
  height: 40px;
}
.print-device
  ::v-deep(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  height: 40px;
  border-radius: 4px;
}
.print-device
  ::v-deep(.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input) {
  height: 40px;
}
.print-device ::v-deep(.ant-select-selection-placeholder) {
  line-height: 38px !important;
}
.print-device ::v-deep(.ant-select-selection-item) {
  line-height: 38px !important;
}
.print-device ::v-deep(.el-input-number) {
  text-align: left;
  width: 240px;
  height: 40px;
}
.print-device ::v-deep(.el-input-number.is-controls-right .el-input__inner) {
  padding-left: 11px;
  text-align: left;
}
.set-print {
  height: 40px;
  font-size: 16px;
  color: #003591;
  line-height: 40px;
  margin-left: 16px;
  cursor: pointer;
}
.btn-group {
  position: absolute;
  display: inline-block;
  bottom: 2px;
}
.btn-group ::v-deep(.ant-btn) {
  margin-right: 16px;
}
.btn-group ::v-deep(.ant-btn:first-child) {
  color: #535d6c;
  border-color: #535d6c;
  background: none;
}
@media only screen and (max-width: 1300px) {
  .printLabel-box .icon-bangzhu {
    font-size: 14px;
  }
  .printLabel-box .printer-tip {
    font-size: 10px;
  }
  .print-content {
    width: 592px;
    height: 385px;
  }
  .print-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .print-content .title {
    height: 18px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
    margin-left: 21px;
  }
  .print-box {
    margin-top: 16px;
  }
  .print-box .print-left {
    width: 320px;
    margin-left: 21px;
  }
  .print-box .print-left-header {
    margin-bottom: 10px;
  }
  .print-left-header .item-title {
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    margin-right: 10px;
  }
  .print-left-header .title-span {
    height: 16px;
    font-size: 10px;
    line-height: 16px;
  }
  .left-nav {
    width: 320px;
    height: 240px;
    padding: 6px;
  }
  .left-btn {
    margin-top: 16px;
    margin-left: 73px;
  }
  .left-btn ::v-deep(.anticon) {
    font-size: 10px;
    margin: 8px 20px 8px 0px;
  }
  .left-btn ::v-deep(.anticon:nth-child(2)) {
    margin-right: 14px;
  }

  .left-btn > div {
    font-size: 10px;
    line-height: 16px;
    width: 53px;
    height: 28px;
    padding-top: 5px;
    margin-right: 14px;
  }

  .print-box .print-right {
    margin-left: 21px;
  }

  .print-right .right-item {
    margin-bottom: 16px;
  }
  .print-right .item-title {
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    margin-right: 10px;
  }
  .print-right .title-span {
    height: 16px;
    font-size: 10px;
    line-height: 16px;
  }
  .select-print {
    margin-top: 10px;
  }
  .select-print ::v-deep(.ant-input) {
    width: 160px;
    height: 26px;
  }

  .print-device {
    margin-top: 10px;
  }
  .print-device ::v-deep(.ant-select) {
    width: 160px;
    height: 26px;
  }

  .print-device
    ::v-deep(.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector) {
    height: 26px;
    border-radius: 4px;
  }
  .print-device
    ::v-deep(.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input) {
    height: 26px;
  }
  .print-device ::v-deep(.ant-select-selection-placeholder) {
    line-height: 24px !important;
  }
  .print-device ::v-deep(.ant-select-selection-item) {
    line-height: 24px !important;
  }
  .print-device ::v-deep(.el-input) {
    line-height: 30px;
  }
  .print-device ::v-deep(.el-input-number) {
    width: 160px;
    line-height: 30px !important;
  }

  .print-device ::v-deep(.el-input-number.is-controls-right .el-input__inner) {
    height: 30px !important;
    line-height: 30px !important;
  }

  .print-device
    ::v-deep(.el-input-number.is-controls-right .el-input-number__increase) {
    height: 15px !important;
    line-height: 15px !important;
  }
  .print-device
    ::v-deep(.el-input-number.is-controls-right .el-input-number__decrease) {
    height: 15px !important;
    line-height: 15px !important;
  }

  .set-print {
    display: inline-block;
    height: 26px;
    font-size: 10px;
    line-height: 26px;
    margin-left: 10px;
  }
  .btn-group ::v-deep(.ant-btn) {
    margin-right: 10px;
    min-width: 30px;
  }
}
</style>
