import router from "@/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // nprogress样式文件
import i18n from "../language/i18n";
import { getAuthorization } from "./request";
const whiteList = [
  "home",
  "index",
  "user",
  "shareTemplate",
  "cloudTemplate",
  "cloudData",
  "home",
  "editLabel",
  "member",
  "pdfPrint",
]; // 需要登陆的页面
function loadRoutes() {}
const setPageTitleTxt = (meta) => {
  const { label } = meta;
  if (label) {
    window.document.title = i18n.global.t(label);
  }
};
function loadGuards() {
  router.afterEach((to, from) => {
    NProgress.start();
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    to.meta.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
  });
  router.beforeEach(async (to, from, next) => {
    function canUserAccess(to) {
      setTimeout(() => {
        return true;
      }, 5000);
    }

    let routerOne = to.path.split("/")[1];
    if (whiteList.indexOf(routerOne) > 0) {
      //该页面需要登录，判断token是否存在，不存在则跳转到登录页
      if (getAuthorization()) {
        next();
      } else {
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      }
    } else {
      //该页面不需要登录,直接跳转
      if (routerOne == "login" && getAuthorization()) {
        next("/index");
      } else {
        next();
      }
    }

    return await canUserAccess(to);
    NProgress.start();
  });
  router.afterEach((to, form) => {
    setPageTitleTxt(to.meta);
    NProgress.done();
  });
}

export { loadGuards, loadRoutes };

