<template>
  <div
    class="edit-print"
    id="print"
    @click="
      (showImgList = false),
        (showIcons = false),
        (showRects = false),
        (isShowMenu = false),
        (showShortcutKey = false),
        (showCloudFolder = false),
        (showCloudDataList = false),
        (databaseBtn = false)
    "
  >
    <!-- 生成条形码 -->
    <img
      id="barcode"
      ref="barcode"
      style="position: fixed; left: -200%; top: -200%; z-index: -1"
    />

    <!-- 生成错误条形码 -->
    <div style="position: fixed; left: -200%; top: -200%; z-index: -1">
      <div
        style="position: relative; width: 100%; height: 100%"
        ref="badcodeImage"
        id="badcodeImage"
        v-if="subgroup.length > 0 && subIndex > -1"
      >
        <img
          v-if="
            subgroup[subIndex].Type == 'GeneralBarcode' && subgroup[subIndex].badBarcode
          "
          style="
            width: 68px;
            height: 68px;
            color: red;
            position: absolute;
            left: calc(50% - 24px);
            top: calc(50% - 24px);
          "
          src="../../assets/error.png"
        />
        <img v-if="origionCodeImage" :src="imgHeader + origionCodeImage" />
      </div>
    </div>

    <!-- 拖拉组件效果 -->
    <div
      class="unit-div"
      v-if="unitFlag"
      :style="{
        left: point.left + 'px',
        top: point.top + 'px',
        cursor: cursor,
      }"
    >
      <div class="u-img">
        <img
          class="obj-fit"
          :src="unit[unitIndex].img"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div class="u-text">{{ unit[unitIndex].text }}</div>
    </div>
    <!-- 拖拉形状效果 -->
    <div
      class="unit-div"
      v-if="rectsFlag"
      :style="{
        left: point.left + 'px',
        top: point.top + 'px',
        cursor: cursor,
      }"
    >
      <div class="u-img">
        <img
          class="obj-fit"
          :src="rects[rectsIndex].img"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div class="u-text">{{ rects[rectsIndex].text }}</div>
    </div>
    <!-- 拖拉图标效果 -->
    <div
      class="unit-div"
      v-if="iconFlag"
      :style="{
        left: point.left + 'px',
        top: point.top + 'px',
        cursor: cursor,
      }"
    >
      <div class="u-img">
        <img
          class="obj-fit"
          :src="iconList[iconIndex].url"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div class="u-text">{{ iconList[iconIndex].name }}</div>
    </div>
    <!-- 左侧操作栏 -->
    <div
      class="edit-print-left"
      ref="printLeft"
      ondragstart="return false;"
      oncontextmenu="return false;"
    >
      <div class="unit-title">
        <img
          class="here-img"
          v-if="VUE_APP_VERSION == 'hprt'"
          src="../../assets/here/here-logo.png"
          alt=""
        />
        <img v-else class="edit-logo" src="../../assets/logo.png" alt="" />
      </div>
      <div class="unit-box">
        <div
          class="unit-li"
          v-for="(item, index) in unit"
          :key="index"
          @click.stop=""
          @mousedown="handleUnit($event, index)"
          @click="handledbUnit($event, index)"
        >
          <div class="u-img">
            <img class="obj-fit" :src="item.img" style="width: 40%; margin: 0 auto" />
          </div>
          <div class="u-text">{{ item.text }}</div>
          <div
            v-if="item.type == 'Rectangle' || item.type == 'tubiao'"
            class="iconfont icon-youxia u-text-icons"
          ></div>
        </div>
        <!-- 形状操作栏 -->
        <div v-if="showRects" class="unit-rect">
          <div
            v-for="(item, index) in rects"
            :key="index"
            @click.stop=""
            @mousedown="handleRects($event, index)"
            @click="handledbRects($event, index)"
            class="unit-rect-li"
          >
            <div class="u-img">
              <img class="obj-fit" :src="item.img" style="width: 40%; margin: 0 auto" />
            </div>
            <div class="u-text">{{ item.text }}</div>
          </div>
        </div>
        <!-- 图标操作栏 -->
        <div v-if="showIcons" class="unit-icons">
          <div class="edit-icon-box">
            <div class="icon-left">
              <el-tree
                :data="iconsData"
                :props="defaultProps"
                node-key="id"
                :default-expanded-keys="[iconId]"
                :current-node-key="iconChildrenId"
                @node-click="handleNodeClick"
              ></el-tree>
            </div>
            <div class="icon-right">
              <div class="icons-title">{{ iconTitle }}</div>
              <div class="icons-main">
                <div
                  class="icons-item"
                  v-for="(item, index) in iconList"
                  :key="index"
                  @click.stop=""
                  @mousedown="handleIcons($event, index)"
                  @click="handledbIcons($event, index)"
                >
                  <el-image class="icons-item-img" :src="item.url" :alt="item.name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 顶部信息栏 -->
    <div class="edit-print-top" ref="printTop">
      <div class="edit-print-exit" @click="$router.back()">
        <i class="iconfont icon-arrow-right" ></i
        >{{ $t(`button.exitEdit`) }}
      </div>
      <div class="edit-print-name" @click="editLableName">
        <div style="display: flex; align-items: center">
          <div class="edit-print-name-text">{{ labelList.name }}</div>
          <i class="el-icon-edit-outline" style="pading-left: 7px"></i>
        </div>
        <div v-if="showCanvas">
          <div v-if="MeasureUnit == 'Mm'" class="edit-print-name-size">
            {{ labelList.width + MeasureUnit.toLowerCase() }}X{{
              labelList.height + MeasureUnit.toLowerCase()
            }}
          </div>
          <div v-else class="edit-print-name-size">
            {{ labelList.width }}' X {{ labelList.height }}'
          </div>
        </div>
      </div>
      <div class="top-btn">
        <el-button @click="handleOpenSet">
          <img src="../../assets/tag.png" class="btn-img" style="vertical-align: top" />{{
            $t(`button.labelset`)
          }}
        </el-button>
        <el-button @click="handleSave" :disabled="isSaveBtn">
          <img
            src="../../assets/save.png"
            class="btn-img"
            style="vertical-align: top"
          />{{ $t(`button.save`) }}
        </el-button>
        <el-button @click="handlePrint"
          ><img
            src="../../assets/print.png"
            class="btn-img"
            style="vertical-align: top"
          />{{ $t(`button.printLabel`) }}</el-button
        >
      </div>
    </div>
    <!-- 顶部工具操作栏 -->
    <div class="edit-print-tool" ref="printTool">
      <!-- 锁定、撤销、恢复 -->
      <div
        class="align-icon"
        v-for="(item, index) in tools.toolOne"
        :key="index"
        @click="handleHistory(index)"
      >
        <div
          v-if="item.id == 1"
          class="iconfont obj-fit"
          :class="item.img"
          :style="{ color: subIndex > -1 && Lock ? '#003591' : '' }"
        ></div>
        <div
          v-if="item.id == 2"
          class="iconfont obj-fit"
          :class="item.img"
          :style="{
            color: (listType == 1 ? historyIndex < 0 : historyIndex < 1)
              ? 'rgba(123, 137, 161, 0.4)'
              : '',
          }"
        ></div>
        <div
          v-if="item.id == 3"
          class="iconfont obj-fit"
          :class="item.img"
          :style="{
            color: historyIndex == history.length - 1 ? 'rgba(123, 137, 161, 0.4)' : '',
          }"
        ></div>
        <div class="align-icon-hover" v-if="item.id == 1">
          <div class="triangle_border_up"></div>
          <div class="align-icon-text" v-if="subIndex > -1 && Lock">
            {{ $t(`editAttr.attrText53`) }}
          </div>
          <div class="align-icon-text" v-else>{{ item.text }}</div>
        </div>
        <div class="align-icon-hover" v-else>
          <div class="triangle_border_up"></div>
          <div class="align-icon-text">{{ item.text }}</div>
        </div>
      </div>
      <div class="align-icon-fenge"></div>
      <!-- 对齐操作 -->
      <div
        class="align-icon"
        v-for="(item, index) in tools.toolTwo"
        :key="index"
        @click="handleSet(index)"
      >
        <div
          v-if="item.id != 10 && item.id != 11"
          class="iconfont obj-fit"
          :class="item.img"
          :style="{
            color: selectUnit.length <= 1 ? 'rgba(123, 137, 161, 0.4)' : '',
          }"
        ></div>
        <div
          v-else
          class="iconfont obj-fit"
          :class="item.img"
          :style="{
            color: selectUnit.length > 1 ? 'rgba(123, 137, 161, 0.4)' : '',
          }"
        ></div>
        <div class="align-icon-hover">
          <div class="triangle_border_up"></div>
          <div class="align-icon-text">{{ item.text }}</div>
        </div>
      </div>
      <div class="align-icon-fenge"></div>
      <!-- 置顶置底 -->
      <div
        class="align-icon"
        v-for="(item, index) in tools.toolThree"
        :key="index"
        @click="handleOrder(index)"
      >
        <div class="iconfont obj-fit" :class="item.img"></div>
        <div class="align-icon-hover">
          <div class="triangle_border_up"></div>
          <div class="align-icon-text">{{ item.text }}</div>
        </div>
      </div>
      <div class="align-icon-fenge"></div>
      <!-- 复制、粘贴、删除 -->
      <div
        class="align-icon"
        v-for="(item, index) in tools.toolFour"
        :key="index"
        @click="handleOperate(index)"
      >
        <div
          class="iconfont obj-fit"
          :class="item.img"
          :style="{
            color: copyData.length == 0 && index == 1 ? 'rgba(123, 137, 161, 0.4)' : '',
          }"
        ></div>
        <div class="align-icon-hover">
          <div class="triangle_border_up"></div>
          <div class="align-icon-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <!-- 右侧属性栏 -->
    <div
      class="edit-print-right"
      ref="printRight"
      @mousedown="cancelMoreSelect()"
      v-if="subgroup.length > 0 && subIndex >= 0"
    >
      <template v-if="showAttrPanel">
        <div class="p-title">{{ subgroup[subIndex].Name }}</div>
        <!-- 时间数据类型 -->
        <div class="p-text" v-if="subgroup[subIndex].Type == 'TimeText'">
          <div class="p-step-title">{{ $t(`editAttr.attrText35`) }}</div>
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].DataType"
            @change="dataTypeChange($event)"
            :placeholder="$t(`editAttr.attrText36`)"
          >
            <el-option
              v-for="item in dataType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 文本、条形码、二维码数据类型 -->
        <div
          class="p-text"
          v-if="
            subgroup[subIndex].Type == 'WinText' ||
            subgroup[subIndex].Type == 'GeneralBarcode' ||
            subgroup[subIndex].Type == 'QRCode'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText2`) }}</div>
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].DataSourceType"
            :placeholder="$t(`editAttr.attrText36`)"
            @change="dataSourceChange($event)"
          >
            <el-option
              v-for="item in dataSource"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 普通文本输入框 -->
        <div
          class="p-textarea"
          v-if="
            (subgroup[subIndex].Type == 'WinText' ||
              subgroup[subIndex].Type == 'GeneralBarcode' ||
              subgroup[subIndex].Type == 'QRCode') &&
            subgroup[subIndex].DataSourceList[dataSourceIndex].DataSourceType == 0
          "
        >
          <el-input
            type="textarea"
            resize="none"
            :rows="3"
            :placeholder="$t(`editAttr.attrText47`)"
            v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].FirstValue"
            @input="dataInput($event)"
          >
          </el-input>
        </div>
        <!-- 日期时间输入框 -->
        <div class="p-textarea" v-if="subgroup[subIndex].Type == 'TimeText'">
          <el-input
            type="textarea"
            resize="none"
            :rows="3"
            :disabled="subgroup[subIndex].DataType == 0 ? true : false"
            :placeholder="$t(`editAttr.attrText47`)"
            v-model="subgroup[subIndex].Data"
            @input="timeDataInput($event)"
          >
          </el-input>
        </div>
        <!-- 数据库文本 -->
        <div
          class="p-textarea"
          style="display: flex; align-items: center; justify-content: space-between"
          v-if="
            (subgroup[subIndex].Type == 'WinText' ||
              subgroup[subIndex].Type == 'GeneralBarcode' ||
              subgroup[subIndex].Type == 'QRCode') &&
            subgroup[subIndex].DataSourceList[dataSourceIndex].DataSourceType == 1
          "
        >
          <!-- 连接数据库 -->
          <div style="position: relative; width: 50%">
            <el-button
              style="width: 100%"
              @click.stop="
                showCloudFolder || showCloudDataList || databaseBtn
                  ? ((databaseBtn = false),
                    (showCloudFolder = false),
                    (showCloudDataList = false))
                  : (databaseBtn = true)
              "
              icon="el-icon-link"
            >
              {{ $t(`editAttr.attrText37`) }}</el-button
            >
            <div class="database-btn" v-if="databaseBtn">
              <div @click.stop="getFolderList()">
                {{ $t(`editAttr.attrText67`) }}
              </div>
              <div @click.stop="getFileUpload">
                {{ $t(`editAttr.attrText68`) }}
              </div>
              <input
                style="display: none"
                type="file"
                name="cover"
                accept=".xls,.xlsx"
                @change="getLocalData"
                id="selectBtn"
              />
            </div>
            <div class="database-list" v-if="showCloudFolder">
              <div class="cloudFileBack">
                <i
                  class="el-icon-arrow-left"
                  @click.stop="
                    (showCloudFolder = false),
                      (showDataList = false),
                      (databaseBtn = true)
                  "
                ></i>
              </div>
              <div
                class="database-list-box"
                v-for="(item, index) in cloudFileList"
                :key="index"
                @click.stop="getList(index, 2)"
              >
                <div>{{ item.name }}</div>
              </div>
            </div>
            <div class="database-list" v-if="showCloudDataList">
              <div class="cloudFileBack">
                <i class="el-icon-arrow-left" @click.stop="cloudFileBack"></i>
              </div>
              <div
                class="database-list-box"
                v-for="(item, index) in cloudDataList"
                :key="index"
                @click.stop="readFile(index)"
              >
                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
          <!-- 插入数据 -->
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].ColumnName"
            @change="dataColumnChange($event)"
            :placeholder="$t(`editAttr.attrText38`)"
          >
            <el-option
              v-for="item in database"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 数据库文本的数据类型 -->
        <div
          v-if="
            (subgroup[subIndex].Type == 'WinText' ||
              subgroup[subIndex].Type == 'GeneralBarcode' ||
              subgroup[subIndex].Type == 'QRCode') &&
            subgroup[subIndex].DataSourceList[dataSourceIndex].DataSourceType == 1
          "
        >
          <!-- 数据类型 -->
          <div class="p-text">
            <div class="p-step-title">{{ $t(`editAttr.attrText35`) }}</div>
            <el-select
              class="p-text-select"
              :popper-append-to-body="false"
              v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].DataType"
              @change="dateBaseTypeChange($event)"
              :placeholder="$t(`editAttr.attrText36`)"
            >
              <el-option
                v-for="item in dateBaseType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 日期格式 -->
          <div
            class="p-text"
            v-if="subgroup[subIndex].DataSourceList[dataSourceIndex].DataType == 0"
          >
            <div class="p-step-title">{{ $t(`editAttr.attrText43`) }}</div>
            <el-select
              class="p-text-select"
              :popper-append-to-body="false"
              v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].DataFormat"
              @change="dateBaseTypeChange($event)"
              :placeholder="$t(`editAttr.attrText36`)"
            >
              <el-option
                v-for="item in dateFormat"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 数字格式 -->
          <div
            class="p-text"
            v-if="subgroup[subIndex].DataSourceList[dataSourceIndex].DataType == 1"
          >
            <div class="p-step-title">{{ $t(`editAttr.attrText42`) }}</div>
            <el-select
              class="p-text-select"
              :popper-append-to-body="false"
              v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].NumberFormat"
              @change="dateBaseTypeChange($event)"
              :placeholder="$t(`editAttr.attrText36`)"
            >
              <el-option
                v-for="item in numberFormat"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 序列类型 -->
        <div
          class="p-text"
          v-if="
            (subgroup[subIndex].Type == 'WinText' ||
              subgroup[subIndex].Type == 'GeneralBarcode' ||
              subgroup[subIndex].Type == 'QRCode') &&
            subgroup[subIndex].DataSourceList[dataSourceIndex].DataSourceType == 2
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText92`) }}</div>
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].SequenceType"
            :placeholder="$t(`editAttr.attrText36`)"
            @change="sequenceTypeChange($event)"
          >
            <el-option
              v-for="item in SequenceType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 序列化文本 -->
        <div
          class="p-textarea"
          v-if="
            (subgroup[subIndex].Type == 'WinText' ||
              subgroup[subIndex].Type == 'GeneralBarcode' ||
              subgroup[subIndex].Type == 'QRCode') &&
            subgroup[subIndex].DataSourceList[dataSourceIndex].DataSourceType == 2
          "
        >
          <div class="p-serialization">
            <!-- 递变数值 -->
            <div class="p-InitialValue">
              <div>{{ $t(`editAttr.attrText39`) }}</div>
              <el-input
                v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].InitialValue"
                @focus="closeAttrPanel = false"
                @blur="InitialValueBlur()"
                @input="changeInitialValue($event)"
              ></el-input>
            </div>
            <!-- 递变量 -->
            <div>
              <div>{{ $t(`editAttr.attrText40`) }}</div>
              <el-input-number
                v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].AddedValue"
                :step="1"
                :precision="0"
                size="large"
                controls-position="right"
                @focus="closeAttrPanel = false"
                @blur="
                  (closeAttrPanel = true),
                    (subgroup[subIndex].DataSourceList[
                      dataSourceIndex
                    ].AddedValue = subgroup[subIndex].DataSourceList[dataSourceIndex]
                      .AddedValue
                      ? subgroup[subIndex].DataSourceList[dataSourceIndex].AddedValue
                      : 0)
                "
                @change="changeAddedValue($event)"
              ></el-input-number>
            </div>
            <!-- 数量 -->
            <div>
              <div>{{ $t(`editAttr.attrText41`) }}</div>
              <el-input-number
                v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].Quantity"
                :step="1"
                :min="1"
                :precision="0"
                size="large"
                controls-position="right"
                @focus="closeAttrPanel = false"
                @blur="
                  (closeAttrPanel = true),
                    (subgroup[subIndex].DataSourceList[
                      dataSourceIndex
                    ].Quantity = subgroup[subIndex].DataSourceList[dataSourceIndex]
                      .Quantity
                      ? subgroup[subIndex].DataSourceList[dataSourceIndex].Quantity
                      : 1)
                "
                @change="changeQuantity($event)"
              >
              </el-input-number>
            </div>
          </div>
        </div>
        <!-- 元素值选择 -->
        <div
          class="p-element"
          v-if="
            (subgroup[subIndex].Type == 'WinText' ||
              subgroup[subIndex].Type == 'GeneralBarcode' ||
              subgroup[subIndex].Type == 'QRCode') &&
            subgroup[subIndex].DataSourceList[dataSourceIndex].DataSourceType == 3
          "
        >
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].DataSourceList[dataSourceIndex].ReferenceId"
            :placeholder="$t(`editAttr.attrText36`)"
            @change="elementChange($event)"
          >
            <el-option
              v-for="item in elementList"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 数据列表 -->
        <div
          v-if="
            subgroup[subIndex].Type == 'WinText' ||
            subgroup[subIndex].Type == 'GeneralBarcode' ||
            subgroup[subIndex].Type == 'QRCode'
          "
        >
          <!-- 数据列表 -->
          <div
            ref="combinationBox"
            class="p-combination"
            v-if="subgroup[subIndex].DataSourceList.length > 1"
          >
            <ul>
              <li
                v-for="(list, i) in subgroup[subIndex].DataSourceList"
                :key="i"
                :style="{
                  backgroundColor: dataSourceIndex == i ? 'rgba(0, 53, 145, 0.1)' : '',
                }"
                @click="handleDataSourceList(i)"
              >
                <div class="combination-text">{{ list.FirstValue }}</div>
                <div class="combination-icon" v-show="dataSourceIndex == i">
                  <span class="iconfont icon-plus" @click.stop="dealSourceData(0)"></span>
                  <span
                    class="iconfont icon-minus"
                    @click.stop="dealSourceData(1)"
                  ></span>
                  <span class="iconfont icon-up" @click.stop="dealSourceData(2)"></span>
                  <span class="iconfont icon-down" @click.stop="dealSourceData(3)"></span>
                </div>
              </li>
            </ul>
          </div>
          <!-- 新增数据 -->
          <div v-else class="p-textarea">
            <el-button style="width: 100%" @click="dealSourceData(0)">{{
              $t(`editAttr.attrText102`)
            }}</el-button>
          </div>
        </div>
        <!-- 日期  时间类型 -->
        <div
          class="p-text"
          v-if="subgroup[subIndex].Type == 'TimeText' && subgroup[subIndex].DataType == 0"
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText18`) }}</div>
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].TimeDate"
            @change="timeTypeChange($event)"
            :placeholder="$t(`editAttr.attrText36`)"
          >
            <el-option
              v-for="item in dataTime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 天数偏移 分钟偏移 -->
        <div
          class="p-fonts"
          v-if="subgroup[subIndex].Type == 'TimeText' && subgroup[subIndex].DataType == 0"
        >
          <!-- 天数偏移 -->
          <div style="width: 45%">
            <div class="p-step-title">{{ $t(`editAttr.attrText95`) }}</div>
            <el-input-number
              class="p-text-select"
              v-model="subgroup[subIndex].DayOffset"
              controls-position="right"
              :step="1"
              :precision="0"
              size="large"
              @focus="closeAttrPanel = false"
              @blur="closeAttrPanel = true"
              @change="DateOffsetChange($event, 0)"
            >
            </el-input-number>
          </div>
          <!-- 分钟偏移 -->
          <div style="width: 45%">
            <div class="p-step-title">{{ $t(`editAttr.attrText96`) }}</div>
            <el-input-number
              class="p-text-select"
              v-model="subgroup[subIndex].MinuteOffset"
              controls-position="right"
              :step="1"
              :precision="0"
              size="large"
              @focus="closeAttrPanel = false"
              @blur="closeAttrPanel = true"
              @change="DateOffsetChange($event, 1)"
            >
            </el-input-number>
          </div>
        </div>
        <!-- 数字  数字格式 -->
        <div
          class="p-text"
          v-if="subgroup[subIndex].Type == 'TimeText' && subgroup[subIndex].DataType == 1"
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText42`) }}</div>
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].NumberFormat"
            :placeholder="$t(`editAttr.attrText36`)"
            @change="numFormatChange($event)"
          >
            <el-option
              v-for="item in numberFormat"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 日期格式 -->
        <div
          v-if="subgroup[subIndex].Type == 'TimeText' && subgroup[subIndex].DataType == 0"
        >
          <!-- 日期格式 -->
          <div class="p-text">
            <div class="p-step-title">{{ $t(`editAttr.attrText43`) }}</div>
            <el-select
              class="p-text-select"
              :popper-append-to-body="false"
              v-model="subgroup[this.subIndex].DataFormat"
              @change="dateFormatChange($event)"
              :placeholder="$t(`editAttr.attrText36`)"
            >
              <el-option
                v-for="item in dateFormat"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 日期选择 -->
        <div
          v-if="
            subgroup[subIndex].Type == 'TimeText' &&
            subgroup[subIndex].DataType == 0 &&
            subgroup[subIndex].TimeDate == 1
          "
        >
          <!-- 选择日期 -->
          <div class="p-text">
            <div class="p-step-title">{{ $t(`editAttr.attrText44`) }}</div>
            <el-date-picker
              style="width: 100%"
              type="datetime"
              v-model="subgroup[subIndex].selectTime"
              placeholder="选择日期时间"
              format="YYYY/MM/DD"
              @change="datePickerChange($event)"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- 条码类型 -->
        <div
          class="p-text"
          v-if="
            subgroup[subIndex].Type == 'GeneralBarcode' ||
            subgroup[subIndex].Type == 'QRCode'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText21`) }}</div>
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].BarcodeType"
            :placeholder="$t(`editAttr.attrText36`)"
            @change="codeChange($event)"
          >
            <el-option
              v-for="item in CodeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 纠错率 -->
        <div
          class="p-text"
          v-if="
            (subgroup[subIndex].Type == 'QRCode' &&
              subgroup[subIndex].BarcodeType == 'QR_CODE') ||
            subgroup[subIndex].BarcodeType == 'PDF_417'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText29`) }}</div>
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="subgroup[subIndex].ECLevelBits"
            :placeholder="$t(`editAttr.attrText36`)"
            @change="LevelBitsChange($event)"
          >
            <el-option
              v-for="item in eclevelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 数据位置 -->
        <div class="p-text" v-if="subgroup[subIndex].Type == 'GeneralBarcode'">
          <div class="p-step-title">{{ $t(`editAttr.attrText22`) }}</div>
          <el-select
            class="p-text-select"
            :popper-append-to-body="false"
            v-model="dataLocationValue"
            :placeholder="$t(`editAttr.attrText36`)"
            @change="datashowChange($event)"
          >
            <el-option
              v-for="item in dataLocation"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 缩放倍率 -->
        <div
          class="p-text"
          v-if="
            subgroup[subIndex].Type == 'GeneralBarcode' ||
            subgroup[subIndex].Type == 'QRCode'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText25`) }}</div>
          <el-input-number
            style="width: 100%"
            v-model="subgroup[subIndex].Zoom"
            controls-position="right"
            :step="1"
            :min="2"
            :max="50"
            :precision="0"
            size="large"
            @focus="closeAttrPanel = false"
            @blur="closeAttrPanel = true"
            @change="zoomChange($event)"
          >
          </el-input-number>
        </div>
        <!-- 字体 -->
        <div
          class="p-text"
          v-if="
            subgroup[subIndex].Type == 'WinText' ||
            subgroup[subIndex].Type == 'GeneralBarcode'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText6`) }}</div>
          <el-select
            class="p-text-select"
            v-model="subgroup[subIndex].Font['@FontFamily']"
            @change="fontFamilyChange($event)"
            :placeholder="$t(`editAttr.attrText36`)"
          >
            <el-option
              v-for="item in family"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 字号 样式 -->
        <div
          class="p-fonts"
          v-if="
            subgroup[subIndex].Type == 'WinText' ||
            subgroup[subIndex].Type == 'GeneralBarcode' ||
            subgroup[subIndex].Type == 'TimeText'
          "
        >
          <!-- 字号 -->
          <div>
            <div class="p-step-title">{{ $t(`editAttr.attrText7`) }}</div>
            <el-input-number
              v-model="subgroup[subIndex].Font['@FontSize']"
              controls-position="right"
              :step="1"
              :precision="1"
              :max="100"
              :min="minFontSize"
              size="large"
              @focus="closeAttrPanel = false"
              @blur="
                (closeAttrPanel = true),
                  (subgroup[subIndex].Font['@FontSize'] = subgroup[subIndex].Font[
                    '@FontSize'
                  ]
                    ? subgroup[subIndex].Font['@FontSize']
                    : minFontSize)
              "
              @change="fontSizeChange($event)"
            >
            </el-input-number>
          </div>
          <!-- 样式 -->
          <div>
            <div class="p-step-title">{{ $t(`editAttr.attrText8`) }}</div>
            <div class="p-font-icon">
              <div class="b-icon" @click="handleFontStyle(1)">
                <div
                  :style="{
                    color:
                      subgroup[subIndex].Font['@FontStyle']
                        .split(', ')
                        .filter((ele) => ele == 'Bold') == 'Bold'
                        ? '#003591'
                        : '',
                  }"
                  class="iconfont icon-bolder b-icon-font"
                ></div>
              </div>
              <div class="b-icon" @click="handleFontStyle(2)">
                <div
                  :style="{
                    color:
                      subgroup[subIndex].Font['@FontStyle']
                        .split(', ')
                        .filter((ele) => ele == 'Italic') == 'Italic'
                        ? '#003591'
                        : '',
                  }"
                  class="iconfont icon-xieti b-icon-font"
                ></div>
              </div>
              <div class="b-icon" @click="handleFontStyle(3)">
                <div
                  :style="{
                    color:
                      subgroup[subIndex].Font['@FontStyle']
                        .split(', ')
                        .filter((ele) => ele == 'Underline') == 'Underline'
                        ? '#003591'
                        : '',
                  }"
                  class="iconfont icon-xiahuaxian b-icon-font"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 添加图片 -->
        <div class="p-text" v-if="subgroup[subIndex].Type == 'Image'">
          <div class="p-img-btn" style="margin-top: 24px">
            <el-upload
              action=""
              :on-change="getFile"
              list-type="picture"
              :auto-upload="false"
              accept=".jpeg,.jpg,.png"
            >
              <el-button>{{ $t(`editAttr.attrText30`) }}</el-button>
            </el-upload>
            <div class="p-cloud-btn" style="margin-top: 16px">
              <el-button @click.stop="getFolderList()">{{
                $t(`editAttr.attrText31`)
              }}</el-button>
              <div class="p-cloudImg" v-if="showCloudFolder">
                <div
                  class="p-cloudImg-box"
                  v-for="(item, index) in cloudFileList"
                  :key="index"
                  @click.stop="getList(index, 1)"
                >
                  <div>{{ item.name }}</div>
                </div>
              </div>
              <div class="p-cloudImg" v-if="showCloudDataList">
                <div class="cloudFileBack">
                  <i class="el-icon-arrow-left" @click.stop="cloudFileBack"></i>
                </div>
                <div
                  class="p-cloudImg-box"
                  v-for="(item, index) in cloudDataList"
                  :key="index"
                  @click.stop="chooseImage(index)"
                >
                  <img :src="item.url" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 旋转 -->
        <div
          class="p-textarea"
          v-if="
            subgroup[subIndex].Type == 'WinText' ||
            subgroup[subIndex].Type == 'GeneralBarcode' ||
            subgroup[subIndex].Type == 'Image' ||
            subgroup[subIndex].Type == 'TimeText'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText9`) }}</div>
          <div class="b-rotate">
            <div
              class="rotate"
              @click="handleRotateFixed(0)"
              :style="{ color: rotateIndex == 0 ? '#003591' : '#7b89a1' }"
            >
              0°
            </div>
            <div
              class="rotate"
              :style="{ color: rotateIndex == 90 ? '#003591' : '#7b89a1' }"
              @click="handleRotateFixed(90)"
            >
              90°
            </div>
            <div
              class="rotate"
              :style="{ color: rotateIndex == 180 ? '#003591' : '#7b89a1' }"
              @click="handleRotateFixed(180)"
            >
              180°
            </div>
            <div
              class="rotate"
              :style="{ color: rotateIndex == 270 ? '#003591' : '#7b89a1' }"
              @click="handleRotateFixed(270)"
            >
              270°
            </div>
          </div>
        </div>
        <!-- 对齐 -->
        <div
          class="p-textarea"
          v-if="
            subgroup[subIndex].Type == 'WinText' || subgroup[subIndex].Type == 'TimeText'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText94`) }}</div>
          <div class="b-location">
            <div class="b-icon">
              <div
                @click="(subgroup[subIndex].TextAlign = 0), handleRecord()"
                :style="{
                  color: subgroup[subIndex].TextAlign == 0 ? '#003591' : '',
                }"
                class="iconfont icon-align-left b-icon-font"
              ></div>
            </div>
            <div class="b-icon">
              <div
                @click="(subgroup[subIndex].TextAlign = 1), handleRecord()"
                :style="{
                  color: subgroup[subIndex].TextAlign == 1 ? '#003591' : '',
                }"
                class="iconfont icon-align-center b-icon-font"
              ></div>
            </div>
            <div class="b-icon">
              <div
                @click="(subgroup[subIndex].TextAlign = 2), handleRecord()"
                :style="{
                  color: subgroup[subIndex].TextAlign == 2 ? '#003591' : '',
                }"
                class="iconfont icon-align-right b-icon-font"
              ></div>
            </div>
          </div>
        </div>
        <!-- 等比缩放 适应画布 -->
        <div class="p-text" v-if="subgroup[subIndex].Type == 'Image'">
          <div class="p-img-check">
            <el-checkbox v-model="subgroup[subIndex].ISParticipating">{{
              $t(`editAttr.attrText32`)
            }}</el-checkbox>
            <el-button class="p-img-btn" @click="AdaptCanvas">{{
              $t(`editAttr.attrText46`)
            }}</el-button>
          </div>
        </div>
        <!-- 条码文本位置 -->
        <div class="p-textarea" v-if="subgroup[subIndex].Type == 'GeneralBarcode'">
          <div class="b-location">
            <div
              class="location"
              :style="{ color: locationIndex == 1 ? '#003591' : '' }"
              @click="locationClick(1)"
            >
              {{ $t(`editAttr.attrText26`) }}
            </div>
            <div
              class="location"
              :style="{ color: locationIndex == 2 ? '#003591' : '' }"
              @click="locationClick(2)"
            >
              {{ $t(`editAttr.attrText27`) }}
            </div>
            <div
              class="location"
              :style="{ color: locationIndex == 3 ? '#003591' : '' }"
              @click="locationClick(3)"
            >
              {{ $t(`editAttr.attrText28`) }}
            </div>
          </div>
        </div>
        <!-- 字间距  行间距 -->
        <div class="p-fonts" v-if="subgroup[subIndex].Type == 'WinText'">
          <!-- 字间距 -->
          <div style="width: 45%">
            <div class="p-step-title">{{ $t(`editAttr.attrText10`) }}</div>
            <el-input-number
              class="p-text-select"
              style="width: 100%"
              v-model="subgroup[subIndex].WordSpacing"
              controls-position="right"
              :step="1"
              :min="-5"
              :max="50"
              :precision="0"
              size="large"
              @focus="closeAttrPanel = false"
              @change="handleRecord"
              @blur="
                (closeAttrPanel = true),
                  (subgroup[subIndex].WordSpacing = subgroup[subIndex].WordSpacing
                    ? subgroup[subIndex].WordSpacing
                    : 0)
              "
            >
            </el-input-number>
          </div>
          <!-- 行间距 -->
          <div style="width: 45%">
            <div class="p-step-title">{{ $t(`editAttr.attrText11`) }}</div>
            <el-input-number
              class="p-text-select"
              style="width: 100%"
              v-model="subgroup[subIndex].RowSpacing1"
              controls-position="right"
              :step="1"
              :min="-5"
              :max="50"
              :precision="0"
              size="large"
              @focus="closeAttrPanel = false"
              @change="handleRecord"
              @blur="
                (closeAttrPanel = true),
                  (subgroup[subIndex].RowSpacing1 = subgroup[subIndex].RowSpacing1
                    ? subgroup[subIndex].RowSpacing1
                    : 0)
              "
            >
            </el-input-number>
          </div>
        </div>
        <!-- 自动换行 -->
        <div
          class="p-fonts"
          style="margin-top: 16px"
          v-if="
            subgroup[subIndex].Type == 'WinText' || subgroup[subIndex].Type == 'TimeText'
          "
        >
          <div>
            <el-checkbox v-model="subgroup[subIndex].AutoWrap" @change="handleRecord">{{
              $t(`editAttr.attrText12`)
            }}</el-checkbox>
          </div>
          <div v-if="subgroup[subIndex].AutoWrap">
            <el-input-number
              v-model="subgroup[subIndex].BoxWidth"
              controls-position="right"
              :step="1"
              :precision="2"
              :min="1"
              :max="1200"
              size="large"
              @focus="closeAttrPanel = false"
              @change="handleRecord"
              @blur="
                (closeAttrPanel = true),
                  (subgroup[subIndex].BoxWidth = subgroup[subIndex].BoxWidth
                    ? subgroup[subIndex].BoxWidth
                    : 200)
              "
            >
            </el-input-number>
          </div>
        </div>
        <!-- 镜像  黑反 -->
        <div
          class="p-textarea"
          style="display: flex; align-items: center; justify-content: space-between"
          v-if="subgroup[subIndex].Type == 'WinText'"
        >
          <el-button
            style="width: 50%"
            @click="
              (subgroup[subIndex].MirrorImage = !subgroup[subIndex].MirrorImage),
                handleRecord()
            "
            ><img
              src="../../assets/jingxiang.png"
              style="vertical-align: top"
              class="btn-img"
            />{{ $t(`editAttr.attrText13`) }}</el-button
          >
          <el-button
            style="width: 50%"
            @click="
              (subgroup[subIndex].AntiBlack = !subgroup[subIndex].AntiBlack),
                handleRecord()
            "
            ><img
              src="../../assets/heifan.png"
              style="vertical-align: top"
              class="btn-img"
            />{{ $t(`editAttr.attrText14`) }}</el-button
          >
        </div>
        <!-- 图形线条类型 粗细 -->
        <div
          class="p-text"
          v-if="
            subgroup[subIndex].Type == 'LineVertical' ||
            subgroup[subIndex].Type == 'LineHorizontal' ||
            subgroup[subIndex].Type == 'Line' ||
            subgroup[subIndex].Type == 'Rectangle' ||
            subgroup[subIndex].Type == 'RoundRectangle' ||
            subgroup[subIndex].Type == 'Diamond' ||
            subgroup[subIndex].Type == 'Ellipse' ||
            subgroup[subIndex].Type == 'Triangle' ||
            subgroup[subIndex].Type == 'SolidRectangle'
          "
        >
          <!-- 线条类型 -->
          <div>
            <div class="p-step-title">{{ $t(`editAttr.attrText33`) }}</div>
            <el-select
              class="p-text-select"
              :popper-append-to-body="false"
              v-model="subgroup[subIndex].DashStyle"
              :placeholder="$t(`editAttr.attrText36`)"
            >
              <el-option :value="0" :label="$t(`editAttr.attrText48`)"></el-option>
              <el-option :value="1" :label="$t(`editAttr.attrText49`)"></el-option>
            </el-select>
          </div>
          <!-- 线条粗细 -->
          <div>
            <div class="p-step-title">{{ $t(`editAttr.attrText34`) }}</div>
            <el-input-number
              style="width: 100%"
              controls-position="right"
              v-model="subgroup[subIndex].PenWidth"
              :min="1"
              :max="20"
              :precision="0"
              size="large"
              @focus="closeAttrPanel = false"
              @blur="
                (closeAttrPanel = true),
                  (subgroup[subIndex].PenWidth = subgroup[subIndex].PenWidth
                    ? subgroup[subIndex].PenWidth
                    : 3)
              "
            >
            </el-input-number>
          </div>
          <!-- 圆角半径 -->
          <div v-if="subgroup[subIndex].Type == 'RoundRectangle'">
            <div class="p-step-title">{{ $t(`editAttr.attrText45`) }}</div>
            <el-input-number
              style="width: 100%"
              controls-position="right"
              v-model="subgroup[subIndex].CornerRadius"
              :min="0"
              :max="50"
              :precision="0"
              size="large"
              @focus="closeAttrPanel = false"
              @blur="
                (closeAttrPanel = true),
                  (subgroup[subIndex].CornerRadius = subgroup[subIndex].CornerRadius
                    ? subgroup[subIndex].CornerRadius
                    : 10)
              "
            >
            </el-input-number>
          </div>
        </div>
        <!-- 表格 -->
        <div v-if="subgroup[subIndex].Type == 'Table'">
          <!-- 表格操作 -->
          <div class="p-textarea">
            <div class="b-location">
              <div class="b-icon table-tool">
                <div
                  class="iconfont icon-multiple-choice b-icon-font"
                  :style="{ color: multiple_cell ? '#003591' : '' }"
                  @click="handleMulCell()"
                ></div>
                <div class="align-icon-hover">
                  <div class="triangle_border_up"></div>
                  <div class="align-icon-text">
                    {{ $t(`editAttr.attrText82`) }}
                  </div>
                </div>
              </div>
              <div class="b-icon table-tool">
                <div
                  class="iconfont icon-insert-row b-icon-font"
                  @click="insertRow()"
                ></div>
                <div class="align-icon-hover">
                  <div class="triangle_border_up"></div>
                  <div class="align-icon-text">
                    {{ $t(`editAttr.attrText109`) }}
                  </div>
                </div>
              </div>
              <div class="b-icon table-tool">
                <div
                  class="iconfont icon-insert-cloumn b-icon-font"
                  @click="insertCloumn()"
                ></div>
                <div class="align-icon-hover">
                  <div class="triangle_border_up"></div>
                  <div class="align-icon-text">
                    {{ $t(`editAttr.attrText110`) }}
                  </div>
                </div>
              </div>
              <div class="b-icon table-tool">
                <div
                  class="iconfont icon-delete-row b-icon-font"
                  @click="deleteRow()"
                ></div>
                <div class="align-icon-hover">
                  <div class="triangle_border_up"></div>
                  <div class="align-icon-text">
                    {{ $t(`editAttr.attrText111`) }}
                  </div>
                </div>
              </div>
              <div class="b-icon table-tool">
                <div
                  class="iconfont icon-delete-cloumn b-icon-font"
                  @click="deleteCloumn()"
                ></div>
                <div class="align-icon-hover">
                  <div class="triangle_border_up"></div>
                  <div class="align-icon-text">
                    {{ $t(`editAttr.attrText112`) }}
                  </div>
                </div>
              </div>
              <div
                class="b-icon table-tool"
                v-if="
                  subgroup[subIndex].DataSourceList[cellIndex].hasOwnProperty(
                    'children'
                  ) && !multiple_cell
                "
              >
                <div
                  class="iconfont icon-split b-icon-font"
                  @click="cancelMergeCell()"
                ></div>
                <div class="align-icon-hover">
                  <div class="triangle_border_up"></div>
                  <div class="align-icon-text">
                    {{ $t(`editAttr.attrText114`) }}
                  </div>
                </div>
              </div>
              <div class="b-icon table-tool" v-else>
                <div
                  class="iconfont icon-merge b-icon-font"
                  :style="{
                    color: !multiple_cell ? 'rgba(123, 137, 161, 0.4)' : '',
                  }"
                  @click="mergeCell()"
                ></div>
                <div class="align-icon-hover">
                  <div class="triangle_border_up"></div>
                  <div class="align-icon-text">
                    {{ $t(`editAttr.attrText113`) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="!multiple_cell">
            <!-- 文本数据类型 -->
            <div class="p-text">
              <div class="p-step-title">{{ $t(`editAttr.attrText2`) }}</div>
              <el-select
                class="p-text-select"
                :popper-append-to-body="false"
                v-model="subgroup[subIndex].DataSourceList[cellIndex].DataSourceType"
                :placeholder="$t(`editAttr.attrText36`)"
                @change="tableDataSourceChange($event)"
              >
                <el-option
                  v-for="item in tableDataSource"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 文本内容 -->
            <div
              class="p-textarea"
              v-if="subgroup[subIndex].DataSourceList[cellIndex].DataSourceType == 0"
            >
              <div class="p-step-title">{{ $t(`editAttr.attrText108`) }}</div>
              <el-input
                type="textarea"
                resize="none"
                :rows="3"
                :placeholder="$t(`editAttr.attrText47`)"
                v-model="subgroup[subIndex].DataSourceList[cellIndex].Data"
                @input="handleRecord"
              >
              </el-input>
            </div>
            <!-- 数据库文本 -->
            <div
              class="p-textarea"
              v-if="subgroup[subIndex].DataSourceList[cellIndex].DataSourceType == 1"
              style="display: flex; align-items: center; justify-content: space-between"
            >
              <!-- 连接数据库 -->
              <div style="position: relative; width: 50%">
                <el-button
                  style="width: 100%"
                  @click.stop="
                    showCloudFolder || showCloudDataList || databaseBtn
                      ? ((databaseBtn = false),
                        (showCloudFolder = false),
                        (showCloudDataList = false))
                      : (databaseBtn = true)
                  "
                  icon="el-icon-link"
                >
                  {{ $t(`editAttr.attrText37`) }}</el-button
                >
                <div class="database-btn" v-if="databaseBtn">
                  <div @click.stop="getFolderList()">
                    {{ $t(`editAttr.attrText67`) }}
                  </div>
                  <div @click.stop="getFileUpload">
                    {{ $t(`editAttr.attrText68`) }}
                  </div>
                  <input
                    style="display: none"
                    type="file"
                    name="cover"
                    accept=".xls,.xlsx"
                    @change="getLocalData"
                    id="selectBtn"
                  />
                </div>
                <div class="database-list" v-if="showCloudFolder">
                  <div class="cloudFileBack">
                    <i
                      class="el-icon-arrow-left"
                      @click.stop="
                        (showCloudFolder = false),
                          (showDataList = false),
                          (databaseBtn = true)
                      "
                    ></i>
                  </div>
                  <div
                    class="database-list-box"
                    v-for="(item, index) in cloudFileList"
                    :key="index"
                    @click.stop="getList(index, 2)"
                  >
                    <div>{{ item.name }}</div>
                  </div>
                </div>
                <div class="database-list" v-if="showCloudDataList">
                  <div class="cloudFileBack">
                    <i class="el-icon-arrow-left" @click.stop="cloudFileBack"></i>
                  </div>
                  <div
                    class="database-list-box"
                    v-for="(item, index) in cloudDataList"
                    :key="index"
                    @click.stop="readFile(index)"
                  >
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div>
              <!-- 插入数据 -->
              <el-select
                class="p-text-select"
                :popper-append-to-body="false"
                v-model="subgroup[subIndex].DataSourceList[cellIndex].ColumnName"
                @change="dataColumnChange($event)"
                :placeholder="$t(`editAttr.attrText38`)"
              >
                <el-option
                  v-for="item in database"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 数据库文本的数据类型 -->
            <div v-if="subgroup[subIndex].DataSourceList[cellIndex].DataSourceType == 1">
              <!-- 数据类型 -->
              <div class="p-text">
                <div class="p-step-title">{{ $t(`editAttr.attrText35`) }}</div>
                <el-select
                  class="p-text-select"
                  :popper-append-to-body="false"
                  v-model="subgroup[subIndex].DataSourceList[cellIndex].DataType"
                  @change="dateBaseTypeChange($event)"
                  :placeholder="$t(`editAttr.attrText36`)"
                >
                  <el-option
                    v-for="item in dateBaseType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 日期格式 -->
              <div
                class="p-text"
                v-if="subgroup[subIndex].DataSourceList[cellIndex].DataType == 0"
              >
                <div class="p-step-title">{{ $t(`editAttr.attrText43`) }}</div>
                <el-select
                  class="p-text-select"
                  :popper-append-to-body="false"
                  v-model="subgroup[subIndex].DataSourceList[cellIndex].DataFormat"
                  @change="dateBaseTypeChange($event)"
                  :placeholder="$t(`editAttr.attrText36`)"
                >
                  <el-option
                    v-for="item in dateFormat"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 数字格式 -->
              <div
                class="p-text"
                v-if="subgroup[subIndex].DataSourceList[cellIndex].DataType == 1"
              >
                <div class="p-step-title">{{ $t(`editAttr.attrText42`) }}</div>
                <el-select
                  class="p-text-select"
                  :popper-append-to-body="false"
                  v-model="subgroup[subIndex].DataSourceList[cellIndex].NumberFormat"
                  @change="dateBaseTypeChange($event)"
                  :placeholder="$t(`editAttr.attrText36`)"
                >
                  <el-option
                    v-for="item in numberFormat"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 序列类型 -->
            <div
              class="p-text"
              v-if="subgroup[subIndex].DataSourceList[cellIndex].DataSourceType == 2"
            >
              <div class="p-step-title">{{ $t(`editAttr.attrText92`) }}</div>
              <el-select
                class="p-text-select"
                :popper-append-to-body="false"
                v-model="subgroup[subIndex].DataSourceList[cellIndex].SequenceType"
                :placeholder="$t(`editAttr.attrText36`)"
                @change="sequenceTypeChange($event)"
              >
                <el-option
                  v-for="item in SequenceType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 序列化文本 -->
            <div
              class="p-textarea"
              v-if="subgroup[subIndex].DataSourceList[cellIndex].DataSourceType == 2"
            >
              <div class="p-serialization">
                <!-- 递变数值 -->
                <div class="p-InitialValue">
                  <div>{{ $t(`editAttr.attrText39`) }}</div>
                  <el-input
                    v-model="subgroup[subIndex].DataSourceList[cellIndex].InitialValue"
                    @focus="closeAttrPanel = false"
                    @blur="InitialValueBlur()"
                    @input="changeInitialValue($event)"
                  ></el-input>
                </div>
                <!-- 递变量 -->
                <div>
                  <div>{{ $t(`editAttr.attrText40`) }}</div>
                  <el-input-number
                    v-model="subgroup[subIndex].DataSourceList[cellIndex].AddedValue"
                    :step="1"
                    :precision="0"
                    size="large"
                    controls-position="right"
                    @focus="closeAttrPanel = false"
                    @blur="
                      (closeAttrPanel = true),
                        (subgroup[subIndex].DataSourceList[
                          cellIndex
                        ].AddedValue = subgroup[subIndex].DataSourceList[cellIndex]
                          .AddedValue
                          ? subgroup[subIndex].DataSourceList[cellIndex].AddedValue
                          : 0)
                    "
                    @change="changeAddedValue($event)"
                  ></el-input-number>
                </div>
                <!-- 数量 -->
                <div>
                  <div>{{ $t(`editAttr.attrText41`) }}</div>
                  <el-input-number
                    v-model="subgroup[subIndex].DataSourceList[cellIndex].Quantity"
                    :step="1"
                    :min="1"
                    :precision="0"
                    size="large"
                    controls-position="right"
                    @focus="closeAttrPanel = false"
                    @blur="
                      (closeAttrPanel = true),
                        (subgroup[subIndex].DataSourceList[cellIndex].Quantity = subgroup[
                          subIndex
                        ].DataSourceList[cellIndex].Quantity
                          ? subgroup[subIndex].DataSourceList[cellIndex].Quantity
                          : 1)
                    "
                    @change="changeQuantity($event)"
                  >
                  </el-input-number>
                </div>
              </div>
            </div>
          </template>
          <!-- 表格线条粗细 -->
          <div class="p-text">
            <div class="p-step-title">{{ $t(`editAttr.attrText34`) }}(mm)</div>
            <el-input-number
              style="width: 100%"
              controls-position="right"
              v-model="subgroup[subIndex].PenWidth"
              :step="0.1"
              :min="0.3"
              :max="5"
              :precision="1"
              size="large"
              @focus="closeAttrPanel = false"
              @change="changeTableLine"
              @blur="closeAttrPanel = true"
            >
            </el-input-number>
          </div>
          <!-- 表格行高-->
          <div class="p-text" v-if="!multiple_cell">
            <div class="p-step-title">{{ $t(`editAttr.attrText106`) }}(mm)</div>
            <el-input-number
              style="width: 100%"
              controls-position="right"
              v-model="subgroup[subIndex].DataSourceList[cellIndex].Height"
              :step="0.1"
              :min="1"
              :precision="1"
              size="large"
              @focus="closeAttrPanel = false"
              @change="changeRowHeight"
              @blur="closeAttrPanel = true"
            >
            </el-input-number>
          </div>
          <!-- 表格列宽 -->
          <div class="p-text" v-if="!multiple_cell">
            <div class="p-step-title">{{ $t(`editAttr.attrText107`) }}(mm)</div>
            <el-input-number
              style="width: 100%"
              controls-position="right"
              v-model="subgroup[subIndex].DataSourceList[cellIndex].Width"
              :step="0.1"
              :min="1"
              :precision="1"
              size="large"
              @focus="closeAttrPanel = false"
              @change="changeCloumnWidth"
              @blur="closeAttrPanel = true"
            >
            </el-input-number>
          </div>
          <!-- 字体 -->
          <div class="p-text">
            <div class="p-step-title">{{ $t(`editAttr.attrText6`) }}</div>
            <el-select
              class="p-text-select"
              v-model="subgroup[subIndex].DataSourceList[cellIndex].Font['@FontFamily']"
              @change="fontTableFamilyChange"
              :placeholder="$t(`editAttr.attrText36`)"
            >
              <el-option
                v-for="font in family"
                :key="font.value"
                :label="font.label"
                :value="font.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 字号 样式 -->
          <div class="p-fonts">
            <!-- 字号 -->
            <div>
              <div class="p-step-title">{{ $t(`editAttr.attrText7`) }}</div>
              <el-input-number
                v-model="subgroup[subIndex].DataSourceList[cellIndex].Font['@FontSize']"
                controls-position="right"
                :step="1"
                :max="100"
                :precision="0"
                :min="minFontSize"
                size="large"
                @focus="closeAttrPanel = false"
                @change="fontTableSizeChange"
                @blur="closeAttrPanel = true"
              >
              </el-input-number>
            </div>
            <!-- 样式 -->
            <div>
              <div class="p-step-title">{{ $t(`editAttr.attrText8`) }}</div>
              <div class="p-font-icon">
                <div class="b-icon" @click="handleTableFontStyle(1)">
                  <div
                    :style="{
                      color:
                        subgroup[subIndex].DataSourceList[cellIndex].Font['@FontStyle']
                          .split(', ')
                          .filter((ele) => ele == 'Bold') == 'Bold'
                          ? '#003591'
                          : '',
                    }"
                    class="iconfont icon-bolder b-icon-font"
                  ></div>
                </div>
                <div class="b-icon" @click="handleTableFontStyle(2)">
                  <div
                    :style="{
                      color:
                        subgroup[subIndex].DataSourceList[cellIndex].Font['@FontStyle']
                          .split(', ')
                          .filter((ele) => ele == 'Italic') == 'Italic'
                          ? '#003591'
                          : '',
                    }"
                    class="iconfont icon-xieti b-icon-font"
                  ></div>
                </div>
                <div class="b-icon" @click="handleTableFontStyle(3)">
                  <div
                    :style="{
                      color:
                        subgroup[subIndex].DataSourceList[cellIndex].Font['@FontStyle']
                          .split(', ')
                          .filter((ele) => ele == 'Underline') == 'Underline'
                          ? '#003591'
                          : '',
                    }"
                    class="iconfont icon-xiahuaxian b-icon-font"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 旋转 -->
          <div class="p-textarea">
            <div class="p-step-title">{{ $t(`editAttr.attrText9`) }}</div>
            <div class="b-rotate">
              <div
                class="rotate"
                @click="handleRotateFixed(0)"
                :style="{ color: rotateIndex == 0 ? '#003591' : '#7b89a1' }"
              >
                0°
              </div>
              <div
                class="rotate"
                :style="{ color: rotateIndex == 90 ? '#003591' : '#7b89a1' }"
                @click="handleRotateFixed(90)"
              >
                90°
              </div>
              <div
                class="rotate"
                :style="{ color: rotateIndex == 180 ? '#003591' : '#7b89a1' }"
                @click="handleRotateFixed(180)"
              >
                180°
              </div>
              <div
                class="rotate"
                :style="{ color: rotateIndex == 270 ? '#003591' : '#7b89a1' }"
                @click="handleRotateFixed(270)"
              >
                270°
              </div>
            </div>
          </div>
          <!-- 对齐 -->
          <div class="p-textarea">
            <div class="p-step-title">{{ $t(`editAttr.attrText94`) }}</div>
            <div class="b-location">
              <div class="b-icon">
                <div
                  @click="handleTextAlign(0)"
                  :style="{
                    color:
                      subgroup[subIndex].DataSourceList[cellIndex].TextAlign == 0
                        ? '#003591'
                        : '',
                  }"
                  class="iconfont icon-align-left b-icon-font"
                ></div>
              </div>
              <div class="b-icon">
                <div
                  @click="handleTextAlign(1)"
                  :style="{
                    color:
                      subgroup[subIndex].DataSourceList[cellIndex].TextAlign == 1
                        ? '#003591'
                        : '',
                  }"
                  class="iconfont icon-align-center b-icon-font"
                ></div>
              </div>
              <div class="b-icon">
                <div
                  @click="handleTextAlign(2)"
                  :style="{
                    color:
                      subgroup[subIndex].DataSourceList[cellIndex].TextAlign == 2
                        ? '#003591'
                        : '',
                  }"
                  class="iconfont icon-align-right b-icon-font"
                ></div>
              </div>
            </div>
          </div>
          <!-- 字间距 行间距 -->
          <div class="p-fonts">
            <!-- 字间距 -->
            <div style="width: 45%">
              <div class="p-step-title">{{ $t(`editAttr.attrText10`) }}</div>
              <el-input-number
                class="p-text-select"
                style="width: 100%"
                v-model="subgroup[subIndex].DataSourceList[cellIndex].WordSpacing"
                controls-position="right"
                :step="1"
                :min="-5"
                :max="50"
                :precision="0"
                size="large"
                @focus="closeAttrPanel = false"
                @change="wordSpacingChange"
                @blur="closeAttrPanel = true"
              >
              </el-input-number>
            </div>
            <!-- 行间距 -->
            <div style="width: 45%">
              <div class="p-step-title">{{ $t(`editAttr.attrText11`) }}</div>
              <el-input-number
                class="p-text-select"
                style="width: 100%"
                v-model="subgroup[subIndex].DataSourceList[cellIndex].RowSpacing1"
                controls-position="right"
                :step="1"
                :min="-5"
                :max="50"
                :precision="0"
                size="large"
                @focus="closeAttrPanel = false"
                @change="rowSpacingChange"
                @blur="closeAttrPanel = true"
              >
              </el-input-number>
            </div>
          </div>
          <!-- 镜像  黑反 -->
          <div
            class="p-textarea"
            style="display: flex; align-items: center; justify-content: space-between"
          >
            <el-button style="width: 50%" @click="handleMirror"
              ><img
                src="../../assets/jingxiang.png"
                style="vertical-align: top"
                class="btn-img"
              />{{ $t(`editAttr.attrText13`) }}</el-button
            >
            <el-button style="width: 50%" @click="handleAntiBlack"
              ><img
                src="../../assets/heifan.png"
                style="vertical-align: top"
                class="btn-img"
              />{{ $t(`editAttr.attrText14`) }}</el-button
            >
          </div>
        </div>
        <!-- 非线条位置 -->
        <div
          class="p-step"
          style="height: 250px"
          v-if="
            subgroup[subIndex].Type != 'Line' &&
            subgroup[subIndex].Type != 'LineVertical' &&
            subgroup[subIndex].Type != 'LineHorizontal'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText15`) }}</div>
          <div class="p-step-item">
            <div class="p-step-li">
              <div class="p-step-li-title">X:</div>
              <el-input-number
                v-model="subgroup[subIndex].StartX"
                :precision="2"
                :step="1"
                size="large"
                controls-position="right"
                @focus="closeAttrPanel = false"
                @blur="
                  (closeAttrPanel = true),
                    (subgroup[subIndex].StartX = subgroup[subIndex].StartX
                      ? subgroup[subIndex].StartX
                      : 0)
                "
              ></el-input-number>
            </div>
            <div class="p-step-li">
              <div class="p-step-li-title">Y:</div>
              <el-input-number
                v-model="subgroup[subIndex].StartY"
                :precision="2"
                :step="1"
                size="large"
                controls-position="right"
                @focus="closeAttrPanel = false"
                @blur="
                  (closeAttrPanel = true),
                    (subgroup[subIndex].StartY = subgroup[subIndex].StartY
                      ? subgroup[subIndex].StartY
                      : 0)
                "
              ></el-input-number>
            </div>
          </div>
          <div class="p-step-item">
            <div class="p-step-li">
              <div class="p-step-li-title">W:</div>
              <el-input-number
                v-model="subgroup[subIndex].Width"
                :precision="2"
                :step="1"
                :disabled="subgroup[subIndex].Type != 'Image' ? true : false"
                size="large"
                :min="1"
                controls-position="right"
                @focus="closeAttrPanel = false"
                @blur="
                  (closeAttrPanel = true),
                    (subgroup[subIndex].Width = subgroup[subIndex].Width
                      ? subgroup[subIndex].Width
                      : 0)
                "
              ></el-input-number>
            </div>
            <div class="p-step-li">
              <div class="p-step-li-title">H:</div>
              <el-input-number
                v-model="subgroup[subIndex].Height"
                :precision="2"
                :step="1"
                :disabled="subgroup[subIndex].Type != 'Image' ? true : false"
                size="large"
                :min="1"
                controls-position="right"
                @focus="closeAttrPanel = false"
                @blur="
                  (closeAttrPanel = true),
                    (subgroup[subIndex].Height = subgroup[subIndex].Height
                      ? subgroup[subIndex].Height
                      : 0)
                "
              ></el-input-number>
            </div>
          </div>
        </div>
        <!-- 线条位置 -->
        <div
          class="p-step"
          style="height: 250px"
          v-if="
            subgroup[subIndex].Type == 'Line' ||
            subgroup[subIndex].Type == 'LineVertical' ||
            subgroup[subIndex].Type == 'LineHorizontal'
          "
        >
          <div class="p-step-title">{{ $t(`editAttr.attrText15`) }}</div>
          <div class="p-step-item">
            <div class="p-step-li">
              <div class="p-step-li-title">X:</div>
              <el-input-number
                v-model="subgroup[subIndex].StartX"
                :precision="2"
                :step="1"
                size="large"
                controls-position="right"
                @focus="closeAttrPanel = false"
                @blur="
                  (closeAttrPanel = true),
                    (subgroup[subIndex].StartX = subgroup[subIndex].StartX
                      ? subgroup[subIndex].StartX
                      : 0)
                "
              ></el-input-number>
            </div>
            <div class="p-step-li">
              <div class="p-step-li-title">Y:</div>
              <el-input-number
                v-model="subgroup[subIndex].StartY"
                :precision="2"
                :step="1"
                size="large"
                controls-position="right"
                @focus="closeAttrPanel = false"
                @blur="
                  (closeAttrPanel = true),
                    (subgroup[subIndex].StartY = subgroup[subIndex].StartY
                      ? subgroup[subIndex].StartY
                      : 0)
                "
              ></el-input-number>
            </div>
          </div>
          <div class="p-step-item">
            <div class="p-step-li">
              <div class="p-step-li-title">W:</div>
              <el-input-number
                v-model="subgroup[subIndex].Width"
                :precision="2"
                :step="1"
                :disabled="true"
                size="large"
                controls-position="right"
              ></el-input-number>
            </div>
            <div class="p-step-li">
              <div class="p-step-li-title">H:</div>
              <el-input-number
                v-model="subgroup[subIndex].Height"
                :precision="2"
                :step="1"
                :disabled="true"
                size="large"
                controls-position="right"
              ></el-input-number>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- 画布操作区域 -->
    <div class="edit-print-box" id="printBox" @mousedown="handleMoreSelect">
      <!-- 画布标尺 -->
      <SketchRule
        v-show="showCanvas"
        id="editRule"
        :thick="state.thick"
        :scale="state.scale"
        :width="state.width"
        :height="state.height"
        :start-x="state.startX"
        :start-y="state.startY"
        :palette="state.palette"
        :corner-active="true"
        :lines="state.lines"
      >
      </SketchRule>
      <!-- 多选框选 -->
      <div
        class="edit-print-select"
        v-show="printSelectFlag"
        :style="{
          width: printSelect.width + 'px',
          height: printSelect.height + 'px',
          left: printSelect.x + 'px',
          top: printSelect.y + 'px',
        }"
      ></div>
      <div
        class="design"
        id="design"
        ref="imageDom"
        @contextmenu.prevent="showRightMenu"
        :style="{
          width: template.width + 'mm',
          height: template.height + 'mm',
          left: template.x + 'mm',
          top: template.y + 'mm',
          background: showCanvas ? '#fff' : 'none',
          visibility: isGetSize ? 'visible' : 'hidden',
          transform: 'scale(' + multiple + ')',
        }"
      >
        <!-- 背景图片 -->
        <img
          v-if="template.Background"
          :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            width: template.width + 'mm',
            height: template.height + 'mm',
          }"
          :src="imgHeader + template.Background"
          alt=""
        />
        <template v-for="(item, index) in subgroup" :key="index">
          <!-- 水平线 -->
          <div v-if="item.Type == 'LineHorizontal'">
            <div
              :ref="'print' + index"
              @click.stop="handleSelect(index, $event)"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.Width + 'mm',
                height: item.PenWidth / conversion + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                style="width: 100%; height: 100%; position: absolute"
                @mousedown="handleSubgroup($event, index, 1)"
              >
                <line
                  :x1="0"
                  :y1="item.PenWidth / conversion / 2 + 'mm'"
                  :x2="item.Width + 'mm'"
                  :y2="item.PenWidth / conversion / 2 + 'mm'"
                  style="fill: none; stroke: black"
                  :stroke-dasharray="
                    item.DashStyle == 0 ? '0' : item.PenWidth / conversion + 'mm'
                  "
                  :style="{
                    strokeWidth: item.PenWidth / conversion + 'mm',
                  }"
                />
              </svg>
              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="drag-css unit-left"
                v-if="index == subIndex"
                :style="{
                  background: item.Lock ? 'rgb(123, 137, 161)' : 'rgba(0, 53, 145, 1)',
                  borderRadius: '50%',
                  left: '-3px',
                  bottom: 'calc(50% - 3px)',
                  width: '6px',
                  height: '6px',
                }"
                @mousedown="handleSubgroup($event, index, 3)"
              ></div>
              <div
                class="drag-css unit-right"
                :style="{
                  background: item.Lock ? 'rgb(123, 137, 161)' : 'rgba(0, 53, 145, 1)',
                  borderRadius: '50%',
                  right: '-3px',
                  bottom: 'calc(50% - 3px)',
                  width: '6px',
                  height: '6px',
                }"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 5)"
              ></div>
            </div>
          </div>
          <!-- 垂直线 -->
          <div v-if="item.Type == 'LineVertical'">
            <div
              :ref="'print' + index"
              @click.stop="handleSelect(index, $event)"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.PenWidth / conversion + 'mm',
                height: item.Height + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                style="width: 100%; height: 100%; position: absolute"
                @mousedown="handleSubgroup($event, index, 1)"
              >
                <line
                  :x1="item.PenWidth / conversion / 2 + 'mm'"
                  :y1="0"
                  :x2="item.PenWidth / conversion / 2 + 'mm'"
                  :y2="item.Height + 'mm'"
                  style="fill: none; stroke: black"
                  :stroke-dasharray="
                    item.DashStyle == 0 ? '0' : item.PenWidth / conversion + 'mm'
                  "
                  :style="{
                    strokeWidth: item.PenWidth / conversion + 'mm',
                  }"
                />
              </svg>
              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="drag-css unit-top"
                :style="{
                  background: item.Lock ? 'rgb(123, 137, 161)' : 'rgba(0, 53, 145, 1)',
                  borderRadius: '50%',
                  right: 'calc(50% - 3px)',
                  top: '-3px',
                  width: '6px',
                  height: '6px',
                }"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 4)"
              ></div>
              <div
                class="drag-css unit-bottom"
                :style="{
                  background: item.Lock ? 'rgb(123, 137, 161)' : 'rgba(0, 53, 145, 1)',
                  borderRadius: '50%',
                  right: 'calc(50% - 3px)',
                  bottom: '-3px',
                  width: '6px',
                  height: '6px',
                }"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 6)"
              ></div>
            </div>
          </div>
          <!-- 斜线 -->
          <div v-if="item.Type == 'Line'">
            <div
              :ref="'print' + index"
              class="edit-print-li"
              :class="returnClass(index)"
              @click.stop="handleSelect(index, $event)"
              :style="{
                zIndex: item.zOrder,
                width: item.Width + 'mm',
                height: item.Height + 'mm',
                left: item.EndX < item.StartX ? item.EndX + 'mm' : item.StartX + 'mm',
                top: item.EndY < item.StartY ? item.EndY + 'mm' : item.StartY + 'mm',
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                style="width: 100%; height: 100%; position: absolute"
                @mousedown="handleSubgroup($event, index, 1)"
              >
                <line
                  :x1="
                    item.EndX < item.StartX
                      ? item.Width - item.PenWidth / conversion / 2 + 'mm'
                      : item.PenWidth / conversion / 2 + 'mm'
                  "
                  :y1="
                    item.EndY < item.StartY
                      ? item.Height - item.PenWidth / conversion / 2 + 'mm'
                      : item.PenWidth / conversion / 2 + 'mm'
                  "
                  :x2="
                    item.EndX < item.StartX
                      ? item.PenWidth / conversion / 2 + 'mm'
                      : item.Width - item.PenWidth / conversion / 2 + 'mm'
                  "
                  :y2="
                    item.EndY < item.StartY
                      ? item.PenWidth / conversion / 2 + 'mm'
                      : item.Height - item.PenWidth / conversion / 2 + 'mm'
                  "
                  style="fill: none; stroke: black"
                  :stroke-dasharray="
                    item.DashStyle == 0 ? '0' : item.PenWidth / conversion + 'mm'
                  "
                  :style="{
                    strokeWidth: item.PenWidth / conversion + 'mm',
                  }"
                />
              </svg>
              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="bigs-icon"
                :style="{
                  background: item.Lock ? 'rgb(123, 137, 161)' : 'rgba(0, 53, 145, 1)',
                  right:
                    item.EndX < item.StartX
                      ? item.Width - (8 * 25.4) / 96 / 2 + 'mm'
                      : (-8 * 25.4) / 96 / 2 + 'mm',
                  bottom:
                    item.EndY < item.StartY
                      ? item.Height - (8 * 25.4) / 96 / 2 + 'mm'
                      : (-8 * 25.4) / 96 / 2 + 'mm',
                  width: '8px',
                  height: '8px',
                }"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 7)"
              ></div>
            </div>
          </div>
          <!-- 图片、矩形、圆角矩形、实心矩形 -->
          <div
            v-if="
              item.Type == 'Image' ||
              item.Type == 'Rectangle' ||
              item.Type == 'RoundRectangle' ||
              item.Type == 'SolidRectangle'
            "
          >
            <div
              @click.stop="handleSelect(index, $event)"
              :ref="'print' + index"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.Width + 'mm',
                height: item.Height + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
                transform: 'rotate(' + item.AngleRound + 'deg)',
                transformOrigin: '0 0',
              }"
            >
              <!-- 图片 -->
              <img
                v-if="item.Type == 'Image'"
                :src="imgHeader + item.OriginalImage"
                @mousedown="handleSubgroup($event, index, 1)"
              />
              <!-- 矩形 -->
              <svg
                v-if="item.Type == 'Rectangle'"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                style="width: 100%; height: 100%; position: absolute"
                @mousedown="handleSubgroup($event, index, 1)"
              >
                <rect
                  :x="item.PenWidth / 2 / conversion + 'mm'"
                  :y="item.PenWidth / 2 / conversion + 'mm'"
                  :width="item.Width - item.PenWidth / conversion + 'mm'"
                  :height="item.Height - item.PenWidth / conversion + 'mm'"
                  style="fill: none; stroke: black"
                  :stroke-dasharray="
                    item.DashStyle == 0 ? '0' : item.PenWidth / conversion + 'mm'
                  "
                  :style="{
                    strokeWidth: item.PenWidth / conversion + 'mm',
                  }"
                />
              </svg>
              <!-- 圆角矩形 -->
              <svg
                v-if="item.Type == 'RoundRectangle'"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                style="width: 100%; height: 100%; position: absolute"
                @mousedown="handleSubgroup($event, index, 1)"
              >
                <rect
                  :x="item.PenWidth / 2 / conversion + 'mm'"
                  :y="item.PenWidth / 2 / conversion + 'mm'"
                  :width="item.Width - item.PenWidth / conversion + 'mm'"
                  :height="item.Height - item.PenWidth / conversion + 'mm'"
                  :rx="item.CornerRadius / conversion + 'mm'"
                  :ry="item.CornerRadius / conversion + 'mm'"
                  style="fill: none; stroke: black"
                  :stroke-dasharray="
                    item.DashStyle == 0 ? '0' : item.PenWidth / conversion + 'mm'
                  "
                  :style="{
                    strokeWidth: item.PenWidth / conversion + 'mm',
                  }"
                />
              </svg>
              <!-- 实心矩形 -->
              <div
                v-if="item.Type == 'SolidRectangle'"
                class="edit-print-shape"
                @mousedown="handleSubgroup($event, index, 1)"
                :style="{
                  background: '#000',
                  borderWidth: item.PenWidth / conversion + 'mm',
                  borderStyle: item.DashStyle == 0 ? 'solid' : 'dashed',
                }"
              ></div>
              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="bigs-icon"
                v-if="index == subIndex && !item.Lock"
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 'ne-resize'
                      : 'nw-resize',
                }"
                @mousedown="handleSubgroup($event, index, 2)"
              ></div>
              <div
                class="drag-css unit-left"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 's-resize'
                      : 'w-resize',
                }"
                @mousedown="handleSubgroup($event, index, 3)"
              ></div>
              <div
                class="drag-css unit-top"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 'w-resize'
                      : 's-resize',
                }"
                @mousedown="handleSubgroup($event, index, 4)"
              ></div>
              <div
                class="drag-css unit-right"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 's-resize'
                      : 'w-resize',
                }"
                @mousedown="handleSubgroup($event, index, 5)"
              ></div>
              <div
                class="drag-css unit-bottom"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 'w-resize'
                      : 's-resize',
                }"
                @mousedown="handleSubgroup($event, index, 6)"
              ></div>
            </div>
          </div>
          <!-- 条形码、二维码 -->
          <div v-if="item.Type == 'GeneralBarcode' || item.Type == 'QRCode'">
            <div
              @click.stop="handleSelect(index, $event)"
              :ref="'print' + index"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.Width + 'mm',
                height: item.Height + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
                transform: 'rotate(' + item.AngleRound + 'deg)',
                transformOrigin: '0 0',
              }"
            >
              <img
                v-if="item.BarcodeImage"
                :src="imgHeader + item.BarcodeImage"
                @mousedown="handleSubgroup($event, index, 1)"
              />
              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="bigs-icon"
                v-if="index == subIndex && !item.Lock && !item.badBarcode"
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 'ne-resize'
                      : 'nw-resize',
                }"
                @mousedown="handleSubgroup($event, index, 2)"
              ></div>
              <div
                class="drag-css unit-left"
                v-if="
                  index == subIndex &&
                  !item.ISParticipating &&
                  item.Type != 'QRCode' &&
                  !item.Lock &&
                  !item.badBarcode
                "
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 's-resize'
                      : 'w-resize',
                }"
                @mousedown="handleSubgroup($event, index, 3)"
              ></div>
              <div
                class="drag-css unit-top"
                v-if="
                  index == subIndex &&
                  !item.ISParticipating &&
                  item.Type != 'QRCode' &&
                  !item.Lock &&
                  !item.badBarcode
                "
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 'w-resize'
                      : 's-resize',
                }"
                @mousedown="handleSubgroup($event, index, 4)"
              ></div>
              <div
                class="drag-css unit-right"
                v-if="
                  index == subIndex &&
                  !item.ISParticipating &&
                  item.Type != 'QRCode' &&
                  !item.Lock &&
                  !item.badBarcode
                "
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 's-resize'
                      : 'w-resize',
                }"
                @mousedown="handleSubgroup($event, index, 5)"
              ></div>
              <div
                class="drag-css unit-bottom"
                v-if="
                  index == subIndex &&
                  !item.ISParticipating &&
                  item.Type != 'QRCode' &&
                  !item.Lock &&
                  !item.badBarcode
                "
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 'w-resize'
                      : 's-resize',
                }"
                @mousedown="handleSubgroup($event, index, 6)"
              ></div>
            </div>
          </div>
          <!-- 文本时间 -->
          <div v-if="item.Type == 'WinText' || item.Type == 'TimeText'">
            <div
              @click.stop="handleSelect(index, $event)"
              :ref="'print' + index"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.AutoWrap ? item.BoxWidth + 'mm' : item.Width + 'mm',
                height: item.Height + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
                transform: 'rotate(' + item.AngleRound + 'deg)',
                transformOrigin: '0 0',
              }"
            >
              <EditDiv
                :data="item"
                :index="index"
                v-model:value="item.Data"
                @getDiv="getDivWidth"
              >
              </EditDiv>
              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="!item.Lock"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="bigs-icon"
                v-if="index == subIndex && !item.Lock"
                :style="{
                  cursor:
                    item.AngleRound == 90 || item.AngleRound == 270
                      ? 'ne-resize'
                      : 'nw-resize',
                }"
                @mousedown="handleSubgroup($event, index, 2)"
              ></div>
            </div>
          </div>
          <!-- 椭圆 -->
          <div v-if="item.Type == 'Ellipse'">
            <div
              @click.stop="handleSelect(index, $event)"
              :ref="'print' + index"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.Width + 'mm',
                height: item.Height + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
                transform: 'rotate(' + item.AngleRound + 'deg)',
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                :style="{
                  width: 'calc(100% + ' + (2 * item.PenWidth) / conversion + 'mm)',
                  height: 'calc(100% + ' + (2 * item.PenWidth) / conversion + 'mm)',
                }"
                @mousedown="handleSubgroup($event, index, 1)"
              >
                <ellipse
                  :cx="item.Width / 2 + 'mm'"
                  :cy="item.Height / 2 + 'mm'"
                  :rx="(item.Width - (2 * item.PenWidth) / conversion) / 2 + 'mm'"
                  :ry="(item.Height - (2 * item.PenWidth) / conversion) / 2 + 'mm'"
                  style="fill: none; stroke: black"
                  :stroke-dasharray="
                    item.DashStyle == 0 ? '0' : item.PenWidth / conversion + 'mm'
                  "
                  :style="{
                    strokeWidth: item.PenWidth / conversion + 'mm',
                  }"
                />
              </svg>

              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex && !item.Lock"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="bigs-icon"
                v-if="index == subIndex && !item.Lock"
                @mousedown="handleSubgroup($event, index, 2)"
              ></div>
              <div
                class="drag-css unit-left"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 3)"
              ></div>
              <div
                class="drag-css unit-top"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 4)"
              ></div>
              <div
                class="drag-css unit-right"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 5)"
              ></div>
              <div
                class="drag-css unit-bottom"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 6)"
              ></div>
            </div>
          </div>
          <!-- 菱形 -->
          <div v-if="item.Type == 'Diamond'">
            <div
              @click.stop="handleSelect(index, $event)"
              :ref="'print' + index"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.Width + 'mm',
                height: item.Height + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100%"
                height="100%"
                @mousedown="handleSubgroup($event, index, 1)"
                preserveAspectRatio="none"
              >
                <polygon
                  :points="
                    (
                      ((item.Vertice[3]['@X'] / conversion -
                        item.StartX +
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString() +
                    ',' +
                    (
                      ((item.Vertice[3]['@Y'] / conversion - item.StartY) * 96) /
                      25.4
                    ).toString() +
                    ' ' +
                    (
                      ((item.Vertice[0]['@X'] / conversion - item.StartX) * 96) /
                      25.4
                    ).toString() +
                    ',' +
                    (
                      ((item.Vertice[0]['@Y'] / conversion -
                        item.StartY +
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString() +
                    ' ' +
                    (
                      ((item.Vertice[1]['@X'] / conversion -
                        item.StartX -
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString() +
                    ',' +
                    (
                      ((item.Vertice[1]['@Y'] / conversion - item.StartY) * 96) /
                      25.4
                    ).toString() +
                    ' ' +
                    (
                      ((item.Vertice[2]['@X'] / conversion - item.StartX) * 96) /
                      25.4
                    ).toString() +
                    ',' +
                    (
                      ((item.Vertice[2]['@Y'] / conversion -
                        item.StartY -
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString()
                  "
                  style="fill: none; stroke: black"
                  :stroke-dasharray="
                    item.DashStyle == 0 ? '0' : item.PenWidth / conversion + 'mm'
                  "
                  :style="{
                    strokeWidth: item.PenWidth / conversion + 'mm',
                  }"
                />
              </svg>

              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex && !item.Lock"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="bigs-icon"
                v-if="index == subIndex && !item.Lock"
                @mousedown="handleSubgroup($event, index, 2)"
              ></div>
              <div
                class="drag-css unit-left"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 3)"
              ></div>
              <div
                class="drag-css unit-top"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 4)"
              ></div>
              <div
                class="drag-css unit-right"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 5)"
              ></div>
              <div
                class="drag-css unit-bottom"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 6)"
              ></div>
            </div>
          </div>
          <!-- 三角形 -->
          <div v-if="item.Type == 'Triangle'">
            <div
              @click.stop="handleSelect(index, $event)"
              :ref="'print' + index"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.Width + 'mm',
                height: item.Height + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="100%"
                height="100%"
                preserveAspectRatio="none"
                @mousedown="handleSubgroup($event, index, 1)"
              >
                <polygon
                  :points="
                    (
                      ((item.Vertice[2]['@X'] / conversion - item.StartX) * 96) /
                      25.4
                    ).toString() +
                    ',' +
                    (
                      ((item.Vertice[2]['@Y'] / conversion -
                        item.StartY +
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString() +
                    ' ' +
                    (
                      ((item.Vertice[1]['@X'] / conversion -
                        item.StartX -
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString() +
                    ',' +
                    (
                      ((item.Vertice[1]['@Y'] / conversion -
                        item.StartY -
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString() +
                    ' ' +
                    (
                      ((item.Vertice[0]['@X'] / conversion -
                        item.StartX +
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString() +
                    ',' +
                    (
                      ((item.Vertice[0]['@Y'] / conversion -
                        item.StartY -
                        item.PenWidth / conversion) *
                        96) /
                      25.4
                    ).toString()
                  "
                  style="fill: none; stroke: black"
                  :stroke-dasharray="
                    item.DashStyle == 0 ? '0' : item.PenWidth / conversion + 'mm'
                  "
                  :style="{
                    strokeWidth: item.PenWidth / conversion + 'mm',
                  }"
                />
              </svg>
              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex && !item.Lock"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="bigs-icon"
                v-if="index == subIndex && !item.Lock"
                @mousedown="handleSubgroup($event, index, 2)"
              ></div>
              <div
                class="drag-css unit-left"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 3)"
              ></div>
              <div
                class="drag-css unit-top"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 4)"
              ></div>
              <div
                class="drag-css unit-right"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 5)"
              ></div>
              <div
                class="drag-css unit-bottom"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 6)"
              ></div>
            </div>
          </div>
          <!-- 表格 -->
          <div v-if="item.Type == 'Table'">
            <div
              @click.stop="handleSelect(index, $event)"
              :ref="'print' + index"
              :id="'print' + index"
              class="edit-print-li"
              :class="returnClass(index)"
              :style="{
                zIndex: item.zOrder,
                width: item.Width + 'mm',
                height: item.Height + 'mm',
                left: item.StartX + 'mm',
                top: item.StartY + 'mm',
                transform: 'rotate(' + item.AngleRound + 'deg)',
                transformOrigin: '0 0',
              }"
            >
              <div
                @mousedown="handleSubgroup($event, index, 1)"
                style="width: 100%; height: 100%; position: relative; background: #000"
              >
                <div
                  style="position: absolute; overflow: hidden"
                  v-for="(cell, c_Index) in item.DataSourceList"
                  :key="c_Index"
                  :class="mulCellClass(cell, index)"
                  :style="{
                    width: cell.Width + 'mm',
                    height: cell.Height + 'mm',
                    left: cell.StartX + 'mm',
                    top: cell.StartY + 'mm',
                    background: cell.AntiBlack
                      ? '#000'
                      : subIndex == index && cellIndex == c_Index
                      ? 'rgb(188 213 235)'
                      : '#fff',
                  }"
                >
                  <EditDiv
                    style="
                      text-shadow: none;
                      display: flex;
                      align-items: center;
                      flex-wrap: wrap;
                    "
                    :style="{
                      color: cell.AntiBlack ? '#fff' : '#000',
                      justifyContent:
                        cell.TextAlign == 1
                          ? 'center'
                          : cell.TextAlign == 2
                          ? 'flex-end'
                          : 'flex-start',
                    }"
                    :data="cell"
                    v-model:value="cell.Data"
                  >
                  </EditDiv>
                </div>
              </div>
              <div
                class="blueborder"
                v-if="index == subIndex"
                :style="{
                  border: item.Lock ? '1px solid #7b89a1' : '1px solid #003591',
                }"
              ></div>
              <div
                class="drag-icon"
                v-if="index == subIndex && !item.Lock"
                @mousedown="handleSubgroup($event, index, 1)"
                @contextmenu.prevent="showRightMenu"
              ></div>
              <div
                class="drag-css unit-right"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 5)"
              ></div>
              <div
                class="drag-css unit-bottom"
                v-if="index == subIndex && !item.ISParticipating && !item.Lock"
                @mousedown="handleSubgroup($event, index, 6)"
              ></div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 右键菜单 -->
    <div
      class="right-menu"
      v-if="isShowMenu"
      :style="{
        top: rightMenuY + 'px',
        left: rightMenuX + 'px',
      }"
    >
      <div class="right-menu0" @mousemove="(isShowAlign = false), (isShowOrder = false)">
        <div class="right-menu-item" @click="handleAllGroup()">
          <div>{{ $t(`editAttr.attrText78`) }}</div>
          <div>Ctrl+A</div>
        </div>
      </div>
      <div class="right-menu1" @mousemove="(isShowAlign = false), (isShowOrder = false)">
        <div
          class="right-menu-item"
          @click="subIndex > -1 || selectUnit.length > 0 ? handleOperate(0) : ''"
          :style="{
            color: subIndex > -1 || selectUnit.length > 0 ? '#535d6c' : '#d6dce6',
          }"
        >
          <div>{{ $t(`editAttr.attrText50`) }}</div>
          <div>Ctrl+C</div>
        </div>
        <div
          class="right-menu-item"
          @click="copyData.length > 0 ? handleOperate(1) : ''"
          :style="{ color: copyData.length > 0 ? '#535d6c' : '#d6dce6' }"
        >
          <div>
            {{ $t(`editAttr.attrText51`) }}
          </div>
          <div>Ctrl+V</div>
        </div>
      </div>
      <div class="right-menu2">
        <div
          v-if="!Lock"
          class="right-menu-item"
          @click="subIndex > -1 && selectUnit.length == 0 ? handleHistory(0) : ''"
          @mousemove="(isShowAlign = false), (isShowOrder = false)"
          :style="{
            color: subIndex > -1 && selectUnit.length == 0 ? '#535d6c' : '#d6dce6',
          }"
        >
          {{ $t(`editAttr.attrText52`) }}
        </div>
        <div
          v-else
          class="right-menu-item"
          @click="handleHistory(0)"
          @mousemove="(isShowAlign = false), (isShowOrder = false)"
        >
          {{ $t(`editAttr.attrText53`) }}
        </div>
        <div
          class="right-menu-item right-menu-item-align"
          @mousemove="
            subIndex > -1 || selectUnit.length > 0
              ? ((isShowAlign = true), (isShowOrder = false))
              : ''
          "
          :style="{
            background: isShowAlign ? 'rgba(0, 53, 145, 0.1)' : '',
            color: subIndex > -1 || selectUnit.length > 0 ? '#535d6c' : '#d6dce6',
          }"
        >
          {{ $t(`editAttr.attrText54`) }}
          <i class="el-icon-caret-right"></i>
        </div>
        <div
          class="right-menu-item"
          :style="{
            background: isShowOrder ? 'rgba(0, 53, 145, 0.1)' : '',
            color: subIndex > -1 || selectUnit.length > 0 ? '#535d6c' : '#d6dce6',
          }"
          @mousemove="
            subIndex > -1 || selectUnit.length > 0
              ? ((isShowOrder = true), (isShowAlign = false))
              : ''
          "
        >
          {{ $t(`editAttr.attrText63`) }}<i class="el-icon-caret-right"></i>
        </div>
        <div class="right-align-menu" v-if="isShowAlign">
          <div
            class="right-menu-item"
            @click="handleSet(0)"
            :style="{ color: selectUnit.length > 0 ? '#535d6c' : '#d6dce6' }"
          >
            {{ $t(`editAttr.attrText55`) }}
          </div>
          <div
            class="right-menu-item"
            @click="handleSet(1)"
            :style="{ color: selectUnit.length > 0 ? '#535d6c' : '#d6dce6' }"
          >
            {{ $t(`editAttr.attrText56`) }}
          </div>
          <div
            class="right-menu-item"
            @click="handleSet(2)"
            :style="{ color: selectUnit.length > 0 ? '#535d6c' : '#d6dce6' }"
          >
            {{ $t(`editAttr.attrText57`) }}
          </div>
          <div
            class="right-menu-item"
            @click="handleSet(4)"
            :style="{ color: selectUnit.length > 0 ? '#535d6c' : '#d6dce6' }"
          >
            {{ $t(`editAttr.attrText58`) }}
          </div>
          <div
            class="right-menu-item"
            @click="handleSet(3)"
            :style="{ color: selectUnit.length > 0 ? '#535d6c' : '#d6dce6' }"
          >
            {{ $t(`editAttr.attrText59`) }}
          </div>
          <div
            class="right-menu-item"
            @click="handleSet(5)"
            :style="{ color: selectUnit.length > 0 ? '#535d6c' : '#d6dce6' }"
          >
            {{ $t(`editAttr.attrText60`) }}
          </div>
          <div
            class="right-menu-item"
            @click="handleSet(6)"
            :style="{ color: selectUnit.length == 0 ? '#535d6c' : '#d6dce6' }"
          >
            {{ $t(`editAttr.attrText61`) }}
          </div>
          <div
            class="right-menu-item"
            @click="handleSet(7)"
            :style="{ color: selectUnit.length == 0 ? '#535d6c' : '#d6dce6' }"
          >
            {{ $t(`editAttr.attrText62`) }}
          </div>
        </div>
        <div class="right-order-menu" v-if="isShowOrder">
          <div class="right-menu-item" @click="handleOrder(1)">
            {{ $t(`editAttr.attrText64`) }}
          </div>
          <div class="right-menu-item" @click="handleOrder(0)">
            {{ $t(`editAttr.attrText65`) }}
          </div>
        </div>
      </div>
      <div class="right-menu3" @mousemove="(isShowAlign = false), (isShowOrder = false)">
        <div class="right-menu-item" @click="handleOpenSet()">
          {{ $t(`button.labelset`) }}
        </div>
        <div
          class="right-menu-item right-menu-del"
          @click="subIndex > -1 ? handleOperate(2) : ''"
          :style="{ color: subIndex > -1 ? '#f73e3e' : '#d6dce6' }"
        >
          <div>{{ $t(`editAttr.attrText66`) }}</div>
          <div>Delete</div>
        </div>
      </div>
    </div>
    <!-- 底部菜单 -->
    <div
      class="print-bottom"
      :style="{
        right:
          subgroup.length > 0 && subIndex > -1
            ? screenWidth > 1300
              ? '380px'
              : '260px'
            : '20px',
      }"
    >
      <!-- 新手引导、查看快捷键 -->
      <div class="print-bottom-popover">
        <el-popover placement="top" width="200" v-model:visible="visible">
          <div>
            <div
              class="print-bottom-shortcutKey-menu"
              style="border-bottom: 1px solid #d6dce6"
              @click="(visible = false), (showShortcutKey = true)"
            >
              {{ $t(`editAttr.attrText87`) }}
            </div>
            <div
              class="print-bottom-shortcutKey-menu"
              @click="(visible = false), (showEditGuide = true)"
            >
              {{ $t(`editAttr.attrText86`) }}
            </div>
          </div>
          <template v-slot:reference>
            <div class="print-bottom-shortcutKey" @click.stop="showShortcutKey = false">
              <div class="iconfont icon-bangzhu"></div>
              <!-- <img src="../../assets/help.png" alt=""> -->
            </div>
          </template>
        </el-popover>
        <!-- 快捷键说明 -->
        <div
          class="shortcutKey-menu"
          v-show="showShortcutKey"
          :style="{
            width:
              screenWidth > 1300
                ? language == 'zh'
                  ? '320px'
                  : '380px'
                : language == 'zh'
                ? '223px'
                : '293px',
          }"
        >
          <div class="shortcutKey-title">{{ $t(`editAttr.attrText88`) }}</div>
          <div class="shortcutKey-menuMain">
            <div
              class="shortcutKey-item"
              v-for="(item, index) in shortcutKey"
              :key="index"
            >
              <div>{{ item.name }}</div>
              <div class="shortcutKey-item-key">{{ item.key }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 适应画布、画布缩放 -->
      <div class="print-bottom-btn">
        <div class="print-bottom-btn-img0">
          <div class="iconfont icon-yibiyi" @click="adaptTemplate"></div>
          <div class="print-bottom-hover">
            <div class="print-bottom-hover-text">
              {{ $t(`editAttr.attrText89`) }}
            </div>
            <div class="print-bottom-hover-triangle"></div>
          </div>
        </div>
        <div class="print-bottom-btn-img">
          <div class="iconfont icon-suoxiao" @click="handleNarrow"></div>
          <div class="print-bottom-hover">
            <div class="print-bottom-hover-text">
              {{ $t(`editAttr.attrText90`) }}
            </div>
            <div class="print-bottom-hover-triangle"></div>
          </div>
        </div>
        <el-slider
          class="print-bottom-btn-slider"
          v-model="sliderValue"
          :min="0"
          :max="100"
          :step="1"
          @change="changeSlider"
        ></el-slider>
        <div class="print-bottom-btn-img">
          <div class="iconfont icon-fangda" @click="handleEnlarge"></div>
          <div class="print-bottom-hover">
            <div class="print-bottom-hover-text">
              {{ $t(`editAttr.attrText91`) }}
            </div>
            <div class="print-bottom-hover-triangle"></div>
          </div>
        </div>
      </div>
    </div>

    <Rename ref="renameRef" @rename="handleRename" />
    <CoverFile ref="coverFileRef" @refreshFile="refreshFile" />
    <CreateLabel
      ref="createLabelRef"
      type="edit"
      :dataType="Number(listType)"
      :data="listType == 1 ? labelList : jsonData"
      :labelName="labelList.name"
      @set="handleSetLabel"
    />
    <PrintLabel
      v-if="printLabel"
      :fromEdit="true"
      :labelData="labelData"
      :labelDetail="labelList"
      @cancel="printLabel = false"
    />
    <EditGuide v-if="showEditGuide && showCanvas" @close="closeEditGuide" />
    <MemberDia ref="MemberDiaRef" />
  </div>
</template>
<script>
import MemberDia from "@/components/MemberDia.vue";
import JsBarcode from "jsbarcode";
import EditDiv from "@/components/EditDiv";
import QRCode from "qrcodejs2";
import { message } from "ant-design-vue";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import Rename from "@/components/Rename.vue";
import axios from "axios/dist/axios.min.js";
import { fontUrlArr } from "@/utils/fontFamily";
import CoverFile from "@/components/CoverFile.vue";
import CreateLabel from "@/components/CreateLabel.vue";
import PrintLabel from "@/components/PrintLabel.vue";
import EditGuide from "@/components/EditGuide.vue";
import { SketchRule } from "vue3-sketch-ruler";
import 'vue3-sketch-ruler/lib/style.css'
// import "./css/sketch-ruler.css";
import XLSX from "xlsx";
import drawComponent from "./js/drawComponent.js"; //绘制元件
import alignment from "./js/alignment.js"; //元件对齐 对齐辅助线
import attribute from "./js/attribute.js"; //元件属性操作
import table_attr from "./js/table_attr.js"; //表格属性操作
import data from "./js/data.js"; //初始化属性数据
import { propertyType, dataTypeChange } from "./js/propertyType.js"; //设置数据类型
import deformation from "./js/deformation.js"; //操作组件 选中、全选、变形
import "./css/edit.css";
import "./css/edit-small.css";
import { nextTick } from "vue-demi";
import changedpi from "./js/changeDpi.js";
import { getCurrentDates } from "@/utils/formatTime";
import { randomNumberStr, qiniuFilename } from "@/utils/util";

export default {
  mixins: [data, deformation, drawComponent, attribute, alignment, table_attr],
  components: {
    EditDiv,
    Rename,
    CreateLabel,
    PrintLabel,
    EditGuide,
    SketchRule,
    CoverFile,
    MemberDia,
  },
  data() {
    return {
      userMemberInfo: "", //记录用户会员信息
      VUE_APP_VERSION: process.env.VUE_APP_VERSION,
      origionCodeImage: "", //用来存储错误二维码的底图
      fontsizeMsg: "",
      elemMinFontSize: -1, //元件最小字号
      queryImgList: {
        id: "",
        user_id: "",
        type: 1,
        page: 1,
        size: 999,
        order: "",
      },
      showCloudFolder: false,
      showCloudDataList: false,
      cloudFileList: [],
      cloudDataList: [],
      eclevelList: [],
      minFontSize: 12, //浏览器最小字号
      minMultiple: 1,
      maxMultiple: 1,
      start_design: "", //存储一倍时模板信息
      sliderValue: 70, //滑块值
      //标尺数据
      state: {
        scale: 1,
        startX: -200,
        startY: -200,
        lines: {
          h: [],
          v: [],
        },
        thick: 20,
        width: 1500,
        height: 780,
        isShowRuler: true, // 显示标尺
        isShowReferLine: true, // 显示参考线
        palette: {
          bgColor: "#fff",
          longfgColor: "#7B89A1",
          shortfgColor: "#7B89A1",
          fontColor: "#7B89A1",
          lineColor: "#EB5648",
          borderColor: "#7B89A1",
          cornerActiveColor: "rgb(235, 86, 72, 0.6)",
        },
      },
      screenWidth: 0,
      showEditGuide: true, //显示新手指导
      pasteNum: 0, //粘贴次数
      showShortcutKey: false, //显示快捷键说明
      visible: false, //显示底部按钮菜单
      isGetSize: false, //判断文本宽高是否计算完成
      isMoreSelect: false, //判断左键点击是否是多选
      saveAsToOther: false, //判断保存、另存为
      configuration: [], //配置文件大小
      loadingInstance: "",
      isSaveBtn: false, //是否禁用保存按钮
      getcloudData: false, //判断是否获取的是云端数据
      ConnectionStr: "",
      labelData: "",
      printLabel: false, //显示打印预览组件
      Lock: false, //判断锁定解锁
      rightMenuX: 0,
      rightMenuY: 0,
      isShowOrder: false, //显示图层顺序菜单
      isShowAlign: false, //显示对齐菜单
      isShowMenu: false, //显示右键菜单
      isCreate: true, //判断条形码、二维码是否已生成
      databaseBtn: false,
      showDataList: false,
      databaseList: [],
      iconsData: [],
      defaultProps: {
        value: "id",
        children: "son",
        label: "name",
      },
      iconList: [],
      iconTitle: "",
      iconId: "",
      iconChildrenId: "",
      iconIndex: -1,
      showSelect: true,
      showCanvas: false,
      multiple: 1,
      labelList: [],
      database: [],
      elementList: [],
      databaseValue: "",
      textareaValue: "",
      unitIndex: 0,
      rectsIndex: 0,
      template: {
        //模板信息
        width: 374,
        height: 299,
        x: 0,
        y: 0,
        Background: "",
        PrintBackground: "",
      },
      screen: {
        //中间画布区域宽高
        width: 0,
        height: 0,
      },
      subgroup: [],
      subIndex: -1, //当前元素指针
      history: [], //历史
      historyIndex: -1, //历史记录指针
      historyTwo: [], //历史
      //七牛云token
      postData: {
        token: "",
        url: "",
        key: "",
      },
      imgHeader: "data:image/png;base64,",
      copyData: [],
      CodeType: [],
      renameLabel: {
        renameVisible: false,
        renameType: "label",
        renameId: "",
        renameValue: "",
      },
      jsonData: [],
      userInfo: "",
      language: this.$i18n.locale,
      rotateIndex: 0,
      locationIndex: "",
      listType: "", //1新建标签  2编辑标签  3 共享标签编辑
      fileUrl: "",
      temId: "",
      fileImage: "",
      MeasureUnit: "",
      UnitConversion: 1, //单位毫米时为1，1英寸等于25.4毫米，英寸时为25.4, 将英寸换算成毫米
      conversion: 3.96, // 1mm约等于3.96点
      adaptTime: null,
      dataSourceIndex: 0,
    };
  },
  created() {
    this.configuration = JSON.parse(localStorage.getItem("configuration"));
    this.family = this.family.concat(fontUrlArr);
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },

  mounted() {
    this.getMemberUser();
    this.minFontSize = Number(localStorage.getItem("minFontSize"));
    let firstEdit = localStorage.getItem("firstEdit");
    if (firstEdit == "true") {
      this.showEditGuide = true;
    } else {
      this.showEditGuide = false;
    }
    this.screenWidth = document.body.clientWidth;
    //设置中心编辑区域的大小
    this.screen.width =
      document.querySelector("#printBox").getBoundingClientRect().width - 360;
    this.screen.height = document
      .querySelector("#printBox")
      .getBoundingClientRect().height;
    this.listType = localStorage.getItem("listType");
    if (this.listType == 1) {
      this.getTemplateFiles();
      //新建标签
      let newList = localStorage.getItem("newLabelList");
      this.labelList = JSON.parse(newList);
      // console.log(this.labelList);
      this.MeasureUnit = this.labelList.unit;
      if (this.MeasureUnit.toLowerCase() == "inch") {
        this.UnitConversion = 25.4;
      } else {
        this.UnitConversion = 1;
      }
      //设置画布背景图片
      this.template.Background = this.labelList.Background;
      this.template.PrintBackground = this.labelList.PrintBackground;
      //设置画布大小
      this.template.width = this.labelList.width * this.UnitConversion;
      this.template.height = this.labelList.height * this.UnitConversion;
      this.template.x = ((this.screen.width * 25.4) / 96 - this.template.width - 6) / 2;
      this.template.y = ((this.screen.height * 25.4) / 96 - this.template.height - 6) / 2;
      this.template.x = parseInt(this.template.x * 10) / 10;
      this.template.y = parseInt(this.template.y * 10) / 10;
      this.showCanvas = true;
      this.isGetSize = true;
      //适应画布大小
      this.adaptTemplate();
    } else {
      //编辑标签
      let list = localStorage.getItem("list");
      this.labelList = JSON.parse(list);
      // console.log(this.labelList);
      //解析XML
      this.writeFile(this.labelList.file_pc);
    }
    const that = this;
    document.onkeydown = function (event) {
      var e = event || window.e;
      var keyCode = e.keyCode || e.which;
      //37左移 38上移 39右移  40下移 46删除 65全选  67复制 80打印 83保存/另存为  86粘贴 89恢复  90撤销
      if (e.target.localName == "body") {
        switch (keyCode) {
          case 37:
            if (that.selectUnit.length > 0) {
              that.selectUnit.forEach((item) => {
                let index = item.index;
                that.subgroup[index].StartX--;
                if (item.Type == "Diamond") {
                  //菱形移动
                  let spaceWidth =
                    (that.subgroup[index].Width * 4 -
                      (that.subgroup[index].Vertice[1]["@X"] -
                        that.subgroup[index].Vertice[3]["@X"])) /
                    2;

                  that.subgroup[index].Vertice[0]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    (that.subgroup[index].Width / 2) * 4;

                  that.subgroup[index].Vertice[1]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    that.subgroup[index].Width * 4 -
                    spaceWidth;

                  that.subgroup[index].Vertice[2]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    (that.subgroup[index].Width / 2) * 4;

                  that.subgroup[index].Vertice[3]["@X"] =
                    that.subgroup[index].StartX * 4 + spaceWidth;
                } else if (that.subgroup[index].Type == "Triangle") {
                  //三角形移动
                  //计算剩余高度
                  that.subgroup[index].Vertice[2]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    (that.subgroup[index].Width / 2) * 4;

                  that.subgroup[index].Vertice[0]["@X"] =
                    that.subgroup[index].StartX * 4 + that.subgroup[index].PenWidth * 2;

                  that.subgroup[index].Vertice[1]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    that.subgroup[index].Width * 4 -
                    that.subgroup[index].PenWidth * 2;
                } else if (that.subgroup[index].Type == "Line") {
                  that.subgroup[index].EndX--;
                }
              });
              that.handleRecord();
            }
            break;
          case 38:
            if (that.selectUnit.length > 0) {
              that.selectUnit.forEach((item) => {
                let index = item.index;
                that.subgroup[index].StartY--;
                if (that.subgroup[index].Type == "Diamond") {
                  //菱形移动
                  let spaceHeiht =
                    (that.subgroup[index].Height * 4 -
                      (that.subgroup[index].Vertice[2]["@Y"] -
                        that.subgroup[index].Vertice[0]["@Y"])) /
                    2;
                  that.subgroup[index].Vertice[0]["@Y"] =
                    that.subgroup[index].StartY * 4 + spaceHeiht;

                  that.subgroup[index].Vertice[1]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    (that.subgroup[index].Height / 2) * 4;

                  that.subgroup[index].Vertice[2]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    that.subgroup[index].Height * 4 -
                    spaceHeiht;

                  that.subgroup[index].Vertice[3]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    (that.subgroup[index].Height / 2) * 4;
                } else if (that.subgroup[index].Type == "Triangle") {
                  //三角形移动
                  //计算剩余高度
                  let spaceHeiht =
                    (that.subgroup[index].Height * 4 -
                      (that.subgroup[index].Vertice[1]["@Y"] -
                        that.subgroup[index].Vertice[2]["@Y"])) /
                    2;

                  that.subgroup[index].Vertice[2]["@Y"] =
                    that.subgroup[index].StartY * 4 + spaceHeiht;

                  that.subgroup[index].Vertice[0]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    that.subgroup[index].Height * 4 -
                    spaceHeiht;

                  that.subgroup[index].Vertice[1]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    that.subgroup[index].Height * 4 -
                    spaceHeiht;
                } else if (that.subgroup[index].Type == "Line") {
                  that.subgroup[index].EndY--;
                }
              });
              that.handleRecord();
            }
            break;
          case 39:
            if (that.selectUnit.length > 0) {
              that.selectUnit.forEach((item) => {
                let index = item.index;
                that.subgroup[index].StartX++;
                if (that.subgroup[index].Type == "Diamond") {
                  //菱形移动
                  let spaceWidth =
                    (that.subgroup[index].Width * 4 -
                      (that.subgroup[index].Vertice[1]["@X"] -
                        that.subgroup[index].Vertice[3]["@X"])) /
                    2;

                  that.subgroup[index].Vertice[0]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    (that.subgroup[index].Width / 2) * 4;

                  that.subgroup[index].Vertice[1]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    that.subgroup[index].Width * 4 -
                    spaceWidth;

                  that.subgroup[index].Vertice[2]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    (that.subgroup[index].Width / 2) * 4;

                  that.subgroup[index].Vertice[3]["@X"] =
                    that.subgroup[index].StartX * 4 + spaceWidth;
                } else if (that.subgroup[index].Type == "Triangle") {
                  //三角形移动
                  //计算剩余高度
                  that.subgroup[index].Vertice[2]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    (that.subgroup[index].Width / 2) * 4;

                  that.subgroup[index].Vertice[0]["@X"] =
                    that.subgroup[index].StartX * 4 + that.subgroup[index].PenWidth * 2;

                  that.subgroup[index].Vertice[1]["@X"] =
                    that.subgroup[index].StartX * 4 +
                    that.subgroup[index].Width * 4 -
                    that.subgroup[index].PenWidth * 2;
                } else if (that.subgroup[index].Type == "Line") {
                  that.subgroup[index].EndX++;
                }
              });
              that.handleRecord();
            }
            break;
          case 40:
            if (that.selectUnit.length > 0) {
              that.selectUnit.forEach((item) => {
                let index = item.index;
                that.subgroup[index].StartY++;
                if (that.subgroup[index].Type == "Diamond") {
                  //菱形移动
                  let spaceHeiht =
                    (that.subgroup[index].Height * 4 -
                      (that.subgroup[index].Vertice[2]["@Y"] -
                        that.subgroup[index].Vertice[0]["@Y"])) /
                    2;
                  that.subgroup[index].Vertice[0]["@Y"] =
                    that.subgroup[index].StartY * 4 + spaceHeiht;

                  that.subgroup[index].Vertice[1]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    (that.subgroup[index].Height / 2) * 4;

                  that.subgroup[index].Vertice[2]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    that.subgroup[index].Height * 4 -
                    spaceHeiht;

                  that.subgroup[index].Vertice[3]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    (that.subgroup[index].Height / 2) * 4;
                } else if (that.subgroup[index].Type == "Triangle") {
                  //三角形移动
                  //计算剩余高度
                  let spaceHeiht =
                    (that.subgroup[index].Height * 4 -
                      (that.subgroup[index].Vertice[1]["@Y"] -
                        that.subgroup[index].Vertice[2]["@Y"])) /
                    2;

                  that.subgroup[index].Vertice[2]["@Y"] =
                    that.subgroup[index].StartY * 4 + spaceHeiht;

                  that.subgroup[index].Vertice[0]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    that.subgroup[index].Height * 4 -
                    spaceHeiht;

                  that.subgroup[index].Vertice[1]["@Y"] =
                    that.subgroup[index].StartY * 4 +
                    that.subgroup[index].Height * 4 -
                    spaceHeiht;
                } else if (that.subgroup[index].Type == "Line") {
                  that.subgroup[index].EndY++;
                }
              });
              that.handleRecord();
            }
            break;
          case 46:
            that.handleOperate(2);
            break;
          case 65:
            if (e.ctrlKey) {
              // let data = JSON.parse(JSON.stringify(that.subgroup));
              that.subgroup.forEach((value, index) => {
                let isExist = that.selectUnit.some((item) => item.Id == value.Id);
                if (!isExist) {
                  e.index = index;
                  that.selectUnit.push(value);
                }
              });
              that.subIndex = -1;
            }
            break;
          case 67:
            if (e.ctrlKey) {
              that.handleOperate(0);
            }
            break;
          case 80:
            if (e.ctrlKey) {
              e.preventDefault();
              that.handlePrint();
            }
            break;
          case 83:
            if (e.ctrlKey) {
              e.preventDefault();
              if (e.shiftKey) {
                that.saveAsToOther = true;
                that.handleSave();
              } else {
                that.saveAsToOther = false;
                that.handleSave();
              }
            }
            break;
          case 86:
            if (e.ctrlKey) {
              that.handleOperate(1);
            }
            break;
          case 89:
            if (e.ctrlKey) {
              that.handleHistory(2);
            }
            break;
          case 90:
            if (e.ctrlKey) {
              that.handleHistory(1);
            }
            break;
        }
      }
    };

    let imageDom = this.$refs.imageDom;
    //监听多选
    imageDom.addEventListener("mousedown", function (event) {
      if (event.button == 0) {
        if (event.ctrlKey) {
          that.isMoreSelect = true;
          if (that.subIndex > -1) {
            // let data = JSON.parse(JSON.stringify(that.subgroup[that.subIndex]));
            let data = that.subgroup[that.subIndex];
            let isExist = that.selectUnit.some((item) => item.Id === data.Id);
            if (!isExist) {
              data.index = that.subIndex;
              that.selectUnit.push(data);
            } else {
              let _index = that.selectUnit.findIndex(
                (item) => item.index === that.subIndex
              );
              that.selectUnit.splice(_index, 1);
              that.subIndex = -1;
            }
          }
        }
        if (!event.ctrlKey && that.subIndex < 0) {
          that.isMoreSelect = false;
          that.selectUnit = [];
        }
      } else {
        that.isMoreSelect = false;
      }
    });
    //监听滚轮事件  实现画布放大缩小
    document.addEventListener(
      "mousewheel",
      function (event) {
        //正值向上，负值向下
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
          that.handleWheel(event, 1);
        }
      },
      {
        passive: false,
      }
    );
    //firefox //监听滚轮事件  实现画布放大缩小
    document.addEventListener(
      "DOMMouseScroll",
      function (event) {
        //正值向下，负值向上
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
          that.handleWheel(event, 2);
        }
      },
      {
        passive: false,
      }
    );
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.clientWidth;
        let screenHeight = document.body.clientHeight;
        that.screen.width = that.screenWidth - that.$refs.printLeft.offsetWidth - 360;
        that.screen.height =
          screenHeight -
          that.$refs.printTop.offsetHeight -
          that.$refs.printTool.offsetHeight;
        clearTimeout(that.adaptTime);
        that.adaptTime = setTimeout(() => {
          that.start_design = "";
          that.adaptTemplate();
          that.adaptTime = null;
        }, 300);
      })();
    };
  },

  methods: {
    //获取用户会员信息
    getMemberUser() {
      this.$axioshanma("/api/tem.app_member/getMemberUser", {
        user_id: this.userInfo.userid,
      }).then((res) => {
        this.userMemberInfo = res;
      });
    },
    //获取模板默认文件夹的ID
    getTemplateFiles() {
      this.$axioshanma("/api/tem.app_public/getUserClassList", {
        user_id: this.userInfo.userid,
      }).then((res) => {
        this.temId = res[0].id;
      });
    },
    //xml转Json
    writeFile(fileUrl) {
      this.systemTimer = null;
      var form = new FormData();
      form.append("url", fileUrl);
      this.$axiosnet("/LoadXml/writeFile", form)
        .then((res) => {
          this.getTemplateFiles();
          //判断是否是base64字符串
          let regx = /^[\s\S]*\.tprts$/;
          if (regx.test(fileUrl)) {
            let jsonString = decodeURIComponent(escape(atob(res)));
            this.jsonData = JSON.parse(jsonString);
          } else {
            this.jsonData = JSON.parse(res);
          }
          // console.log(this.jsonData, 222);
          this.MeasureUnit = this.jsonData.PrtLable.PictureArea.LabelPage["@MeasureUnit"];
          if (this.MeasureUnit.toLowerCase() == "inch") {
            this.UnitConversion = 25.4;
          } else {
            this.UnitConversion = 1;
          }
          //设置画布背景图片
          this.template.Background = this.jsonData.PrtLable.PictureArea.LabelPage.Background;
          this.template.PrintBackground = this.jsonData.PrtLable.PictureArea.LabelPage.PrintBackground;
          //设置画布大小
          this.template.width =
            this.jsonData.PrtLable.PictureArea.LabelPage.LabelWidth * this.UnitConversion;
          this.template.height =
            this.jsonData.PrtLable.PictureArea.LabelPage.LabelHeight *
            this.UnitConversion;

          let Count = this.jsonData.PrtLable.ObjectList["@Count"];
          if (Count > 0) {
            let DrawObject = this.jsonData.PrtLable.ObjectList.DrawObject;
            this.turnFormat(DrawObject, Count);
            this.adaptTemplate();
          } else {
            this.showCanvas = true;
            this.isGetSize = true;
            this.adaptTe4mplate();
          }
        })
        .catch((err) => {});
    },
    //处理解析后的json数据
    turnFormat(data, Count) {
      let dataList = [];
      if (Count == 1) {
        dataList.push(data);
      } else {
        dataList = data;
      }
      this.subgroup = dataList.map((element) => {
        // 字号变成Number类型
        if (element.Font) {
          // element.Font["@FontSize"] = element.Font["@FontSize"] * 1;
          if (this.elemMinFontSize > -1) {
            this.elemMinFontSize =
              this.elemMinFontSize > element.Font["@FontSize"]
                ? element.Font["@FontSize"]
                : this.elemMinFontSize;
          } else {
            this.elemMinFontSize = element.Font["@FontSize"];
          }
        }

        //AngleRound为空的元素默认旋转角度为0
        if (!element.AngleRound) {
          element.AngleRound = 0;
        }

        //判断旋转角度，重置顶点坐标
        element.StartX = element.StartX / this.conversion;
        element.StartY = element.StartY / this.conversion;
        let width = element.Width / this.conversion;
        let height = element.Height / this.conversion;
        let startX = element.StartX;
        let startY = element.StartY;
        if (element.Type != "WinText" && element.Type != "TimeText") {
          switch (element.AngleRound) {
            case "90":
              element.StartX = startX + height;
              element.StartY = startY;
              break;
            case "180":
              element.StartX = startX + width;
              element.StartY = startY + height;
              break;
            case "270":
              element.StartX = startX;
              element.StartY = startY + width;
              break;
            default:
              element.StartX = startX;
              element.StartY = startY;
              break;
          }
        }

        //处理元素宽高
        element.Width = element.Width / this.conversion;
        element.Height = element.Height / this.conversion;
        if (element.BoxWidth) {
          element.BoxWidth = element.BoxWidth / this.conversion;
        }

        //处理表格
        if (element.Type == "Table") {
          element.PenWidth = element.PenWidth / this.conversion;

          element.DicLineLocW = element.DicLineLocW ? element.DicLineLocW.split(",") : [];
          for (let i = 0; i < element.DicLineLocW.length; i++) {
            element.DicLineLocW[i] = (
              Math.round((element.DicLineLocW[i] / this.conversion) * 100) / 100
            ).toFixed(2);
            element.DicLineLocW[i] = Number(element.DicLineLocW[i]);
          }

          element.DicLineLocH = element.DicLineLocH ? element.DicLineLocH.split(",") : [];
          for (let i = 0; i < element.DicLineLocH.length; i++) {
            element.DicLineLocH[i] = (
              Math.round((element.DicLineLocH[i] / this.conversion) * 100) / 100
            ).toFixed(2);
            element.DicLineLocH[i] = Number(element.DicLineLocH[i]);
          }

          let DataSourceList = Array.isArray(element.DataSourceList)
            ? element.DataSourceList
            : [element.DataSourceList];

          let { list, tableWidth, tableHeight, lineNumW, lineNumH } = this.dealTableData(
            DataSourceList,
            element.DicLineLocW,
            element.DicLineLocH
          );
          element.DataSourceList = list;
          element.Row = element.DicLineLocH.length + 1;
          element.Cloumn = element.DicLineLocW.length + 1;
          //重新计算表格的宽高，避免因转换过程中精度丢失导致误差
          element.Width = tableWidth + element.PenWidth * (1 + lineNumW);
          element.Height = tableHeight + element.PenWidth * (1 + lineNumH);
        }

        //处理线条
        if (
          element.Type == "LineHorizontal" ||
          element.Type == "LineVertical" ||
          element.Type == "Line"
        ) {
          element.EndX = element.EndX / this.conversion;
          element.EndY = element.EndY / this.conversion;
          switch (element.Type) {
            case "LineHorizontal":
              element.Width = Math.abs(element.EndX - element.StartX);
              element.Height = element.EndY - element.StartY + 1;
              element.StartY = element.StartY - element.PenWidth / this.conversion / 2;
              if (element.EndX < element.StartX) {
                let temp = element.StartX;
                element.StartX = element.EndX;
                element.EndX = temp;
              }
              break;
            case "LineVertical":
              element.Width = element.EndX - element.StartX + 1;
              element.Height = Math.abs(element.EndY - element.StartY);
              element.StartX = element.StartX - element.PenWidth / this.conversion / 2;
              if (element.EndY < element.StartY) {
                let temp = element.StartY;
                element.StartY = element.EndY;
                element.EndY = temp;
              }
              break;
            case "Line":
              if (element.EndX == element.StartX) {
                element.Width = 1;
              } else {
                element.Width = Math.abs(element.EndX - element.StartX);
              }
              if (element.EndY == element.StartY) {
                element.Height = 1;
              } else {
                element.Height = Math.abs(element.EndY - element.StartY);
              }
              break;
          }
        }

        // 处理文本、二维码、条形码的数据
        if (
          element.Type == "WinText" ||
          element.Type == "GeneralBarcode" ||
          element.Type == "QRCode"
        ) {
          element.DataSourceList = Array.isArray(element.DataSourceList)
            ? element.DataSourceList
            : [element.DataSourceList];

          let value = "";
          element.DataSourceList.map((list) => {
            if (list.DataSourceType == "2") {
              list.AddedValue = Number(list.AddedValue);
              list.Quantity = Number(list.Quantity);
              switch (list.SequenceType) {
                case "1":
                  list.InitialValue = Number(list.InitialValue).toString(16);
                  break;
                case "2":
                  list.InitialValue = this.convertToTwoteenSix(Number(list.InitialValue));
                  break;
                case "3":
                  list.InitialValue = Number(list.InitialValue).toString(36);
                  break;
              }
              list.FirstValue = list.InitialValue;
            }
            value += list.FirstValue;
          });
          element.Data = value;
        }

        //对数据转类型
        element = propertyType(element);
        return element;
      });
      console.log(this.subgroup);

      //浏览器最小字号的判断及提示
      if (this.elemMinFontSize > -1 && this.elemMinFontSize < this.minFontSize) {
        let message;
        if (process.env.VUE_APP_VERSION == "hprt") {
          message =
            this.$t(`tips.tip69`) +
            "<a style='color:#003591;cursor:pointer' href='/help/14/' target='_blank'>" +
            this.$t(`tips.tip70`) +
            "</a>";
        }else if(process.env.VUE_APP_VERSION == "hereLabelTop"){
          message =
            this.$t(`tips.tip69`) +
            "<a style='color:#003591;cursor:pointer' href='/helpDetail' target='_blank'>" +
            this.$t(`tips.tip70`) +
            "</a>";
        } else {
          message =
            this.$t(`tips.tip69`) +
            "<a style='color:#003591;cursor:pointer' href='/helpDetail?id=14' target='_blank'>" +
            this.$t(`tips.tip70`) +
            "</a>";
        }

        this.fontsizeMsg = ElMessage({
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: message,
          type: "error",
          duration: 10000,
          offset: 100,
        });
      }

      //获取数据库
      this.getExcelCloudData();
      //判断是否含有文本，若true，则等文本宽高计算完再显示画布，若false,则直接显示画布
      let isText = this.subgroup.some((item) => item.Type === "WinText" && "TimeText");
      if (!isText) {
        this.isGetSize = true;
      }
      this.$nextTick(() => {
        this.subgroup.forEach((e, index) => {
          if (e.Type == "GeneralBarcode") {
            this.isCreate = false;
            this.handleCreated("1", index);
          } else if (e.Type == "QRCode") {
            this.isCreate = false;
            this.handleCreated("2", index);
          }
        });
        setTimeout(() => {
          this.showCanvas = true;
          this.handleRecord();
        }, 80);
      });
    },
    //加载初始云端数据表内容
    async getExcelCloudData() {
      for (let i = 0; i < this.subgroup.length; i++) {
        if (
          this.subgroup[i].Type == "WinText" ||
          this.subgroup[i].Type == "GeneralBarcode" ||
          this.subgroup[i].Type == "QRCode" ||
          this.subgroup[i].Type == "Table"
        ) {
          var DataSourceList = this.subgroup[i].DataSourceList;
          for (let j = 0; j < DataSourceList.length; j++) {
            let item = DataSourceList[j];
            if (item.DataSourceType == "1") {
              if (item.ColumnName == "") {
                item.DataSourceType = "0";
                continue;
              }
              var re = new RegExp("^(http|https)://", "i");
              var str = item.ConnectionStr;
              //判断当前数据库是否已经连接
              if (this.ConnectionStr == str) {
                let ConnectionData = [];
                this.database.map((val) => {
                  if (val.value == item.ColumnName) {
                    ConnectionData = val.data;
                  }
                });
                item.ConnectionData = ConnectionData;
                item.OriginalConnectionData = ConnectionData;
                item = await this.dealInitConnectionData(item);
              } else {
                var match = re.test(str);
                //Web端数据库
                if (match) {
                  item = await this.loadExcel(item);
                } else {
                  //非Web端数据库
                  if (this.subgroup[i].Type == "Table") {
                    item.ConnectionData = [item.Data];
                    item.OriginalConnectionData = [item.Data];
                  } else {
                    item.ConnectionData = [item.FirstValue];
                    item.OriginalConnectionData = [item.FirstValue];
                  }
                }
              }
            }
          }
        }
      }
    },
    //加载初始云端数据表内容
    loadExcel(obj) {
      return new Promise((resolve, reject) => {
        this.$axioshanma("/api/tem.new_class/import", {
        // this.$axiosnet("/Common/Import", {
          FileUrl: obj.ConnectionStr,
          RowIndex: 0,
          TableIndex: 0,
        })
          .then(async (res) => {
            message.success(this.$t(`tips.tip64`));
            this.ConnectionStr = obj.ConnectionStr;
            this.showDataList = false;
            this.getcloudData = true;
            this.database = [];
            console.log(res,res[0])
            let data = res[0].data;

            for (let i = 0; i < data.length; i++) {
              let sub = {
                label: data[i][0],
                value: data[i][0],
                data: data[i].slice(1),
              };
              this.database.push(sub);
              if (obj.ColumnName == data[i][0]) {
                obj.ConnectionData = data[i].slice(1);
                obj.OriginalConnectionData = data[i].slice(1);
                obj = await this.dealInitConnectionData(obj);
              }
            }
            // console.log(this.database)
            resolve(obj);
          })
          .catch((err) => {
            resolve();
          });
      });
    },
    //根据数据类型处理初始化后的数据库数据
    dealInitConnectionData(data) {
      return new Promise((resolve, reject) => {
        let DataType = data.DataType;
        let DataFormat = data.DataFormat;
        let NumberFormat = data.NumberFormat;
        let OriginalConnectionData = JSON.parse(
          JSON.stringify(data.OriginalConnectionData)
        );
        // 0 日期  1数字  2文本
        switch (DataType) {
          case "0":
            data.ConnectionData = OriginalConnectionData.map((val) => {
              if (isNaN(Number(val))) {
                let date = new Date(val).getTime();
                if (!isNaN(date)) {
                  let newDate = this.timeToDate(date, DataFormat);
                  return newDate;
                } else {
                  return val;
                }
              } else {
                return val;
              }
            });
            break;
          case "1":
            if (NumberFormat == "123456789") {
              data.ConnectionData = OriginalConnectionData.map((val) => {
                var newStr = val.replace(/,/g, "");
                return newStr;
              });
            } else if (NumberFormat == "123,456,789") {
              data.ConnectionData = OriginalConnectionData.map((val) => {
                if (!isNaN(Number(val))) {
                  var newStr = dealNumber(val);
                  return newStr;
                } else {
                  return val;
                }
              });
            }
            break;
          case "2":
            data.ConnectionData = OriginalConnectionData;
            break;
        }
        data.FirstValue = data.ConnectionData[0];
        resolve(data);
      });
    },
    //获取图标分类
    getIConCategory() {
      this.$axioshanma("/api/tem.app_shared/getLogClass").then((res) => {
        this.iconsData = res;
        this.iconId = this.iconsData[0].id;
        this.iconChildrenId = this.iconsData[0].son[0].id;
        this.iconTitle = this.iconsData[0].son[0].name;
        this.getFirstData(this.iconChildrenId);
        this.showIcons = !this.showIcons;
      });
    },
    //获取图标分类内容
    getFirstData(id) {
      let obj = {
        id: id,
      };
      this.$axioshanma("/api/tem.app_shared/getLogList", obj).then((res) => {
        nextTick(() => {
          this.iconList = res;
        });
      });
    },
    //点击图标分类
    handleNodeClick(e) {
      if (!e.son) {
        this.iconTitle = e.name;
        this.getFirstData(e.id);
      }
    },
    //打开重命名组件
    editLableName() {
      this.$refs.renameRef.show("label", this.labelList);
    },
    //重命名标签
    handleRename(newValue) {
      if (this.listType == 1 || this.listType == 3) {
        this.labelList.name = newValue;
        localStorage.setItem("list", JSON.stringify(this.labelList));
        message.success(this.$t(`tips.tip34`));
      } else if (this.listType == 2) {
        let obj = {
          id: this.labelList.id,
          name: newValue,
          user_id: this.userInfo.userid,
          platform: 2,
        };
        this.$axioshanma("/api/tem.app_public/editUserTemplate", obj).then((res) => {
          message.success(this.$t(`tips.tip48`));
          this.labelList = res;
          localStorage.setItem("list", JSON.stringify(this.labelList));
        });
      }
    },
    //显示右键菜单
    showRightMenu(event) {
      this.rightMenuX = event.clientX;
      this.rightMenuY = event.clientY;
      this.isShowMenu = true;
      this.isShowOrder = false;
      this.isShowAlign = false;
      if (this.subIndex > -1) {
        this.Lock = this.subgroup[this.subIndex].Lock;
      } else {
        this.Lock = false;
      }
    },
    //读取云端表格数据
    readFile(index) {
      this.showCloudDataList = false;
      this.showCloudFolder = false;
      this.databaseBtn = false;
      var url = this.cloudDataList[index].url;
      this.$axioshanma("/api/tem.new_class/import", {
        FileUrl: url,
        RowIndex: 0,
        TableIndex: 0,
      }).then((res) => {
        // let data = res[0]
        let data = res[0].data;
        message.success(this.$t(`tips.tip64`));
        this.database = [];
        this.ConnectionStr = url;
        this.getcloudData = true;
        data.map((item) => {
          let sub = {
            label: item[0],
            value: item[0],
            data: item.slice(1),
          };
          this.database.push(sub);
        });
        // console.log(this.database);
      });
    },
    getFileUpload() {
      $("#selectBtn").trigger("click");
      this.databaseBtn = false;
      this.getcloudData = false;
    },
    //解析本地上传的表格
    getLocalData(e) {
      var files = e.target.files;
      if (files.length == 0) {
        message.error(this.$t(`tips.tip65`));
        return;
      } else {
        this.ConnectionStr = files[0].name;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = (e) => {
          let workFile = "";
          try {
            workFile = XLSX.read(e.target.result, {
              type: "binary",
              cellNF: true,
              dateNF: true,
              cellDates: true,
              bookDeps: true,
              raw: true,
            });
          } catch (err) {
            message.error(this.$t(`tips.tip65`));
            return;
          }

          let sheetList = [];
          workFile.SheetNames.forEach((sheetName) => {
            sheetList.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(workFile.Sheets[sheetName], {
                raw: false,
                header: 1,
                defval: "",
              }),
            });
          });
          // console.log(workFile);
          // console.log(sheetList);
          if (sheetList.length == 0) {
            message.error(this.$t(`tips.tip65`));
            return;
          } else {
            message.success(this.$t(`tips.tip64`));
          }

          let sheet = sheetList[0].sheet;
          // console.log(sheet);
          this.database = [];
          let column = sheet[0].length;
          let row = sheet.length;
          // console.log(column, row);

          for (let i = 0; i < column; i++) {
            let data = [];
            for (let j = 1; j < row; j++) {
              data.push(sheet[j][i]);
            }
            let sub = {
              label: sheet[0][i],
              value: sheet[0][i],
              data: data,
            };
            this.database.push(sub);
          }
          // console.log(this.database);
        };
      }
    },
    //获取文本框宽高
    getDivWidth(width, height, index, type) {
      //changeW、changeH用来判断宽高是否有改变，宽高改变，有旋转的组件需要重新计算坐标
      let changeW = true;
      let changeH = true;

      if (width == this.subgroup[index].Width) {
        changeW = false;
      }
      if (height == this.subgroup[index].Height) {
        changeH = false;
      }

      if (type == 0) {
        if (width) {
          this.subgroup[index].Width = width;
        }
        if (height) {
          this.subgroup[index].Height = height;
        }

        //判断旋转角度，重置顶点坐标
        switch (this.subgroup[index].AngleRound) {
          case 90:
            if (changeW) {
              this.subgroup[index].StartX =
                this.subgroup[index].StartX + this.subgroup[index].Height;
            }
            break;
          case 180:
            if (changeW || changeH) {
              this.subgroup[index].StartX =
                this.subgroup[index].StartX + this.subgroup[index].Width;
              this.subgroup[index].StartY =
                this.subgroup[index].StartY + this.subgroup[index].Height;
            }
            break;
          case 270:
            if (changeH) {
              this.subgroup[index].StartY =
                this.subgroup[index].StartY + this.subgroup[index].Width;
            }
            break;
        }
      } else {
        //画布缩放
        if (width) {
          this.subgroup[index].Width = width;
        }
        if (height) {
          this.subgroup[index].Height = height;
        }
      }
      this.isGetSize = true;
    },
    //移动滑块，缩放画布
    changeSlider() {
      this.multiple =
        Math.floor(
          ((this.sliderValue / 100) * (this.maxMultiple - this.minMultiple) +
            this.minMultiple) *
            1000
        ) / 1000;
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    //放大
    handleEnlarge() {
      if (this.sliderValue > 99) {
        return;
      }
      this.sliderValue++;
      this.multiple =
        Math.floor(
          ((this.sliderValue / 100) * (this.maxMultiple - this.minMultiple) +
            this.minMultiple) *
            1000
        ) / 1000;
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    // 缩小
    handleNarrow() {
      if (this.sliderValue < 1) {
        return;
      }
      this.sliderValue--;
      this.multiple =
        Math.floor(
          ((this.sliderValue / 100) * (this.maxMultiple - this.minMultiple) +
            this.minMultiple) *
            1000
        ) / 1000;
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    //模板自适应大小
    adaptTemplate() {
      this.template.x = ((this.screen.width * 25.4) / 96 - this.template.width - 6) / 2;
      this.template.y = ((this.screen.height * 25.4) / 96 - this.template.height - 6) / 2;
      this.template.x = parseInt(this.template.x * 10) / 10;
      this.template.y = parseInt(this.template.y * 10) / 10;
      let printBox = document.querySelector("#printBox").getBoundingClientRect();
      let borderWidth = 1;
      this.state.width = printBox.width - this.state.thick - borderWidth;
      this.state.height = printBox.height - this.state.thick - borderWidth;
      this.multiple = 1;

      this.$nextTick(() => {
        //存储一倍时模板信息
        if (!this.start_design) {
          this.start_design = document.querySelector("#design").getBoundingClientRect();
          this.getMultRange();
        }

        //适配窗口的大小
        this.sliderValue = 70;
        this.multiple =
          Math.floor(
            ((this.sliderValue / 100) * (this.maxMultiple - this.minMultiple) +
              this.minMultiple) *
              1000
          ) / 1000;

        setTimeout(() => {
          this.handleScroll();
        }, 50);
      });
    },
    // 获取缩放倍数的范围
    getMultRange() {
      let printBox = document.querySelector("#printBox").getBoundingClientRect();
      if (this.template.height > 450) {
        this.minMultiple = 0.1;
      } else {
        this.minMultiple = 0.5;
      }
      var left = printBox.left + this.state.thick + 50;
      var top = printBox.top + this.state.thick + 50;
      if (this.start_design.width > this.start_design.height) {
        let beishu =
          ((this.start_design.left - left) * 2 + this.start_design.width) /
          this.start_design.width;
        //判断endTop是否小于top
        let endTop =
          this.start_design.top -
          (beishu * this.start_design.height - this.start_design.height) / 2;
        if (endTop < top) {
          beishu =
            ((this.start_design.top - top) * 2 + this.start_design.height) /
            this.start_design.height;
        }
        //适配窗口的大小
        this.maxMultiple = Math.floor(beishu * 1000) / 1000;
      } else {
        let beishu =
          ((this.start_design.top - top) * 2 + this.start_design.height) /
          this.start_design.height;
        //判断endLeft是否小于left
        let endLeft =
          this.start_design.left -
          (beishu * this.start_design.width - this.start_design.width) / 2;
        if (endLeft < left) {
          beishu =
            ((this.start_design.left - left) * 2 + this.start_design.width) /
            this.start_design.width;
        }
        //适配窗口的大小
        this.maxMultiple = Math.floor(beishu * 1000) / 1000;
      }
    },
    // 标尺开始的刻度
    handleScroll() {
      this.state.scale = (this.multiple * 96) / 25.4;
      let printBox = document.querySelector("#printBox").getBoundingClientRect();
      let design = document.querySelector("#design").getBoundingClientRect();
      let startX = (printBox.left + this.state.thick - design.left) / this.state.scale;
      let startY = (printBox.top + this.state.thick - design.top) / this.state.scale;
      this.state.startX = startX;
      this.state.startY = startY;
    },
    //滚轮缩放
    handleWheel(e, type) {
      let design = document.querySelector("#design").getBoundingClientRect();
      switch (type) {
        case 1:
          if (e.deltaY < 0) {
            if (this.sliderValue == 100) {
              return;
            }
            if (this.multiple + 0.05 > this.maxMultiple) {
              this.multiple = this.maxMultiple;
            } else {
              this.multiple = this.multiple + 0.05;
            }
          } else {
            if (this.sliderValue == 0) {
              return;
            }
            if (this.multiple - 0.05 < this.minMultiple) {
              this.multiple = this.minMultiple;
            } else {
              this.multiple = this.multiple - 0.05;
            }
          }
          break;
        case 2:
          //firefox
          if (e.detail > 0) {
            if (this.sliderValue == 100) {
              return;
            }
            if (this.multiple + 0.05 > this.maxMultiple) {
              this.multiple = this.maxMultiple;
            } else {
              this.multiple = this.multiple + 0.05;
            }
          } else {
            if (this.sliderValue == 0) {
              return;
            }
            if (this.multiple - 0.05 < this.minMultiple) {
              this.multiple = this.minMultiple;
            } else {
              this.multiple = this.multiple - 0.05;
            }
          }
          break;
      }
      this.sliderValue = Math.round(
        ((this.multiple - this.minMultiple) / (this.maxMultiple - this.minMultiple)) * 100
      );
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
    //打开标签设置
    handleOpenSet() {
      this.$refs.createLabelRef.showModal();
    },
    //标签设置
    handleSetLabel(value, type) {
      if (type == 1) {
        this.labelList = value;
        this.MeasureUnit = this.labelList.unit;
        if (this.MeasureUnit.toLowerCase() == "inch") {
          this.UnitConversion = 25.4;
        } else {
          this.UnitConversion = 1;
        }
        this.$nextTick(() => {
          //设置画布背景
          this.template.Background = this.labelList.Background;
          this.template.PrintBackground = this.labelList.PrintBackground;
          //设置画布大小
          this.template.width = this.labelList.width * this.UnitConversion;
          this.template.height = this.labelList.height * this.UnitConversion;
          this.start_design = "";
          this.adaptTemplate();
        });
      } else {
        this.jsonData.PrtLable.PictureArea.LabelPage = value;

        this.labelList.height = value.LabelHeight;
        this.labelList.width = value.LabelWidth;

        this.MeasureUnit = this.jsonData.PrtLable.PictureArea.LabelPage["@MeasureUnit"];
        if (this.MeasureUnit.toLowerCase() == "inch") {
          this.UnitConversion = 25.4;
        } else {
          this.UnitConversion = 1;
        }
        this.jsonData.PrtLable.PictureArea.AreaSize["@Height"] = Math.round(
          (31.7 / 8) * value.LabelHeight * this.UnitConversion
        );
        this.jsonData.PrtLable.PictureArea.AreaSize["@Width"] = Math.round(
          (31.7 / 8) * value.LabelWidth * this.UnitConversion
        );
        this.$nextTick(() => {
          //设置画布背景
          this.template.Background = this.jsonData.PrtLable.PictureArea.LabelPage.Background;
          this.template.PrintBackground = this.jsonData.PrtLable.PictureArea.LabelPage.PrintBackground;
          this.template.width =
            this.jsonData.PrtLable.PictureArea.LabelPage.LabelWidth * this.UnitConversion;
          this.template.height =
            this.jsonData.PrtLable.PictureArea.LabelPage.LabelHeight *
            this.UnitConversion;
          this.start_design = "";
          this.adaptTemplate();
        });
      }
    },
    //点击保存，处理数据
    async handleSave() {
      if (this.subgroup.length == 0) {
        message.error(this.$t(`tips.tip50`));
        return;
      }
      this.isSaveBtn = true;
      await this.getQiniuToken(); //获取七牛云Token
      this.subIndex = -1;
      this.selectUnit = [];

      var newSubgroup = JSON.parse(JSON.stringify(this.subgroup));
      for (var i = 0; i < newSubgroup.length; i++) {
        if (
          newSubgroup[i].Type == "LineVertical" ||
          newSubgroup[i].Type == "LineHorizontal" ||
          newSubgroup[i].Type == "Line"
        ) {
          switch (newSubgroup[i].Type) {
            case "LineHorizontal":
              newSubgroup[i].StartY =
                newSubgroup[i].StartY * this.conversion + newSubgroup[i].PenWidth / 2;
              newSubgroup[i].EndX =
                (newSubgroup[i].Width + newSubgroup[i].StartX) * this.conversion;
              newSubgroup[i].EndY = newSubgroup[i].StartY;
              newSubgroup[i].StartX = newSubgroup[i].StartX * this.conversion;

              break;
            case "LineVertical":
              newSubgroup[i].StartX =
                newSubgroup[i].StartX * this.conversion + newSubgroup[i].PenWidth / 2;
              newSubgroup[i].EndY =
                (newSubgroup[i].Height + newSubgroup[i].StartY) * this.conversion;
              newSubgroup[i].EndX = newSubgroup[i].StartX;
              newSubgroup[i].StartY = newSubgroup[i].StartY * this.conversion;
              break;
            default:
              newSubgroup[i].StartX = newSubgroup[i].StartX * this.conversion;
              newSubgroup[i].StartY = newSubgroup[i].StartY * this.conversion;
              newSubgroup[i].EndX = newSubgroup[i].EndX * this.conversion;
              newSubgroup[i].EndY = newSubgroup[i].EndY * this.conversion;
              break;
          }
          delete newSubgroup[i].Width;
          delete newSubgroup[i].Height;
        } else {
          let w = newSubgroup[i].Width * this.conversion;
          let h = newSubgroup[i].Height * this.conversion;
          newSubgroup[i].Width = w;
          newSubgroup[i].Height = h;
          newSubgroup[i].StartX = newSubgroup[i].StartX * this.conversion;
          newSubgroup[i].StartY = newSubgroup[i].StartY * this.conversion;
        }
        if (newSubgroup[i].hasOwnProperty("BoxWidth")) {
          newSubgroup[i].BoxWidth = newSubgroup[i].BoxWidth * this.conversion;
        }

        if (newSubgroup[i].hasOwnProperty("AngleRound")) {
          switch (newSubgroup[i].AngleRound) {
            case 90:
              newSubgroup[i].StartX = newSubgroup[i].StartX - newSubgroup[i].Height;
              break;
            case 180:
              newSubgroup[i].StartX = newSubgroup[i].StartX - newSubgroup[i].Width;
              newSubgroup[i].StartY = newSubgroup[i].StartY - newSubgroup[i].Height;
              break;
            case 270:
              newSubgroup[i].StartY = newSubgroup[i].StartY - newSubgroup[i].Width;
              break;
          }
        }

        if (newSubgroup[i].Type == "Image") {
          newSubgroup[i].Imgae = "";
          newSubgroup[i].ImageFilePath = "";
        }

        if (newSubgroup[i].Type == "Diamond") {
          let Vertice = newSubgroup[i].Vertice;
          for (var k in Vertice) {
            Vertice[k]["@X"] = Math.round(Vertice[k]["@X"]);
            Vertice[k]["@Y"] = Math.round(Vertice[k]["@Y"]);
          }
          newSubgroup.Vertice = Vertice;
        } else if (newSubgroup[i].Type == "Triangle") {
          let Vertice = newSubgroup[i].Vertice;
          for (let h in Vertice) {
            Vertice[h]["@X"] = Math.round(Vertice[h]["@X"]);
            Vertice[h]["@Y"] = Math.round(Vertice[h]["@Y"]);
          }
          newSubgroup.Vertice = Vertice;
        } else if (newSubgroup[i].Type == "Table") {
          newSubgroup[i].PenWidth = newSubgroup[i].PenWidth * this.conversion;

          for (let line = 0; line < newSubgroup[i].DicLineLocW.length; line++) {
            newSubgroup[i].DicLineLocW[line] = (
              Math.round(newSubgroup[i].DicLineLocW[line] * this.conversion * 100) / 100
            ).toFixed(2);
          }
          newSubgroup[i].DicLineLocW = newSubgroup[i].DicLineLocW.join(",");

          for (let line = 0; line < newSubgroup[i].DicLineLocH.length; line++) {
            newSubgroup[i].DicLineLocH[line] = (
              Math.round(newSubgroup[i].DicLineLocH[line] * this.conversion * 100) / 100
            ).toFixed(2);
          }
          newSubgroup[i].DicLineLocH = newSubgroup[i].DicLineLocH.join(",");

          let list = newSubgroup[i].DataSourceList;
          list.map((data) => {
            data.StartX = data.StartX * this.conversion;
            data.StartY = data.StartY * this.conversion;
            data.Width = data.Width * this.conversion;
            data.Height = data.Height * this.conversion;
            data = dataTypeChange(data, 2);
            data.Key = data.Key.join(",");
            if (data.hasOwnProperty("children")) {
              data.Key = data.children.join("|");
            }
            delete data.children;
            delete data.ConnectionData;
            delete data.OriginalConnectionData;
          });
          delete newSubgroup[i].Row;
          delete newSubgroup[i].Cloumn;
        }
        delete newSubgroup[i].badBarcode;
        delete newSubgroup[i].index;
        delete newSubgroup[i].divL;
        delete newSubgroup[i].divT;
        newSubgroup[i] = dataTypeChange(newSubgroup[i], 0);
      }
      // console.log(newSubgroup);
      // console.log(this.labelList);
      if (this.listType == 1) {
        var json = {
          "?xml": {
            "@version": "1.0",
            "@encoding": "utf-8",
          },
          PrtLable: {
            "#comment": [],
            FileInfo: {
              Creator: {
                "@Platform": "Web",
                "@Version": this.$Version,
              },
            },
            PictureArea: {
              AreaSize: {
                "@Height": Math.round(
                  this.labelList.height * (31.7 / 8) * this.UnitConversion
                ).toString(),
                "@Width": Math.round(
                  this.labelList.width * (31.7 / 8) * this.UnitConversion
                ).toString(),
              },
              LabelPage: {
                "@MeasureUnit": this.labelList.unit,
                "@LabelShape": "Rectangle",
                "@Height": this.labelList.height,
                "@Width": this.labelList.width,
                Rows: this.labelList.line,
                Columns: this.labelList.cloumn,
                RowSpacing: this.labelList.lineHeight,
                ColumnSpacing: this.labelList.spaceCloumn,
                LeftMargin: this.labelList.paddingLeft,
                RightMargin: this.labelList.paddingRight,
                UpperMargin: this.labelList.paddingTop,
                LowerMargin: this.labelList.paddingBottom,
                LabelWidth: this.labelList.width,
                LabelHeight: this.labelList.height,
                Background: this.labelList.Background,
                PrintBackground: this.labelList.PrintBackground,
                Canvas: this.labelList.Canvas,
              },
            },
            ObjectList: {
              "@Count": newSubgroup.length,
              DrawObject: newSubgroup,
            },
          },
        };
      } else {
        var json = {
          "?xml": this.jsonData["?xml"],
          PrtLable: {
            "#comment": this.jsonData.PrtLable["#comment"],
            FileInfo: {
              Creator: {
                "@Platform": "Web",
                "@Version": this.$Version,
              },
            },
            PictureArea: {
              AreaSize: {
                "@Height": Math.round(
                  (31.7 / 8) *
                    this.jsonData.PrtLable.PictureArea.LabelPage.LabelHeight *
                    this.UnitConversion
                ).toString(),
                "@Width": Math.round(
                  (31.7 / 8) *
                    this.jsonData.PrtLable.PictureArea.LabelPage.LabelWidth *
                    this.UnitConversion
                ).toString(),
              },
              LabelPage: this.jsonData.PrtLable.PictureArea.LabelPage,
            },
            ObjectList: {
              "@Count": newSubgroup.length,
              DrawObject: newSubgroup,
            },
          },
        };
      }
      this.loadFile(json);
    },
    //json转xml 获取文件路径
    loadFile(json) {
      const jsonString = JSON.stringify(json);
      // const base64String = btoa(unescape(encodeURIComponent(jsonString)));
      // console.log(base64String)
      // console.log(decodeURIComponent(escape(atob(base64String))))
      var form = new FormData();
      form.append("data", jsonString);
      form.append("type", 0); //是否加密   0不加密 1加密
      form.append("userid", this.userInfo.userid); //用户id
      this.$axiosnet("/LoadXml/loadfile", form)
        .then((res) => {
          this.fileUrl = res;
          if (this.saveAsToOther) {
            window.open(this.postData.url + this.fileUrl.windows, "_parent");
            this.loadingInstance.close();
            this.isSaveBtn = false;
            this.saveAsToOther = false;
          } else {
            this.getLabelImg();
          }
        })
        .catch((err) => {
          this.loadingInstance.close();
          this.isSaveBtn = false;
        });
    },
    //保存生成图片
    getLabelImg() {
      var that = this;
      this.subIndex = -1;
      this.$nextTick(() => {
        let myBox = this.$refs.imageDom;
        html2canvas(myBox, {
          useCORS: true,
        })
          .then(function (canvas) {
            that.fileImage = canvas.toDataURL("image/png", 1);
            that.qinniuApi();
          })
          .catch(() => {
            this.isSaveBtn = false;
          });
      });
    },
    //获取七牛云token
    getQiniuToken() {
      return new Promise((resolve, reject) => {
        var timestamp = Date.parse(new Date());
        timestamp = timestamp / 1000;
        //获取七牛云token
        let data = {
          bucket: this.VUE_APP_VERSION == "hprt" ? "hm-hprt" : "na-hprt",
        };
        this.$axioshanma("/api/tem.app_shared/getToken", data)
          .then((res) => {
            this.postData.token = res.data;
            this.postData.url = res.url;
            this.loadingInstance = ElLoading.service({
              lock: true,
              text: "Loading",
              background: "rgba(0, 0, 0, 0.2)",
            });
            resolve();
          })
          .catch(() => {
            this.isSaveBtn = false;
            resolve();
          });
      });
    },
    //上传保存的图片到七牛云
    qinniuApi() {
      this.loadingInstance.close();
      var savekey = `hm/${
        this.userInfo.userid
      }/tprt/preview/${getCurrentDates()}/${qiniuFilename()}.png`;
      savekey = window.btoa(savekey);
      let config = {
        headers: {
          Authorization: "UpToken " + this.postData.token,
        },
      };
      let baseStrProduct = this.fileImage;
      let urlbase = baseStrProduct.split("base64,")[1];
      axios.post(this.$picupload + savekey, urlbase, config).then((res) => {
        this.fileImage = res.data.key;
        this.saveLabel();
      });
    },
    //保存数据
    saveLabel() {
      if (this.listType == 1 || this.listType == 3) {
        if (this.listType == 1) {
          var width = this.labelList.width;
          var height = this.labelList.height;
        } else if (this.listType == 3) {
          var width = this.jsonData.PrtLable.PictureArea.LabelPage.LabelWidth;
          var height = this.jsonData.PrtLable.PictureArea.LabelPage.LabelHeight;
        }
        let obj = {
          name: this.labelList.name,
          parent_id: this.temId, //默认放在第一个文件夹
          width: width,
          height: height,
          url: this.fileUrl.windows,
          image: this.fileImage,
          platform: 2,
          user_id: this.userInfo.userid,
          windows: this.fileUrl.windows,
          android: this.fileUrl.android,
        };
        this.$axioshanma("/api/tem.app_public/addUserTemplate", obj)
          .then((res) => {
            message.success(this.$t(`tips.tip56`));
            this.labelList = res;
            this.subgroup = [];
            this.listType = 2;
            this.writeFile(this.labelList.file_pc);
            localStorage.setItem("list", JSON.stringify(res));
            localStorage.setItem("listType", 2);
            this.isSaveBtn = false;
          })
          .catch((err) => {
            this.isSaveBtn = false;
            if (err.code == 402) {
              if (process.env.VUE_APP_VERSION == "hprt") {
                this.$refs.MemberDiaRef.show(err.message);
              } else {
                message.error(err.message);
              }
            } else if (err.code == 405) {
              this.$refs.coverFileRef.show(obj);
            }
          });
      } else if (this.listType == 2) {
        let obj = {
          id: this.labelList.id,
          user_id: this.userInfo.userid,
          name: this.labelList.name,
          width: this.jsonData.PrtLable.PictureArea.LabelPage.LabelWidth,
          height: this.jsonData.PrtLable.PictureArea.LabelPage.LabelHeight,
          url: this.fileUrl.windows,
          image: this.fileImage,
          platform: 2,
          windows: this.fileUrl.windows,
          android: this.fileUrl.android,
        };
        this.$axioshanma("/api/tem.app_public/editUserTemplate", obj)
          .then((res) => {
            message.success(this.$t(`tips.tip58`));
            localStorage.setItem("list", JSON.stringify(res));
            this.isSaveBtn = false;
          })
          .catch((err) => {
            this.isSaveBtn = false;
            if (err.code == 402 && process.env.VUE_APP_VERSION == "hprt") {
              this.$refs.MemberDiaRef.show(err.message);
            }
          });
      }
    },
    //覆盖文件成功后刷新数据
    refreshFile(data) {
      this.labelList = data;
      this.subgroup = [];
      this.listType = 2;
      this.writeFile(this.labelList.file_pc);
      localStorage.setItem("list", JSON.stringify(data));
      localStorage.setItem("listType", 2);
    },
    //打印
    handlePrint() {
      if (this.subgroup.length == 0) {
        message.error(this.$t(`tips.tip83`));
        return;
      }

      this.subIndex = -1;
      this.selectUnit = [];
      var newgroup = [];
      var minCount = 0; //记录数据长度
      var firstFind = true;
      let indexArr = []; //记录数据库数据对应的下标
      let sequence = []; //记录序列化数据所在的下标

      //获取序列化数据和数据库数据的最小长度
      this.subgroup.forEach((item, index) => {
        if (
          item.Type == "WinText" ||
          item.Type == "GeneralBarcode" ||
          item.Type == "QRCode" ||
          item.Type == "Table"
        ) {
          item.DataSourceList.map((val, i) => {
            // 序列化文本
            if (val.DataSourceType == "2" && val.Quantity != "" && val.Quantity > 0) {
              let sub = {
                parentIndex: index,
                childIndex: i,
              };
              sequence.push(sub);
              if (firstFind) {
                minCount = val.Quantity;
                firstFind = false;
              } else {
                if (val.Quantity < minCount) {
                  minCount = val.Quantity;
                }
                firstFind = false;
              }
            }
            // 数据库文本
            if (
              val.DataSourceType == "1" &&
              val.ConnectionData &&
              val.ConnectionData.length > 0
            ) {
              let sub = {
                parentIndex: index,
                childIndex: i,
              };
              indexArr.push(sub);
              if (firstFind) {
                minCount = val.ConnectionData.length;
                firstFind = false;
              } else {
                if (val.ConnectionData.length < minCount) {
                  minCount = val.ConnectionData.length;
                }
                firstFind = false;
              }
            }
          });
        }
      });
      // console.log(minCount);
      // console.log(sequence);
      // console.log(indexArr);
      if (minCount > 0) {
        for (let i = 0; i < minCount; i++) {
          let arr = JSON.parse(JSON.stringify(this.subgroup));
          //序列化赋值
          if (sequence.length > 0) {
            for (let j = 0; j < sequence.length; j++) {
              let parentIndex = sequence[j].parentIndex;
              let childIndex = sequence[j].childIndex;
              let data = arr[parentIndex].DataSourceList[childIndex];
              this.dealPrintData(arr[parentIndex].Id);
              let SequenceType = data.SequenceType;
              switch (SequenceType) {
                case "0":
                  var sequenceValue = (
                    data.InitialValue * 1 +
                    data.AddedValue * i
                  ).toString();

                  data[
                    arr[parentIndex].Type == "Table" ? "Data" : "FirstValue"
                  ] = this.setBeforeZero(10, sequenceValue, data);
                  break;
                case "1":
                  var sequenceValue = (
                    parseInt(data.InitialValue, 16) +
                    data.AddedValue * i
                  ).toString(16);
                  data[
                    arr[parentIndex].Type == "Table" ? "Data" : "FirstValue"
                  ] = this.setBeforeZero(16, sequenceValue, data);
                  break;
                case "2":
                  var sequenceValue = this.convertToTwoteenSix(
                    this.convertToTen(data.InitialValue) + data.AddedValue * i
                  );
                  data[
                    arr[parentIndex].Type == "Table" ? "Data" : "FirstValue"
                  ] = this.setBeforeZero(26, sequenceValue, data);
                  break;
                case "3":
                  var sequenceValue = (
                    parseInt(data.InitialValue, 36) +
                    data.AddedValue * i
                  ).toString(36);
                  data[
                    arr[parentIndex].Type == "Table" ? "Data" : "FirstValue"
                  ] = this.setBeforeZero(36, sequenceValue, data);
                  break;
              }
              if (arr[parentIndex].Type != "Table") {
                let value = "";
                arr[parentIndex].DataSourceList.map((val) => {
                  value += val.FirstValue;
                });
                arr[parentIndex].Data = value;

                if (this.reference.length > 0) {
                  this.reference.map((ref) => {
                    arr[ref.PIndex].DataSourceList[ref.CIndex].FirstValue =
                      data.FirstValue;
                    arr[ref.PIndex].Data = "";
                    arr[ref.PIndex].DataSourceList.map((val) => {
                      arr[ref.PIndex].Data += val.FirstValue;
                    });
                  });
                }
              }
            }
          }

          //数据库赋值
          if (indexArr.length > 0) {
            for (let h = 0; h < indexArr.length; h++) {
              let parentIndex = indexArr[h].parentIndex;
              let childIndex = indexArr[h].childIndex;
              let data = arr[parentIndex].DataSourceList[childIndex];
              if (arr[parentIndex].Type == "Table") {
                data.Data = data.ConnectionData[i];
              } else {
                this.dealPrintData(arr[parentIndex].Id);
                data.FirstValue = data.ConnectionData[i];
                let value = "";
                arr[parentIndex].DataSourceList.map((val) => {
                  value += val.FirstValue;
                });
                arr[parentIndex].Data = value;

                if (this.reference.length > 0) {
                  this.reference.map((ref) => {
                    arr[ref.PIndex].DataSourceList[ref.CIndex].FirstValue =
                      data.FirstValue;
                    arr[ref.PIndex].Data = "";
                    arr[ref.PIndex].DataSourceList.map((val) => {
                      arr[ref.PIndex].Data += val.FirstValue;
                    });
                  });
                }
              }
            }
          }

          newgroup.push({ groupIndex: i, group: arr });
        }
      } else {
        newgroup.push({
          groupIndex: 0,
          group: JSON.parse(JSON.stringify(this.subgroup)),
        });
      }
      this.$nextTick(() => {
        //新建标签
        if (this.listType == 1) {
          var cloumn = this.labelList.cloumn;
          var row = this.labelList.line;
          var paddingLeft = this.labelList.paddingLeft;
          var paddingRight = this.labelList.paddingRight;
          var paddingTop = this.labelList.paddingTop;
          var paddingBottom = this.labelList.paddingBottom;
          var ColumnSpacing = this.labelList.spaceCloumn;
          var RowSpacing = this.labelList.lineHeight;
          var width = this.labelList.width;
          var height = this.labelList.height;
          var Background = this.labelList.Background;
          var PrintBackground = this.labelList.PrintBackground;
          var Canvas = this.labelList.Canvas;
        } else if (this.listType == 2 || this.listType == 3) {
          //编辑标签or共享标签
          var cloumn = this.jsonData.PrtLable.PictureArea.LabelPage.Columns;
          var row = this.jsonData.PrtLable.PictureArea.LabelPage.Rows;
          var paddingLeft = this.jsonData.PrtLable.PictureArea.LabelPage.LeftMargin;
          var paddingRight = this.jsonData.PrtLable.PictureArea.LabelPage.RightMargin;
          var paddingTop = this.jsonData.PrtLable.PictureArea.LabelPage.UpperMargin;
          var paddingBottom = this.jsonData.PrtLable.PictureArea.LabelPage.LowerMargin;
          var ColumnSpacing = this.jsonData.PrtLable.PictureArea.LabelPage.ColumnSpacing;
          var RowSpacing = this.jsonData.PrtLable.PictureArea.LabelPage.RowSpacing;
          var Background = this.jsonData.PrtLable.PictureArea.LabelPage.Background;
          var PrintBackground = this.jsonData.PrtLable.PictureArea.LabelPage
            .PrintBackground;
          var Canvas = this.jsonData.PrtLable.PictureArea.LabelPage.Canvas;
          var width = this.jsonData.PrtLable.PictureArea.LabelPage.LabelWidth;
          var height = this.jsonData.PrtLable.PictureArea.LabelPage.LabelHeight;
        }
        this.labelData = {
          name: this.labelList.name,
          width,
          height,
          cloumn,
          row,
          paddingLeft,
          paddingRight,
          paddingTop,
          paddingBottom,
          RowSpacing,
          ColumnSpacing,
          Background,
          PrintBackground,
          Canvas,
          MeasureUnit: this.MeasureUnit,
          newgroup,
        };
        // console.log(this.labelData);
        this.printLabel = true;
      });
    },
    //保存步骤
    handleRecord(type) {
      switch (type) {
        case "add":
          if (this.historyIndex < this.history.length - 1) {
            this.history.splice(this.historyIndex + 1, this.history.length - 1);
            let obj = JSON.parse(JSON.stringify(this.subgroup));
            let arr = JSON.parse(JSON.stringify(this.history));
            arr.push(obj);
            this.history = arr;
            this.historyIndex++;
          } else {
            let obj = JSON.parse(JSON.stringify(this.subgroup));
            let arr = JSON.parse(JSON.stringify(this.history));
            arr.push(obj);
            this.history = arr;
            this.historyIndex++;
          }
          break;
        case "del":
          if (this.historyIndex < this.history.length - 1) {
            this.history.splice(this.historyIndex, this.history.length - 1);
            let obj = JSON.parse(JSON.stringify(this.subgroup));
            let arr = JSON.parse(JSON.stringify(this.history));
            arr.push(obj);
            this.history = arr;
            this.historyIndex++;
          } else {
            let obj = JSON.parse(JSON.stringify(this.subgroup));
            let arr = JSON.parse(JSON.stringify(this.history));
            arr.push(obj);
            this.history = arr;
            this.historyIndex++;
          }
          break;
        default:
          let obj = JSON.parse(JSON.stringify(this.subgroup));
          let arr = JSON.parse(JSON.stringify(this.history));
          arr.push(obj);
          this.history = arr;
          this.historyIndex++;
          break;
      }
      // console.log(this.history);
      // console.log(this.historyIndex);
    },
    //锁定撤销恢复
    handleHistory(type) {
      if (type == 0) {
        if (this.subIndex > -1 && this.selectUnit.length < 2) {
          this.subgroup[this.subIndex].Lock = !this.subgroup[this.subIndex].Lock;
          this.Lock = this.subgroup[this.subIndex].Lock;
          this.handleRecord();
        } else {
          message.warning(this.$t(`tips.tip51`));
        }
      } else if (type == 1) {
        if (this.listType == 1) {
          if (this.historyIndex == 0) {
            this.historyIndex--;
            this.subIndex = -1;
            this.selectUnit = [];
            this.subgroup = [];
            return;
          }
          if (this.historyIndex < 1) {
            return;
          }
          this.selectUnit = [];
          this.historyIndex--;
          let arr = JSON.parse(JSON.stringify(this.history));
          this.subgroup = arr[this.historyIndex];
          this.subIndex = -1;
        } else {
          if (this.historyIndex < 1) {
            return;
          }
          this.selectUnit = [];
          this.historyIndex--;
          let arr = JSON.parse(JSON.stringify(this.history));
          this.subgroup = arr[this.historyIndex];
          this.subIndex = -1;
        }
        this.setSystemTime();
      } else if (type == 2) {
        if (this.historyIndex == this.history.length - 1) {
          return;
        }
        this.historyIndex++;
        this.selectUnit = [];
        let arr = JSON.parse(JSON.stringify(this.history));
        this.subgroup = arr[this.historyIndex];
        this.subIndex = -1;
        this.setSystemTime();
      }
    },
    //置顶置底
    handleOrder(index) {
      if (this.subIndex > -1 || this.selectUnit.length > 0) {
        //多选全选
        if (this.selectUnit.length > 1) {
          // let arr = JSON.parse(JSON.stringify(this.selectUnit));
          let arr = this.selectUnit;
          let resultArr = JSON.parse(JSON.stringify(this.subgroup));
          for (let i = 0; i < arr.length; i++) {
            let delIndex = resultArr.findIndex((item) => item.Id === arr[i].Id);
            resultArr.splice(delIndex, 1);
          }
          if (index == 0) {
            arr.reverse();
            for (let j = 0; j < arr.length; j++) {
              resultArr.unshift(arr[j]);
              this.selectUnit[arr.length - j - 1].index = arr.length - j - 1;
            }
            this.subgroup = resultArr;
            this.subgroup.forEach((e, index) => {
              e.zOrder = index;
            });
          } else if (index == 1) {
            for (let j = 0; j < arr.length; j++) {
              resultArr.push(arr[j]);
              this.selectUnit[j].index = resultArr.length - 1;
            }
            this.subgroup = resultArr;
            this.subgroup.forEach((e, index) => {
              e.zOrder = index;
            });
          }
          this.subIndex = -1;
        } else {
          //单选
          let data = JSON.parse(JSON.stringify(this.subgroup[this.subIndex]));
          // let data = this.subgroup[this.subIndex];
          if (index == 0) {
            this.subgroup.splice(this.subIndex, 1);
            this.subgroup.unshift(data);
            this.subgroup.forEach((e, index) => {
              e.zOrder = index;
            });
            this.subIndex = 0;
            // console.log(this.subgroup);
          } else if (index == 1) {
            this.subgroup.splice(this.subIndex, 1);
            this.subgroup.push(data);
            this.subgroup.forEach((e, index) => {
              e.zOrder = index;
            });
            this.subIndex = this.subgroup.length - 1;
          }
        }
        this.handleRecord();
      } else {
        message.error(this.$t(`tips.tip36`));
        return;
      }
    },
    //复制粘贴删除
    handleOperate(index) {
      if (index == 0) {
        //复制
        // console.log(this.subIndex);
        this.pasteNum = 0;
        this.copyData = [];
        if (this.subIndex > -1 || this.selectUnit.length > 0) {
          //多选复制
          if (this.selectUnit.length > 0) {
            this.copyData = this.selectUnit;
          } else {
            this.copyData.push(JSON.parse(JSON.stringify(this.subgroup[this.subIndex])));
          }
          this.handleRecord();
          message.success(this.$t(`tips.tip35`));
        } else {
          message.error(this.$t(`tips.tip36`));
        }
      } else if (index == 1) {
        //粘贴
        if (this.copyData.length > 0) {
          this.pasteNum++;
          let copyData = JSON.parse(JSON.stringify(this.copyData));
          copyData.forEach((e, index) => {
            let num = 1;
            this.subgroup.forEach((item) => {
              if (item.Type == e.Type) {
                num += 1;
              }
            });
            e.zOrder = this.subgroup.length;
            e.StartX = e.StartX + 5 * this.pasteNum;
            e.StartY = e.StartY + 5 * this.pasteNum;
            e.Id = randomNumberStr(8);
            e.Name = e.Type + num;
            this.subgroup.push(e);
          });
          this.setSystemTime();
          this.handleRecord();
          this.selectUnit = [];
          message.success(this.$t(`tips.tip37`));
        }
      } else if (index == 2) {
        //删除组件
        if (this.subIndex > -1 || this.selectUnit.length > 0) {
          //多选删除
          let recordId = [];
          if (this.subIndex > -1 && this.selectUnit.length == 1) {
            //单选删除
            this.unitHandle = true;
            let Id = this.subgroup[this.subIndex].Id;
            recordId.push(Id);
            let isReferenced = this.subgroup.some((item) => {
              if (
                item.Type == "WinText" ||
                item.Type == "GeneralBarcode" ||
                item.Type == "QRCode"
              ) {
                return item.DataSourceList.some((val) => val.ReferenceId == Id);
              }
            });
            if (isReferenced) {
              //该元素有被其他元素关联
              ElMessageBox.confirm(this.$t(`tips.tip77`), this.$t(`tips.tip71`), {
                confirmButtonText: this.$t(`button.confirm`),
                cancelButtonText: this.$t(`button.cancel`),
                type: "warning",
              })
                .then(() => {
                  this.subgroup.splice(this.subIndex, 1);
                  this.subIndex = this.subIndex - 1;
                  this.dataSourceIndex = 0;
                  this.cellIndex = 0;
                  this.subgroup.forEach((e, index) => {
                    e.zOrder = index;
                  });
                  this.unitHandle = false;
                  this.selectUnit = [];
                  this.delAssociate(recordId);
                  this.setSystemTime();
                  this.handleRecord("del");
                  return;
                })
                .catch(() => {
                  return;
                });
            } else {
              //该元素未被其他元素关联
              this.subgroup.splice(this.subIndex, 1);
              this.subIndex = this.subIndex - 1;
              this.dataSourceIndex = 0;
              this.cellIndex = 0;
              this.subgroup.forEach((e, index) => {
                e.zOrder = index;
              });
              this.unitHandle = false;
              this.selectUnit = [];
              this.setSystemTime();
              this.handleRecord("del");
            }
          } else {
            let isReferenced = false;
            let newArr = this.subgroup.filter((sub) => {
              return !this.selectUnit.find((prop) => {
                return prop.Id == sub.Id;
              });
            });

            this.selectUnit.map((unit) => {
              if (!isReferenced) {
                isReferenced = newArr.some((item) => {
                  if (
                    item.Type == "WinText" ||
                    item.Type == "GeneralBarcode" ||
                    item.Type == "QRCode"
                  ) {
                    return item.DataSourceList.some((val) => val.ReferenceId == unit.Id);
                  }
                });
              }
            });

            if (isReferenced) {
              //选中元素有被其他元素关联
              ElMessageBox.confirm(this.$t(`tips.tip78`), this.$t(`tips.tip71`), {
                confirmButtonText: this.$t(`button.confirm`),
                cancelButtonText: this.$t(`button.cancel`),
                type: "warning",
              })
                .then(() => {
                  for (let item of this.selectUnit) {
                    if (
                      item.Type == "WinText" ||
                      item.Type == "GeneralBarcode" ||
                      item.Type == "QRCode"
                    ) {
                      recordId.push(item.Id);
                    }
                  }
                  this.subgroup = JSON.parse(JSON.stringify(newArr));

                  this.subgroup.forEach((e, index) => {
                    e.zOrder = index;
                  });

                  this.subIndex = -1;
                  this.dataSourceIndex = 0;
                  this.cellIndex = 0;
                  this.selectUnit = [];
                  this.setSystemTime();
                  this.delAssociate(recordId);
                  this.handleRecord("del");
                  return;
                })
                .catch(() => {
                  return;
                });
            } else {
              //选中元素没有被其他元素关联
              this.subgroup = JSON.parse(JSON.stringify(newArr));
              this.subgroup.forEach((e, index) => {
                e.zOrder = index;
              });

              this.subIndex = -1;
              this.dataSourceIndex = 0;
              this.cellIndex = 0;
              this.selectUnit = [];
              this.setSystemTime();
              this.handleRecord("del");
            }
          }
        } else {
          message.error(this.$t(`tips.tip36`));
        }
      }
    },
    //获取本地添加的图片
    getFile(file, fileList) {
      // console.log(file);
      // console.log(file.raw.type);
      var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
      if (!testmsg) {
        message.error(this.$t(`tips.tip39`));
        return;
      }
      if (file.raw.size / 1024 > this.configuration.image_size) {
        message.error(
          this.$t(`tips.tip30`) + this.configuration.image_size + this.$t(`tips.tip31`)
        );
        return;
      }
      this.imgHeader = "data:" + file.raw.type + ";base64,";
      this.getBase64(file.raw).then((res) => {
        let imgs = new Image();
        imgs.src = res;
        imgs.onload = () => {
          let w = imgs.width;
          let h = imgs.height;
          if (imgs.width > 150) {
            h = Math.floor(imgs.height / (imgs.width / 150));
            w = 150;
          }
          this.subgroup[this.subIndex].Width = (w * 25.4) / 96;
          this.subgroup[this.subIndex].Height = (h * 25.4) / 96;
          this.subgroup[this.subIndex].Image = "";
          this.subgroup[this.subIndex].OriginalImage = imgs.src.split(",")[1];
        };
      });
    },
    //本地路径转base64
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    //获取云端数据文件夹列表
    getFolderList() {
      if (this.showCloudFolder || this.showCloudDataList) {
        this.showCloudDataList = false;
        this.showCloudFolder = false;
        return;
      }
      this.$axioshanma("/api/tem.app_public/getCloudClassList", {
        user_id: this.userInfo.userid,
      }).then((res) => {
        this.cloudFileList = res;
        this.showCloudFolder = true;
        this.databaseBtn = false;
      });
    },
    //获取云端数据当前文件夹数据
    getList(index, type) {
      if (
        this.showCloudDataList &&
        this.cloudFileList[index].id == this.queryImgList.id
      ) {
        this.showCloudDataList = !this.showCloudDataList;
        this.queryImgList.id = "";
        return;
      }
      this.queryImgList.type = type;
      this.queryImgList.user_id = this.userInfo.userid;
      this.queryImgList.id = this.cloudFileList[index].id;
      this.$axioshanma("/api/tem.app_public/getCloudList", this.queryImgList).then(
        (res) => {
          this.cloudDataList = res.list;
          if (this.cloudDataList.length == 0) {
            message.error(this.$t(`tips.tip62`));
            return;
          }
          this.showCloudFolder = false;
          this.showCloudDataList = true;
        }
      );
    },
    //返回文件夹列表
    cloudFileBack() {
      this.showCloudDataList = false;
      this.databaseBtn = false;
      this.getFolderList();
      this.cloudDataList = [];
    },
    //选择云端图片
    chooseImage(index) {
      this.showCloudFolder = false;
      this.showCloudDataList = false;

      let imgs = new Image();
      imgs.src = this.cloudDataList[index].url;
      imgs.onload = () => {
        let w = imgs.width;
        let h = imgs.height;
        if (imgs.width > 150) {
          h = Math.floor(imgs.height / (imgs.width / 150));
          w = 150;
        }
        this.subgroup[this.subIndex].Width = (w * 25.4) / 96;
        this.subgroup[this.subIndex].Height = (h * 25.4) / 96;
      };
      this.URLgetBase64(this.cloudDataList[index].url, "pic", (dataURL) => {
        let src = dataURL.split(",")[1];
        this.subgroup[this.subIndex].Image = "";
        this.subgroup[this.subIndex].OriginalImage = src;
        this.subgroup[this.subIndex].ImageFilePath = "";
      });
    },
    //七牛云路径转base64
    URLgetBase64(url, type, callback) {
      var Img = new Image(),
        dataURL = "";
      Img.src = url + "?v=" + Math.random();
      Img.setAttribute("crossOrigin", "Anonymous");
      Img.onload = function () {
        var canvas = document.createElement("canvas");
        if (type == "icon") {
          var width = Img.width;
          var height = Img.height;
        } else {
          if (Img.width > 1000) {
            width = Img.width / 4;
            height = Img.height / 4;
          } else {
            width = Img.width;
            height = Img.height;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(Img, 0, 0, width, height);
        dataURL = canvas.toDataURL("image/png", 0.5);
        return callback ? callback(dataURL) : null;
      };
    },
    //生成错误条形码
    createErrorCode() {
      var that = this;
      return new Promise(function (resolve, reject) {
        let box = that.$refs["badcodeImage"];
        html2canvas(box, {
          useCORS: true,
        }).then(function (canvas) {
          let data = canvas.toDataURL("image/png", 1);
          let image = data.split("base64,")[1];
          resolve(image);
        });
      });
    },
    //生成条形码二维码图片
    handleCreated(type, index) {
      //type == 1条形码   type == 2二维码
      if (type == 1) {
        document.getElementById("barcode").innerHTML = "";
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if (reg.test(this.subgroup[index].Data)) {
          if (!this.subgroup[index].badBarcode) {
            message.error(this.$t(`tips.tip38`));
          }
          this.subgroup[index].badBarcode = true;
          this.origionCodeImage = JSON.parse(
            JSON.stringify(this.subgroup[index].BarcodeImage)
          );
          this.$nextTick(async () => {
            this.subgroup[index].BarcodeImage = await this.createErrorCode();
            let imgs1 = new Image();
            imgs1.onload = () => {
              let w = imgs1.width;
              let h = imgs1.height;
              // this.subgroup[index].Width = (w * 25.4) / 96;
              // this.subgroup[index].Height = (h * 25.4) / 96;
            };
            imgs1.src = this.imgHeader + this.subgroup[index].BarcodeImage;
            return false;
          });
        }

        if (this.subgroup[index].DisplaPosition == 2) {
          var textAlign = "center";
        } else if (this.subgroup[index].DisplaPosition == 1) {
          var textAlign = "left";
        } else if (this.subgroup[index].DisplaPosition == 3) {
          var textAlign = "right";
        }
        let FontStyle = this.subgroup[index].Font["@FontStyle"];
        var fontOptions = "";
        if (FontStyle.indexOf("Bold") != -1) {
          fontOptions = "Bold";
        }
        if (FontStyle.indexOf("Italic") != -1) {
          fontOptions = "Italic";
        }
        if (FontStyle.indexOf("Bold") != -1 && FontStyle.indexOf("Italic") != -1) {
          fontOptions = "Bold Italic";
        }

        // 4.16 是为了生成为300dip时放大图片足够清晰
        let barcode = {
          format: this.subgroup[index].BarcodeType,
          width: 0.35 * this.subgroup[index].Zoom * 4.16,
          height: this.subgroup[index].BarCodeHight * 4.16,
          fontSize: this.subgroup[index].Font["@FontSize"] * 4.16,
          font: this.subgroup[index].Font["@FontFamily"],
          fontOptions: fontOptions,
          textAlign: textAlign,
          displayValue: this.subgroup[index].HasLabel, //是否显示数据
          margin: 10,
          textPosition: "bottom",
          background: "",
        };
        var data = this.subgroup[index].Data;
        try {
          JsBarcode("#barcode", data, barcode);
          this.subgroup[index].badBarcode = false; //判断是否能生成条形码
          let base64Str = changedpi.changeDpiDataUrl(this.$refs.barcode.src, 300);
          // let baseStrProduct = this.$refs.barcode.src.split("base64,")[1];
          let baseStrProduct = base64Str.split("base64,")[1];
          let img = baseStrProduct;
          this.subgroup[index].BarcodeImage = img;
          this.subgroup[index].RightBarcodeImage = img; //存储正确的二维码
          let imgs = new Image();
          imgs.src = this.imgHeader + img;
          var that = this;
          imgs.onload = () => {
            let w = imgs.width / 4.16;
            let h = imgs.height / 4.16;
            that.subgroup[index].Width = (w * 25.4) / 96;
            that.subgroup[index].zoomWidth =
              that.subgroup[index].Width / that.subgroup[index].Zoom;
            // that.subgroup[index].Height = (h * 25.4) / 96;
            that.isCreate = true;
          };
        } catch (err) {
          this.subgroup[index].badBarcode = true;
          if (this.subgroup[index].RightBarcodeImage) {
            this.origionCodeImage = JSON.parse(
              JSON.stringify(this.subgroup[index].RightBarcodeImage)
            );
            this.$nextTick(async () => {
              message.error(this.$t(`tips.tip80`));
              this.subgroup[index].BarcodeImage = await this.createErrorCode();
              let imgs1 = new Image();
              var that = this;
              imgs1.onload = function () {
                let w = imgs1.width;
                let h = imgs1.height;
                // that.subgroup[index].Width = (w * 25.4) / 96;
                // that.subgroup[index].Height = (h * 25.4) / 96;
              };
              imgs1.src = this.imgHeader + this.subgroup[index].BarcodeImage;
            });
          }
        }
      } else if (type == 2) {
        let ECLevelBits = this.subgroup[index].ECLevelBits;
        let bcid;
        let eclevel = "";
        switch (this.subgroup[index].BarcodeType) {
          case "PDF_417":
            bcid = "pdf417";
            let value = ECLevelBits.replace(/[^\d]/g, " ");
            if (value) {
              eclevel = value;
            } else {
              eclevel = 0;
            }
            break;
          case "DATA_MATRIX":
            bcid = "datamatrix";
            break;
          case "AZTEC":
            bcid = "azteccode";
            eclevel = "23";
            break;
          default:
            bcid = "qrcode";
            if (ECLevelBits == "Medium") {
              eclevel = "M";
            } else if (ECLevelBits == "Medium High") {
              eclevel = "Q";
            } else if (ECLevelBits == "High") {
              eclevel = "H";
            } else {
              eclevel = "L";
            }
            break;
        }
        let canvas = document.createElement("canvas");
        let options = {
          bcid,
          text: this.subgroup[index].Data,
          scale: this.subgroup[index].Zoom,
          eclevel,
        };
        bwipjs.toCanvas(canvas, options);
        let baseStrProduct = canvas.toDataURL("image/png").split("base64,")[1];
        this.subgroup[index].BarcodeImage = baseStrProduct;

        let qrImg = new Image();
        qrImg.onload = () => {
          this.subgroup[index].Width = ((qrImg.width / 2) * 25.4) / 96;
          this.subgroup[index].Height = ((qrImg.height / 2) * 25.4) / 96;
          this.subgroup[index].QRCodeWidth =
            this.subgroup[index].Width / this.subgroup[index].Zoom;
          this.subgroup[index].QRCodeHeight =
            this.subgroup[index].Height / this.subgroup[index].Zoom;
          this.isCreate = true;
        };
        qrImg.src = canvas.toDataURL("image/png");
      } else {
        return;
      }
    },
    //关闭新手引导
    closeEditGuide() {
      this.showEditGuide = false;
      localStorage.setItem("firstEdit", "false");
    },
  },
  unmounted() {
    clearInterval(this.systemTimer);
    this.systemTimer = null;
    this.fontsizeMsg ? this.fontsizeMsg.close() : "";
    document.onkeydown = null;
  },
  destoryed() {
    clearInterval(this.systemTimer);
    this.systemTimer = null;
    message.destroy();
    document.onkeydown = null;
    document.removeEventListener("mousewheel");
    document.removeEventListener("DOMMouseScroll");
  },
};
</script>
