import i18n from "../language/i18n";
// 时间戳转日期 几天后
export const formatTime = (time) => {
  const d = new Date(time * 1000);

  const now = new Date();
  const diff = d.getDate() - now.getDate();

  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();

  if (month == now.getMonth() + 1 && diff > 0 && diff < 8) {
    return (
      i18n.global.t(`label.label57`) + diff + i18n.global.t(`label.label58`)
    );
  } else if (year == now.getFullYear() && month == now.getMonth() + 1 && diff == 0) {
    return i18n.global.t(`label.label60`);
  }

  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }

  return (
    i18n.global.t(`label.label57`) +
    year +
    "-" +
    month +
    "-" +
    date +
    i18n.global.t(`label.label59`)
  );
};

// 时间戳转日期
export const formatDate = (time) => {
  const d = new Date(time * 1000);

  const now = new Date();
  const diff = d.getDate() - now.getDate();

  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();

  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }

  return year + "-" + month + "-" + date;
};

//获取当前时间
export const getDates = () => {
  var dd = new Date();
  var year = dd.getFullYear();
  var month = dd.getMonth() + 1;
  var date = dd.getDate();
  var hours = dd.getHours();
  var minute = dd.getMinutes();
  var second = dd.getSeconds();
  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  if (second < 10) {
    second = "0" + second;
  }
  return (
    year + "-" + month + "-" + date + " " + hours + ":" + minute + ":" + second
  );
};

//获取当前日期
export const getCurrentDates = () => {
  var dd = new Date()
  var year = dd.getFullYear()
  var month = dd.getMonth() + 1
  if (month < 10) {
    month = "0" + month
  }
  var date = dd.getDate()
  if (date < 10) {
    date = "0" + date
  }
  return `${year}-${month}-${date}` 
}
