import { message } from "ant-design-vue";
var parent = "";
var alignValue = 0.25; //设置误差值,单位mm  1mm == 12px
var AngleRound = ""; //拖拽元素的旋转角度
var line = "0.1mm";
const alignment = {
  data() {
    return {
      dragData: [], //存放拖拽组件的坐标信息
      referenceArr: [], //存放参考元素
    };
  },
  methods: {
    //对齐操作
    handleSet(type) {
      //设置组件对齐
      //多选对齐
      if (this.selectUnit.length > 1) {
        let array = JSON.parse(JSON.stringify(this.selectUnit));
        if (type == 0) {
          //左对齐
          let left = Math.min.apply(
            Math,
            array.map(function (val) {
              switch (val.Type) {
                case "Line":
                  if (val.EndX < val.StartX) {
                    return val.EndX;
                  } else {
                    return val.StartX;
                  }
                case "WinText":
                case "TimeText":
                  if (val.AngleRound == 90) {
                    return val.StartX - val.Height;
                  } else if (val.AngleRound == 180) {
                    if (val.AutoWrap) {
                      return val.StartX - val.BoxWidth;
                    }
                    return val.StartX - val.Width;
                  } else {
                    return val.StartX;
                  }
                default:
                  if (val.AngleRound == 90) {
                    return val.StartX - val.Height;
                  } else if (val.AngleRound == 180) {
                    return val.StartX - val.Width;
                  } else {
                    return val.StartX;
                  }
              }
            })
          );

          for (let i = 0; i < array.length; i++) {
            let AngleRound = this.subgroup[array[i].index].AngleRound;
            switch (this.subgroup[array[i].index].Type) {
              case "Line":
                if (
                  this.subgroup[array[i].index].StartX >
                  this.subgroup[array[i].index].EndX
                ) {
                  this.subgroup[array[i].index].StartX =
                    left -
                    this.subgroup[array[i].index].Width +
                    this.subgroup[array[i].index].PenWidth / 4;
                  this.subgroup[array[i].index].EndX =
                    left + this.subgroup[array[i].index].PenWidth / 4;
                } else {
                  this.subgroup[array[i].index].StartX = left;
                  this.subgroup[array[i].index].EndX =
                    left + Math.abs(this.subgroup[array[i].index].Width);
                }
                break;
              case "WinText":
              case "TimeText":
                if (AngleRound == 90) {
                  this.subgroup[array[i].index].StartX =
                    left + this.subgroup[array[i].index].Height;
                } else if (AngleRound == 180) {
                  if (this.subgroup[array[i].index].AutoWrap) {
                    this.subgroup[array[i].index].StartX =
                      left + this.subgroup[array[i].index].BoxWidth;
                  } else {
                    this.subgroup[array[i].index].StartX =
                      left + this.subgroup[array[i].index].Width;
                  }
                } else {
                  this.subgroup[array[i].index].StartX = left;
                }
                break;
              default:
                if (AngleRound == 90) {
                  this.subgroup[array[i].index].StartX =
                    left + this.subgroup[array[i].index].Height;
                } else if (AngleRound == 180) {
                  this.subgroup[array[i].index].StartX =
                    left + this.subgroup[array[i].index].Width;
                } else {
                  this.subgroup[array[i].index].StartX = left;
                }
                break;
            }
          }
        } else if (type == 1) {
          //垂直居中
          let width = Math.max.apply(
            Math,
            array.map(function (val) {
              switch (val.Type) {
                case "Line":
                case "LineVertical":
                case "LineHorizontal":
                  return val.Width;
                case "WinText":
                case "TimeText":
                  if (val.AngleRound == 90 || val.AngleRound == 270) {
                    return val.Height;
                  } else {
                    if (val.AutoWrap) {
                      return val.BoxWidth;
                    }
                    return val.Width;
                  }
                default:
                  if (val.AngleRound == 90 || val.AngleRound == 270) {
                    return val.Height;
                  } else {
                    return val.Width;
                  }
              }
            })
          );
          let left = 0;
          for (let i = 0; i < array.length; i++) {
            let AngleRound = array[i].AngleRound;
            switch (array[i].Type) {
              case "Line":
              case "LineVertical":
              case "LineHorizontal":
                if (array[i].Width == width) {
                  left = array[i].Width;
                }
                break;
              default:
                if (AngleRound == 90) {
                  if (array[i].Height == width) {
                    left = array[i].StartX - array[i].Height;
                  }
                } else if (AngleRound == 270) {
                  if (array[i].Height == width) {
                    left = array[i].StartX;
                  }
                } else if (AngleRound == 180) {
                  if (
                    (array[i].Type == "WinText" ||
                      array[i].Type == "TimeText") &&
                    array[i].AutoWrap
                  ) {
                    if (array[i].BoxWidth == width) {
                      left = array[i].StartX - array[i].BoxWidth;
                    } else {
                      if (array[i].Width == width) {
                        left = array[i].StartX - array[i].Width;
                      }
                    }
                  }
                } else {
                  if (
                    (array[i].Type == "WinText" ||
                      array[i].Type == "TimeText") &&
                    array[i].AutoWrap
                  ) {
                    if (array[i].BoxWidth == width) {
                      left = array[i].StartX;
                    } else {
                      if (array[i].Width == width) {
                        left = array[i].StartX;
                      }
                    }
                  }
                }
                break;
            }
          }

          for (let i = 0; i < array.length; i++) {
            let AngleRound = this.subgroup[array[i].index].AngleRound;
            switch (this.subgroup[array[i].index].Type) {
              case "Line":
                this.subgroup[array[i].index].StartX =
                  (width - array[i].Width) / 2 + left;
                this.subgroup[array[i].index].EndX =
                  this.subgroup[array[i].index].StartX +
                  this.subgroup[array[i].index].Width;
                break;
              case "LineVertical":
              case "LineHorizontal":
                this.subgroup[array[i].index].StartX =
                  (width - array[i].Width) / 2 + left;
                break;
              default:
                if (AngleRound == 90) {
                  this.subgroup[array[i].index].StartX =
                    (width - array[i].Height) / 2 +
                    left +
                    this.subgroup[array[i].index].Height;
                } else if (AngleRound == 180) {
                  if (
                    (this.subgroup[array[i].index].Type == "WinText" ||
                      this.subgroup[array[i].index].Type == "TimeText") &&
                    this.subgroup[array[i].index].AutoWrap
                  ) {
                    this.subgroup[array[i].index].StartX =
                      width -
                      array[i].BoxWidth / 2 +
                      left +
                      this.subgroup[array[i].index].BoxWidth;
                  } else {
                    this.subgroup[array[i].index].StartX =
                      (width - array[i].Width) / 2 +
                      left +
                      this.subgroup[array[i].index].Width;
                  }
                } else if (AngleRound == 270) {
                  this.subgroup[array[i].index].StartX =
                    (width - array[i].Height) / 2 + left;
                } else {
                  if (
                    (this.subgroup[array[i].index].Type == "WinText" ||
                      this.subgroup[array[i].index].Type == "TimeText") &&
                    this.subgroup[array[i].index].AutoWrap
                  ) {
                    this.subgroup[array[i].index].StartX =
                      (width - array[i].BoxWidth) / 2 + left;
                  } else {
                    this.subgroup[array[i].index].StartX =
                      (width - array[i].Width) / 2 + left;
                  }
                }
                break;
            }
          }
        } else if (type == 2) {
          //右对齐
          let width = Math.max.apply(
            Math,
            array.map(function (val) {
              switch (val.Type) {
                case "Line":
                  if (val.StartX > val.EndX) {
                    return val.Width + val.EndX;
                  } else {
                    return val.Width + val.StartX;
                  }
                case "LineVertical":
                case "LineHorizontal":
                  return val.Width + val.StartX;
                default:
                  if (val.AngleRound == 90 || val.AngleRound == 180) {
                    return val.StartX;
                  } else if (val.AngleRound == 270) {
                    return val.Height + val.StartX;
                  } else {
                    if (
                      (val.Type == "WinText" || val.Type == " TimeText") &&
                      val.AutoWrap
                    ) {
                      return val.BoxWidth + val.StartX;
                    }
                    return val.Width + val.StartX;
                  }
              }
            })
          );

          for (let i = 0; i < array.length; i++) {
            let AngleRound = this.subgroup[array[i].index].AngleRound;
            switch (this.subgroup[array[i].index].Type) {
              case "Line":
                if (
                  this.subgroup[array[i].index].StartX >
                  this.subgroup[array[i].index].EndX
                ) {
                  this.subgroup[array[i].index].StartX =
                    width - this.subgroup[array[i].index].PenWidth / 4;
                  this.subgroup[array[i].index].EndX =
                    this.subgroup[array[i].index].StartX +
                    this.subgroup[array[i].index].Width;
                } else {
                  this.subgroup[array[i].index].StartX = width - array[i].Width;
                  this.subgroup[array[i].index].EndX =
                    this.subgroup[array[i].index].StartX +
                    this.subgroup[array[i].index].Width;
                }

                break;
              case "LineVertical":
              case "LineHorizontal":
                this.subgroup[array[i].index].StartX = width - array[i].Width;
                break;
              default:
                if (AngleRound == 90 || AngleRound == 180) {
                  this.subgroup[array[i].index].StartX = width;
                } else if (AngleRound == 270) {
                  this.subgroup[array[i].index].StartX =
                    width - array[i].Height;
                } else {
                  if (
                    (this.subgroup[array[i].index].Type == "WinText" ||
                      this.subgroup[array[i].index].Type == " TimeText") &&
                    this.subgroup[array[i].index].AutoWrap
                  ) {
                    this.subgroup[array[i].index].StartX =
                      width - array[i].BoxWidth;
                  } else {
                    this.subgroup[array[i].index].StartX =
                      width - array[i].Width;
                  }
                }
                break;
            }
          }
        } else if (type == 3) {
          //顶对齐
          let top = Math.min.apply(
            Math,
            array.map(function (val) {
              switch (val.Type) {
                case "Line":
                  if (val.EndY < val.StartY) {
                    return val.EndY;
                  } else {
                    return val.StartY;
                  }
                default:
                  if (val.AngleRound == 180) {
                    return val.StartY - val.Height;
                  } else if (val.AngleRound == 270) {
                    if (
                      (val.Type == "WinText" || val.Type == " TimeText") &&
                      val.AutoWrap
                    ) {
                      return val.StartX - val.BoxWidth;
                    }
                    return val.StartY - val.Width;
                  } else {
                    return val.StartY;
                  }
              }
            })
          );
          for (let i = 0; i < array.length; i++) {
            let AngleRound = this.subgroup[array[i].index].AngleRound;
            switch (this.subgroup[array[i].index].Type) {
              case "Line":
                if (
                  this.subgroup[array[i].index].StartY >
                  this.subgroup[array[i].index].EndY
                ) {
                  this.subgroup[array[i].index].StartY =
                    top + Math.abs(this.subgroup[array[i].index].Height);
                  this.subgroup[array[i].index].EndY = top;
                } else {
                  this.subgroup[array[i].index].StartY = top;
                  this.subgroup[array[i].index].EndY =
                    top + Math.abs(this.subgroup[array[i].index].Height);
                }
                break;
              default:
                if (AngleRound == 180) {
                  this.subgroup[array[i].index].StartY =
                    top + this.subgroup[array[i].index].Height;
                } else if (AngleRound == 270) {
                  if (
                    (this.subgroup[array[i].index].Type == "WinText" ||
                      this.subgroup[array[i].index].Type == " TimeText") &&
                    this.subgroup[array[i].index].AutoWrap
                  ) {
                    this.subgroup[array[i].index].StartY =
                      top + this.subgroup[array[i].index].BoxWidth;
                  } else {
                    this.subgroup[array[i].index].StartY =
                      top + this.subgroup[array[i].index].Width;
                  }
                } else {
                  this.subgroup[array[i].index].StartY = top;
                }
                break;
            }
          }
        } else if (type == 4) {
          //水平居中
          let height = Math.max.apply(
            Math,
            array.map(function (val) {
              switch (val.Type) {
                case "Line":
                case "LineVertical":
                case "LineHorizontal":
                  return val.Height;
                default:
                  if (val.AngleRound == 90 || val.AngleRound == 270) {
                    if (
                      (val.Type == "WinText" || val.Type == " TimeText") &&
                      val.AutoWrap
                    ) {
                      return val.BoxWidth;
                    }
                    return val.Width;
                  } else {
                    return val.Height;
                  }
              }
            })
          );
          let top = 0;
          for (let i = 0; i < array.length; i++) {
            let AngleRound = array[i].AngleRound;
            switch (array[i].Type) {
              case "Line":
              case "LineVertical":
              case "LineHorizontal":
                if (array[i].Height == height) {
                  top = array[i].Height;
                }
                break;
              default:
                if (AngleRound == 90) {
                  if (
                    (array[i].Type == "WinText" ||
                      array[i].Type == " TimeText") &&
                    array[i].AutoWrap
                  ) {
                    if (array[i].BoxWidth == height) {
                      top = array[i].StartY;
                    }
                  } else {
                    if (array[i].Width == height) {
                      top = array[i].StartY;
                    }
                  }
                } else if (AngleRound == 270) {
                  if (
                    (array[i].Type == "WinText" ||
                      array[i].Type == " TimeText") &&
                    array[i].AutoWrap
                  ) {
                    if (array[i].BoxWidth == height) {
                      top = array[i].StartY - array[i].BoxWidth6;
                    }
                  } else {
                    if (array[i].Width == height) {
                      top = array[i].StartY - array[i].Width;
                    }
                  }
                } else if (AngleRound == 180) {
                  if (array[i].Height == height) {
                    top = array[i].StartY - array[i].Height;
                  }
                } else {
                  if (array[i].Height == height) {
                    top = array[i].StartY;
                  }
                }
                break;
            }
          }
          for (let i = 0; i < array.length; i++) {
            let AngleRound = this.subgroup[array[i].index].AngleRound;
            switch (this.subgroup[array[i].index].Type) {
              case "Line":
                this.subgroup[array[i].index].StartY =
                  (height - array[i].Height) / 2 + top;
                this.subgroup[array[i].index].EndY =
                  this.subgroup[array[i].index].StartY +
                  this.subgroup[array[i].index].Height;
                break;
              case "LineVertical":
              case "LineHorizontal":
                this.subgroup[array[i].index].StartY =
                  (height - array[i].Height) / 2 + top;
                break;
              default:
                if (AngleRound == 90) {
                  if (
                    (this.subgroup[array[i].index].Type == "WinText" ||
                      this.subgroup[array[i].index].Type == " TimeText") &&
                    this.subgroup[array[i].index].AutoWrap
                  ) {
                    this.subgroup[array[i].index].StartY =
                      (height - array[i].BoxWidth) / 2 + top;
                  } else {
                    this.subgroup[array[i].index].StartY =
                      (height - array[i].Width) / 2 + top;
                  }
                } else if (AngleRound == 180) {
                  this.subgroup[array[i].index].StartY =
                    (height - array[i].Height) / 2 + top + array[i].Height;
                } else if (AngleRound == 270) {
                  if (
                    (this.subgroup[array[i].index].Type == "WinText" ||
                      this.subgroup[array[i].index].Type == " TimeText") &&
                    this.subgroup[array[i].index].AutoWrap
                  ) {
                    this.subgroup[array[i].index].StartY =
                      (height - array[i].BoxWidth) / 2 +
                      top +
                      array[i].BoxWidth;
                  } else {
                    this.subgroup[array[i].index].StartY =
                      (height - array[i].Width) / 2 + top + array[i].Width;
                  }
                } else {
                  this.subgroup[array[i].index].StartY =
                    (height - array[i].Height) / 2 + top;
                }
                break;
            }
          }
        } else if (type == 5) {
          //底对齐
          let height = Math.max.apply(
            Math,
            array.map(function (val) {
              switch (val.Type) {
                case "Line":
                  if (val.StartY > val.EndY) {
                    return val.Height + val.EndY;
                  } else {
                    return val.Height + val.StartY;
                  }
                case "LineVertical":
                case "LineHorizontal":
                  return val.Height + val.StartY;
                default:
                  if (val.AngleRound == 90) {
                    if (
                      (val.Type == "WinText" || val.Type == " TimeText") &&
                      val.AutoWrap
                    ) {
                      return val.BoxWidth + val.StartY;
                    }
                    return val.Width + val.StartY;
                  } else if (val.AngleRound == 180 || val.AngleRound == 270) {
                    return val.StartY;
                  } else {
                    return val.Height + val.StartY;
                  }
              }
            })
          );
          for (let i = 0; i < array.length; i++) {
            let AngleRound = this.subgroup[array[i].index].AngleRound;
            switch (this.subgroup[array[i].index].Type) {
              case "Line":
                if (
                  this.subgroup[array[i].index].StartY >
                  this.subgroup[array[i].index].EndY
                ) {
                  this.subgroup[array[i].index].StartY = height;
                  this.subgroup[array[i].index].EndY =
                    this.subgroup[array[i].index].StartY +
                    this.subgroup[array[i].index].Height;
                } else {
                  this.subgroup[array[i].index].StartY =
                    height - array[i].Height;
                  this.subgroup[array[i].index].EndY =
                    this.subgroup[array[i].index].StartY +
                    this.subgroup[array[i].index].Height;
                }
                break;
              case "LineVertical":
              case "LineHorizontal":
                this.subgroup[array[i].index].StartY = height - array[i].Height;
                break;
              default:
                if (AngleRound == 90) {
                  if (
                    (this.subgroup[array[i].index].Type == "WinText" ||
                      this.subgroup[array[i].index].Type == " TimeText") &&
                    this.subgroup[array[i].index].AutoWrap
                  ) {
                    this.subgroup[array[i].index].StartY =
                      height - array[i].BoxWidth;
                  } else {
                    this.subgroup[array[i].index].StartY =
                      height - array[i].Width;
                  }
                } else if (AngleRound == 180 || AngleRound == 270) {
                  this.subgroup[array[i].index].StartY = height;
                } else {
                  this.subgroup[array[i].index].StartY =
                    height - array[i].Height;
                }
                break;
            }
          }
        }
      } else {
        //单选对齐
        if (this.subIndex < 0) {
          message.error(this.$t(`tips.tip36`));
          return;
        }
        if (this.subgroup[this.subIndex].Lock) {
          return;
        }
        let AngleRound = this.subgroup[this.subIndex].AngleRound;
        let width = this.subgroup[this.subIndex].Width;
        //文本自动换行
        if (
          (this.subgroup[this.subIndex].Type == "WinText" ||
            this.subgroup[this.subIndex].Type == "TimeText") &&
          this.subgroup[this.subIndex].AutoWrap
        ) {
          width = this.subgroup[this.subIndex].BoxWidth;
        }
        if (type == 6) {
          switch (this.subgroup[this.subIndex].Type) {
            case "LineVertical":
              var left =
                (this.template.width -
                  this.subgroup[this.subIndex].PenWidth / 4) /
                2;
              this.subgroup[this.subIndex].StartX = left;
              this.subgroup[this.subIndex].EndX = left + width;
              break;
            case "LineHorizontal":
            case "Line":
              var left = (this.template.width - width) / 2;
              this.subgroup[this.subIndex].StartX = left;
              this.subgroup[this.subIndex].EndX = left + width;
              break;
            default:
              if (AngleRound == 90) {
                var left =
                  (this.template.width - this.subgroup[this.subIndex].Height) /
                  2;
                this.subgroup[this.subIndex].StartX =
                  left + this.subgroup[this.subIndex].Height;
              } else if (AngleRound == 180) {
                var left = (this.template.width - width) / 2;
                this.subgroup[this.subIndex].StartX = left + width;
              } else if (AngleRound == 270) {
                var left =
                  (this.template.width - this.subgroup[this.subIndex].Height) /
                  2;
                this.subgroup[this.subIndex].StartX = left;
              } else {
                var left = (this.template.width - width) / 2;
                this.subgroup[this.subIndex].StartX = left;
              }
              break;
          }
        } else if (type == 7) {
          switch (this.subgroup[this.subIndex].Type) {
            case "LineHorizontal":
              var top =
                (this.template.height -
                  this.subgroup[this.subIndex].PenWidth / this.conversion) /
                2;
              this.subgroup[this.subIndex].StartY = top;
              this.subgroup[this.subIndex].EndY = top + width;
              break;
            case "LineVertical":
            case "Line":
              var top =
                (this.template.height - this.subgroup[this.subIndex].Height) /
                2;
              this.subgroup[this.subIndex].StartY = top;
              this.subgroup[this.subIndex].EndY = top + width;
              break;
            default:
              if (AngleRound == 90) {
                var top = (this.template.height - width) / 2;
                this.subgroup[this.subIndex].StartY = top;
              } else if (AngleRound == 180) {
                var top =
                  (this.template.height - this.subgroup[this.subIndex].Height) /
                  2;
                this.subgroup[this.subIndex].StartY =
                  top + this.subgroup[this.subIndex].Height;
              } else if (AngleRound == 270) {
                var top = (this.template.height - width) / 2;
                this.subgroup[this.subIndex].StartY = top + width;
              } else {
                var top =
                  (this.template.height - this.subgroup[this.subIndex].Height) /
                  2;
                this.subgroup[this.subIndex].StartY = top;
              }
              break;
          }
        }
      }
      this.handleRecord();
    },
    //获取参考对象
    referenceObject(Index) {
      parent = document.getElementById("design");
      let index = Index;
      AngleRound = this.subgroup[index].AngleRound;
      let elementWidth = this.subgroup[index].Width;
      let elementHeight = this.subgroup[index].Height;

      if (
        (this.subgroup[index].Type == "WinText" ||
          this.subgroup[index].Type == "TimeText") &&
        this.subgroup[index].AutoWrap
      ) {
        elementWidth = this.subgroup[index].BoxWidth;
      }
      switch (AngleRound) {
        case 0:
          this.dragData.subTop = this.subgroup[index].StartY;
          this.dragData.subLeft = this.subgroup[index].StartX;
          this.dragData.subBottom = this.subgroup[index].StartY + elementHeight;
          this.dragData.subRight = this.subgroup[index].StartX + elementWidth;
          this.dragData.subCenterH =
            this.subgroup[index].StartY + elementHeight / 2;
          this.dragData.subCenterV =
            this.subgroup[index].StartX + elementWidth / 2;
          break;
        case 90:
          this.dragData.subTop = this.subgroup[index].StartY;
          this.dragData.subLeft = this.subgroup[index].StartX - elementHeight;
          this.dragData.subBottom = this.subgroup[index].StartY + elementWidth;
          this.dragData.subRight = this.subgroup[index].StartX;
          this.dragData.subCenterH =
            this.subgroup[index].StartY + elementWidth / 2;
          this.dragData.subCenterV =
            this.subgroup[index].StartX - elementHeight / 2;
          break;
        case 180:
          this.dragData.subTop = this.subgroup[index].StartY - elementHeight;
          this.dragData.subLeft = this.subgroup[index].StartX - elementWidth;
          this.dragData.subBottom = this.subgroup[index].StartY;
          this.dragData.subRight = this.subgroup[index].StartX;
          this.dragData.subCenterH =
            this.subgroup[index].StartY - elementHeight / 2;
          this.dragData.subCenterV =
            this.subgroup[index].StartX - elementWidth / 2;
          break;
        case 270:
          this.dragData.subTop = this.subgroup[index].StartY - elementWidth;
          this.dragData.subLeft = this.subgroup[index].StartX;
          this.dragData.subBottom = this.subgroup[index].StartY;
          this.dragData.subRight = this.subgroup[index].StartX + elementHeight;
          this.dragData.subCenterH =
            this.subgroup[index].StartY - elementWidth / 2;
          this.dragData.subCenterV =
            this.subgroup[index].StartX + elementHeight / 2;
          break;
      }

      this.referenceArr = [];
      this.getCanvasLine(index);
      this.getElementLine(index);
    },
    // 获取需要绘制的画布参考线
    getCanvasLine(index) {
      //绘制画布对齐线
      //画布左对齐参考线
      if (
        Math.abs(this.dragData.subLeft) <= alignValue ||
        Math.abs(this.dragData.subRight) <= alignValue ||
        Math.abs(this.dragData.subCenterV) <= alignValue
      ) {
        this.drawCanvasLine(index, "left");
      } else {
        let elem = document.getElementById("canvasLeft");
        elem ? parent.removeChild(elem) : "";
      }
      //画布右对齐参考线
      if (
        Math.abs(this.template.width - this.dragData.subLeft) <= alignValue ||
        Math.abs(this.template.width - this.dragData.subRight) <= alignValue ||
        Math.abs(this.template.width - this.dragData.subCenterV) <= alignValue
      ) {
        this.drawCanvasLine(index, "right");
      } else {
        let elem = document.getElementById("canvasRight");
        elem ? parent.removeChild(elem) : "";
      }
      //画布顶对齐参考线
      if (
        Math.abs(this.dragData.subTop) <= alignValue ||
        Math.abs(this.dragData.subBottom) <= alignValue ||
        Math.abs(this.dragData.subCenterH) <= alignValue
      ) {
        this.drawCanvasLine(index, "top");
      } else {
        let elem = document.getElementById("canvasTop");
        elem ? parent.removeChild(elem) : "";
      }
      //画布底对齐参考线
      if (
        Math.abs(this.template.height - this.dragData.subTop) <= alignValue ||
        Math.abs(this.template.height - this.dragData.subBottom) <=
          alignValue ||
        Math.abs(this.template.height - this.dragData.subCenterH) <= alignValue
      ) {
        this.drawCanvasLine(index, "bottom");
      } else {
        let elem = document.getElementById("canvasBottom");
        elem ? parent.removeChild(elem) : "";
      }
      //画布水平居中对齐参考线
      if (
        Math.abs(this.template.height / 2 - this.dragData.subTop) <=
          alignValue ||
        Math.abs(this.template.height / 2 - this.dragData.subBottom) <=
          alignValue ||
        Math.abs(this.template.height / 2 - this.dragData.subCenterH) <=
          alignValue
      ) {
        this.drawCanvasLine(index, "centerV");
      } else {
        let elem = document.getElementById("canvasCenterV");
        elem ? parent.removeChild(elem) : "";
      }
      //画布垂直居中对齐参考线
      if (
        Math.abs(this.template.width / 2 - this.dragData.subLeft) <=
          alignValue ||
        Math.abs(this.template.width / 2 - this.dragData.subRight) <=
          alignValue ||
        Math.abs(this.template.width / 2 - this.dragData.subCenterV) <=
          alignValue
      ) {
        this.drawCanvasLine(index, "centerH");
      } else {
        let elem = document.getElementById("canvasCenterH");
        elem ? parent.removeChild(elem) : "";
      }
    },
    //绘制画布参考线
    drawCanvasLine(index, type) {
      let elem = document.createElement("div");
      elem.style.backgroundColor = "#ff4d4f ";
      elem.style.zIndex = "999";
      elem.style.position = "absolute";
      let Width = this.subgroup[index].Width;
      let Height = this.subgroup[index].Height;
      if (
        (this.subgroup[index].Type == "WinText" ||
          this.subgroup[index].Type == "TimeText") &&
        this.subgroup[index].AutoWrap
      ) {
        Width = this.subgroup[index].BoxWidth;
      }
      switch (type) {
        case "left":
          let canvasLeft = document.getElementById("canvasLeft");
          canvasLeft ? parent.removeChild(canvasLeft) : "";
          elem.style.left = "0mm";
          elem.style.top = "0mm";
          elem.style.width = line;
          elem.style.height = this.template.height + "mm";
          elem.setAttribute("id", "canvasLeft");
          //元件左边对齐画布左边
          if (Math.abs(this.dragData.subLeft) <= alignValue) {
            if (AngleRound == 0 || AngleRound == 270) {
              this.subgroup[index].StartX = 0;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartX = Height;
            } else {
              this.subgroup[index].StartX = Width;
            }
          }
          //元件右边对齐画布左边
          if (Math.abs(this.dragData.subRight) <= alignValue) {
            if (AngleRound == 0) {
              this.subgroup[index].StartX = -Width;
            } else if (AngleRound == 270) {
              this.subgroup[index].StartX = -Height;
            } else {
              this.subgroup[index].StartX = 0;
            }
          }
          //元件中间对齐画布左边
          if (Math.abs(this.dragData.subCenterV) <= alignValue) {
            if (AngleRound == 0) {
              this.subgroup[index].StartX = -Width / 2;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartX = Height / 2;
            } else if (AngleRound == 270) {
              this.subgroup[index].StartX = -Heigth / 2;
            } else {
              this.subgroup[index].StartX = Width / 2;
            }
          }
          break;
        case "right":
          let canvasRight = document.getElementById("canvasRight");
          canvasRight ? parent.removeChild(canvasRight) : "";
          elem.style.left = this.template.width + "mm";
          elem.style.top = "0mm";
          elem.style.width = line;
          elem.style.height = this.template.height + "mm";
          elem.setAttribute("id", "canvasRight");
          //元件左边对齐画布右边
          if (
            Math.abs(this.template.width - this.dragData.subLeft) <= alignValue
          ) {
            if (AngleRound == 0 || AngleRound == 270) {
              this.subgroup[index].StartX = this.template.width;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartX = this.template.width + Height;
            } else {
              this.subgroup[index].StartX = this.template.width + Width;
            }
          }
          //元件右边对齐画布右边
          if (
            Math.abs(this.template.width - this.dragData.subRight) <= alignValue
          ) {
            if (AngleRound == 0) {
              this.subgroup[index].StartX = this.template.width - Width;
            } else if (AngleRound == 270) {
              this.subgroup[index].StartX = this.template.width - Height;
            } else {
              this.subgroup[index].StartX = this.template.width;
            }
          }
          //元件中间对齐画布右边
          if (
            Math.abs(this.template.width - this.dragData.subCenterV) <=
            alignValue
          ) {
            if (AngleRound == 0) {
              this.subgroup[index].StartX = this.template.width - Width / 2;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartX = this.template.width + Height / 2;
            } else if (AngleRound == 180) {
              this.subgroup[index].StartX = this.template.width + Width / 2;
            } else if (AngleRound == 270) {
              this.subgroup[index].StartX = this.template.width - Height / 2;
            }
          }
          break;
        case "top":
          let canvasTop = document.getElementById("canvasTop");
          canvasTop ? parent.removeChild(canvasTop) : "";
          elem.style.left = "0mm";
          elem.style.top = "0mm";
          elem.style.height = line;
          elem.style.width = this.template.width + "mm";
          elem.setAttribute("id", "canvasTop");
          //元件顶部对齐画布顶部
          if (Math.abs(this.dragData.subTop) <= alignValue) {
            if (AngleRound == 0 || AngleRound == 90) {
              this.subgroup[index].StartY = 0;
            } else if (AngleRound == 180) {
              this.subgroup[index].StartY = Height;
            } else {
              this.subgroup[index].StartY = Width;
            }
          }
          //元件底部对齐画布顶部
          if (Math.abs(this.dragData.subBottom) <= alignValue) {
            if (AngleRound == 0) {
              this.subgroup[index].StartY = -Height;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartY = -Width;
            } else {
              this.subgroup[index].StartY = 0;
            }
          }
          //元件中间对齐画布顶部
          if (Math.abs(this.dragData.subCenterH) <= alignValue) {
            if (AngleRound == 0) {
              this.subgroup[index].StartY = -Height / 2;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartY = -Width / 2;
            } else if (AngleRound == 180) {
              this.subgroup[index].StartY = Height / 2;
            } else {
              this.subgroup[index].StartY = Width / 2;
            }
          }
          break;
        case "bottom":
          let canvasBottom = document.getElementById("canvasBottom");
          canvasBottom ? parent.removeChild(canvasBottom) : "";
          elem.style.left = "0mm";
          elem.style.top = this.template.height + "mm";
          elem.style.height = line;
          elem.style.width = this.template.width + "mm";
          elem.setAttribute("id", "canvasBottom");
          //元件顶部对齐画布底部
          if (
            Math.abs(this.template.height - this.dragData.subTop) <= alignValue
          ) {
            if (AngleRound == 0 || AngleRound == 90) {
              this.subgroup[index].StartY = this.template.height;
            } else if (AngleRound == 180) {
              this.subgroup[index].StartY = this.template.height + Height;
            } else {
              this.subgroup[index].StartY = this.template.height + Width;
            }
          }
          //元件底部对齐画布底部
          if (
            Math.abs(this.template.height - this.dragData.subBottom) <=
            alignValue
          ) {
            if (AngleRound == 0) {
              this.subgroup[index].StartY = this.template.height - Height;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartY = this.template.height - Width;
            } else {
              this.subgroup[index].StartY = this.template.height;
            }
          }
          //元件中间对齐画布底部
          if (
            Math.abs(this.template.height - this.dragData.subCenterH) <=
            alignValue
          ) {
            if (AngleRound == 0) {
              this.subgroup[index].StartY = this.template.height - Height / 2;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartY = this.template.height - Width / 2;
            } else if (AngleRound == 180) {
              this.subgroup[index].StartY = this.template.height + Height / 2;
            } else if (AngleRound == 270) {
              this.subgroup[index].StartY = this.template.height + Width / 2;
            }
          }
          break;
        case "centerV":
          let canvasCenterV = document.getElementById("canvasCenterV");
          canvasCenterV ? parent.removeChild(canvasCenterV) : "";
          elem.style.left = "0mm";
          elem.style.top = this.template.height / 2 + "mm";
          elem.style.height = line;
          elem.style.width = this.template.width + "mm";
          elem.setAttribute("id", "canvasCenterV");
          if (
            Math.abs(this.template.height / 2 - this.dragData.subBottom) <=
            alignValue
          ) {
            if (AngleRound == 0) {
              this.subgroup[index].StartY = this.template.height / 2 - Height;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartY = this.template.height / 2 - Width;
            } else {
              this.subgroup[index].StartY = this.template.height / 2;
            }
          }
          if (
            Math.abs(this.template.height / 2 - this.dragData.subTop) <=
            alignValue
          ) {
            if (AngleRound == 0 || AngleRound == 90) {
              this.subgroup[index].StartY = this.template.height / 2;
            } else {
              this.subgroup[index].StartY = this.template.height / 2 + Height;
            }
          }
          if (
            Math.abs(this.template.height / 2 - this.dragData.subCenterH) <=
            alignValue
          ) {
            if (AngleRound == 0) {
              this.subgroup[index].StartY =
                this.template.height / 2 - Height / 2;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartY =
                this.template.height / 2 - Width / 2;
            } else if (AngleRound == 180) {
              this.subgroup[index].StartY =
                this.template.height / 2 + Height / 2;
            } else if (AngleRound == 270) {
              this.subgroup[index].StartY =
                this.template.height / 2 + Width / 2;
            }
          }
          break;
        case "centerH":
          let canvasCenterH = document.getElementById("canvasCenterH");
          canvasCenterH ? parent.removeChild(canvasCenterH) : "";
          elem.style.left = this.template.width / 2 + "mm";
          elem.style.top = "0mm";
          elem.style.height = this.template.height + "mm";
          elem.style.width = line;
          elem.setAttribute("id", "canvasCenterH");
          if (
            Math.abs(this.template.width / 2 - this.dragData.subLeft) <=
            alignValue
          ) {
            if (AngleRound == 0 || AngleRound == 270) {
              this.subgroup[index].StartX = this.template.width / 2;
            } else if (AngleRound == 180) {
              this.subgroup[index].StartX = this.template.width / 2 + Width;
            } else {
              this.subgroup[index].StartX = this.template.width / 2 + Height;
            }
          }
          if (
            Math.abs(this.template.width / 2 - this.dragData.subRight) <=
            alignValue
          ) {
            if (AngleRound == 0 || AngleRound == 270) {
              this.subgroup[index].StartX = this.template.width / 2 - Width;
            } else {
              this.subgroup[index].StartX = this.template.width / 2;
            }
          }
          if (
            Math.abs(this.template.width / 2 - this.dragData.subCenterV) <=
            alignValue
          ) {
            if (AngleRound == 0) {
              this.subgroup[index].StartX = this.template.width / 2 - Width / 2;
            } else if (AngleRound == 90) {
              this.subgroup[index].StartX =
                this.template.width / 2 + Height / 2;
            } else if (AngleRound == 180) {
              this.subgroup[index].StartX = this.template.width / 2 + Width / 2;
            } else if (AngleRound == 270) {
              this.subgroup[index].StartX =
                this.template.width / 2 - Height / 2;
            }
          }
          break;
      }
      parent.appendChild(elem);
    },

    //获取需要绘制的元件参考线
    getElementLine(index) {
      let topArr = [];
      let bottomArr = [];
      let centerHArr = [];
      let leftArr = [];
      let rightArr = [];
      let centerVArr = [];

      //将移动元素从数组里去掉，获取未移动元素数组
      let list = JSON.parse(JSON.stringify(this.subgroup));
      list.splice(index, 1);

      //判断误差，小于误差将该元素添加到参考元素数组
      list.map((item) => {
        let width = item.Width;
        let height = item.Height;
        if (
          (item.Type == "WinText" || item.Type == "TimeText") &&
          item.AutoWrap
        ) {
          width = item.BoxWidth;
        }
        switch (item.AngleRound) {
          case 0:
            var itemTop = item.StartY;
            var itemLeft = item.StartX;
            var itemBottom = item.StartY + height;
            var itemRight = item.StartX + width;
            var itemCenterH = item.StartY + height / 2;
            var itemCenterV = item.StartX + width / 2;
            break;
          case 90:
            var itemTop = item.StartY;
            var itemLeft = item.StartX - height;
            var itemBottom = item.StartY + width;
            var itemRight = item.StartX;
            var itemCenterH = item.StartY + width / 2;
            var itemCenterV = item.StartX - height / 2;
            break;
          case 180:
            var itemTop = item.StartY - height;
            var itemLeft = item.StartX - width;
            var itemBottom = item.StartY;
            var itemRight = item.StartX;
            var itemCenterH = item.StartY - height / 2;
            var itemCenterV = item.StartX - width / 2;
            break;
          case 270:
            var itemTop = item.StartY - width;
            var itemLeft = item.StartX;
            var itemBottom = item.StartY;
            var itemRight = item.StartX + height;
            var itemCenterH = item.StartY - width / 2;
            var itemCenterV = item.StartX + height / 2;
            break;
        }
        let data = {
          itemTop,
          itemLeft,
          itemBottom,
          itemRight,
          itemCenterH,
          itemCenterV,
          id: item.Id,
        };

        //顶对顶、顶对底、顶对中
        if (
          Math.abs(this.dragData.subTop - itemTop) <= alignValue ||
          Math.abs(this.dragData.subTop - itemBottom) <= alignValue ||
          Math.abs(this.dragData.subTop - itemCenterH) <= alignValue
        ) {
          topArr.push(data);
        }

        //底对顶、底对底、底对中
        if (
          Math.abs(this.dragData.subBottom - itemTop) <= alignValue ||
          Math.abs(this.dragData.subBottom - itemBottom) <= alignValue ||
          Math.abs(this.dragData.subBottom - itemCenterH) <= alignValue
        ) {
          bottomArr.push(data);
        }

        //中对顶、中对底、中对中
        if (
          Math.abs(this.dragData.subCenterH - itemTop) <= alignValue ||
          Math.abs(this.dragData.subCenterH - itemBottom) <= alignValue ||
          Math.abs(this.dragData.subCenterH - itemCenterH) <= alignValue
        ) {
          centerHArr.push(data);
        }

        //左对左  左对右 左对中
        if (
          Math.abs(this.dragData.subLeft - itemLeft) <= alignValue ||
          Math.abs(this.dragData.subLeft - itemRight) <= alignValue ||
          Math.abs(this.dragData.subLeft - itemCenterV) <= alignValue
        ) {
          leftArr.push(data);
        }

        //右对左  右对右 右对中
        if (
          Math.abs(this.dragData.subRight - itemLeft) <= alignValue ||
          Math.abs(this.dragData.subRight - itemRight) <= alignValue ||
          Math.abs(this.dragData.subRight - itemCenterV) <= alignValue
        ) {
          rightArr.push(data);
        }

        //中对左、中对右、中对中
        if (
          Math.abs(this.dragData.subCenterV - itemLeft) <= alignValue ||
          Math.abs(this.dragData.subCenterV - itemRight) <= alignValue ||
          Math.abs(this.dragData.subCenterV - itemCenterV) <= alignValue
        ) {
          centerVArr.push(data);
        }
      });

      //绘制元件对齐线
      if (topArr.length > 0) {
        this.drawHorizontalLine(index, topArr, "top");
      } else {
        let elem = document.getElementById("topLine");
        elem ? parent.removeChild(elem) : "";
      }

      if (bottomArr.length > 0) {
        this.drawHorizontalLine(index, bottomArr, "bottom");
      } else {
        let elem = document.getElementById("bottomLine");
        elem ? parent.removeChild(elem) : "";
      }

      if (centerHArr.length > 0) {
        this.drawHorizontalLine(index, centerHArr, "centerH");
      } else {
        let elem = document.getElementById("centerHLine");
        elem ? parent.removeChild(elem) : "";
      }

      if (leftArr.length > 0) {
        this.drawVerticalLine(index, leftArr, "left");
      } else {
        let elem = document.getElementById("leftLine");
        elem ? parent.removeChild(elem) : "";
      }

      if (rightArr.length > 0) {
        this.drawVerticalLine(index, rightArr, "right");
      } else {
        let elem = document.getElementById("rightLine");
        elem ? parent.removeChild(elem) : "";
      }

      if (centerVArr.length > 0) {
        this.drawVerticalLine(index, centerVArr, "centerV");
      } else {
        let elem = document.getElementById("centerVLine");
        elem ? parent.removeChild(elem) : "";
      }
    },

    //元件水平辅助线
    drawHorizontalLine(index, dataArr, type) {
      let AngleRound = this.subgroup[index].AngleRound;
      //当参考元素数组不为空的时候获取所有参考元素的最小左坐标和最大右坐标
      if (dataArr.length > 0) {
        let left = Math.min.apply(
          Math,
          dataArr.map(function (val) {
            return val.itemLeft;
          })
        );
        let right = Math.max.apply(
          Math,
          dataArr.map(function (val) {
            return val.itemRight;
          })
        );
        let elem = document.createElement("div");
        elem.style.backgroundColor = "#ff4d4f";
        elem.style.zIndex = "999";
        elem.style.position = "absolute";
        elem.style.height = line;
        let top = 0;
        var that = this;
        switch (type) {
          //顶对顶  顶对底 顶对中
          case "top":
            let topLine = document.getElementById("topLine");
            topLine ? parent.removeChild(topLine) : "";
            top = Math.min.apply(
              Math,
              dataArr.map(function (val) {
                that.getReference(val);
                if (
                  Math.abs(that.dragData.subTop - val.itemTop) <= alignValue
                ) {
                  return that.dragData.subTop - val.itemTop;
                }
                if (
                  Math.abs(that.dragData.subTop - val.itemBottom) <= alignValue
                ) {
                  return that.dragData.subTop - val.itemBottom;
                }
                if (
                  Math.abs(that.dragData.subTop - val.itemCenterH) <= alignValue
                ) {
                  return that.dragData.subTop - val.itemCenterH;
                }
              })
            );
            elem.setAttribute("id", "topLine");
            elem.style.top = this.dragData.subTop - top + "mm";

            break;
          //底对顶  底对底 底对中
          case "bottom":
            let bottomLine = document.getElementById("bottomLine");
            bottomLine ? parent.removeChild(bottomLine) : "";
            top = Math.min.apply(
              Math,
              dataArr.map(function (val) {
                that.getReference(val);
                if (
                  Math.abs(that.dragData.subBottom - val.itemTop) <= alignValue
                ) {
                  return that.dragData.subBottom - val.itemTop;
                }
                if (
                  Math.abs(that.dragData.subBottom - val.itemBottom) <=
                  alignValue
                ) {
                  return that.dragData.subBottom - val.itemBottom;
                }
                if (
                  Math.abs(that.dragData.subBottom - val.itemCenterH) <=
                  alignValue
                ) {
                  return that.dragData.subBottom - val.itemCenterH;
                }
              })
            );
            elem.setAttribute("id", "bottomLine");
            elem.style.top = this.dragData.subBottom - top + "mm";
            break;
          //中对顶  中对底  中对中
          case "centerH":
            let centerHLine = document.getElementById("centerHLine");
            centerHLine ? parent.removeChild(centerHLine) : "";
            top = Math.min.apply(
              Math,
              dataArr.map(function (val) {
                that.getReference(val);
                if (
                  Math.abs(that.dragData.subCenterH - val.itemTop) <= alignValue
                ) {
                  return that.dragData.subCenterH - val.itemTop;
                }
                if (
                  Math.abs(that.dragData.subCenterH - val.itemBottom) <=
                  alignValue
                ) {
                  return that.dragData.subCenterH - val.itemBottom;
                }
                if (
                  Math.abs(that.dragData.subCenterH - val.itemCenterH) <=
                  alignValue
                ) {
                  return that.dragData.subCenterH - val.itemCenterH;
                }
              })
            );
            elem.setAttribute("id", "centerHLine");
            elem.style.top = this.dragData.subCenterH - top + "mm";
            break;
        }
        if (AngleRound == 0 || AngleRound == 90) {
          this.subgroup[index].StartY = this.dragData.subTop - top;
        } else if (AngleRound == 180 || AngleRound == 270) {
          this.subgroup[index].StartY = this.dragData.subBottom - top;
        }
        //判断移动元素的位置，并赋值left坐标
        if (this.dragData.subLeft < left) {
          var dist = right - this.dragData.subLeft;
          elem.style.left = this.dragData.subLeft + "mm";
        } else if (this.dragData.subLeft > right) {
          var dist = this.dragData.subRight - left;
          elem.style.left = left + "mm";
        } else if (
          this.dragData.subLeft > left &&
          this.dragData.subLeft < right
        ) {
          var dist = right - left;
          elem.style.left = left + "mm";
        }
        elem.style.width = dist + "mm";
        parent.appendChild(elem);
      }
    },
    //元件垂直辅助线
    drawVerticalLine(index, dataArr, type) {
      let AngleRound = this.subgroup[index].AngleRound;

      //当参考元素数组不为空的时候获取所有参考元素的最小左坐标和最大右坐标
      if (dataArr.length > 0) {
        let top = Math.min.apply(
          Math,
          dataArr.map(function (val) {
            return val.itemTop;
          })
        );
        let bottom = Math.max.apply(
          Math,
          dataArr.map(function (val) {
            return val.itemBottom;
          })
        );
        let elem = document.createElement("div");
        elem.style.backgroundColor = "#ff4d4f";
        elem.style.zIndex = "999";
        elem.style.position = "absolute";
        elem.style.width = line;
        let left = 0;
        var that = this;
        switch (type) {
          //左对左 左对右 左对中
          case "left":
            let leftLine = document.getElementById("leftLine");
            leftLine ? parent.removeChild(leftLine) : "";
            left = Math.min.apply(
              Math,
              dataArr.map(function (val) {
                that.getReference(val);
                if (
                  Math.abs(that.dragData.subLeft - val.itemLeft) <= alignValue
                ) {
                  return that.dragData.subLeft - val.itemLeft;
                }
                if (
                  Math.abs(that.dragData.subLeft - val.itemRight) <= alignValue
                ) {
                  return that.dragData.subLeft - val.itemRight;
                }
                if (
                  Math.abs(that.dragData.subLeft - val.itemCenterV) <=
                  alignValue
                ) {
                  return that.dragData.subLeft - val.itemCenterV;
                }
              })
            );
            elem.setAttribute("id", "leftLine");
            elem.style.left = this.dragData.subLeft - left + "mm";
            break;
          //右对左 右对右 右对中
          case "right":
            let rightLine = document.getElementById("rightLine");
            rightLine ? parent.removeChild(rightLine) : "";
            left = Math.min.apply(
              Math,
              dataArr.map(function (val) {
                that.getReference(val);
                if (
                  Math.abs(that.dragData.subRight - val.itemLeft) <= alignValue
                ) {
                  return that.dragData.subRight - val.itemLeft;
                }
                if (
                  Math.abs(that.dragData.subRight - val.itemRight) <= alignValue
                ) {
                  return that.dragData.subRight - val.itemRight;
                }
                if (
                  Math.abs(that.dragData.subRight - val.itemCenterV) <=
                  alignValue
                ) {
                  return that.dragData.subRight - val.itemCenterV;
                }
              })
            );
            elem.setAttribute("id", "rightLine");
            elem.style.left = this.dragData.subRight - left + "mm";
            break;
          //中对左  中对右  中对中
          case "centerV":
            let centerVLine = document.getElementById("centerVLine");
            centerVLine ? parent.removeChild(centerVLine) : "";
            left = Math.min.apply(
              Math,
              dataArr.map(function (val) {
                that.getReference(val);
                if (
                  Math.abs(that.dragData.subCenterV - val.itemLeft) <=
                  alignValue
                ) {
                  return that.dragData.subCenterV - val.itemLeft;
                }
                if (
                  Math.abs(that.dragData.subCenterV - val.itemRight) <=
                  alignValue
                ) {
                  return that.dragData.subCenterV - val.itemRight;
                }
                if (
                  Math.abs(that.dragData.subCenterV - val.itemCenterV) <=
                  alignValue
                ) {
                  return that.dragData.subCenterV - val.itemCenterV;
                }
              })
            );
            elem.setAttribute("id", "centerVLine");
            elem.style.left = this.dragData.subCenterV - left + "mm";
            break;
        }
        if (AngleRound == 0 || AngleRound == 270) {
          this.subgroup[index].StartX = this.dragData.subLeft - left;
        } else if (AngleRound == 90 || AngleRound == 180) {
          this.subgroup[index].StartX = this.dragData.subRight - left;
        }
        //判断移动元素的位置，并赋值left坐标
        if (this.dragData.subTop < top) {
          var dist = bottom - this.dragData.subTop;
          elem.style.top = this.dragData.subTop + "mm";
        } else if (this.dragData.subTop > bottom) {
          var dist = this.dragData.subBottom - top;
          elem.style.top = top + "mm";
        } else if (
          this.dragData.subTop > top &&
          this.dragData.subTop < bottom
        ) {
          var dist = bottom - top;
          elem.style.top = top + "mm";
        }
        elem.style.height = dist + "mm";
        parent.appendChild(elem);
      }
    },
    //获取参考对象
    getReference(val) {
      let isRef = this.referenceArr.find((item) => item.id == val.id);
      if (!isRef) {
        this.referenceArr.push(val);
      }
    },
    //移除对齐参考线
    removeLine() {
      parent = document.getElementById("design");
      let topLine = document.getElementById("topLine");
      topLine ? parent.removeChild(topLine) : "";

      let bottomLine = document.getElementById("bottomLine");
      bottomLine ? parent.removeChild(bottomLine) : "";

      let centerHLine = document.getElementById("centerHLine");
      centerHLine ? parent.removeChild(centerHLine) : "";

      let leftLine = document.getElementById("leftLine");
      leftLine ? parent.removeChild(leftLine) : "";

      let rightLine = document.getElementById("rightLine");
      rightLine ? parent.removeChild(rightLine) : "";

      let centerVLine = document.getElementById("centerVLine");
      centerVLine ? parent.removeChild(centerVLine) : "";

      let canvasLeft = document.getElementById("canvasLeft");
      canvasLeft ? parent.removeChild(canvasLeft) : "";

      let canvasRight = document.getElementById("canvasRight");
      canvasRight ? parent.removeChild(canvasRight) : "";

      let canvasTop = document.getElementById("canvasTop");
      canvasTop ? parent.removeChild(canvasTop) : "";

      let canvasBottom = document.getElementById("canvasBottom");
      canvasBottom ? parent.removeChild(canvasBottom) : "";

      let canvasCenterV = document.getElementById("canvasCenterV");
      canvasCenterV ? parent.removeChild(canvasCenterV) : "";

      let canvasCenterH = document.getElementById("canvasCenterH");
      canvasCenterH ? parent.removeChild(canvasCenterH) : "";
    },
  },
};
export default alignment;
