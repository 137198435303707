<template>
  <div>
    <a-modal
      class="select-box"
      :title="$t(`editAttr.attrText100`)"
      centered
      :visible="selectVisible"
      :cancelText="$t(`button.cancel`)"
      :okText="$t(`button.confirm`)"
      :width="738"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <a-input-search
        v-model:value="searchValue"
        :placeholder="$t(`tips.tip53`)"
        @search="handleSearch"
      />
      <div class="select-nav">
        <a-table
          :bordered="true"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          size="small"
        >
        </a-table>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { message } from "ant-design-vue";
import { defineComponent, getCurrentInstance, reactive, toRefs } from "vue";
export default defineComponent({
  props: {},
  components: {},
  setup(props, context) {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      selectVisible: false,
      columns: [],
      dataSource: [],
      originDataSource: [],
      list: [],
      newList: [],
      selectedRowKeys: [],
      searchValue: "",
    });

    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };

    const handleSearch = () => {
      // state.selectedRowKeys = [];
      if (state.searchValue == "") {
        state.dataSource = state.originDataSource;
        // state.dataSource.map((val) => {
        //   state.selectedRowKeys.push(val["key"]);
        // });
      } else {
        state.dataSource = state.originDataSource.filter((row) => {
          for (let key in row) {
            if (String(row[key]).indexOf(state.searchValue) > -1) {
              // state.selectedRowKeys.push(row["key"]);
              return row;
            }
          }
        });
      }
    };

    const show = (list, select) => {
      state.selectVisible = true;
      state.list = list;

      let firstData = state.list[0].group;
      firstData.map((element, eleIndex) => {
        if (
          element.Type == "WinText" ||
          element.Type == "GeneralBarcode" ||
          element.Type == "QRCode" ||
          element.Type == "Table"
        ) {
          element.DataSourceList.map((item, itemIndex) => {
            let cloumn = {};
            //整理表格列数据
            if (item.DataSourceType == "1") {
              let Index = state.columns.findIndex(
                (cloumn) =>
                  cloumn.database == item.ConnectionStr && cloumn.title == item.ColumnName
              );
              if (Index == -1) {
                cloumn = {
                  title: item.ColumnName,
                  dataIndex: state.columns.length,
                  key: state.columns.length,
                  align: "center",
                  database: item.ConnectionStr, //存储数据库名称
                };
                state.columns.push(cloumn);
              }
            }
            if (item.DataSourceType == "2") {
              cloumn = {
                title: element.Name,
                dataIndex: state.columns.length,
                key: state.columns.length,
                align: "center",
                database: "",
              };
              state.columns.push(cloumn);
            }

            //整理表格行数据
            if (item.DataSourceType != "0") {
              if (state.dataSource.length > 1) {
                for (let i = 0; i < state.list.length; i++) {
                  if (state.list[i].group[eleIndex].Type == "Table") {
                    state.dataSource[i][cloumn.dataIndex] =
                      state.list[i].group[eleIndex].DataSourceList[itemIndex].Data;
                  } else {
                    state.dataSource[i][cloumn.dataIndex] =
                      state.list[i].group[eleIndex].DataSourceList[itemIndex].FirstValue;
                  }
                }
              } else {
                for (let i = 0; i < state.list.length; i++) {
                  let row;
                  if (state.list[i].group[eleIndex].Type == "Table") {
                    row = {
                      key: i + 1,
                      [cloumn.dataIndex]:
                        state.list[i].group[eleIndex].DataSourceList[itemIndex].Data,
                    };
                  } else {
                    row = {
                      key: i + 1,
                      [cloumn.dataIndex]:
                        state.list[i].group[eleIndex].DataSourceList[itemIndex]
                          .FirstValue,
                    };
                  }

                  state.dataSource.push(row);
                }
              }
            }
          });
        }
      });
      // console.log(state.columns);
      // console.log(state.dataSource);

      //设置勾选状态
      state.selectedRowKeys = select;

      //存储原始的表格数据  用来做关键字搜索
      state.originDataSource = state.dataSource;
      if (state.columns.length > 0) {
        state.columns.unshift({
          title: "序号",
          dataIndex: "key",
          key: "key",
          align: "center",
          database: "", //存储数据库名称
          width: 50,
        });
      }
    };
    const handleOk = () => {
      if (state.selectedRowKeys.length == 0) {
        message.error(proxy.$t(`tips.tip52`));
        return;
      }
      state.selectedRowKeys.sort(function (a, b) {
        return a - b;
      });
      state.selectedRowKeys.map((key, index) => {
        let data = JSON.parse(JSON.stringify(state.list[key - 1]));
        data.groupIndex = index;
        state.newList.push(data);
      });
      context.emit("confirm", state.newList, state.selectedRowKeys);
      handleCancel();
    };
    const handleCancel = () => {
      state.selectVisible = false;
      state.list = [];
      state.columns = [];
      state.dataSource = [];
      state.selectedRowKeys = [];
      state.searchValue = "";
      state.selectedRowKeys = [];
      state.newList = [];
    };

    return {
      ...toRefs(state),
      show,
      handleCancel,
      handleOk,
      handleSearch,
      onSelectChange,
    };
  },
});
</script>
<style>
.select-box .ant-modal-content {
  height: 635px;
  background: #ffffff;
  border-radius: 4px;
}
.select-box .ant-modal-body {
  margin-top: 24px;
}
.select-box .ant-input-affix-wrapper {
  width: 320px;
  height: 40px;
}
.select-nav {
  margin-top: 24px;
  width: 100%;
  height: 393px;
  border-radius: 2px;
  border: 1px solid #d6dce6;
  padding: 16px;
}
.select-nav .ant-table-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}
.select-nav .ant-table-thead > tr > th {
  background: #fff;
  color: #7b89a1;
  font-size: 14px;
  font-weight: 500;
}
.select-nav .ant-table-tbody > tr > td {
  font-size: 14px;
  color: #535d6c;
}
@media only screen and (max-width: 1300px) {
  .select-box .ant-modal-content {
    height: 440px;
  }
  .select-box .ant-modal-title {
    height: 18px;
    font-size: 14px;
    line-height: 18px;
  }
  .select-box .ant-input-affix-wrapper {
    width: 213px;
    height: 26px;
  }
  .select-nav {
    margin-top: 16px;
    width: 100%;
    height: 262px;
    padding: 10px;
  }
  .select-nav .ant-table-wrapper {
    width: 100%;
    height: 100%;
  }
}
</style>
