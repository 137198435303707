import en from './en'
import es from './es'
import pt from './pt'
import ru from './ru'
import zh from './zh'
export default {
    zh,
    en,
    pt,
    es,
    ru
}