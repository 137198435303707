<template>
  <div>
    <a-modal
      class="rename-box"
      :title="$t(`button.rename`)"
      centered
      :visible="renameVisible"
      :cancelText="$t(`button.cancel`)"
      :okText="$t(`button.save`)"
      @cancel="handleCancel"
      @ok="handleRename"
    >
      <div class="rename-title">{{ title }}：</div>
      <a-input v-model:value="renameValue" />
    </a-modal>
  </div>
</template>
<script>
import { message } from "ant-design-vue";
import {
  defineComponent,
  watch,
  getCurrentInstance,
  toRefs,
  reactive,
} from "vue";

export default defineComponent({
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    let globalProperties =
      getCurrentInstance().appContext.config.globalProperties;
    const axios = globalProperties.$axioshanma;

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const state = reactive({
      renameVisible: false,
      type: "",
      title: "",
      renameValue: "",
      value: {},
    });

    const show = (type, value) => {
      setTitle(type);
      state.type = type;
      state.renameVisible = true;
      state.renameValue = value.name;
      state.value = value;
    };

    const setTitle = (type) => {
      switch (type) {
        case "cloudDataFolder":
        case "cloudTemplateFolder":
          state.title = proxy.$t(`label.label4`);
          break;
        case "cloudDataFile":
          state.title = proxy.$t(`label.label5`);
          break;
        case "cloudTemplateFile":
        case "label":
          state.title = proxy.$t(`label.label6`);
      }
    };

    const handleRename = () => {
      if (state.renameValue == "") {
        message.error(proxy.$t(`tips.tip41`));
        return;
      }
      var reg = new RegExp('[\\\\/:*?"<>|]');
      if (reg.test(state.renameValue)) {
        message.error(proxy.$t(`tips.tip42`) + '\\/:*?"<>|');
        return;
      }
      switch (state.type) {
        // 云端数据文件夹
        case "cloudDataFolder":
          axios("/api/tem.app_public/editCloudClass", {
            id: state.value.id,
            name: state.renameValue,
            user_id: userInfo.userid,
          })
            .then((res) => {
              message.success(proxy.$t(`tips.tip48`));
              context.emit("refreshForlder");
              handleCancel();
            })
            .catch((err) => {});
          break;
        // 云端数据文件
        case "cloudDataFile":
          axios("/api/tem.app_public/editCloudFile", {
            id: state.value.id,
            name: state.renameValue,
            tid: state.value.Tid,
            user_id: userInfo.userid,
          }).then((res) => {
            message.success(proxy.$t(`tips.tip48`));
            context.emit("refreshFile");
            handleCancel();
          });
          break;
        case "cloudTemplateFolder":
          axios("/api/tem.app_public/editUserClass", {
            id: state.value.id,
            name: state.renameValue,
            user_id: userInfo.userid,
          })
            .then((res) => {
              message.success(proxy.$t(`tips.tip48`));
              context.emit("refreshForlder");
              handleCancel();
            })
            .catch((err) => {});
          break;
        case "cloudTemplateFile":
          axios("/api/tem.app_public/editUserTemplate", {
            id: state.value.id,
            name: state.renameValue,
            user_id: userInfo.userid,
            platform: 2,
          })
            .then((res) => {
              message.success(proxy.$t(`tips.tip48`));
              context.emit("refreshFile");
              handleCancel();
            })
            .catch((err) => {});
          break;
        case "label":
          context.emit("rename", state.renameValue);
          handleCancel();
      }
    };
    const handleCancel = () => {
      state.renameVisible = false;
      state.renameValue = "";
    };

    return {
      ...toRefs(state),
      handleRename,
      handleCancel,
      show,
    };
  },
});
</script>
<style scoped>
.rename-title {
  height: 24px;
  font-size: 16px;
  color: #535d6c;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: 32px;
}
@media only screen and (max-width: 1300px) {
  .rename-title {
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 10px;
    margin-top: 21px;
  }
}
</style>
