//跨域代理前缀
const API_PROXY_PREFIX = "/interface";
const BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_API_BASE_URL
    : API_PROXY_PREFIX;

const API_PROXY_PREFIX_TWO = "/hanma";
const HAMA_URL =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_API_HANMA_URL
    : API_PROXY_PREFIX_TWO;
const API_PROXY_PREFIX_TRE = "/net";
const NET_URL =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_API_NET_URL
    : API_PROXY_PREFIX_TRE;
module.exports = {
  BASE_URL: `${BASE_URL}`,
  HAMA_URL: `${HAMA_URL}`,
  NET_URL: `${NET_URL}`,
};
