import i18n from "../../../language/i18n";
const data = {
  data() {
    return {
      dataLocationValue: 1,
      shortcutKey: [
        {
          name: i18n.global.t(`editAttr.attrText50`),
          key: "Ctrl+C",
        },
        {
          name: i18n.global.t(`editAttr.attrText51`),
          key: "Ctrl+V",
        },
        {
          name: i18n.global.t(`editAttr.attrText69`),
          key: "Ctrl+Z",
        },
        {
          name: i18n.global.t(`editAttr.attrText70`),
          key: "Ctrl+Y",
        },
        {
          name: i18n.global.t(`editAttr.attrText71`),
          key: "Ctrl+S",
        },
        {
          name: i18n.global.t(`editAttr.attrText72`),
          key: "Ctrl+Shift+S",
        },
        {
          name: i18n.global.t(`editAttr.attrText73`),
          key: "Delete",
        },
        {
          name: i18n.global.t(`editAttr.attrText74`),
          key: "Up",
        },
        {
          name: i18n.global.t(`editAttr.attrText75`),
          key: "Down",
        },
        {
          name: i18n.global.t(`editAttr.attrText76`),
          key: "Left",
        },
        {
          name: i18n.global.t(`editAttr.attrText77`),
          key: "Right",
        },
        {
          name: i18n.global.t(`editAttr.attrText82`),
          key: "Ctrl+" + i18n.global.t(`editAttr.attrText85`),
        },
        {
          name: i18n.global.t(`editAttr.attrText78`),
          key: "Ctrl+A",
        },
        {
          name: i18n.global.t(`editAttr.attrText79`),
          key: "Ctrl+P",
        },
        {
          name: i18n.global.t(`editAttr.attrText80`),
          key: "Ctrl+" + i18n.global.t(`editAttr.attrText83`),
        },
        {
          name: i18n.global.t(`editAttr.attrText81`),
          key: "Ctrl+" + i18n.global.t(`editAttr.attrText84`),
        },
      ],
      unit: [
        {
          img: require("../../../assets/wenben.png"),
          text: i18n.global.t(`module.editText1`),
          type: "WinText",
        },
        {
          img: require("../../../assets/time.png"),
          text: i18n.global.t(`module.editText2`),
          type: "TimeText",
        },
        {
          img: require("../../../assets/tiaoxingma.png"),
          text: i18n.global.t(`module.editText3`),
          type: "GeneralBarcode",
        },
        {
          img: require("../../../assets/erweima.png"),
          text: i18n.global.t(`module.editText4`),
          type: "QRCode",
        },
        {
          img: require("../../../assets/tupian.png"),
          text: i18n.global.t(`module.editText5`),
          type: "Image",
        },
        {
          img: require("../../../assets/xingzhuang.png"),
          text: i18n.global.t(`module.editText6`),
          type: "Rectangle",
        },
        {
          img: require("../../../assets/tubiao.png"),
          text: i18n.global.t(`module.editText13`),
          type: "tubiao",
        },
        {
          img: require("../../../assets/xiexian.png"),
          text: i18n.global.t(`module.editText14`),
          type: "Line",
        },
        {
          img: require("../../../assets/shuipingxian.png"),
          text: i18n.global.t(`module.editText15`),
          type: "LineHorizontal",
        },
        {
          img: require("../../../assets/chuizhixian.png"),
          text: i18n.global.t(`module.editText16`),
          type: "LineVertical",
        },
        {
          img: require("../../../assets/table.png"),
          text: i18n.global.t(`module.editText17`),
          type: "Table",
        },
      ],
      rects: [
        {
          img: require("../../../assets/juxing.png"),
          text: i18n.global.t(`module.editText7`),
          type: "Rectangle",
        },
        {
          img: require("../../../assets/yuanjiaojuxing.png"),
          text: i18n.global.t(`module.editText8`),
          type: "RoundRectangle",
        },
        {
          img: require("../../../assets/lingxing.png"),
          text: i18n.global.t(`module.editText9`),
          type: "Diamond",
        },
        {
          img: require("../../../assets/tuoyuan.png"),
          text: i18n.global.t(`module.editText10`),
          type: "Ellipse",
        },
        {
          img: require("../../../assets/sanjiaoxing.png"),
          text: i18n.global.t(`module.editText11`),
          type: "Triangle",
        },
        {
          img: require("../../../assets/shixinjuxing.png"),
          text: i18n.global.t(`module.editText12`),
          type: "SolidRectangle",
        },
      ],
      tools: {
        toolOne: [
          {
            id: 1,
            img: "icon-suoding",
            text: i18n.global.t(`editAttr.attrText52`),
          },
          {
            id: 2,
            img: "icon-chexiao",
            text: i18n.global.t(`editAttr.attrText69`),
          },
          {
            id: 3,
            img: "icon-huifu",
            text: i18n.global.t(`editAttr.attrText70`),
          },
        ],
        toolTwo: [
          {
            id: 4,
            img: "icon-zuoduiqi",
            text: i18n.global.t(`editAttr.attrText55`),
          },
          {
            id: 5,
            img: "icon-chizhijuzhong",
            text: i18n.global.t(`editAttr.attrText56`),
          },
          {
            id: 6,
            img: "icon-youduiqi",
            text: i18n.global.t(`editAttr.attrText57`),
          },
          {
            id: 7,
            img: "icon-dingduiqi",
            text: i18n.global.t(`editAttr.attrText59`),
          },
          {
            id: 8,
            img: "icon-shuipingjuzhong",
            text: i18n.global.t(`editAttr.attrText58`),
          },
          {
            id: 9,
            img: "icon-diduiqi",
            text: i18n.global.t(`editAttr.attrText60`),
          },
          {
            id: 10,
            img: "icon-shuipingfenbu",
            text: i18n.global.t(`editAttr.attrText61`),
          },
          {
            id: 11,
            img: "icon-chizhi",
            text: i18n.global.t(`editAttr.attrText62`),
          },
        ],
        toolThree: [
          {
            id: 12,
            img: "icon-zhidi",
            text: i18n.global.t(`editAttr.attrText65`),
          },
          {
            id: 13,
            img: "icon-zhiding",
            text: i18n.global.t(`editAttr.attrText64`),
          },
        ],
        toolFour: [
          {
            id: 14,
            img: "icon-fuzhi",
            text: i18n.global.t(`editAttr.attrText50`),
          },
          {
            id: 15,
            img: "icon-zhantie",
            text: i18n.global.t(`editAttr.attrText51`),
          },
          {
            id: 16,
            img: "icon-shanchu",
            text: i18n.global.t(`editAttr.attrText66`),
          },
        ],
      },
      BarCode: [
        {
          value: "CODE128",
          label: "CODE128",
        },
        {
          value: "CODE128A",
          label: "CODE128 A",
        },
        {
          value: "CODE128B",
          label: "CODE128 B",
        },
        {
          value: "CODE128C",
          label: "CODE128 C",
        },
        {
          value: "CODE39",
          label: "CODE39",
        },
        {
          value: "ean13",
          label: "EAN13",
        },
        {
          value: "ean8",
          label: "EAN8",
        },
        {
          value: "upc",
          label: "UPCA",
        },
        {
          value: "msi10",
          label: "MSI10",
        },
        {
          value: "pharmacode",
          label: "Pharmacode",
        },
        {
          value: "codabar",
          label: "Codabar",
        },
      ],
      family: [
        {
          value: "@Malgun Gothic",
          label: "@Malgun Gothic",
        },
        {
          value: "Arial",
          label: "Arial",
        },
        {
          value: "黑体",
          label: "黑体",
        },
        {
          value: "微软雅黑",
          label: "微软雅黑",
        },
        {
          value: "宋体",
          label: "宋体",
        },
        {
          value: "楷体",
          label: "楷体",
        },
        {
          value: "仿宋",
          label: "仿宋",
        },
        {
          value: "等线",
          label: "等线",
        },
      ],
      dataSource: [
        {
          value: "0",
          label: i18n.global.t(`editAttr.attrText3`),
        },
        {
          value: "1",
          label: i18n.global.t(`editAttr.attrText4`),
        },
        {
          value: "2",
          label: i18n.global.t(`editAttr.attrText5`),
        },
        {
          value: "3",
          label: i18n.global.t(`editAttr.attrText101`),
        },
      ],
      tableDataSource: [
        {
          value: "0",
          label: i18n.global.t(`editAttr.attrText3`),
        },
        {
          value: "1",
          label: i18n.global.t(`editAttr.attrText4`),
        },
        {
          value: "2",
          label: i18n.global.t(`editAttr.attrText5`),
        },
      ],
      SequenceType: [
        {
          value: "0",
          label: i18n.global.t(`editAttr.attrText93`) + " (0-9)",
        },
        {
          value: "1",
          label: i18n.global.t(`editAttr.attrText103`) + " (0~9,A~F,a~f)",
        },
        {
          value: "2",
          label: i18n.global.t(`editAttr.attrText104`) + " (A~Z,a~z)",
        },
        {
          value: "3",
          label: i18n.global.t(`editAttr.attrText105`) + " (0~9,A~Z,a~z)",
        },
      ],
      correctionRate: [
        {
          value: "Low",
          label: "Low",
        },
        {
          value: "Medium",
          label: "Medium",
        },
        {
          value: "Medium High",
          label: "Medium High",
        },
        {
          value: "High",
          label: "High",
        },
      ],
      dataLocation: [
        {
          value: "0",
          label: i18n.global.t(`editAttr.attrText23`),
        },
        {
          value: "1",
          label: i18n.global.t(`editAttr.attrText24`),
        },
      ],
      dataType: [
        {
          value: "0",
          label: i18n.global.t(`editAttr.attrText16`),
        },
        {
          value: "1",
          label: i18n.global.t(`editAttr.attrText17`),
        },
      ],
      dataTime: [
        {
          value: "0",
          label: i18n.global.t(`editAttr.attrText19`),
        },
        {
          value: "1",
          label: i18n.global.t(`editAttr.attrText20`),
        },
      ],
      dateFormat: [
        {
          value: "yyyy/M/d",
          label: "yyyy/M/d",
        },
        {
          value: "yyyy-M-d",
          label: "yyyy-M-d",
        },
        {
          value: "yyyy.M.d",
          label: "yyyy.M.d",
        },
        {
          value: "H:m:s",
          label: "H:m:s",
        },
        {
          value: "yyyy/MM/dd HH:mm:ss",
          label: "yyyy/MM/dd HH:mm:ss",
        },
        {
          value: "yyyy-MM-dd HH:mm:ss",
          label: "yyyy-MM-dd HH:mm:ss",
        },
        {
          value: "yyyy.MM.dd HH:mm:ss",
          label: "yyyy.MM.dd HH:mm:ss",
        },
        {
          value: "yyyy-MM-dd",
          label: "yyyy-MM-dd",
        },
        {
          value: "yyyy/MM/dd",
          label: "yyyy/MM/dd",
        },
        {
          value: "yyyy.MM.dd",
          label: "yyyy.MM.dd",
        },
        {
          value: "yy-M-d",
          label: "yy-M-d",
        },
        {
          value: "yy/M/d",
          label: "yy/MM/dd",
        },
        {
          value: "yy/MM/dd",
          label: "yy/MM/dd",
        },
        {
          value: "HH:mm:ss",
          label: "HH:mm:ss",
        },
      ],
      numberFormat: [
        {
          value: "123456789",
          label: "123456789",
        },
        {
          value: "123,456,789",
          label: "123,456,789",
        },
      ],
      dateBaseType: [
        {
          value: "2",
          label: i18n.global.t(`module.editText1`),
        },
        {
          value: "0",
          label: i18n.global.t(`editAttr.attrText16`),
        },
        {
          value: "1",
          label: i18n.global.t(`editAttr.attrText17`),
        },
      ],
      QRCode: [
        {
          value: "QR_CODE",
          label: "QR_CODE",
        },
        {
          value: "PDF_417",
          label: "PDF_417",
        },
        {
          value: "DATA_MATRIX",
          label: "DATA_MATRIX",
        },
        {
          value: "AZTEC",
          label: "AZTEC",
        },
      ],
    };
  },
  methods: {
    setDataSource(obj) {
      switch (obj.Type) {
        case "QRCode":
          this.CodeType = this.QRCode;
          break;
        case "GeneralBarcode":
          this.CodeType = this.BarCode;
          if (this.subgroup[this.subIndex].HasLabel) {
            this.dataLocationValue = "1";
          } else {
            this.dataLocationValue = "0";
          }
          // if (this.subgroup[this.subIndex].badBarcode) {
          //   let BarcodeType = this.subgroup[this.subIndex].BarcodeType;
          //   switch (BarcodeType) {
          //     case "CODE128C":
          //       this.subgroup[this.subIndex].Data = "1234567891";
          //       break;
          //     case "pharmacode":
          //       this.subgroup[this.subIndex].Data = "123456";
          //       break;
          //     case "ean13":
          //       this.subgroup[this.subIndex].Data = "1234567890128";
          //       break;
          //     case "ean8":
          //       this.subgroup[this.subIndex].Data = "12345670";
          //       break;
          //     case "codabar":
          //       this.subgroup[this.subIndex].Data = "123456";
          //       break;
          //     case "upc":
          //       this.subgroup[this.subIndex].Data = "123456789999";
          //       break;
          //     default:
          //       this.subgroup[this.subIndex].Data = "12345678910";
          //       break;
          //   }
          //   this.handleCreated("1", this.subIndex);
          // }
          break;
        case "TimeText":
          if (this.subgroup[this.subIndex].TimeDate == 0) {
            this.subgroup[this.subIndex].selectTime = new Date(
              this.timeToDate(new Date().getTime(), "yyyy-MM-dd HH:mm:ss")
            );
          } else if (this.subgroup[this.subIndex].TimeDate == 1) {
            //指定时间
            let data = this.subgroup[this.subIndex].Data;
            let DayOffset = this.subgroup[this.subIndex].DayOffset;
            let MinuteOffset = this.subgroup[this.subIndex].MinuteOffset;
            let time =
              new Date(data).getTime() -
              DayOffset * 24 * 60 * 60 * 1000 -
              MinuteOffset * 60 * 1000;
            this.subgroup[this.subIndex].selectTime = new Date(
              this.timeToDate(time, "yyyy-MM-dd HH:mm:ss")
            );
          }
          break;
      }
    },
    setEclevelList(type) {
      let list = [];
      switch (type) {
        case "QR_CODE":
          list = [
            {
              value: "Low",
              label: "Low",
            },
            {
              value: "Medium",
              label: "Medium",
            },
            {
              value: "Medium High",
              label: "Medium High",
            },
            {
              value: "High",
              label: "High",
            },
          ];
          break;
        case "PDF_417":
          list = [
            {
              value: "L0",
              label: "L0",
            },
            {
              value: "L1",
              label: "L1",
            },
            {
              value: "L2",
              label: "L2",
            },
            {
              value: "L3",
              label: "L3",
            },
            {
              value: "L4",
              label: "L4",
            },
            {
              value: "L5",
              label: "L5",
            },
            {
              value: "L6",
              label: "L6",
            },
            {
              value: "L7",
              label: "L7",
            },
            {
              value: "L8",
              label: "L8",
            },
          ];
          break;
      }
      this.eclevelList = list
    },
  },
};

export default data;
