export default {
  navbar: {
    login: "Login",
    home: "Casa",
    register: "Inscrever",
    shareTemplate: "Modelo da Indústria",
    cloudTemplate: "Modelos de nuvem",
    cloudData: "Dados da nuvem",
    printSetting: "Configurações de impressão",
    moreSetting: "Mais configurações",
    user: "Centro pessoal",
    unit: "Unidade",
    logout: "Assine",
    error404: "Erro 404",
    renamePassword: "Alterar a senha",
    editLabel: "Editar etiqueta",
    member: "Centro de Membros",
    suggest: "Feedback",
    download: "Baixar Software",
    help: "Centro de ajuda",
    bind: "Ligar a conta",
    onlinetool: 'Utilitários',
    pdfPrint: 'impressão de documentos',
  },
  button: {
    login: "Login",
    register: "Inscrever",
    getCode: "Enviar",
    print: "Imprimir agora",
    editLabel: "Editar a etiqueta",
    copyLabel: "Copiar a etiqueta",
    rename: "renomear",
    delete: "Excluir",
    createLabel: "Criar um novo rótulo",
    move: "Mover",
    createFile: "Criar uma nova pasta",
    upload: "Carregar",
    cancel: "Cancelar",
    confirm: "Confirmar",
    save: "Salvar",
    create: "Criar",
    saveToCloudTem: "Salvar no modelo de nuvem",
    download: "Baixar",
    updateAvatar: "Rever",
    forgetPass: "Esqueça a sua senha",
    set: "Configurar",
    goRegister: "Registe-se agora",
    goLogin: "Vá para se inscrever",
    backLogin: "Voltar a iniciar s inscrição",
    reset: "Reset",
    useEmail: "Registe-se por e-mail",
    usePhone: "Registre-se com seu telefone",
    labelset: "Configurações de rótulo",
    printLabel: "Impressão",
    exitEdit: "Saída",
    choose: "Escolher",
    help: "Ajuda",
    downloadPC: "Baixar Software",
    Renewal: "Renovação",
    Upgrade: "Atualizar a adesão",
    bingLogin: "Vincular e entrar",
    upgradeMember: "Atualizar a adesão",
    exchange: "Código de resgate",
    exchangeNow: 'Resgate agora',
    expansion: 'Expansão',
  },
  lang: {
    chinese: "Chinês simplificado",
    english: "Inglês",
    portuguese: "Português",
    Spanish: "Espanhol",
    Russian: "Russo",
  },
  tips: {
    tip1: "Login ben sucedido",
    tip2: "Tem a certeza de que pretende eliminar todos os dados da pasta e da pasta?",
    tip3: "Tem a certeza de que pretende eliminar estes dados?",
    tip4: "Tem a certeza de que pretende remover esta etiqueta?",
    tip5: "Introduza o nome/tamanho da etiqueta",
    tip6: "Por favor, entre na indústria",
    tip7: "Insira o número de celular/e-mail",
    tip8: "Introduza o código de confirmação",
    tip9: "Definir nova senha",
    tip10: "Confirme a nova senha",
    tip11: "Digite a senha",
    tip12: "Digite seu nome de usuário",
    tip13: "Digite o número do celular",
    tip14: "Definir senha",
    tip15: "Confirme a Senha",
    tip16: "Escolha um setor",
    tip17: "Digite o e-mail",
    tip18: "Insira o número de celular/e-mail correto",
    tip19: "Verificar Ler Contrato",
    tip20: "Digite o número de celular correto",
    tip21: "Digite o e-mail correto",
    tip22: "A palavra-passe é inconsistente.",
    tip23: "Sem resultados, por favor tente outra coisa",
    tip24: "Não há conteúdo neste momento",
    tip25: "Venha criar a sua etiqueta",
    tip26: "Por favor, selecione o ficheiro de formato tprt/tprts!",
    tip27: "Upload falhou",
    tip28: "A eliminação foi bem sucedida",
    tip29: "Escolha png, jpg, jpeg, xlsx, xls, csv formato files!",
    tip30:
      "O ficheiro é demasiado grande para ser carregado, por favor, carreque menos de",
    tip31: "K!",
    tip32: "Ainda não tem uma conta?",
    tip33: "Tem uma conta?",
    tip34: "Modificado com sucesso",
    tip35: "A replicação foi bem sucedida",
    tip36: "Selecione uma camada",
    tip37: "A pasta foi bem sucedida",
    tip38: "O código de barras não pode conter caracteres chineses!",
    tip39: "Escolha as imagens no formato JPG, JPEG, PNG!",
    tip40: "Sem imagem na nuvem",
    tip41: "O nome é obrigatório",
    tip42: "O nome do arquivo não pode conter nenhum dos seguintes caracteres:",
    tip43: "O apelido não pode estar vazio",
    tip44:
      "A modificação é bem sucedida e será redirecionado para a página de login",
    tip45: "Se a sobrescrever o arquivo já existe",
    tip46: "Erro de rede!",
    tip47:
      "O ficheiro é muito grande, por favor, use um cliente pc para abrir!",
    tip48: "A modificação foi bem sucedida",
    tip49: "A modificação falhou",
    tip50: "O conteúdo está vazio e não pode ser salvo",
    tip51: "Selecione uma única camada",
    tip52: "Selecione pelo menos um pedaço de dados",
    tip53: "Insira palavras-chav",
    tip54: "Código de verificação enviado com sucesso",
    tip55: "Registo bem sucedido",
    tip56: "Adicionado com sucesso",
    tip57: "A mudança foi bem sucedida.",
    tip58: "Salvo com sucesso",
    tip59: "submetido com sucesso",
    tip60: "Carregue imagens com menos de 2M",
    tip61: "O conteúdo não pode ser vazio",
    tip62: "A pasta atual não tem dados carregados",
    tip63:
      "Para a segurança da sua conta, por favor ligue o seu número de telemóvel/endereço de e-mail!",
    tip64: "Conectado com sucesso!",
    tip65: "Falha na conexão!",
    tip66: "Ligação bem sucedida!",
    tip67:
      "Por favor, use a permissão para modificar o apelido após atualizar a associação!",
    tip68: "Autorização bem sucedida!",
    tip69:
      "O tamanho da fonte é exibido de forma anormal, ajuste o tamanho mínimo da fonte do navegador!de letra do navegador.",
    tip70: "Como modificar?",
    tip71: "Dicas",
    tip72:
      "Foi associado a outros elementos, e não pode ser associado a um elemento!",
    tip73: "Por favor, insira um número decimal de 0~9",
    tip74: "Introduza um número hexamal composto de 0 a 9, A a F",
    tip75: "Introduza um número hexamal composto por A a Z",
    tip76: "Por favor insira 0~9, A~Z composto de 30-hexadecimal",
    tip77:
      "Os dados atuais são referenciados por outro elemento, é certo que é para apagar?",
    tip78:
      "Os dados atuais existem que são referenciados por outros elementos, é certo que é para apagar?",
    tip79:
      "Recomenda-se a utilização de navegadores Chrome e Microsoft Edge para edição ou impressão de etiquetas!",
    tip80: "O formato de codificação está incorreto!",
    tip81: "Verificação de segurança",
    tip82: "Não consegue ver claramente? Mudar um",
    tip83: "As impressões não podem estar vazias!",
    tip84: "Selecione uma impressora",
    tip85: "Clique ",
    tip86: " em Download para instalar e iniciar",
    tip87: " o Assistente de Impressão",
    tip88: "Se estiver instalado e iniciado, clique aqui para se ",
    tip89: "conectar",
    tip90:
      "Falha ao obter impressora, por favor, reconecte o Assistente de Impressão!",
    tip91: "Entregue com sucesso!",
    tip92: "A entrega falhou, por favor tente novamente!",
    tip93: "Instale e inicie o Assistente de Impressão!",
    tip94: 'Pagamento bem sucedido',
    tip95: 'Tempo limite de pagamento',
    tip96: 'Introduza o código de resgate',
    tip97: 'Instruções de utilização',
    tip98: 'Os códigos de resgate podem ser obtidos participando das atividades oficiais de operação realizadas pela Hanma;',
    tip99: 'Processo de resgate: verifique as informações da conta, preencha o código de resgate e clique em Resgatar Agora;',
    tip100: 'Se for membro, o período de validade da adesão será prolongado após o resgate;',
    tip101: 'Por favor, preste atenção ao tempo de resgate e resgate dentro do período de validade do resgate.',
    tip102: 'Resgate bem-sucedido',
    tip103: 'Erro de análise de modelo',
    tip104: 'A função de formulário é um privilégio apenas para membros, por favor, use-a depois de atualizar sua associação!',
    tip105: 'Por favor, abra-o com um navegador de computador!'
  },
  label: {
    label1: "Pasta",
    label2: "Etiquetas usadas recentemente",
    label3: "Nome",
    label4: "Editar o nome da pasta",
    label5: "Editar o nome do ficheiro",
    label6: "Editar o nome do rótulo",
    label7: "Modifique o apelido",
    label8: "Editar o apelido",
    label9: "Alterar a palavra-passe",
    label10: "Apelido",
    label11: "Conta",
    label12: "Senha",
    label13: "Indústria",
    label14: "Selecione a indústria",
    label15: "Rever",
    label16: "O nome do rótulo",
    label17: "Guarde a etiqueta para",
    label18: "Mover o rótulo para",
    label19: "Pré-visualização de impressão",
    label20: "O tamanho da etiqueta",
    label21: "O tipo de modelo",
    label23: "Largura",
    label24: "Altura",
    label25: "Multi-colunas multi-linha",
    label26: "Colunas",
    label27: "Linhas",
    label28: "Espaçamento",
    label29: "Espaçamento",
    label30: "Margem",
    label31: "Esquerda",
    label32: "Certo",
    label33: "Acima",
    label34: "Sob",
    label35: "A minha criação",
    label36: "O nome dos dados",
    label37: "Adicionar uma data",
    label38: "Padrão",
    label39: "Nome A-Z",
    label40: "Nome Z-A",
    label41: "Date ASC",
    label42: "Data DESC",
    label43: "Tamanho ASC",
    label44: "Tamanho DESC",
    label45: "Login de conta",
    label46: "Reset",
    label47: "Li e concordo ",
    label48: "Acordo de serviço ",
    label49: "e ",
    label50: "Política de Privacidade",
    label51: "Nova pasta 1",
    label52: "Atualize membros para desfrutar de privilégios exclusivos",
    label53: "WeChat Pay",
    label54: "WeChat",
    label55: "Atualize membros para desfrutar de ",
    label63: " privilégios de membro",
    label56: "Recomendar",
    label57: "Expira em ",
    label58: " dias",
    label59: "",
    label60: "Expira hoje",
    label61: " meses",
    label62: "Login de terceiros",
    label64: "Impressora",
    label65: "Cópias",
    label66: "Preferencial",
  },
  privilege: {
    privilege1: "Aumente o espaço na nuvem",
    privilege2: "Digitalizar código novo",
    privilege3: "Pastas ilimitadas",
    privilege4: "Digitalizar impressão",
    privilege5: "Partilhar Modelos",
    privilege6: "Impressão Rápida",
    privilege7: "Modelos Variáveis",
    privilege8: "Fontes Exclusivas",
    privilege9: "Função de Tabela",
    privilege10: "Imprimir Registro",
    privilege11: "Modificar Apelido",
    privilege12: "Status Distinto",
    privilege13: "Espaço na nuvem"
  },
  editAttr: {
    attrText1: "Propriedades componentes",
    attrText2: "A fonte de dados",
    attrText3: "Texto normal",
    attrText4: "O texto da base de dados",
    attrText5: "Texto serializado",
    attrText6: "Fonte",
    attrText7: "Tamanho",
    attrText8: "Estilo",
    attrText9: "Girar",
    attrText10: "Palavras",
    attrText11: "Linha",
    attrText12: "Quebras de linha",
    attrText13: "Espelho",
    attrText14: "Anti-Black",
    attrText15: "Localização",
    attrText16: "Data",
    attrText17: "Dígito",
    attrText18: "O tipo de tempo",
    attrText19: "Tempo do sistema",
    attrText20: "O tempo especificado",
    attrText21: "O tipo de código de barras",
    attrText22: "A localização dos dados",
    attrText23: "Não exibir",
    attrText24: "Abaixo",
    attrText25: "Ampliação",
    attrText26: "Esquerda",
    attrText27: "Meio",
    attrText28: "Certo",
    attrText29: "Taxa de correção de erros",
    attrText30: "Imagens locais",
    attrText31: "Imagens em nuvem",
    attrText32: "Zoom",
    attrText33: "O tipo de linha",
    attrText34: "A espessura da linha",
    attrText35: "Tipo de dados",
    attrText36: "Por favor, selecione",
    attrText37: "Conectar",
    attrText38: "Inserir dados",
    attrText39: "Valor inicial",
    attrText40: "Incremento",
    attrText41: "Quantidade",
    attrText42: "Formato numer numer",
    attrText43: "Formato de data",
    attrText44: "Selecione a hora",
    attrText45: "Raio de filete",
    attrText46: "Está cheio",
    attrText47: "Por favor, insira algo",
    attrText48: "Sólida",
    attrText49: "Tracejado",
    attrText50: "Cópia",
    attrText51: "Colar",
    attrText52: "Trancando",
    attrText53: "Destravar",
    attrText54: "Alinhar",
    attrText55: "Alinhamento à Esquerda",
    attrText56: "Centrado verticalmente",
    attrText57: "Alinhamento à Direita",
    attrText58: "Centrado horizontalmente",
    attrText59: "Alinhado Superior",
    attrText60: "Alinhado Inferior",
    attrText61: "Distribuição horizontal",
    attrText62: "Distribuição vertical",
    attrText63: "Ordem da camada",
    attrText64: "Mover Para o Topo",
    attrText65: "Mover Para o Fundo",
    attrText66: "Deletar",
    attrText67: "Dados da nuvem",
    attrText68: "Dados locais",
    attrText69: "Revogar",
    attrText70: "Recuperar",
    attrText71: "Salvar",
    attrText72: "Salvar como",
    attrText73: "Deletar",
    attrText74: "Mover para cima",
    attrText75: "Mova para baixo",
    attrText76: "Mova para a esquerda",
    attrText77: "Mover para a direita",
    attrText78: "Selecione tudo",
    attrText79: "Impressão",
    attrText80: "Zoom da tela",
    attrText81: "Menos zoom da tela",
    attrText82: "Seleção múltipla",
    attrText83: "Rolo para cima",
    attrText84: "Rolo para baixo",
    attrText85: "Clique esquerdo",
    attrText86: "Guia de principiante",
    attrText87: "Ver teclas de atalho",
    attrText88: "Descrições de atalhos",
    attrText89: "Janela adaptativa",
    attrText90: "Reduzir o zoom",
    attrText91: "Ampliar",
    attrText92: "Tipo serializado",
    attrText93: "Decimal",
    attrText94: "Alinhar",
    attrText95: "Compensação(d)",
    attrText96: "Compensação(m)",
    attrText97: "Imagem de fundo",
    attrText98: "Dados",
    attrText99: "Imprime imagem de fundo",
    attrText100: "Selecionar dados",
    attrText101: "Valor objeto",
    attrText102: "Adicionar dados",
    attrText103: "Hexadecimal",
    attrText104: "Letras",
    attrText105: "Letras e números",
    attrText106: "Altura da linha",
    attrText107: "Largura da coluna",
    attrText108: "Conteúdo",
    attrText109: "Inserir linhas",
    attrText110: "Inserir uma coluna",
    attrText111: "Apagar a linha",
    attrText112: "Apagar a coluna",
    attrText113: "Fusão",
    attrText114: "Divisão",
  },
  module: {
    editText1: "Texto",
    editText2: "Hora",
    editText3: "Código de barras",
    editText4: "Código QR",
    editText5: "Imagem",
    editText6: "Forma",
    editText7: "Retângulo",
    editText8: "Retângulo de filete",
    editText9: "Lozenge",
    editText10: "Elíptica",
    editText11: "Triângulo",
    editText12: "Retângulo sólido",
    editText13: "Icone",
    editText14: "Linha",
    editText15: "Horizonte",
    editText16: "Vertical",
    editText17: "Tabela",
  },
  guide: {
    guideText1: "Bem-vindo ao ",
    guideText2: "Simples e eficaz",
    guideText3: "Orientado à cena",
    guideText4: "Recursos úteis",
    guideText5: "Interoperabilidade multiterminal",
    guideText6:
      "O software de design de etiqueta HereLabel permite que o design de etiqueta alcance o que você vê e obtém. Pode ser concluído simplesmente arrastando e soltando. O design de etiqueta nunca foi tão simples e eficiente. Venha e experimente!",
    guideText7: "Pular",
    guideText8: "Comece a aprender",
    guideText9: "Guia: Modelo da Indústria",
    guideText10:
      "Os modelos de etiqueta baseados em cenários fornecem etiquetas comuns em vários setores, como varejo, roupas e códigos de barras.",
    guideText11: "Guia: modelo de nuvem",
    guideText12:
      "As tags pessoais são armazenadas automaticamente na nuvem, as tags de gerenciamento de pasta podem ser criadas e as tags podem ser usadas no PC e em terminais móveis.",
    guideText13: "Guia: Dados na nuvem",
    guideText14:
      "Arquivos como fotos e tabelas podem ser armazenados permanentemente na nuvem e podem ser usados a qualquer hora, em qualquer lugar na Web, PC e terminais móveis.",
    guideText15: "Guia: Centro Pessoal",
    guideText16:
      "Clique para modificar o idioma, troque a unidade ou entre na página do centro pessoal para modificar as informações.",
    guideText17: "Guia: Nova etiqueta",
    guideText18:
      "Defina o nome do rótulo, tamanho, número de linhas e número de colunas e comece a criar seu próprio rótulo!",
    guideText19: "Consegui",
    guideText20: "Comece a criar rótulos",
    guideText21: "Bem-vindo à página de edição",
    guideText22:
      "Apenas alguns passos simples para concluir a edição do rótulo, venham e aprendam juntos!",
    guideText23: "Biblioteca de componentes",
    guideText24:
      "Fornece uma variedade de componentes, como texto, hora, código de barras, etc., e os componentes correspondentes podem ser adicionados clicando ou arrastando com o mouse.",
    guideText25: "continue estudando",
    guideText26: "Área de função",
    guideText27:
      "As funções do componente incluem bloquear, desfazer, restaurar, alinhamento, camada superior, camada inferior, copiar, colar e excluir.",
    guideText28:
      "Você também pode clicar com o botão direito do mouse no componente na tela para abrir o menu do botão direito para operação.",
    guideText29: "Área de Atributo",
    guideText30:
      "Selecione o componente para abrir a área de atributos, modificar a fonte de dados, estilo, tamanho, posição e outros atributos do componente correspondente.",
    guideText31: "Salvar Impressão",
    guideText32:
      "Clique em configurações de etiqueta para modificar o tamanho da etiqueta atual. <br /> Depois de terminar de editar a etiqueta, você pode tentar imprimir a etiqueta atual!",
    guideText33: "Eu aprendi",
    guideText34: "Próximo",
    guideText35:
      "O software de design de etiqueta HereLabel permite que o design de etiqueta alcance o que você vê e obtém. Pode ser concluído simplesmente arrastando e soltando. O design de etiqueta nunca foi tão simples e eficiente. Venha e experimente!",
  },
  suggest: {
    suggest1: "Tipo de pergunta",
    suggest2: "Função anormal",
    suggest3: "Conselhos de produtos",
    suggest4: "Descrição do problema",
    suggest5:
      "Descreva detalhadamente o problema que você encontrou e nós o resolveremos o mais rápido possível!",
    suggest6: "Detalhes do contato",
    suggest7: "Insira suas informações de contato",
    suggest8: "Screenshot do problema",
    suggest9: "Enviar",
  },
  document: {
    text1: 'Abrir o documento',
    text2: 'Clique ou arraste e solte o arquivo diretamente nesta área para carregá-lo',
    text3: 'Corte automático',
    text4: 'Recorte inteligente de etiquetas',
    text5: 'Recortar Personalizado',
    text6: 'Impressão directa',
    text7: 'Documentação',
    text8: 'Cortar o excesso de espaço em branco em documentos com um clique',
    text9: 'Segmentação inteligente de etiquetas',
    text10: 'Cortar em vários ficheiros de etiquetas',
    text11: 'Linhas',
    text12: 'Colunas',
    text13: 'Exemplo',
    text14: 'Por exemplo: 2 * 2',
    text15: 'Próximo passo',
    text16: 'Tamanho do papel',
    text17: 'personalizado',
    text18: 'Largura',
    text19: 'Altura',
    text20: 'Efeito de impressão',
    text21: 'Ficheiro',
    text22: 'Imagem',
    text23: 'Resolução',
    text24: 'Método do arranjo',
    text25: 'Esticar',
    text26: 'Recheio',
    text27: 'Proporção',
    text28: 'Número de cópias impressas',
    text29: 'Intervalo de Páginas',
    text30: 'Inteiro',
    text31: 'Página actual',
    text32: 'Intervalo de Páginas',
    text33: 'Intervalo de páginas (por exemplo, 1-3, 5)',
    text34: 'Passo anterior',
    text35: 'Culturas',
    text36: 'Envie arquivos txt, pptx, ppt, pdf, doc, docx, xls, xlsx, bp, jpg, png',
    text37: 'O conteúdo do documento está vazio',
    text38: 'Erro de entrada do intervalo de páginas',
    text39: 'Falha na análise',
    text40: 'Aplicar todo o documento',
  }
};
