import { message } from "ant-design-vue";
import { ElLoading } from "element-plus";
import i18n from "../language/i18n";
import { getAuthorization, setAuthorization } from "./request";
// 401拦截
let loadingInstance = "";
const resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */

  onFulfilled (response, options) {
    loadingInstance.close();
    let t = new Date(new Date().getTime() + 2 * 60 * 60 * 1000); //登录过期时间
    let url = response.config.url;
    if (response.data.ResultCode) {
      var code = response.data.ResultCode;
    } else {
      var code = response.data.code;
    }
    switch (code) {
      case 200:
        if (getAuthorization()) {
          setAuthorization({
            token: getAuthorization(),
            expireAt: new Date(t),
          });
        }
        if (response.data.Data) {
          return response.data.Data;
        } else {
          if (
            url == "/hanma/api/tem.app_public/getSharedClass" ||
            url == "/hanma/api/tem.app_public/getSharedList" ||
            url == "/hanma/api/tem.app_public/getSearchList"
          ) {
            return {
              list: response.data.data,
              count: response.data.count,
            };
          }
          return response.data.data;
        }
      case 209:
        message.error(response.data.Reason);
        return Promise.reject(response.data.Reason);
      case 301:
        message.error(response.data.Reason);
        removeAuthorization();
        setTimeout(() => {
          location.reload();
        }, 1000);
        return;
      case 500:
        if (response.data.Reason) {
          message.error(response.data.Reason);
          return Promise.reject(response.data.Reason);
        } else if (response.data.message) {
          message.error(response.data.message);
          return Promise.reject(response.data.message);
        } else if (response.data.msg) {
          message.error(response.data.msg);
          return Promise.reject(response.data.msg);
        }
      case 401:
        //账号未绑定前去绑定页
        message.error(response.data.Reason);
        return Promise.reject(response.data);
      case 402:
      case 403:
        //403文件夹数量上限
        //402云空间上限
        return Promise.reject(response.data);
      case 405:
        //覆盖模板
        message.error(response.data.message);
        return Promise.reject(response.data);
    }
    if (code === 200) {
      if (response.data.Data) {
        return response.data.Data;
      } else {
        return response.data.data;
      }
    } else {
      if (response.data.Reason) {
        message.error(response.data.Reason);
        return Promise.reject(response.data.Reason);
      } else if (response.data.message) {
        message.error(response.data.message);
        return Promise.reject(response.data.message);
      } else if (response.data.msg) {
        message.error(response.data.msg);
        return Promise.reject(response.data.msg);
      }
    }
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected (error, options) {

    loadingInstance.close();
    // const {message} = options
    message.error(i18n.global.t(`tips.tip46`));
    return Promise.reject(error);

    // const {message} = options
    // const {response} = error
    // if (response.status === 401) {
    //   message.error('无此权限')
    // }
    // return Promise.reject(error)
  },
};

// const resp403 = {
//   onFulfilled(response, options) {
//     const {message} = options
//     console.log(response,77)
//     if (response.status !== 200) {
//       message.error('请求被拒绝')
//     }
//     return response
//   },
//   onRejected(error, options) {
//     const {message} = options
//     const {response} = error
//     if (response.status === 403) {
//       message.error('请求被拒绝')
//     }
//     return Promise.reject(error)
//   }
// }

const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config, options) {
    const path = options.router.currentRoute._rawValue.path;
    const url = config.url;
    if (
      (path == "/index" ||
        path == "/cloudTemplate" ||
        path == "/shareTemplate") &&
      (url == "/net/LoadXml/writeFile" ||  url == "/hanma/api/tem.new_class/import" )
    ) {
      //首页、共享模板、云端模板页面点击立即打印，不加载样式
      return config;
    }
    if (url == "/hanma/api/tem.app_member/getStatusOrder") {
      //获取订单状态不显示加载样式
      return config;
    } else {
      loadingInstance = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.2)",
      });
    }
    //
    // const { url, xsrfCookieName } = config
    // if (url.indexOf('login') === -1 && xsrfCookieName && !Cookie.get(xsrfCookieName)) {
    //     message.warning('认证 token 已过期，请重新登录')
    // }
    config.headers.language = i18n.global.locale.value;
    return config;
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected (error, options) {
    // const { message } = options
    message.error(error.message);
    loadingInstance.close();
    return Promise.reject();
  },
};

export default {
  request: [reqCommon], // 请求拦截
  response: [resp401], // 响应拦截
};
