<template>
  <div
    style="
      outline: none;
      user-select: none;
      cursor: move;
      width: 100%;
      height: 100%;
    "
    :style="{
      whiteSpace: list.AutoWrap ? 'pre-wrap' : 'nowrap',
      wordBreak: list.AutoWrap ? 'break-all' : '',
      textAlign:
        list.TextAlign == 1 ? 'center' : list.TextAlign == 2 ? 'right' : 'left',

      fontSize: list.Font['@FontSize'] / 4 + 'mm',
      color: list.AntiBlack ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
      fontFamily: list.Font['@FontFamily'],
      fontWeight:
        list.Font['@FontStyle'].split(', ').filter((ele) => ele == 'Bold') ==
        'Bold'
          ? 'Bold'
          : 'normal',
      letterSpacing: list.WordSpacing / 4 + 'mm',
      lineHeight:
        list.RowSpacing1 == 0
          ? list.Font['@FontSize'] + 'px'
          : list.RowSpacing1 + list.Font['@FontSize'] + 'px',
      transform: `rotateY(${list.MirrorImage ? 180 : 0}deg)`,
    }"
    :contenteditable="canEdit"
    @focus="isLocked = true"
    @blur="isLocked = false"
    @input="changeText"
  >
    <span
      ref="editdiv"
      v-html="innerText"
      style="padding: 0 0 0 0; display: inline-block; position: relative"
      :style="{
        transform: `skewX(${
          list.Font['@FontStyle']
            .split(', ')
            .filter((ele) => ele == 'Italic') == 'Italic'
            ? -15
            : 0
        }deg)`,
        textDecoration:
          list.Font['@FontStyle']
            .split(', ')
            .filter((ele) => ele == 'Underline') == 'Underline'
            ? 'Underline'
            : '',
        top: list.RowSpacing1 == 0 ? 0 : -list.RowSpacing1 / 2 + 'px',
      }"
    ></span>
    <img
      style="
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: calc(100% + 1px);
      "
      v-if="list.AntiBlack"
      src="../assets/black-bg.png"
      alt=""
    />
  </div>
</template>
<script>
function filterText(value) {
  if (value) {
    value = value.replace(/[\n]/gi, "<br/>");
    value = value.replace(/[\s]/gi, "&nbsp;");
    return value;
  } else {
    return value;
  }
}
export default {
  name: "editDiv",
  props: {
    value: {
      type: String,
      default: "",
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: -1,
    },
    pindex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      innerText: this.value,
      isLocked: false,
      textDecoration: "",
      list: "",
    };
  },
  computed: {
    textAttr() {
      const {
        AngleRound,
        AutoWrap,
        BoxWidth,
        Data,
        TextAlign,
        WordSpacing,
        RowSpacing1,
      } = this.data;
      return {
        AngleRound,
        AutoWrap,
        BoxWidth,
        Data,
        TextAlign,
        WordSpacing,
        RowSpacing1,
      };
    },
  },
  watch: {
    value() {
      if (!this.isLocked || !this.innerText) {
        this.innerText = filterText(this.value);
      }
    },
    data: {
      deep: true,
      handler(newV, oldV) {
        this.list = newV;
      },
    },
    textAttr() {
      this.resetWH();
    },
    "data.Font": {
      deep: true,
      handler(newV, oldV) {
        this.resetWH();
      },
    },
  },
  created() {
    this.list = this.data;
    this.innerText = filterText(this.value);
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.editdiv) {
        var width = this.$refs.editdiv.offsetWidth;
        var height = this.$refs.editdiv.offsetHeight - this.list.RowSpacing1;

        this.$emit(
          "getDiv",
          (width * 25.4) / 96,
          (height * 25.4) / 96,
          this.index,
          0,
          this.pindex,
        );
      }
    });
  },
  methods: {
    resetWH() {
      this.$nextTick(() => {
        if (this.$refs.editdiv) {
          var width = this.$refs.editdiv.offsetWidth;
          var height = this.$refs.editdiv.offsetHeight - this.list.RowSpacing1;

          this.$emit(
            "getDiv",
            (width * 25.4) / 96,
            (height * 25.4) / 96,
            this.index,
            1
          );
        }
      });
    },
    changeText(e) {
      this.$emit("input", this.$el.innerHTML);
    },
  },
};
</script>
<style>
.edit-div {
  white-space: nowrap;
}

.edit-div1 {
  word-break: break-all;
  white-space: pre-wrap;
}

.edit-div:empty::before {
  content: attr(placeholder);
}
</style>
