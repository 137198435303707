import { randomNumberStr } from "@/utils/util";
import JsBarcode from "jsbarcode";
import changedpi from "../js/changeDpi";
const drawComponent = {
  data() {
    return {
      dbClick: false, //判断是否双击
      unitType: "WinText",
      rectsType: "Rectangle",
      showRects: false,
      showIcons: false,
      point: {
        left: 0,
        top: 0,
      },
      cursor: "no-drop",
      unitFlag: false, //组件拖动div
      rectsFlag: false,
      iconFlag: false,
    };
  },
  methods: {
    //选择了文本
    handleText(left, top) {
      if (this.dbClick) {
        left = left - 20;
        top = top - 6;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "WinText") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length, //图层顺序
        Name: "WinText" + (Number(num) + 1), //名称
        OriginalData: "Label Designer", //原始数据
        Data: "Label Designer", //修改后数据
        Font: {
          "@FontFamily": "Arial",
          "@FontSize": 12,
          "@FontStyle": "Regular",
        }, //字体样式
        Vert: false, //用不上
        TimeDate: 0, //指定时间  0为系统时间 1为指定时间
        Key: "", //用不上
        RowSpacing1: 0, //行间距
        WordSpacing: 0, //字间距
        MigrationTime: "0", //迁移时间
        AutoWrap: false, //自动换行
        BoxWidth: 20, //自动换行为true时的宽度
        StartX: (left * 25.4) / 96 - 11 / this.multiple, //偏移量
        StartY: (top * 25.4) / 96 - 2 / this.multiple, //偏移量
        Height: 4, //高度
        Width: 22, //宽度
        AngleRound: 0, //旋转角度
        Type: this.unitType, //类型
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0, //线条类型
        FillColor: "-16777216",
        Lock: false, //是否锁定
        Id: randomNumberStr(8), //自动生成
        AntiBlack: false, //反黑
        MirrorImage: false, //镜像
        TextAlign: 0, //文本对齐
        DataSourceList: [
          {
            DataSourceType: "0", //数据源 0普通文本 1数据库文本 2 序列化文本 3元素值
            FirstValue: "Label Designer", //显示文本值，连接数据库的时候，会获取绑定列的第一个值，用来显示
            ColumnName: "", //数据库列名
            QuerySql: "", //选择的数据库
            TableName: "", //数据库表名
            ConnectionStr: "", //插入字段名
            DataType: "2", //数据类型 0为日期 1为数字 2文本
            DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
            NumberFormat: "123456789", //数字形式
            SequenceType: "0", //序列类型
            InitialValue: "", //初始递变数值
            AddedValue: "", //递变量
            Quantity: "", //递变数量
            ReferenceId: "", //引用元素值的Id
          },
        ],
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.setDataSource(obj);
      this.handleRecord("add");
    },
    //选择了日期
    handleTime(left, top) {
      if (this.dbClick) {
        left = left - 33;
        top = top - 6;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "TimeText") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length, //图层顺序
        Name: "TimeText" + (Number(num) + 1), //名称
        OriginalData: this.timeToDate(
          new Date().getTime(),
          "yyyy-MM-dd HH:mm:ss"
        ), //原始数据
        Data: this.timeToDate(new Date().getTime(), "yyyy-MM-dd HH:mm:ss"), //修改后数据
        Font: {
          "@FontFamily": "Arial",
          "@FontSize": 12,
          "@FontStyle": "Regular",
        }, //字体样式
        Vert: false,
        DataType: "0", //数据类型 0为日期 1为数字
        TimeDate: "0", //指定时间  0为系统时间 1为指定时间
        DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
        DayOffset: 0, //天数偏移
        MinuteOffset: 0, //分钟偏移
        Key: "",
        NumberFormat: "123456789", //数字形式
        RowSpacing1: 0, //行间距
        WordSpacing: 0, //字间距
        MigrationTime: 0, //迁移时间
        AutoWrap: false, //自动换行
        BoxWidth: 20, //自动换行为true时的宽度
        DataSourceType: "0", //数据源 0普通文本 1数据库文本 2 序列化文本
        FirstValue: "",
        StartX: (left * 25.4) / 96 - 15 / this.multiple, //偏移量
        StartY: (top * 25.4) / 96 - 2 / this.multiple, //偏移量
        Height: 4, //高度
        Width: 30, //宽度
        AngleRound: 0, //旋转角度
        Type: this.unitType, //类型
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false, //是否锁定
        Id: randomNumberStr(8),
        AntiBlack: false, //反黑
        MirrorImage: false, //镜像
        TextAlign: 0, //文本对齐
        selectTime: new Date(
          this.timeToDate(new Date().getTime(), "yyyy-MM-dd HH:mm:ss")
        ),
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.setDataSource(obj);
      this.setSystemTime();
      this.handleRecord("add");
    },
    //选择了条形码
    handleBarcode(left, top) {
      this.CodeType = this.BarCode;
      if (this.dbClick) {
        left = left - 25;
        top = top - 7;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "GeneralBarcode") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "BarCode" + (Number(num) + 1),
        BarcodeImage: "",
        BarcodeType: "CODE128",
        HasLabel: true,
        Localtype: "BOTTOMCENTER",
        Font: {
          "@FontFamily": "Arial",
          "@FontSize": 14,
          "@FontStyle": "Regular",
        },
        Zoom: 5,
        OrgSize: 50,
        DisplaPosition: 2,
        BarCodeHight: 44,
        Data: "012345678910",
        Key: "",
        StartX: (left * 25.4) / 96 - 25 / this.multiple,
        StartY: (top * 25.4) / 96 - 7 / this.multiple,
        Height: 15,
        Width: 50,
        AngleRound: 0,
        Type: this.unitType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
        DataSourceList: [
          {
            DataSourceType: "0", //数据源 0普通文本 1数据库文本 2 序列化文本 3元素值
            FirstValue: "012345678910", //显示文本值，连接数据库的时候，会获取绑定列的第一个值，用来显示
            ColumnName: "", //数据库列名
            QuerySql: "", //选择的数据库
            TableName: "", //数据库表名
            ConnectionStr: "", //插入字段名
            DataType: "2", //数据类型 0为日期 1为数字 2文本
            DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
            NumberFormat: "123456789", //数字形式
            SequenceType: "0", //序列类型
            InitialValue: "", //初始递变数值
            AddedValue: "", //递变量
            Quantity: "", //递变数量
            ReferenceId: "", //上一级引用元素值的Id
          },
        ],
      };
      // 4.16 是为了生成为300dip时放大图片足够清晰
      let barcode = {
        format: obj.BarcodeType,
        width: 0.35 * obj.Zoom * 4.16,
        height: obj.BarCodeHight * 4.16,
        fontSize: obj.Font["@FontSize"] * 4.16,
        font: obj.Font["@FontFamily"],
        fontOptions: "",
        textAlign: "center",
        displayValue: true,
        margin: 5,
        textPosition: "bottom",
        background: "",
      };
      JsBarcode("#barcode", obj.Data, barcode);
      let base64Str = changedpi.changeDpiDataUrl(this.$refs.barcode.src, 300);
      // let baseStrProduct = this.$refs.barcode.src.split("base64,")[1];
      let baseStrProduct = base64Str.split("base64,")[1];
      obj.BarcodeImage = baseStrProduct;
      obj.RightBarcodeImage = baseStrProduct;
      let imgs = new Image();
      imgs.src = this.imgHeader + obj.BarcodeImage;
      imgs.onload = () => {
        let w = imgs.width / 4.16;
        let h = imgs.height / 4.16;
        obj.Width = (w * 25.4) / 96;
        obj.zoomWidth = obj.Width / obj.Zoom;
        obj.StartX = (left * 25.4) / 96 - obj.Width / 2 / this.multiple;
        obj.StartY = (top * 25.4) / 96 - obj.Height / 2 / this.multiple;
        this.subgroup.push(obj);
        this.subIndex = this.subgroup.length - 1;
        this.dataSourceIndex = 0;
        this.dataLocationValue = 1;
        this.locationIndex = 2;
        this.selectUnit = [];
        let data = obj;
        data.index = this.subIndex;
        this.selectUnit.push(data);
        this.setDataSource(obj);
        this.handleRecord("add");
      };
    },
    //选择二维码
    handleQRCode(left, top) {
      this.CodeType = this.QRCode;
      if (this.dbClick) {
        left = left - 9;
        top = top - 9;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "QRCode") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "QRCode" + (Number(num) + 1),
        BarcodeType: "QR_CODE",
        ECLevelBits: "Low",
        BarcodeImage: "",
        Zoom: 2,
        OrgSize: 3,
        QRCodeWidth: 3,
        QRCodeHeight: 3,
        BarCodeHight: 3,
        Data: "PRT",
        Key: "",
        StartX: (left * 25.4) / 96,
        StartY: (top * 25.4) / 96,
        Height: 19.84,
        Width: 19.84,
        AngleRound: 0,
        Type: this.unitType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
        DataSourceList: [
          {
            DataSourceType: "0", //数据源 0普通文本 1数据库文本 2 序列化文本 3元素值
            FirstValue: "PRT", //显示文本值，连接数据库的时候，会获取绑定列的第一个值，用来显示
            ColumnName: "", //数据库列名
            QuerySql: "", //选择的数据库
            TableName: "", //数据库表名
            ConnectionStr: "", //插入数据表名
            DataType: "2", //数据类型 0为日期 1为数字 2文本
            DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
            NumberFormat: "123456789", //数字形式
            SequenceType: "", //序列类型
            InitialValue: "", //初始递变数值
            AddedValue: "", //递变量
            Quantity: "", //递变数量
            ReferenceId: "", //上一级引用元素值的Id
          },
        ],
      };

      // let barcode = {
      //   text: obj.Data,
      //   width: obj.Width * obj.Zoom,
      //   height: obj.Height * obj.Zoom,
      //   correctLevel: QRCode.CorrectLevel.L,
      //   colorLight: "transparent",
      // };

      let canvas = document.createElement("canvas");
      let options = {
        bcid: "qrcode",
        text: obj.Data,
        scale: obj.Zoom,
        eclevel: "L",
      };
      bwipjs.toCanvas(canvas, options);
      let baseStrProduct = canvas.toDataURL("image/png").split("base64,")[1];

      this.$nextTick(() => {
        let img = baseStrProduct;
        obj.BarcodeImage = img;
        let imgs = new Image();
        imgs.src = this.imgHeader + img;
        imgs.onload = () => {
          obj.QRCodeWidth = 21 * 25.4 /96;
          obj.QRCodeHeight = 21 * 25.4 /96;
          obj.Width = obj.QRCodeWidth * obj.Zoom;
          obj.Height = obj.QRCodeHeight * obj.Zoom;
          obj.StartX = obj.StartX - obj.Width / 2 / this.multiple;
          obj.StartY = obj.StartY - obj.Height / 2 / this.multiple;
          this.isCreate = true;
          this.subgroup.push(obj);
          this.subIndex = this.subgroup.length - 1;
          this.selectUnit = [];
          let data = obj;
          data.index = this.subIndex;
          this.selectUnit.push(data);
          this.setDataSource(obj);
          this.setEclevelList(obj.BarcodeType);
          this.handleRecord("add");
        };
      });
    },
    //选择图片
    handleImg(left, top) {
      if (this.dbClick) {
        left = left - 10;
        top = top - 10;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "Image") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      var imgSrc = require("../../../assets/tupian.png");
      var image = new Image();
      image.src = imgSrc;
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        var dataUrl = canvas.toDataURL("image/png");
        var base64 = JSON.parse(JSON.stringify(dataUrl));

        let obj = {
          zOrder: this.subgroup.length,
          Name: "Image" + (Number(num) + 1),
          OriginalImage: base64.split(",")[1],
          Mirror: "None",
          Inverse: false,
          Halftone: "None",
          ISParticipating: true, //等比缩放
          ImageFilePath: "",
          Image: "",
          StartX: (left * 25.4) / 96 - 10 / this.multiple,
          StartY: (top * 25.4) / 96 - 10 / this.multiple,
          Height: 20,
          Width: 20,
          AngleRound: 0,
          Data: null,
          Type: this.unitType,
          Color: "-16777216",
          PenWidth: 3,
          DashStyle: 0,
          FillColor: "-16777216",
          Lock: false,
          Id: randomNumberStr(8),
        };
        this.subgroup.push(obj);
        this.subIndex = this.subgroup.length - 1;
        this.dataSourceIndex = 0;
        this.selectUnit = [];
        let data = obj;
        data.index = this.subIndex;
        this.selectUnit.push(data);
        this.handleRecord("add");
      };
    },
    //选择斜线
    handleLine(left, top) {
      if (this.dbClick) {
        left = left - 10;
        top = top - 5;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "Line") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "Line" + (Number(num) + 1),
        StartX: (left * 25.4) / 96 - 10 / this.multiple,
        StartY: (top * 25.4) / 96 - 5 / this.multiple,
        EndX: (left * 25.4) / 96 - 10 / this.multiple + 20,
        EndY: (top * 25.4) / 96 - 5 / this.multiple + 10,
        Width: 20,
        Height: 10,
        AngleRound: 0,
        Type: this.unitType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择水平线
    handleLevel(left, top) {
      if (this.dbClick) {
        left = left - 10;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "LineHorizontal") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "LineHorizontal" + (Number(num) + 1),
        StartX: (left * 25.4) / 96 - 10 / this.multiple,
        StartY: (top * 25.4) / 96,
        EndX: (left * 25.4) / 96 - 10 / this.multiple + 20,
        EndY: 1,
        Width: 20,
        Height: 1,
        Type: this.unitType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        AngleRound: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择垂直线
    handleVertical(left, top) {
      if (this.dbClick) {
        top = top - 10;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "LineVertical") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "LineVertical" + (Number(num) + 1),
        StartX: (left * 25.4) / 96,
        StartY: (top * 25.4) / 96 - 10 / this.multiple,
        EndX: 1,
        EndY: (top * 25.4) / 96 - 10 / this.multiple + 20,
        Width: 1,
        Height: 20,
        Type: this.unitType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        AngleRound: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择矩形
    handleShape(left, top) {
      if (this.dbClick) {
        left = left - 15;
        top = top - 10;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "Rectangle") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "Rectangle" + (Number(num) + 1),
        StartX: (left * 25.4) / 96 - 15 / this.multiple,
        StartY: (top * 25.4) / 96 - 10 / this.multiple,
        Height: 20,
        Width: 30,
        AngleRound: 0,
        Data: null,
        Type: this.rectsType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择圆角矩形
    handleRoundRect(left, top) {
      if (this.dbClick) {
        left = left - 15;
        top = top - 10;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "RoundRectangle") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "RoundRectangle" + (Number(num) + 1),
        StartX: (left * 25.4) / 96 - 15 / this.multiple,
        StartY: (top * 25.4) / 96 - 10 / this.multiple,
        Height: 20,
        Width: 30,
        CornerRadius: 10,
        AngleRound: 0,
        Data: null,
        Type: this.rectsType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择菱形
    handleDiamond(left, top) {
      if (this.dbClick) {
        left = left - 42;
        top = top - 36;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "Diamond") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "Diamond" + (Number(num) + 1),
        Vertice: [
          {
            "@X": ((left * 25.4) / 96 - 21 / this.multiple) * 4 + 21 * 4,
            "@Y": ((top * 25.4) / 96 - 18 / this.multiple) * 4,
            "@order": "0",
          },
          {
            "@X": ((left * 25.4) / 96 - 21 / this.multiple) * 4 + 42 * 4,
            "@Y": ((top * 25.4) / 96 - 18 / this.multiple) * 4 + 18 * 4,
            "@order": "1",
          },
          {
            "@X": ((left * 25.4) / 96 - 21 / this.multiple) * 4 + 21 * 4,
            "@Y": ((top * 25.4) / 96 - 18 / this.multiple) * 4 + 36 * 4,
            "@order": "2",
          },
          {
            "@X": ((left * 25.4) / 96 - 21 / this.multiple) * 4,
            "@Y": ((top * 25.4) / 96 - 18 / this.multiple) * 4 + 18 * 4,
            "@order": "3",
          },
        ],
        StartX: (left * 25.4) / 96 - 21 / this.multiple,
        StartY: (top * 25.4) / 96 - 18 / this.multiple,
        Height: 36,
        Width: 42,
        AngleRound: 0,
        Data: null,
        Type: this.rectsType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择椭圆
    handleElliptical(left, top) {
      if (this.dbClick) {
        left = left - 50;
        top = top - 10;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "Ellipse") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "Ellipse" + (Number(num) + 1),
        StartX: (left * 25.4) / 96 - 20 / this.multiple,
        StartY: (top * 25.4) / 96 - 10 / this.multiple,
        Height: 20,
        Width: 50,
        AngleRound: 0,
        Data: null,
        Type: this.rectsType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择三角形
    handleTriangle(left, top) {
      if (this.dbClick) {
        left = left - 42;
        top = top - 35;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "Triangle") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "Triangle" + (Number(num) + 1),
        Vertice: [
          {
            "@X": ((left * 25.4) / 96 - 21 / this.multiple) * 4,
            "@Y": ((top * 25.4) / 96 - 18 / this.multiple) * 4 + 36 * 4,
            "@order": "0",
          },
          {
            "@X": ((left * 25.4) / 96 - 21 / this.multiple) * 4 + 42 * 4,
            "@Y": ((top * 25.4) / 96 - 18 / this.multiple) * 4 + 36 * 4,
            "@order": "1",
          },
          {
            "@X": ((left * 25.4) / 96 - 21 / this.multiple) * 4 + 21 * 4,
            "@Y": ((top * 25.4) / 96 - 18 / this.multiple) * 4,
            "@order": "2",
          },
        ],
        StartX: (left * 25.4) / 96 - 21 / this.multiple,
        StartY: (top * 25.4) / 96 - 18 / this.multiple,
        Height: 36,
        Width: 42,
        AngleRound: 0,
        Data: null,
        Type: this.rectsType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
        // InitialValue: "",
        // AddedValue: "",
        // EndValue: "",
        // IntervalValue: ""
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择实心矩形
    handleSolidRect(left, top) {
      if (this.dbClick) {
        left = left - 15;
        top = top - 10;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "SolidRectangle") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length,
        Name: "SolidRectangle" + (Number(num) + 1),
        StartX: (left * 25.4) / 96,
        StartY: (top * 25.4) / 96,
        Height: 20,
        Width: 30,
        AngleRound: 0,
        Data: null,
        Type: this.rectsType,
        Color: "-16777216",
        PenWidth: 3,
        DashStyle: 0,
        FillColor: "-16777216",
        Lock: false,
        Id: randomNumberStr(8),
        // InitialValue: "",
        // AddedValue: "",
        // EndValue: "",
        // IntervalValue: ""
      };
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      this.dataSourceIndex = 0;
      this.selectUnit = [];
      let data = obj;
      data.index = this.subIndex;
      this.selectUnit.push(data);
      this.handleRecord("add");
    },
    //选择图标
    handleIconImg(left, top) {
      if (this.dbClick) {
        left = left - 12;
        top = top - 12;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "Image") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      this.URLgetBase64(
        this.iconList[this.iconIndex].url,
        "icon",
        (dataURL) => {
          let src = dataURL.split(",")[1];
          let obj = {
            zOrder: this.subgroup.length,
            Name: "Image" + (Number(num) + 1),
            OriginalImage: src,
            Mirror: "None",
            Inverse: false,
            Halftone: "None",
            ISParticipating: true, //等比缩放
            ImageFilePath: "",
            Image: "",
            StartX: (left * 25.4) / 96 - 12 / this.multiple,
            StartY: (top * 25.4) / 96 - 12 / this.multiple,
            Height: 24,
            Width: 24,
            AngleRound: 0,
            Data: null,
            Type: "Image",
            Color: "-16777216",
            PenWidth: 3,
            DashStyle: 0,
            FillColor: "-16777216",
            Lock: false,
            Id: randomNumberStr(8),
            // InitialValue: "",
            // AddedValue: "",
            // EndValue: "",
            // IntervalValue: ""
          };
          this.subgroup.push(obj);
          this.subIndex = this.subgroup.length - 1;
          this.dataSourceIndex = 0;
          this.selectUnit = [];
          let data = obj;
          data.index = this.subIndex;
          this.selectUnit.push(data);
          this.handleRecord("add");
        }
      );
    },
    //选择表格
    handleTable(left, top) {
      if (this.dbClick) {
        left = left - 15;
        top = top - 7;
      }
      let num = 0;
      this.subgroup.forEach((e) => {
        if (e.Type == "Table") {
          let str = e.Name.replace(/[^0-9]/gi, "");
          num = num <= str ? str : num;
        }
      });
      let obj = {
        zOrder: this.subgroup.length, //图层顺序
        Name: "Table" + (Number(num) + 1), //名称
        StartX: (left * 25.4) / 96 - 15 / this.multiple, //偏移量
        StartY: (top * 25.4) / 96 - 7 / this.multiple, //偏移量
        Width: 31.5,
        Height: 15.5,
        PenWidth: 0.5,
        Type: this.unitType, //类型
        Lock: false, //是否锁定
        DataSourceList: [],
        DicLineLocW: [15.75], //列线的坐标（线的左上角），用来处理合并单元格
        DicLineLocH: [7.75], //行线的坐标（线的左上角），用来处理合并单元格
        Row: 2,
        Cloumn: 2,
        AngleRound: 0, //旋转角度
        Id: randomNumberStr(8), //自动生成
      };
      let sub = {
        Data: "",
        Font: {
          "@FontFamily": "Arial",
          "@FontSize": 12,
          "@FontStyle": "Regular",
        }, //字体样式
        RowSpacing1: 0, //行间距
        WordSpacing: 0, //字间距
        StartX: 0.5,
        StartY: 0.5,
        Height: 7, //高度
        Width: 15, //宽度
        TextAlign: 1, //文本对齐
        AntiBlack: false, //反黑
        MirrorImage: false, //镜像
        AutoWrap: true, //自动换行
        DataSourceType: "0", //数据源 0普通文本 1数据库文本
        ColumnName: "", //数据库列名
        QuerySql: "", //选择的数据库
        TableName: "", //数据库表名
        ConnectionStr: "", //插入字段名
        DataType: "2", //数据类型 0为日期 1为数字 2文本
        DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
        NumberFormat: "123456789", //数字形式
        SequenceType: "0", //序列类型
        InitialValue: "", //初始递变数值
        AddedValue: "", //递变量
        Quantity: "", //递变数量
      };
      for (let i = 1; i < 5; i++) {
        let data = JSON.parse(JSON.stringify(sub));
        if (i == 1) {
          data.StartX = 0.5;
          data.StartY = 0.5;
          data.Key = [0, 0];
        } else if (i == 2) {
          data.StartX = 16;
          data.StartY = 0.5;
          data.Key = [0, 1];
        } else if (i == 3) {
          data.StartX = 0.5;
          data.StartY = 8;
          data.Key = [1, 0];
        } else if (i == 4) {
          data.StartX = 16;
          data.StartY = 8;
          data.Key = [1, 1];
        }
        obj.DataSourceList.push(data);
      }
      this.cellIndex = 0;
      this.subgroup.push(obj);
      this.subIndex = this.subgroup.length - 1;
      let selectData = obj;
      selectData.index = this.subIndex;
      this.selectUnit.push(selectData);
      this.handleRecord("add");
    },
    //按下组件
    handleUnit(event, index) {
      setTimeout(() => {
        if (this.dbClick) {
          return;
        }
        if (index == 6) {
          this.showRects = false;
          this.getIConCategory();
        } else if (index == 5) {
          this.showRects = !this.showRects;
          this.showIcons = false;
        } else {
          event.preventDefault();
          this.showIcons = false;
          this.showRects = false;
          this.point.left = event.pageX - 20;
          this.point.top = event.pageY - 20;
          this.unitIndex = index;
          this.unitType = this.unit[index].type;
          if (this.unitType == "Table") {
            if (
              this.userMemberInfo.status != 1 &&
              process.env.VUE_APP_VERSION == "hprt"
            ) {
              this.$refs.MemberDiaRef.show(this.$t(`tips.tip104`));
              return;
            }
          }
          this.unitFlag = true;
          let print = document.getElementById("print");
          let design = document
            .querySelector("#design")
            .getBoundingClientRect();
          print.onmousemove = (e) => {
            if (!this.unitFlag) {
              return;
            }
            this.point.left = e.pageX - 20;
            this.point.top = e.pageY - 20;
            if (this.point.left > design.left && this.point.top > design.top) {
              this.cursor = "move";
              this.selectUnit = [];
            } else {
              this.cursor = "no-drop";
            }
          };
          print.onmouseup = (e) => {
            this.unitFlag = false;
            print.onmouseup = null;
            print.onmousemove = null;
            let left = (this.point.left - design.left) / this.multiple;
            let top = (this.point.top - design.top) / this.multiple;
            if (this.cursor == "move") {
              this.cursor = "no-drop";
              if (this.unitType == "WinText") {
                this.handleText(left, top);
              } else if (this.unitType == "GeneralBarcode") {
                this.handleBarcode(left, top);
              } else if (this.unitType == "QRCode") {
                this.handleQRCode(left, top);
              } else if (this.unitType == "Image") {
                this.handleImg(left, top);
              } else if (this.unitType == "TimeText") {
                this.handleTime(left, top);
              } else if (this.unitType == "Line") {
                this.handleLine(left, top);
              } else if (this.unitType == "LineHorizontal") {
                this.handleLevel(left, top);
              } else if (this.unitType == "LineVertical") {
                this.handleVertical(left, top);
              } else if (this.unitType == "Table") {
                this.handleTable(left, top);
              }
              this.showAttrPanel = true;
            }
          };
        }
      }, 250);
    },
    //双击按下组件
    handledbUnit(event, index) {
      this.dbClick = true;
      this.selectUnit = [];
      this.unitType = this.unit[index].type;
      let design = document.querySelector("#design").getBoundingClientRect();
      let left = design.width / 2 / this.multiple;
      let top = design.height / 2 / this.multiple;
      if (index == 6) {
        this.showRects = false;
        this.getIConCategory();
      } else if (index == 5) {
        this.showRects = !this.showRects;
        this.showIcons = false;
      } else {
        if (this.unitType == "WinText") {
          this.handleText(left, top);
        } else if (this.unitType == "GeneralBarcode") {
          this.handleBarcode(left, top);
        } else if (this.unitType == "QRCode") {
          this.handleQRCode(left, top);
        } else if (this.unitType == "Image") {
          this.handleImg(left, top);
        } else if (this.unitType == "TimeText") {
          this.handleTime(left, top);
        } else if (this.unitType == "Line") {
          this.handleLine(left, top);
        } else if (this.unitType == "LineHorizontal") {
          this.handleLevel(left, top);
        } else if (this.unitType == "LineVertical") {
          this.handleVertical(left, top);
        } else if (this.unitType == "Table") {
          if (
            this.userMemberInfo.status != 1 &&
            process.env.VUE_APP_VERSION == "hprt"
          ) {
            this.$refs.MemberDiaRef.show(this.$t(`tips.tip104`));
            this.dbClick = false;
            return;
          } else {
            this.handleTable(left, top);
          }
        }
      }
      this.showAttrPanel = true;
      setTimeout(() => {
        this.dbClick = false;
      }, 500);
    },
    //按下形状
    handleRects(event, index) {
      setTimeout(() => {
        if (this.dbClick) {
          return;
        }
        event.preventDefault();
        this.point.left = event.pageX - 20;
        this.point.top = event.pageY - 20;
        this.rectsIndex = index;
        this.rectsType = this.rects[index].type;
        this.rectsFlag = true;
        let print = document.getElementById("print");
        let design = document.querySelector("#design").getBoundingClientRect();
        print.onmousemove = (e) => {
          if (!this.rectsFlag) {
            return;
          }
          this.point.left = e.pageX - 20;
          this.point.top = e.pageY - 20;
          if (this.point.left > design.left && this.point.top > design.top) {
            this.cursor = "move";
            this.selectUnit = [];
          } else {
            this.cursor = "no-drop";
          }
        };
        print.onmouseup = (e) => {
          this.rectsFlag = false;
          print.onmouseup = null;
          print.onmousemove = null;
          let left = (this.point.left - design.left) / this.multiple;
          let top = (this.point.top - design.top) / this.multiple;
          if (this.cursor == "move") {
            this.cursor = "no-drop";
            if (this.rectsType == "Rectangle") {
              this.handleShape(left, top);
            } else if (this.rectsType == "RoundRectangle") {
              this.handleRoundRect(left, top);
            } else if (this.rectsType == "Diamond") {
              this.handleDiamond(left, top);
            } else if (this.rectsType == "Ellipse") {
              this.handleElliptical(left, top);
            } else if (this.rectsType == "Triangle") {
              this.handleTriangle(left, top);
            } else if (this.rectsType == "SolidRectangle") {
              this.handleSolidRect(left, top);
            }
            this.showRects = false;
            this.showAttrPanel = true;
          }
        };
      }, 250);
    },
    //双击按下形状
    handledbRects(event, index) {
      this.dbClick = true;
      this.selectUnit = [];
      this.rectsType = this.rects[index].type;

      let design = document.querySelector("#design").getBoundingClientRect();
      let left = design.width / 2 / this.multiple;
      let top = design.height / 2 / this.multiple;

      if (this.rectsType == "Rectangle") {
        this.handleShape(left, top);
      } else if (this.rectsType == "RoundRectangle") {
        this.handleRoundRect(left, top);
      } else if (this.rectsType == "Diamond") {
        this.handleDiamond(left, top);
      } else if (this.rectsType == "Ellipse") {
        this.handleElliptical(left, top);
      } else if (this.rectsType == "Triangle") {
        this.handleTriangle(left, top);
      } else if (this.rectsType == "SolidRectangle") {
        this.handleSolidRect(left, top);
      }
      this.showAttrPanel = true;
      setTimeout(() => {
        this.showRects = false;
        this.dbClick = false;
      }, 500);
    },
    //按下图标
    handleIcons(event, index) {
      setTimeout(() => {
        if (this.dbClick) {
          return;
        }
        event.preventDefault();
        this.point.left = event.pageX - 20;
        this.point.top = event.pageY - 20;
        this.iconIndex = index;
        this.iconFlag = true;
        let print = document.getElementById("print");
        let design = document.querySelector("#design").getBoundingClientRect();
        print.onmousemove = (e) => {
          if (!this.iconFlag) {
            return;
          }
          this.point.left = e.pageX - 20;
          this.point.top = e.pageY - 20;
          if (this.point.left > design.left && this.point.top > design.top) {
            this.cursor = "move";
            this.selectUnit = [];
          } else {
            this.cursor = "no-drop";
          }
        };
        print.onmouseup = (e) => {
          this.iconFlag = false;
          print.onmouseup = null;
          print.onmousemove = null;
          let left = (this.point.left - design.left) / this.multiple;
          let top = (this.point.top - design.top) / this.multiple;
          if (this.cursor == "move") {
            this.cursor = "no-drop";
            this.handleIconImg(left, top);
            this.showIcons = false;
          }
        };
      }, 250);
    },
    //双击按下图标
    handledbIcons(event, index) {
      this.dbClick = true;
      this.selectUnit = [];
      this.iconIndex = index;
      let design = document.querySelector("#design").getBoundingClientRect();
      let left = design.width / 2 / this.multiple;
      let top = design.height / 2 / this.multiple;
      this.handleIconImg(left, top);
      this.showAttrPanel = true;
      setTimeout(() => {
        this.showIcons = false;
        this.dbClick = false;
      }, 500);
    },
  },
};

export default drawComponent;
