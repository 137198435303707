<template>
  <div
    class="createLable-box"
    v-if="modalVisible"
    @click="(showCloudFolder = false), (showCloudDataList = false), close()"
  >
    <div class="content-box" @click.stop="">
      <CloseOutlined class="create-close-btn" @click="close()" />
      <div class="title" v-if="type == 'edit'">{{ $t(`button.labelset`) }}</div>
      <div class="title" v-else>{{ $t(`button.createLabel`) }}</div>
      <div class="main-create">
        <div class="left-box" ref="leftBox">
          <div
            class="drawBox"
            :style="
              'width: ' +
              boxData.boxWidth +
              'px;height:' +
              boxData.boxHeight +
              'px;top:' +
              boxData.boxTop +
              'px;left:' +
              boxData.boxLeft +
              'px;'
            "
          >
            <div
              v-for="(item, index) in boxData.boxCount"
              :key="index"
              :ref="(el) => (itemdrawBox[index] = el)"
              class="itemdrawBox"
              :style="{
                width: boxData.itemWidth + 'px',
                height: boxData.itemHeight + 'px',
                marginRight: formState.spaceCloumn * UnitConversion + 'px',
                marginBottom: formState.lineHeight * UnitConversion + 'px',
              }"
            >
              <img
                v-if="formState.Background"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                :src="'data:image/png;base64,' + formState.Background"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right-box">
          <div class="right-nav">
            <a-form
              :model="formState"
              labelAlign="left"
              ref="formRef"
              style="width: 100%"
            >
              <div class="item-title" style="margin-top: 0">
                {{ $t(`label.label16`) }}
              </div>
              <a-form-item class="title-input" name="name">
                <a-input
                  :disabled="!canSetName"
                  v-model:value="formState.name"
                  style="border-radius: 5px"
                />
              </a-form-item>
              <div class="item-title">
                {{ $t(`label.label20`) }}
                <a-select
                  v-model:value="formState.unit"
                  :bordered="false"
                  @change="unitChange"
                >
                  <a-select-option value="Mm">mm</a-select-option>
                  <a-select-option value="Inch">inch</a-select-option>
                </a-select>
              </div>
              <a-row class="size-box">
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label23`)" name="width">
                    <a-input-number
                      @change="handleChange($event, 1)"
                      id="inputNumber"
                      v-model:value="formState.width"
                      :step="0.001"
                      :min="0.001"
                      :max="400"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label24`)" name="height">
                    <a-input-number
                      @change="handleChange($event, 2)"
                      id="inputNumber"
                      v-model:value="formState.height"
                      :min="0.001"
                      :max="1800"
                      :step="0.001"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <div class="item-title">{{ $t(`label.label25`) }}</div>
              <a-row class="size-box">
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label26`)" name="cloumn">
                    <a-input-number
                      @change="handleChange($event, 3)"
                      id="inputNumber"
                      v-model:value="formState.cloumn"
                      :min="1"
                      :step="1"
                      :precision="0"
                      :max="20"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label28`)" name="spaceCloumn">
                    <a-input-number
                      @change="handleChange($event, 4)"
                      id="inputNumber"
                      v-model:value="formState.spaceCloumn"
                      :min="0.0"
                      :max="100.0"
                      :precision="3"
                      :step="1"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row class="size-box">
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label27`)" name="line">
                    <a-input-number
                      @change="handleChange($event, 5)"
                      id="inputNumber"
                      v-model:value="formState.line"
                      :min="1"
                      :max="20"
                      :precision="0"
                      :step="1"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label29`)" name="lineHeight">
                    <a-input-number
                      @change="handleChange($event, 6)"
                      id="inputNumber"
                      v-model:value="formState.lineHeight"
                      :min="0.0"
                      :max="100.0"
                      :precision="3"
                      :step="1"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <div class="item-title">{{ $t(`label.label30`) }}</div>
              <a-row class="size-box">
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label31`)" name="paddingLeft">
                    <a-input-number
                      @change="handleChange($event, 7)"
                      id="inputNumber"
                      v-model:value="formState.paddingLeft"
                      :min="0.0"
                      :max="100.0"
                      :precision="3"
                      :step="1"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label32`)" name="paddingRight">
                    <a-input-number
                      @change="handleChange($event, 8)"
                      id="inputNumber"
                      v-model:value="formState.paddingRight"
                      :min="0.0"
                      :max="100.0"
                      :precision="3"
                      :step="1"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row class="size-box">
                <a-col :span="12">
                  <a-form-item :label="$t(`label.label33`)" name="paddingTop">
                    <a-input-number
                      @change="handleChange($event, 9)"
                      id="inputNumber"
                      v-model:value="formState.paddingTop"
                      :min="0.0"
                      :max="100.0"
                      :precision="3"
                      :step="1"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    :label="$t(`label.label34`)"
                    name="paddingBottom"
                  >
                    <a-input-number
                      @change="handleChange($event, 10)"
                      id="inputNumber"
                      v-model:value="formState.paddingBottom"
                      :min="0.0"
                      :max="100.0"
                      :precision="3"
                      :step="1"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <div class="item-title">{{ $t(`editAttr.attrText97`) }}</div>
              <div class="create-add-img" v-if="!formState.Background">
                <a-upload
                  class="head-btn"
                  accept=".jpeg,.jpg,.png"
                  action=""
                  :showUploadList="false"
                  :beforeUpload="beforeUpload"
                >
                  <a-button>{{ $t(`editAttr.attrText30`) }}</a-button>
                </a-upload>
                <div style="position: relative">
                  <a-button @click.stop="getFolderList">{{
                    $t(`editAttr.attrText31`)
                  }}</a-button>
                  <div class="create-cloudFolder" v-if="showCloudFolder">
                    <div
                      class="create-cloudFolder-box"
                      v-for="(item, index) in cloudFileList"
                      :key="index"
                      @click.stop="getList(index)"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                  <div class="create-cloudFolder" v-if="showCloudDataList">
                    <div class="cloudFileBack">
                      <i class="el-icon-arrow-left" @click="cloudFileBack" />
                    </div>
                    <div
                      class="create-cloudFolder-box"
                      v-for="(item, index) in cloudDataList"
                      :key="index"
                      @click.stop="chooseImage(index)"
                    >
                      <img :src="item.url" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="create-background-img" v-else>
                <img
                  class="background-img"
                  :src="imgHeader + formState.Background"
                  alt=""
                />
                <div class="iconfont icon-delete" @click="handleDelete"></div>
              </div>
            </a-form>
            <div class="create-btn-group">
              <a-button @click="close()">{{ $t(`button.cancel`) }}</a-button>
              <a-button type="primary" @click="handleSave()">{{
                $t(`button.confirm`)
              }}</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { CloseOutlined } from "@ant-design/icons-vue";
import {
  defineComponent,
  reactive,
  getCurrentInstance,
  ref,
  toRefs,
  nextTick,
  onMounted,
  onUnmounted,
} from "vue";
export default defineComponent({
  components: {
    CloseOutlined,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    dataType: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
    labelName: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    let globalProperties =
      getCurrentInstance().appContext.config.globalProperties;
    const axios = globalProperties.$axioshanma;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const state = reactive({
      modalVisible: false,
      formState: {
        name: "",
        unit: globalProperties.$unit, //单位
        width: 100.0, //宽度
        height: 80.0, //高度
        cloumn: 1, //列数
        spaceCloumn: 0.0, //列间距
        line: 1, //行数
        lineHeight: 0.0, //行间距
        paddingLeft: 0.0, //左边距
        paddingRight: 0.0, //右边距
        paddingTop: 0.0, //上边距
        paddingBottom: 0.0, //下边距
        Background: "", //存背景图片
        PrintBackground: "False",
        Canvas: "", //画布背景
      },
      boxData: {
        boxWidth: 100.0,
        boxHeight: 80.0,
        itemWidth: 100.0,
        itemHeight: 80.0,
        boxCount: 0,
        beishu: 0,
        boxTop: 0,
        boxLeft: 0,
      },
      itemdrawBox: [],
      leftBox: null,
      canSetName: true,
      LabelPage: {},
      formRef: null,
      ImageList: [],
      UnitConversion: 1,
      imgHeader: "data:image/png;base64,",
      cloudFileList: [],
      cloudDataList: [],
      showCloudDataList: false,
      showCloudFolder: false,
      queryList: {
        id: "",
        user_id: userInfo.userid,
        type: 1,
        page: 1,
        size: 999,
        order: "",
      },
    });
    const setDate = () => {
      //编辑设置数据
      if (props.type == "edit") {
        state.canSetName = false;
        //新建编辑
        if (props.dataType == 1) {
          state.formState = {
            name: props.data.name,
            unit: props.data.unit,
            width: props.data.width,
            height: props.data.height,
            cloumn: props.data.cloumn,
            spaceCloumn: props.data.spaceCloumn,
            line: props.data.line,
            lineHeight: props.data.lineHeight,
            paddingLeft: props.data.paddingLeft,
            paddingRight: props.data.paddingRight,
            paddingTop: props.data.paddingTop,
            paddingBottom: props.data.paddingBottom,
            Background: props.data.Background,
            PrintBackground: props.data.PrintBackground,
            Canvas: props.data.Canvas,
          };
        } else {
          //文件编辑
          state.LabelPage = props.data.PrtLable.PictureArea.LabelPage;
          state.formState = {
            name: props.labelName,
            unit: state.LabelPage["@MeasureUnit"],
            LabelShape: state.LabelPage["@LabelShape"],
            width: state.LabelPage.LabelWidth,
            height: state.LabelPage.LabelHeight,
            cloumn: state.LabelPage.Columns,
            spaceCloumn: state.LabelPage.ColumnSpacing,
            line: state.LabelPage.Rows,
            lineHeight: state.LabelPage.RowSpacing,
            paddingLeft: state.LabelPage.LeftMargin,
            paddingRight: state.LabelPage.RightMargin,
            paddingTop: state.LabelPage.UpperMargin,
            paddingBottom: state.LabelPage.LowerMargin,
            Background: state.LabelPage.Background,
            PrintBackground: state.LabelPage.PrintBackground,
            Canvas: state.LabelPage.Canvas,
          };
        }
      } else {
        //新建
        state.canSetName = true;
        switch (globalProperties.$unit) {
          case "Mm":
            state.formState.width = 100;
            state.formState.height = 80;
            state.boxData.boxWidth = 100;
            state.boxData.boxHeight = 80;
            break;
          case "Inch":
            state.formState.width = 3.937;
            state.formState.height = 3.15;
            state.boxData.boxWidth = 3.937;
            state.boxData.boxHeight = 3.15;
        }
      }
      setLeftBox();
    };
    const setLeftBox = () => {
      state.boxData.boxCount = state.formState.cloumn * state.formState.line;
      let width =
        state.formState.width * state.UnitConversion * state.formState.cloumn +
        (state.formState.cloumn - 1) *
          (state.formState.spaceCloumn * state.UnitConversion);
      let height =
        state.formState.height * state.UnitConversion * state.formState.line +
        (state.formState.line - 1) *
          (state.formState.lineHeight * state.UnitConversion);

      if (width > height) {
        state.boxData.beishu = (state.leftBox.offsetWidth - 20) / width;
        state.boxData.boxWidth = state.leftBox.offsetWidth - 20;
        state.boxData.boxHeight = height * state.boxData.beishu;
        state.boxData.boxTop =
          (state.leftBox.offsetHeight -
            state.boxData.boxHeight +
            state.formState.lineHeight * state.UnitConversion) /
          2;
        state.boxData.boxLeft =
          (state.leftBox.offsetWidth -
            state.boxData.boxWidth +
            state.formState.spaceCloumn * state.UnitConversion) /
          2;
        if (state.boxData.boxHeight > state.leftBox.offsetHeight) {
          state.boxData.boxHeight = state.leftBox.offsetHeight - 20;
          state.boxData.boxWidth = width * state.boxData.beishu;
          state.boxData.boxTop =
            (state.leftBox.offsetHeight -
              state.boxData.boxHeight +
              state.formState.lineHeight * state.UnitConversion) /
            2;
          state.boxData.boxLeft =
            (state.leftBox.offsetWidth -
              state.boxData.boxWidth +
              state.formState.spaceCloumn * state.UnitConversion) /
            2;
          state.boxData.itemWidth =
            state.boxData.boxWidth / state.formState.cloumn -
            state.formState.spaceCloumn * state.UnitConversion;
          state.boxData.itemHeight =
            state.boxData.boxHeight / state.formState.line -
            state.formState.lineHeight * state.UnitConversion;
        } else {
          state.boxData.itemWidth =
            state.boxData.boxWidth / state.formState.cloumn -
            state.formState.spaceCloumn * state.UnitConversion;
          state.boxData.itemHeight =
            state.boxData.boxHeight / state.formState.line -
            state.formState.lineHeight * state.UnitConversion;
        }
      } else {
        state.boxData.beishu = (state.leftBox.offsetHeight - 20) / height;
        state.boxData.boxWidth = width * state.boxData.beishu;
        state.boxData.boxHeight = state.leftBox.offsetHeight - 20;
        state.boxData.boxTop =
          (state.leftBox.offsetHeight -
            state.boxData.boxHeight +
            state.formState.lineHeight * state.UnitConversion) /
          2;
        state.boxData.boxLeft =
          (state.leftBox.offsetWidth -
            state.boxData.boxWidth +
            state.formState.spaceCloumn * state.UnitConversion) /
          2;
        state.boxData.itemWidth =
          state.boxData.boxWidth / state.formState.cloumn -
          state.formState.spaceCloumn * state.UnitConversion;
        state.boxData.itemHeight =
          state.boxData.boxHeight / state.formState.line -
          state.formState.lineHeight * state.UnitConversion;
      }
    };
    const showModal = () => {
      state.modalVisible = true;
      nextTick(() => {
        setDate();
        window.onresize = () => {
          setLeftBox();
        };
      });
    };
    const unitChange = (e) => {
      globalProperties.$unit = e;
      switch (state.formState.unit) {
        case "Mm":
          state.UnitConversion = 1;
          state.formState.width = (state.formState.width * 25.4).toFixed(3);
          state.formState.height = (state.formState.height * 25.4).toFixed(3);
          state.formState.spaceCloumn = (
            state.formState.spaceCloumn * 25.4
          ).toFixed(3);
          state.formState.lineHeight = (
            state.formState.lineHeight * 25.4
          ).toFixed(3);
          state.formState.paddingLeft = (
            state.formState.paddingLeft * 25.4
          ).toFixed(3);
          state.formState.paddingRight = (
            state.formState.paddingRight * 25.4
          ).toFixed(3);
          state.formState.paddingTop = (
            state.formState.paddingTop * 25.4
          ).toFixed(3);
          state.formState.paddingBottom = (
            state.formState.paddingBottom * 25.4
          ).toFixed(3);
          break;
        case "Inch":
          state.UnitConversion = 25.4;
          state.formState.width = (state.formState.width / 25.4).toFixed(3);
          state.formState.height = (state.formState.height / 25.4).toFixed(3);
          state.formState.spaceCloumn = (
            state.formState.spaceCloumn / 25.4
          ).toFixed(3);
          state.formState.lineHeight = (
            state.formState.lineHeight / 25.4
          ).toFixed(3);
          state.formState.paddingLeft = (
            state.formState.paddingLeft / 25.4
          ).toFixed(3);
          state.formState.paddingRight = (
            state.formState.paddingRight / 25.4
          ).toFixed(3);
          state.formState.paddingTop = (
            state.formState.paddingTop / 25.4
          ).toFixed(3);
          state.formState.paddingBottom = (
            state.formState.paddingBottom / 25.4
          ).toFixed(3);
      }
      setLeftBox();
    };
    const handleSave = () => {
      //编辑
      if (props.type == "edit") {
        //新建编辑
        if (props.dataType == 1) {
          let labelData = JSON.parse(JSON.stringify(state.formState));
          context.emit("set", labelData, 1);
          close();
        } else {
          //编辑
          let labelData = JSON.parse(JSON.stringify(state.formState));
          state.LabelPage = {
            ["@MeasureUnit"]: labelData.unit,
            ["@LabelShape"]: labelData.LabelShape,
            ["@Height"]: labelData.width,
            ["@Width"]: labelData.height,
            LabelWidth: labelData.width,
            LabelHeight: labelData.height,
            Columns: labelData.cloumn,
            ColumnSpacing: labelData.spaceCloumn,
            Rows: labelData.line,
            RowSpacing: labelData.lineHeight,
            LeftMargin: labelData.paddingLeft,
            RightMargin: labelData.paddingRight,
            UpperMargin: labelData.paddingTop,
            LowerMargin: labelData.paddingBottom,
            Background: labelData.Background,
            PrintBackground: labelData.PrintBackground,
            Canvas: labelData.Canvas,
          };
          context.emit("set", state.LabelPage, 2);
          close();
        }
      } else {
        //新建
        var reg = new RegExp('[\\\\/:*?"<>|]');
        if (state.formState.name == "") {
          message.error(proxy.$t(`tips.tip41`));
        } else if (reg.test(state.formState.name)) {
          message.error(proxy.$t(`tips.tip42`) + '\\/:*?"<>|');
        } else {
          localStorage.setItem("newLabelList", JSON.stringify(state.formState));
          localStorage.setItem("listType", 1);
          router.push({ path: "/editLabel" });
          close();
        }
      }
    };
    const handleChange = (e, type) => {
      nextTick(() => {
        if (!e) {
          switch (type) {
            case 1:
              state.formState.width = 100;
              break;
            case 2:
              state.formState.height = 80;
              break;
            case 3:
              state.formState.cloumn = 1;
              break;
            case 4:
              state.formState.spaceCloumn = 0.0;
              break;
            case 5:
              state.formState.line = 1;
              break;
            case 6:
              state.formState.lineHeight = 0.0;
              break;
            case 7:
              state.formState.paddingLeft = 0.0;
              break;
            case 8:
              state.formState.paddingRight = 0.0;
              break;
            case 9:
              state.formState.paddingTop = 0.0;
              break;
            case 10:
              state.formState.paddingBottom = 0.0;
              break;
          }
        }
        setLeftBox();
      });
    };
    const close = () => {
      state.formRef.resetFields();
      state.modalVisible = false;
      window.onresize = null;
    };
    //获取本地添加的图片
    const beforeUpload = (file) => {
      var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      if (!testmsg) {
        message.error(proxy.$t(`tips.tip39`));
        return;
      }
      var configuration = JSON.parse(localStorage.getItem("configuration"));
      if (file.size / 1024 > configuration.image_size) {
        message.error(
          proxy.$t(`tips.tip30`) +
            configuration.image_size +
            proxy.$t(`tips.tip31`)
        );
        return;
      }
      getBase64(file).then((res) => {
        state.formState.Background = res.split(",")[1];
        state.formState.PrintBackground = "True";
      });
      return false;
    };
    //本地路径转base64
    const getBase64 = (file) => {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    };
    //获取文件夹列表
    const getFolderList = () => {
      if (state.showCloudFolder || state.showCloudDataList) {
        state.showCloudDataList = false;
        state.showCloudFolder = false;
        return;
      }
      axios("/api/tem.app_public/getCloudClassList", {
        user_id: userInfo.userid,
      }).then((res) => {
        state.cloudFileList = res;
        state.showCloudFolder = true;
      });
    };
    //获取列表数据
    const getList = (index) => {
      if (
        state.showCloudDataList &&
        state.cloudFileList[index].id == state.queryList.id
      ) {
        state.showCloudDataList = !state.showCloudDataList;
        state.queryList.id = "";
        return;
      }
      state.queryList.id = state.cloudFileList[index].id;
      axios("/api/tem.app_public/getCloudList", state.queryList).then((res) => {
        state.cloudDataList = res.list;
        if (state.cloudDataList.length == 0) {
          message.error(proxy.$t(`tips.tip40`));
          return;
        }
        state.showCloudFolder = false;
        state.showCloudDataList = true;
      });
    };

    const cloudFileBack = () => {
      state.showCloudDataList = false;
      getFolderList();
      state.cloudDataList = [];
    };
    //选择云端图片
    const chooseImage = (index) => {
      state.showCloudFolder = false;
      state.showCloudDataList = false;
      URLgetBase64(state.cloudDataList[index].url, "pic", (dataURL) => {
        state.formState.Background = dataURL.split(",")[1];
        state.formState.PrintBackground = "True";
      });
    };
    //七牛云路径转base64
    const URLgetBase64 = (url, type, callback) => {
      var Img = new Image(),
        dataURL = "";
      Img.src = url + "?v=" + Math.random();
      Img.setAttribute("crossOrigin", "Anonymous");
      Img.onload = function () {
        var canvas = document.createElement("canvas");
        if (type == "icon") {
          var width = Img.width;
          var height = Img.height;
        } else {
          if (Img.width > 1000) {
            width = Img.width / 4;
            height = Img.height / 4;
          } else {
            width = Img.width;
            height = Img.height;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(Img, 0, 0, width, height);
        dataURL = canvas.toDataURL("image/png", 0.5);
        return callback ? callback(dataURL) : null;
      };
    };
    const handleDelete = () => {
      state.formState.Background = "";
      state.formState.PrintBackground = "False";
    };
    return {
      ...toRefs(state),
      handleSave,
      unitChange,
      handleChange,
      showModal,
      getFolderList,
      getList,
      close,
      chooseImage,
      handleDelete,
      beforeUpload,
      cloudFileBack,
    };
  },
});
</script>
<style scoped>
.drawBox {
  position: absolute;
  line-height: 0;
}
.itemdrawBox {
  background: #ffffff;
  display: inline-block;
  position: relative;
}

.createLable-box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-box {
  width: 1395px;
  height: 802px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
}
.create-close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
}
.content-box .title {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 28px;
  margin-top: 30px;
  margin-left: 32px;
}
.main-create {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
}
.main-create .left-box {
  width: 768px;
  height: 680px;
  background: rgba(0, 53, 145, 0.1);
  border-radius: 4px;
  margin-left: 32px;
  position: relative;
  margin-top: 24px;
}
.main-create .right-box {
  margin-top: 24px;
  width: 532px;
  margin-left: 31px;
}
.main-create .right-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.right-box .item-title {
  height: 24px;
  font-size: 16px;
  color: #535d6c;
  line-height: 24px;
  margin-bottom: 14px;
  margin-top: 16px;
}

.size-box {
  width: 100%;
}
.right-box ::v-deep(.ant-form-item) {
  height: 40px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.size-box ::v-deep(.ant-form-item-label > label) {
  max-width: 150px;
  width: 100%;
  min-width: 85px;
  height: 22px;
  font-size: 16px;
  color: #1a1d21;
  line-height: 22px;
  margin-top: 9px;
  margin-right: 9px;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.size-box ::v-deep(.ant-input-number) {
  width: 100px;
  height: 40px;
  line-height: 40px;
}
.create-add-img {
  display: flex;
}
.create-add-img ::v-deep(.ant-btn) {
  min-width: 204px;
  height: 40px;
  line-height: 24px;
  border-radius: 4px;
  color: #1a1d21;
  border: 1px solid #d6dce6;
  margin-right: 16px;
  background: none;
  font-size: 16px;
}
.create-btn-group {
  display: inline-block;
  margin-top: 26px;
}
.create-btn-group ::v-deep(.ant-btn:first-child) {
  color: #535d6c;
  border-color: #535d6c;
  margin-right: 16px;
  background: none;
}
.create-background-img {
  display: flex;
}
.background-img {
  width: 40px;
  height: 40px;
  border: 1px solid #d6dce6;
  margin-right: 8px;
}
.create-background-img ::v-deep(.iconfont) {
  font-size: 26px;
}
.create-cloudFolder {
  position: absolute;
  width: calc(100% - 16px);
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  z-index: 999;
  bottom: 40px;
  left: 0px;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px rgba(145, 152, 165, 0.24);
}

.create-cloudFolder-box {
  height: 40px;
  font-size: 16px;
  color: #535d6c;
  line-height: 40px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-cloudFolder-box img {
  object-fit: contain;
  width: 30px;
  height: 30px;
}

.create-cloudFolder-box:not(:last-child) {
  border-bottom: 1px solid #d6dce6;
}

.create-cloudFolder-box:hover {
  background: #f6f6f6;
}
.cloudFileBack {
  margin-left: -16px;
  margin-top: -8px;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
}
@media only screen and (max-width: 1300px) {
  .content-box {
    width: 930px;
    height: 530px;
  }
  .create-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .content-box .title {
    height: 18px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
    margin-left: 21px;
  }
  .main-create .left-box {
    height: 445px;
    margin-left: 21px;
  }
  .main-create .right-box {
    width: 610px;
    margin-left: 21px;
  }
  .right-box .item-title {
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .right-box .title-input ::v-deep(.ant-input) {
    width: 354px;
    height: 30px;
  }
  .right-box ::v-deep(.ant-form-item) {
    height: 26px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .size-box ::v-deep(.ant-form-item-label > label) {
    max-width: 78px;
    min-width: 57px;
    height: 15px;
    font-size: 10px;
    line-height: 15px;
    margin-top: 8px;
    margin-right: 8px;
  }
  .size-box ::v-deep(.ant-input-number) {
    width: 70px;
    height: 30px;
    font-size: 10px;
    line-height: 24px;
  }
  .create-add-img ::v-deep(.ant-btn) {
    min-width: 136px;
    height: 26px;
    line-height: 16px;
    font-size: 10px;
    margin-right: 16px;
  }
  .create-btn-group {
    margin-top: 17px;
  }
  .create-btn-group ::v-deep(.ant-btn:first-child) {
    margin-right: 10px;
  }
  .item-title ::v-deep(.ant-select) {
    font-size: 10px !important;
  }
  .background-img {
    width: 26px;
    height: 26px;
  }
  .create-background-img ::v-deep(.iconfont) {
    font-size: 17px;
  }
  .create-cloudFolder {
    width: calc(100% - 16px);
    max-height: 200px;
    bottom: 26px;
    padding: 5px 10px;
  }

  .create-cloudFolder-box {
    height: 28px;
    font-size: 10px;
    line-height: 28px;
  }
  .create-cloudFolder-box img {
    width: 20px;
    height: 20px;
  }
  .cloudFileBack {
    margin-left: -10px;
    margin-top: -5px;
    width: 14px;
    height: 14px;
  }
}
</style>
