import { randomNumberStr } from "@/utils/util";
import { propertyType } from "../js/propertyType.js"; //设置数据类型
const table_attr = {
  data() {
    return {
      cellIndex: 0,
      multiple_cell: false, //单元格多选
      mulCellArr: [], //多选选择的单元格
    };
  },
  watch: {
    subIndex() {
      if (this.subIndex < 0) {
        //元件取消选中时，取消单元格多选及选中状态
        (this.multiple_cell = false), (this.mulCellArr = []);
        this.cellIndex = -1;
      }
    },
  },
  methods: {
    //打开编辑时的数据处理
    dealTableData(source, DicLineLocW, DicLineLocH) {
      let DataSourceList = JSON.parse(JSON.stringify(source));
      let tableWidth = 0;
      let tableHeight = 0;
      let lineNumW = 0;
      let lineNumH = 0;

      const changeType = (arr) => {
        for (let i = 0; i < arr.length; i++) {
          arr[i] = Number(arr[i]);
        }
        return arr;
      };

      let list = DataSourceList.map((cell) => {
        //处理单元格的序列化文本
        if (cell.DataSourceType == "2") {
          cell.AddedValue = Number(cell.AddedValue);
          cell.Quantity = Number(cell.Quantity);
          switch (cell.SequenceType) {
            case "1":
              cell.InitialValue = Number(cell.InitialValue).toString(16);
              break;
            case "2":
              cell.InitialValue = this.convertToTwoteenSix(
                Number(cell.InitialValue)
              );
              break;
            case "3":
              cell.InitialValue = Number(cell.InitialValue).toString(36);
              break;
          }
          cell.Data = cell.InitialValue;
        }

        if (cell.Key.indexOf("|") > -1) {
          //存在合并单元格
          let children = cell.Key.split("|");
          for (let i = 0; i < children.length; i++) {
            children[i] = children[i].split(",");
          }
          let newChildren = [];
          children.map((child) => {
            child = changeType(child)
            if (DicLineLocW.length == 0 && DicLineLocH.length == 0) {
              if (child[0] == 0 && child[1] == 0) {
                newChildren.push(child);
              }
            } else if (DicLineLocW.length == 0 && DicLineLocH.length > 0) {
              if (child[1] == 0) {
                newChildren.push(child);
              }
            } else if (DicLineLocW.length == 0 && DicLineLocH.length > 0) {
              if (child[0] == 0) {
                newChildren.push(child);
              }
            } else if (DicLineLocW.length > 0 && DicLineLocH.length > 0) {
              newChildren.push(child);
            }
          });
          if (newChildren.length > 1) {
            cell.children = newChildren;
          }
          cell.Key = changeType(newChildren[0]);
        } else {
          cell.Key = cell.Key.split(",");
          cell.Key = changeType(cell.Key);
        }
        cell = propertyType(cell);
        cell.Width = cell.Width / this.conversion;
        cell.Height = cell.Height / this.conversion;
        cell.StartX = cell.StartX / this.conversion;
        cell.StartY = cell.StartY / this.conversion;

        // 字号变成Number类型
        if (cell.Font) {
          if (this.elemMinFontSize > -1) {
            this.elemMinFontSize =
              this.elemMinFontSize > cell.Font["@FontSize"]
                ? cell.Font["@FontSize"]
                : this.elemMinFontSize;
          } else {
            this.elemMinFontSize = cell.Font["@FontSize"];
          }
        }
        if (cell.Key[0] == 0) {
          tableWidth += cell.Width;
          lineNumW++;
        }
        if (cell.Key[1] == 0) {
          tableHeight += cell.Height;
          lineNumH++;
        }
        return cell;
      });
      list.sort(this.dealSort);
      return { list, tableWidth, tableHeight, lineNumW, lineNumH };
    },

    //表格数据源切换
    tableDataSourceChange() {
      let data = this.subgroup[this.subIndex].DataSourceList[this.cellIndex];
      data.ColumnName = "";
      data.QuerySql = "";
      data.ConnectionStr = "";
      data.AddedValue = "";
      data.Quantity = "";
      data.InitialValue = "";
      if (data.DataSourceType == 2) {
        data.SequenceType = "0";
        data.Data = '0';
        data.AddedValue = 0;
        data.Quantity = 1;
        data.InitialValue = 0;
      }
      this.handleRecord();
    },
    // 点击多选
    handleMulCell() {
      this.multiple_cell = !this.multiple_cell;
      let list = JSON.parse(
        JSON.stringify(this.subgroup[this.subIndex].DataSourceList)
      );
      if (this.multiple_cell) {
        this.mulCellArr.push(list[this.cellIndex]);
      } else {
        this.mulCellArr = [];
      }
    },
    //处理多选单元格的选中
    dealMulCell(offsetX, offsetY) {
      let list = JSON.parse(
        JSON.stringify(this.subgroup[this.subIndex].DataSourceList)
      );

      for (let i = 0; i < list.length; i++) {
        let data = list[i];
        if (
          offsetX > data.StartX &&
          offsetX < data.StartX + data.Width &&
          offsetY > data.StartY &&
          offsetY < data.StartY + data.Height
        ) {
          let existIndex = this.mulCellArr.findIndex(
            (obj) => obj.Key.toString() == data.Key.toString()
          );
          if (existIndex == -1) {
            this.mulCellArr.push(data);
          } else {
            if (this.mulCellArr.length > 1) {
              this.mulCellArr.splice(existIndex, 1);
            }
          }
          break;
        }
      }
      this.$nextTick(() => {
        let index = list.findIndex(
          (obj) => obj.Key.toString() == this.mulCellArr[0].Key.toString()
        );
        this.cellIndex = index;
      });
    },
    //设置单元格多选背景色
    mulCellClass(val, index) {
      if (index == this.subIndex) {
        let classname = "";
        //多选时，选中的单元格增加背景色
        for (let i = 0; i < this.mulCellArr.length; i++) {
          if (val.Key.toString() == this.mulCellArr[i].Key.toString()) {
            classname = "mulCell";
          }
        }
        return classname;
      }
    },
    //设置线条粗细
    changeTableLine(newVal, oldVal) {
      let DicLineLocH = this.subgroup[this.subIndex].DicLineLocH;
      let DicLineLocW = this.subgroup[this.subIndex].DicLineLocW;
      let list = this.subgroup[this.subIndex].DataSourceList;

      let PenWidth = oldVal;
      if (this.subgroup[this.subIndex].PenWidth) {
        PenWidth = this.subgroup[this.subIndex].PenWidth;
      } else {
        //默认值 防止PenWidth值为空的情况
        this.$nextTick(() => {
          this.subgroup[this.subIndex].PenWidth = PenWidth;
        });
      }

      for (let i = 0; i < DicLineLocH.length; i++) {
        DicLineLocH[i] =
          DicLineLocH[i] +
          (PenWidth - oldVal) * (i + 1) +
          (PenWidth - oldVal) / 2;
      }
      for (let i = 0; i < DicLineLocW.length; i++) {
        DicLineLocW[i] =
          DicLineLocW[i] +
          (PenWidth - oldVal) * (i + 1) +
          (PenWidth - oldVal) / 2;
      }
      
      list.map((data) => {
        data.StartX = data.StartX + (PenWidth - oldVal) * (data.Key[1] + 1);
        data.StartY = data.StartY + (PenWidth - oldVal) * (data.Key[0] + 1);
        if (data.hasOwnProperty("children")) {
          let lastKey = data.children[data.children.length - 1];
          data.Width =
            data.Width + (PenWidth - oldVal) * (lastKey[1] - data.Key[1]);
          data.Height =
            data.Height + (PenWidth - oldVal) * (lastKey[0] - data.Key[0]);
        }
      });

      this.subgroup[this.subIndex].Width =
        this.subgroup[this.subIndex].Width +
        (DicLineLocW.length + 2) * (PenWidth - oldVal);
      this.subgroup[this.subIndex].Height =
        this.subgroup[this.subIndex].Height +
        (DicLineLocH.length + 2) * (PenWidth - oldVal);
      this.$forceUpdate();
      this.handleRecord();
    },
    // 设置行高
    changeRowHeight(newVal, oldVal) {
      let list = this.subgroup[this.subIndex].DataSourceList;
      let DicLineLocH = this.subgroup[this.subIndex].DicLineLocH;
      let Row = this.subgroup[this.subIndex].Row;
      let RowHeight = oldVal; //默认值  防止行高值为空的情况
      //单元格数据
      if (list[this.cellIndex].Height) {
        RowHeight = list[this.cellIndex].Height;
      }

      //选中单元格的key
      var cellKey = list[this.cellIndex].Key;
      if (list[this.cellIndex].hasOwnProperty("children")) {
        let len = list[this.cellIndex].children.length;
        cellKey = list[this.cellIndex].children[len - 1];
      }

      if (cellKey[0] < Row - 1) {
        let oldLine = DicLineLocH[cellKey[0]];
        let newLine = oldLine + (RowHeight - oldVal);
        DicLineLocH[cellKey[0]] = newLine;
      }
      //还需处理后面的DicLineLocH
      for (let i = Number(cellKey[0]) + 1; i < DicLineLocH.length; i++) {
        DicLineLocH[i] = DicLineLocH[i] + (RowHeight - oldVal);
      }

      this.$nextTick(() => {
        for (let i = 0; i < list.length; i++) {
          let data = list[i];
          if (data.Key[0] == cellKey[0]) {
            //与选中单元格同一行的元素，处理Height
            if (i == this.cellIndex) {
              data.Height = RowHeight;
            } else {
              data.Height = data.Height + (RowHeight - oldVal);
            }
          } else if (data.Key[0] < cellKey[0] && i != this.cellIndex) {
            if (data.hasOwnProperty("children")) {
              let index = data.children.findIndex(
                (key) => key[0] == cellKey[0]
              );
              if (index > -1) {
                data.Height = data.Height + (RowHeight - oldVal);
              }
            }
          } else if (data.Key[0] > cellKey[0]) {
            //处于选中单元格所在行的下方，处理startY
            data.StartY = data.StartY + (RowHeight - oldVal);
          }
        }

        this.subgroup[this.subIndex].Height =
          this.subgroup[this.subIndex].Height + (RowHeight - oldVal);
        this.handleRecord();
      });
    },
    // 设置列宽
    changeCloumnWidth(newVal, oldVal) {
      let list = this.subgroup[this.subIndex].DataSourceList;
      let DicLineLocW = this.subgroup[this.subIndex].DicLineLocW;
      let Cloumn = this.subgroup[this.subIndex].Cloumn;
      let CloumnWidth = oldVal; //默认值  防止行高值为空的情况
      //单元格数据
      if (list[this.cellIndex].Width) {
        CloumnWidth = list[this.cellIndex].Width;
      }

      //选中单元格的key
      var cellKey = list[this.cellIndex].Key;
      if (list[this.cellIndex].hasOwnProperty("children")) {
        let len = list[this.cellIndex].children.length;
        cellKey = list[this.cellIndex].children[len - 1];
      }

      if (cellKey[1] < Cloumn - 1) {
        let oldLine = DicLineLocW[cellKey[1]];
        let newLine = oldLine + (CloumnWidth - oldVal);
        DicLineLocW[cellKey[1]] = newLine;
      }

      //还需处理后面的DicLineLocW
      for (let i = Number(cellKey[1]) + 1; i < DicLineLocW.length; i++) {
        DicLineLocW[i] = DicLineLocW[i] + (CloumnWidth - oldVal);
      }

      this.$nextTick(() => {
        for (let i = 0; i < list.length; i++) {
          let data = list[i];
          if (data.Key[1] == cellKey[1]) {
            //与选中单元格同一列的元素，处理Width
            if (i == this.cellIndex) {
              data.Width = CloumnWidth;
            } else {
              data.Width = data.Width + (CloumnWidth - oldVal);
            }
          } else if (data.Key[1] < cellKey[1] && i != this.cellIndex) {
            if (data.hasOwnProperty("children")) {
              let index = data.children.findIndex(
                (key) => key[1] == cellKey[1]
              );
              if (index > -1) {
                data.Width = data.Width + (CloumnWidth - oldVal);
              }
            }
          } else if (data.Key[1] > cellKey[1]) {
            //处于选中单元格所在列的右方，处理startX
            data.StartX = data.StartX + (CloumnWidth - oldVal);
          }
        }
      });

      this.subgroup[this.subIndex].Width =
        this.subgroup[this.subIndex].Width + (CloumnWidth - oldVal);
      this.handleRecord();
    },
    //设置字体样式 加粗 斜体 下划线
    handleTableFontStyle(val) {
      let list = this.subgroup[this.subIndex].DataSourceList;
      if (this.multiple_cell) {
        //处理多选
        for (let i = 0; i < this.mulCellArr.length; i++) {
          let index = list.findIndex(
            (obj) => obj.Key.toString() == this.mulCellArr[i].Key.toString()
          );
          let Font = list[index].Font;
          let fontStyle = this.dealFontStyle(Font, val);
          Font["@FontStyle"] = fontStyle.join(", ");
        }
      } else {
        let Font = list[this.cellIndex].Font;
        let fontStyle = this.dealFontStyle(Font, val);
        Font["@FontStyle"] = fontStyle.join(", ");
      }
      this.handleRecord();
    },
    //设置字体样式 加粗 斜体 下划线
    dealFontStyle(Font, val) {
      let fontStyle = Font["@FontStyle"].split(", ");
      if (val == 1) {
        //加粗
        let index = fontStyle.findIndex((item) => item == "Bold");
        if (index > -1) {
          fontStyle.splice(index, 1);
          if (fontStyle.length == 0) {
            fontStyle[0] = "Regular";
          }
        } else {
          let _index = fontStyle.findIndex((item) => item == "Regular");
          if (_index > -1) {
            fontStyle.splice(_index, 1);
          }
          fontStyle.unshift("Bold");
        }
      } else if (val == 2) {
        let index = fontStyle.findIndex((item) => item == "Italic");
        if (index > -1) {
          fontStyle.splice(index, 1);
          if (fontStyle.length == 0) {
            fontStyle[0] = "Regular";
          }
        } else {
          if (fontStyle[0] == "Bold") {
            fontStyle.splice(1, 0, "Italic");
          } else if (fontStyle[0] == "Regular") {
            fontStyle[0] = "Italic";
          } else {
            fontStyle.unshift("Italic");
          }
        }
      } else if (val == 3) {
        let index = fontStyle.findIndex((item) => item == "Underline");
        if (index > -1) {
          fontStyle.splice(index, 1);
          if (fontStyle.length == 0) {
            fontStyle[0] = "Regular";
          }
        } else {
          if (fontStyle[0] == "Regular") {
            fontStyle[0] = "Underline";
          } else {
            fontStyle.push("Underline");
          }
        }
      }
      return fontStyle;
    },
    //插入行
    insertRow() {
      this.multiple_cell = false;
      this.mulCellArr = [];
      let DataSourceList = this.subgroup[this.subIndex].DataSourceList;
      let list = JSON.parse(JSON.stringify(DataSourceList));
      let PenWidth = this.subgroup[this.subIndex].PenWidth;
      let DicLineLocW = this.subgroup[this.subIndex].DicLineLocW;
      let Cloumn = this.subgroup[this.subIndex].Cloumn; //列数
      let Row = this.subgroup[this.subIndex].Row; //行数

      //获取最后一个单元格
      let lastCell = JSON.parse(JSON.stringify(list))[list.length - 1];

      for (let i = 0; i < Cloumn; i++) {
        let sub = {
          Data: "",
          Font: {
            "@FontFamily": "Arial",
            "@FontSize": 10,
            "@FontStyle": "Regular",
          }, //字体样式
          RowSpacing1: 0, //行间距
          WordSpacing: 0, //字间距
          StartX: 0.5,
          StartY: 0.5,
          Height: 7, //高度
          Width: 15, //宽度
          Id: randomNumberStr(8), //自动生成
          TextAlign: 1, //文本对齐
          AntiBlack: false, //反黑
          MirrorImage: false, //镜像
          AutoWrap: true, //自动换行
          DataSourceType: "0", //数据源 0普通文本 1数据库文本
          ColumnName: "", //数据库列名
          QuerySql: "", //选择的数据库
          TableName: "", //数据库表名
          ConnectionStr: "", //插入字段名
          DataType: "2", //数据类型 0为日期 1为数字 2文本
          DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
          NumberFormat: "123456789", //数字形式
          SequenceType: "0", //序列类型
          InitialValue: "", //初始递变数值
          AddedValue: "", //递变量
          Quantity: "", //递变数量
          Key: [Row, i],
        };
        if (i == 0) {
          sub.StartX = PenWidth;
        } else {
          sub.StartX = DicLineLocW[i - 1] + PenWidth / 2;
        }
        sub.StartY = lastCell.StartY + lastCell.Height + PenWidth;
        if (i == Cloumn - 1) {
          sub.Width =
            this.subgroup[this.subIndex].Width - PenWidth - sub.StartX;
        } else {
          sub.Width = DicLineLocW[i] - PenWidth / 2 - sub.StartX;
        }
        list.push(sub);
      }
      list.sort(this.dealSort);
      this.subgroup[this.subIndex].DataSourceList = list;
      this.subgroup[this.subIndex].DicLineLocH.push(
        this.subgroup[this.subIndex].Height - PenWidth / 2
      );
      this.subgroup[this.subIndex].Height =
        this.subgroup[this.subIndex].Height + 7 + PenWidth;
      this.subgroup[this.subIndex].Row += 1; //行数加一
      this.$forceUpdate();
      this.handleRecord();
    },
    //插入列
    insertCloumn() {
      this.multiple_cell = false;
      this.mulCellArr = [];
      let list = JSON.parse(
        JSON.stringify(this.subgroup[this.subIndex].DataSourceList)
      );
      let PenWidth = this.subgroup[this.subIndex].PenWidth;
      let DicLineLocH = this.subgroup[this.subIndex].DicLineLocH;
      let Cloumn = this.subgroup[this.subIndex].Cloumn; //列数
      let Row = this.subgroup[this.subIndex].Row; //行数

      //取最后一个单元格（最后一个单元格位于最后一列）
      let lastCell = JSON.parse(JSON.stringify(list))[list.length - 1];

      for (let i = 0; i < Row; i++) {
        let sub = {
          Data: "",
          Font: {
            "@FontFamily": "Arial",
            "@FontSize": 10,
            "@FontStyle": "Regular",
          }, //字体样式
          RowSpacing1: 0, //行间距
          WordSpacing: 0, //字间距
          StartX: 0.5,
          StartY: 0.5,
          Height: 7, //高度
          Width: 15, //宽度
          Id: randomNumberStr(8), //自动生成
          TextAlign: 1, //文本对齐
          AntiBlack: false, //反黑
          MirrorImage: false, //镜像
          AutoWrap: true, //自动换行
          DataSourceType: "0", //数据源 0普通文本 1数据库文本
          ColumnName: "", //数据库列名
          QuerySql: "", //选择的数据库
          TableName: "", //数据库表名
          ConnectionStr: "", //插入字段名
          DataType: "2", //数据类型 0为日期 1为数字 2文本
          DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
          NumberFormat: "123456789", //数字形式
          SequenceType: "0", //序列类型
          InitialValue: "", //初始递变数值
          AddedValue: "", //递变量
          Quantity: "", //递变数量
          Key: [i, Cloumn],
        };
        if (i == 0) {
          sub.StartY = PenWidth;
        } else {
          sub.StartY = DicLineLocH[i - 1] + PenWidth / 2;
        }
        sub.StartX = lastCell.StartX + lastCell.Width + PenWidth;
        if (i == Row - 1) {
          sub.Height =
            this.subgroup[this.subIndex].Height - PenWidth - sub.StartY;
        } else {
          sub.Height = DicLineLocH[i] - PenWidth / 2 - sub.StartY;
        }
        list.push(sub);
      }
      list.sort(this.dealSort);
      this.subgroup[this.subIndex].DataSourceList = list;
      this.subgroup[this.subIndex].DicLineLocW.push(
        this.subgroup[this.subIndex].Width - PenWidth / 2
      );
      this.subgroup[this.subIndex].Width =
        this.subgroup[this.subIndex].Width + 15 + PenWidth;
      this.subgroup[this.subIndex].Cloumn += 1; //列数加一
      this.cellIndex = 0;
      this.$forceUpdate();
      this.handleRecord();
    },
    //删除行
    deleteRow() {
      this.multiple_cell = false;
      this.mulCellArr = [];
      let PenWidth = this.subgroup[this.subIndex].PenWidth;
      let DicLineLocH = this.subgroup[this.subIndex].DicLineLocH;
      let list = JSON.parse(
        JSON.stringify(this.subgroup[this.subIndex].DataSourceList)
      );
      let Row = this.subgroup[this.subIndex].Row;
      if (Row == 1) {
        return;
      }
      let newList = list.filter((item) => {
        if (item.hasOwnProperty("children")) {
          let isLast = item.children.some((keyArr) => keyArr[0] == Row - 1);
          if (isLast) {
            item.Height =
              DicLineLocH[DicLineLocH.length - 1] - item.StartY - PenWidth / 2;
            item.children.splice(item.children.length - 1, 1);
          }
          return item;
        } else {
          if (item.Key[0] != Row - 1) {
            return item;
          }
        }
      });

      this.$nextTick(() => {
        this.cellIndex = 0;
        this.subgroup[this.subIndex].DataSourceList = newList;
        this.subgroup[this.subIndex].Height =
          DicLineLocH[DicLineLocH.length - 1] + PenWidth / 2;
        this.subgroup[this.subIndex].Row -= 1;
        this.subgroup[this.subIndex].DicLineLocH.splice(
          DicLineLocH.length - 1,
          1
        );
        this.$forceUpdate();
        this.handleRecord();
      });
    },
    //删除列
    deleteCloumn() {
      this.multiple_cell = false;
      this.mulCellArr = [];
      let list = JSON.parse(
        JSON.stringify(this.subgroup[this.subIndex].DataSourceList)
      );
      let DicLineLocW = this.subgroup[this.subIndex].DicLineLocW;
      let PenWidth = this.subgroup[this.subIndex].PenWidth;
      let Cloumn = this.subgroup[this.subIndex].Cloumn;
      if (Cloumn == 1) {
        return;
      }

      let newList = list.filter((item) => {
        if (item.hasOwnProperty("children")) {
          //合并的单元格
          let isLast = item.children.some((keyArr) => keyArr[1] == Cloumn - 1);
          if (isLast) {
            item.Width =
              DicLineLocW[DicLineLocW.length - 1] - item.StartX - PenWidth / 2;
            item.children.splice(item.children.length - 1, 1);
          }
          return item;
        } else {
          if (item.Key[1] != Cloumn - 1) {
            return item;
          }
        }
      });

      this.$nextTick(() => {
        this.cellIndex = 0;
        this.subgroup[this.subIndex].DataSourceList = newList;
        this.subgroup[this.subIndex].Width =
          DicLineLocW[DicLineLocW.length - 1] + PenWidth / 2;
        this.subgroup[this.subIndex].Cloumn -= 1;
        this.subgroup[this.subIndex].DicLineLocW.splice(
          DicLineLocW.length - 1,
          1
        );
        this.$forceUpdate();
        this.handleRecord();
      });
    },
    // 合并单元格
    mergeCell() {
      if (!this.multiple_cell) return;
      let list = this.subgroup[this.subIndex].DataSourceList;

      // if (this.mulCellArr.length == 1) {
      //   //取消合并单元格
      //   this.cancelMergeCell();
      //   return;
      // }

      
      // 获取选中单元格所包含范围内的所有单元格
      let containCell = this.getContainCell();

      //获取第一个单元格的下标
      let firstObj = JSON.parse(JSON.stringify(containCell))[0];
      let firstIndex = list.findIndex(
        (obj) => obj.Key[0] == firstObj.Key[0] && obj.Key[1] == firstObj.Key[1]
      );

      //计算合并单元的坐标和宽高
      let { newStartX, newStartY, newWidth, newHeight } =
        this.computeRange(containCell);

      let mulCellObj = JSON.parse(JSON.stringify(containCell[0]));
      mulCellObj.StartX = newStartX;
      mulCellObj.StartY = newStartY;
      mulCellObj.Width = newWidth;
      mulCellObj.Height = newHeight;
      //处理合并单元格的Key
      mulCellObj.children = [];
      containCell.map((cell) => {
        if (cell.hasOwnProperty("children")) {
          mulCellObj.children.push(...cell.children);
        } else {
          mulCellObj.children.push(cell.Key);
        }
      });

      const keySort = (val1, val2) => {
        if (val1[0] !== val2[0]) return val1[0] < val2[0] ? -1 : 1;
        else if (val1[1] !== val2[1]) return val1[1] < val2[1] ? -1 : 1;
      };
      mulCellObj.children.sort(keySort);

      for (let i = containCell.length - 1; i > 0; i--) {
        let index = list.findIndex(
          (obj) =>
            obj.Key[0] == containCell[i].Key[0] &&
            obj.Key[1] == containCell[i].Key[1]
        );
        list.splice(index, 1);
      }
      list[firstIndex] = mulCellObj;
      this.cellIndex = firstIndex;
      this.mulCellArr = [];
      this.mulCellArr.push(mulCellObj);
      this.handleRecord();
    },
    // 获取选中单元格所包含范围内的所有单元格
    getContainCell() {
      let firstMulCellObj = this.mulCellArr[0]; //多选第一个单元格
      let lastMulCellObj = this.mulCellArr[this.mulCellArr.length - 1]; //多选最后一个单元格

      let minX = Math.min.apply(
        Math,
        this.mulCellArr.map((item) => {
          return item.StartX;
        })
      );

      let maxX = Math.max.apply(
        Math,
        this.mulCellArr.map((item) => {
          return item.StartX + item.Width;
        })
      );

      let minY = Math.min.apply(
        Math,
        this.mulCellArr.map((item) => {
          return item.StartY;
        })
      );

      let maxY = Math.max.apply(
        Math,
        this.mulCellArr.map((item) => {
          return item.StartY + item.Height;
        })
      );

      let containCell = [];
      let list = this.subgroup[this.subIndex].DataSourceList;
      let oldList = JSON.parse(JSON.stringify(list));

      const range = (val) => {
        let left_point = {
          x: val.StartX,
          y: val.StartY,
        };
        let right_point = {
          x: val.StartX + val.Width,
          y: val.StartY + val.Height,
        };
        let rangeX =
          (left_point.x >= minX && left_point.x <= maxX) ||
          (right_point.x >= minX && right_point.x <= maxX);
        let rangeY =
          (left_point.y >= minY && left_point.y <= maxY) ||
          (right_point.y >= minY && right_point.y <= maxY);

        return rangeX && rangeY;
      };

      if (firstMulCellObj.Key[0] == lastMulCellObj.Key[0]) {
        //同一行
        //过滤出包含在其中的单元格
        containCell = oldList.filter((item, index) => {
          if (
            (item.Key[0] == firstMulCellObj.Key[0] &&
              item.Key[1] >= firstMulCellObj.Key[1] &&
              item.Key[1] <= lastMulCellObj.Key[1]) ||
            range(item)
          ) {
            return item;
          }
        });
      } else if (firstMulCellObj.Key[1] == lastMulCellObj.Key[1]) {
        // 同一列
        //过滤出包含在其中的单元格
        containCell = oldList.filter((item, index) => {
          if (
            (item.Key[1] == firstMulCellObj.Key[1] &&
              item.Key[0] >= firstMulCellObj.Key[0] &&
              item.Key[0] <= lastMulCellObj.Key[0]) ||
            range(item)
          ) {
            return item;
          }
        });
      } else {
        //过滤出包含在其中的单元格
        containCell = oldList.filter((item, index) => {
          if (
            (item.Key[0] >= firstMulCellObj.Key[0] &&
              item.Key[0] <= lastMulCellObj.Key[0] &&
              item.Key[1] >= firstMulCellObj.Key[1] &&
              item.Key[1] <= lastMulCellObj.Key[1]) ||
            range(item)
          ) {
            return item;
          }
        });
      }
      return containCell;
    },
    //取消单个合并单元格
    cancelMergeCell() {
      let tableData = this.subgroup[this.subIndex];
      let DicLineLocW = tableData.DicLineLocW;
      let DicLineLocH = tableData.DicLineLocH;
      let cell = JSON.parse(
        JSON.stringify(tableData.DataSourceList[this.cellIndex])
      );
      if (cell.hasOwnProperty("children")) {
        let newDataList = [];
        for (let i = 0; i < cell.children.length; i++) {
          let data = JSON.parse(JSON.stringify(cell));
          let newCell = ''
          if(i == 0){
            newCell = data
          }else {
            newCell = {
              Data: "",
              Font: {
                "@FontFamily": "Arial",
                "@FontSize": 12,
                "@FontStyle": "Regular",
              },
              StartX: data.StartX,
              StartY: data.StartY,
              Height: data.Height,
              Width: data.Width,
              RowSpacing1: 0,
              WordSpacing: 0, 
              TextAlign: 1,
              AntiBlack: false,
              MirrorImage: false,
              AutoWrap: true,
              DataSourceType: "0",
              ColumnName: "",
              QuerySql: "",
              TableName: "",
              ConnectionStr: "",
              DataType: "2",
              DataFormat: "yyyy-MM-dd HH:mm:ss",
              NumberFormat: "123456789",
              SequenceType: "0",
              InitialValue: "", 
              AddedValue: "",
              Quantity: "",
            };
          }
          //处理坐标
          if (cell.children[i][1] > 0) {
            newCell.StartX =
              DicLineLocW[cell.children[i][1] - 1] + tableData.PenWidth / 2;
          }
          if (cell.children[i][0] > 0) {
            newCell.StartY =
              DicLineLocH[cell.children[i][0] - 1] + tableData.PenWidth / 2;
          }

          //处理宽高
          if (DicLineLocW[cell.children[i][1]]) {
            newCell.Width =
              DicLineLocW[cell.children[i][1]] -
              newCell.StartX -
              tableData.PenWidth / 2;
          } else {
            newCell.Width =
              tableData.Width - tableData.PenWidth - newCell.StartX;
          }
          if (DicLineLocH[cell.children[i][0]]) {
            newCell.Height =
              DicLineLocH[cell.children[i][0]] -
              newCell.StartY -
              tableData.PenWidth / 2;
          } else {
            newCell.Height =
              tableData.Height - tableData.PenWidth - newCell.StartY;
          }
          newCell.Key = cell.children[i];
          delete newCell.children;
          newDataList.push(newCell);
        }

        tableData.DataSourceList.splice(this.cellIndex, 1, ...newDataList);
        tableData.DataSourceList.sort(this.dealSort);
        this.cellIndex = 0;
        this.mulCellArr.splice(0, 1, tableData.DataSourceList[0]);
        this.handleRecord();
        return;
      }
    },
    //计算合并单元格的宽高
    computeRange(containCell) {
      let minX = Math.min.apply(
        Math,
        containCell.map((item) => {
          return item.StartX;
        })
      );

      let maxX = Math.max.apply(
        Math,
        containCell.map((item) => {
          return item.StartX + item.Width;
        })
      );

      let minY = Math.min.apply(
        Math,
        containCell.map((item) => {
          return item.StartY;
        })
      );

      let maxY = Math.max.apply(
        Math,
        containCell.map((item) => {
          return item.StartY + item.Height;
        })
      );
      let newStartX = minX;
      let newStartY = minY;
      let newWidth = maxX - minX;
      let newHeight = maxY - minY;
      return { newStartX, newStartY, newWidth, newHeight };
    },
    //排序
    dealSort(val1, val2) {
      if (val1.Key[0] !== val2.Key[0])
        return val1.Key[0] < val2.Key[0] ? -1 : 1;
      else if (val1.Key[1] !== val2.Key[1])
        return val1.Key[1] < val2.Key[1] ? -1 : 1;
    },
    //修改字体
    fontTableFamilyChange(val) {
      let list = this.subgroup[this.subIndex].DataSourceList;
      if (this.multiple_cell) {
        //处理多选
        for (let i = 0; i < this.mulCellArr.length; i++) {
          let index = list.findIndex(
            (obj) => obj.Key.toString() == this.mulCellArr[i].Key.toString()
          );
          let Font = list[index].Font;
          Font["@FontFamily"] = val;
        }
      } else {
        let Font = list[this.cellIndex].Font;
        Font["@FontFamily"] = val;
      }
      this.handleRecord();
    },
    //修改字号
    fontTableSizeChange(val) {
      let fontSize = val;
      if (!fontSize) {
        //字号为空时默认设置为浏览器最小字号
        fontSize = this.minFontSize;
      }
      let list = this.subgroup[this.subIndex].DataSourceList;
      this.$nextTick(() => {
        if (this.multiple_cell) {
          //处理多选
          for (let i = 0; i < this.mulCellArr.length; i++) {
            let index = list.findIndex(
              (obj) => obj.Key.toString() == this.mulCellArr[i].Key.toString()
            );
            let Font = list[index].Font;
            Font["@FontSize"] = fontSize;
          }
        } else {
          let Font = list[this.cellIndex].Font;
          Font["@FontSize"] = fontSize;
        }
        this.handleRecord();
      });
    },
    //设置对齐
    handleTextAlign(val) {
      let list = this.subgroup[this.subIndex].DataSourceList;
      if (this.multiple_cell) {
        //处理多选
        for (let i = 0; i < this.mulCellArr.length; i++) {
          let index = list.findIndex(
            (obj) => obj.Key.toString() == this.mulCellArr[i].Key.toString()
          );
          list[index].TextAlign = val;
        }
      } else {
        list[this.cellIndex].TextAlign = val;
      }
      this.handleRecord();
    },
    //修改字间距
    wordSpacingChange(val) {
      let wordSpacing = val;
      if (!wordSpacing) {
        //默认字间距
        wordSpacing = 0;
      }
      let list = this.subgroup[this.subIndex].DataSourceList;
      this.$nextTick(() => {
        if (this.multiple_cell) {
          //处理多选
          for (let i = 0; i < this.mulCellArr.length; i++) {
            let index = list.findIndex(
              (obj) => obj.Key.toString() == this.mulCellArr[i].Key.toString()
            );
            list[index].WordSpacing = wordSpacing;
          }
        } else {
          list[this.cellIndex].WordSpacing = wordSpacing;
        }
        this.handleRecord();
      });
    },
    //修改行间距
    rowSpacingChange(val) {
      let rowSpacing = val;
      if (!rowSpacing) {
        //默认行间距
        rowSpacing = 0;
      }
      let list = this.subgroup[this.subIndex].DataSourceList;
      this.$nextTick(() => {
        if (this.multiple_cell) {
          //处理多选
          for (let i = 0; i < this.mulCellArr.length; i++) {
            let index = list.findIndex(
              (obj) => obj.Key.toString() == this.mulCellArr[i].Key.toString()
            );
            list[index].RowSpacing1 = rowSpacing;
          }
        } else {
          list[this.cellIndex].RowSpacing1 = rowSpacing;
        }
        this.handleRecord();
      });
    },
    //镜像
    handleMirror() {
      let list = this.subgroup[this.subIndex].DataSourceList;
      if (this.multiple_cell) {
        //处理多选
        for (let i = 0; i < this.mulCellArr.length; i++) {
          let index = list.findIndex(
            (obj) => obj.Key.toString() == this.mulCellArr[i].Key.toString()
          );
          list[index].MirrorImage = !list[index].MirrorImage;
        }
      } else {
        list[this.cellIndex].MirrorImage = !list[this.cellIndex].MirrorImage;
      }
      this.handleRecord();
    },
    //黑反
    handleAntiBlack() {
      let list = this.subgroup[this.subIndex].DataSourceList;
      if (this.multiple_cell) {
        //处理多选
        for (let i = 0; i < this.mulCellArr.length; i++) {
          let index = list.findIndex(
            (obj) => obj.Key.toString() == this.mulCellArr[i].Key.toString()
          );
          list[index].AntiBlack = !list[index].AntiBlack;
        }
      } else {
        list[this.cellIndex].AntiBlack = !list[this.cellIndex].AntiBlack;
      }
      this.handleRecord();
    },
  },
};

export default table_attr;
