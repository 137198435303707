import { createRouter, createWebHistory } from "vue-router";
import error404 from "../views/404.vue";
const routes = [
  {
    path: "/",
    redirect: "/login",
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/Login.vue"),
    meta: {
      title: "登录",
      label: "navbar.login",
    },
  },
  {
    path: "/prelogin",
    name: "prelogin",
    component: () => import("../views/login/PreLogin.vue"),
    meta: {
      title: "登录",
      label: "navbar.login",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/login/Register.vue"),
    meta: {
      title: "注册",
      label: "navbar.register",
    },
  },
  {
    path: "/forgetpwd",
    name: "forgetpwd",
    component: () => import("../views/login/Forgetpwd.vue"),
    meta: {
      title: "修改密码",
      label: "navbar.renamePassword",
    },
  },
  {
    path: "/bindings",
    name: "bindings",
    component: () => import("../views/login/Bindings.vue"),
    meta: {
      title: "绑定账号",
      label: "navbar.bind",
    },
  },
  {
    path: "/thirdLogin",
    name: "thirdLogin",
    component: () => import("../views/login/ThirdLogin.vue"),
    meta: {
      title: "登录",
      label: "navbar.login",
    },
  },
  {
    path: "/editLabel",
    name: "editLabel",
    component: () => import("../views/editLabel/EditLabel.vue"),
    meta: {
      title: "编辑标签",
      label: "navbar.editLabel",
    },
  },
  {
    path: "/download",
    name: "download",
    component: () => import("../views/help/Download.vue"),
    meta: {
      title: "下载中心",
      label: "navbar.download",
    },
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/help/Help.vue"),
    meta: {
      title: "帮助中心",
      label: "navbar.help",
    },
  },
  {
    path: "/helpCatalog",
    name: "helpCatalog",
    component: () => import("../views/help/HelpCatalog.vue"),
    meta: {
      title: "帮助中心",
      label: "navbar.help",
    },
  },
  {
    path: "/helpDetail",
    name: "helpDetail",
    component: () => import("../views/help/HelpDetail.vue"),
    meta: {
      title: "帮助中心",
      label: "navbar.help",
    },
  },
  {
    path: "/helpSearch",
    name: "helpSearch",
    component: () => import("../views/help/HelpSearch.vue"),
    meta: {
      title: "帮助中心",
      label: "navbar.help",
    },
  },
  {
    path: "/pdfPrint",
    name: "pdfPrint",
    component: () => import("../views/pdfPrint/PDFPrint.vue"),
    meta: {
      title: "PDF文档打印",
      label: "navbar.pdfPrint",
    },
  },
  {
    path: "/404",
    name: "404",
    component: error404,
    meta: {
      title: "404",
      label: "navbar.error404",
    },
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
  {
    path: "/home",
    name: "home",
    redirect: "/index",
    component: () => import("../views/home/Home.vue"),
    meta: {
      title: "首页",
      label: "navbar.home",
    },
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index/Index.vue"),
        meta: {
          title: "首页",
          label: "navbar.home",
        },
      },
      {
        path: "/user",
        name: "user",
        component: () => import("../views/user/User.vue"),
        meta: {
          title: "个人中心",
          label: "navbar.user",
        },
      },
      {
        path: "/member",
        name: "member",
        component: () => import("../views/user/Member.vue"),
        meta: {
          title: "会员中心",
          label: "navbar.member",
        },
      },
      {
        path: "/shareTemplate",
        name: "shareTemplate",
        component: () => import("../views/shareTemplate/ShareTemplate.vue"),
        meta: {
          title: "共享模板",
          label: "navbar.shareTemplate",
        },
      },
      {
        path: "/cloudTemplate",
        name: "cloudTemplate",
        component: () => import("../views/cloudTemplate/CloudTemplate.vue"),
        meta: {
          title: "云端模板",
          label: "navbar.cloudTemplate",
          // keepAlive: true,
        },
      },
      {
        path: "/cloudData",
        name: "cloudData",
        component: () => import("../views/cloudData/CloudData.vue"),
        meta: {
          title: "云端数据",
          label: "navbar.cloudData",
        },
      },
    ],
  },
];

//hprt  hereLabelTop
let path = "";
if (process.env.VUE_APP_VERSION == "hprt" || process.env.VUE_APP_VERSION == 'hereLabelTop') {
  path = "/label";
} else {
  //HereLabel
  path = "/";
}
const router = createRouter({
  history: createWebHistory(path),
  routes,
});

export default router;
