//获取浏览器类型
export const getExplorer = () => {
  const explorer = window.navigator.userAgent;
  if (explorer.indexOf("MSIE") >= 0) {
    return "IE";
  } else if (explorer.indexOf("Firefox") >= 0) {
    return "Firefox";
  } else if (explorer.indexOf("Chrome") >= 0) {
    return "Chrome";
  } else if (explorer.indexOf("Opera") >= 0) {
    return "Opera";
  } else if (explorer.indexOf("Safari") >= 0) {
    return "Safari";
  }
};

//获取操作系统
export const getSystem = () => {
  const explorer = window.navigator.userAgent;
  if (explorer.indexOf("Windows") >= 0) {
    return "Windows";
  } else if (explorer.indexOf("Mac") >= 0) {
    return "Mac";
  } else if (explorer.indexOf("X11") >= 0) {
    return "UNIX";
  } else if (explorer.indexOf("Linux") >= 0) {
    return "Linux";
  } else {
    return "Other";
  }
};


/**
 *
 * @param {*} UA ,就是userAgent
 * @returns  type: 设备类型
 *           masklayer: 就是给外部拿到判断是否显示遮罩层的,一些特殊环境要引导用户到外部去打开访问
 */

 function isMoible(UA) {
  return /(Android|webOS|iPhone|iPad|iPod|tablet|BlackBerry|Mobile)/i.test(UA)
    ? true
    : false;
}

export const deviceType = () => {
  let UA = navigator.userAgent
  console.log(UA)
  if (isMoible(UA)) {
    return "mobile";
  } else {
    return "pc";
  }
}
