import { createStore } from "vuex";

export default createStore({
  state: {
    languageLoad: false,
    user: {
      name: "",
      avatar: "",
    },
    memberInfo: {
      expire_time: "",
      member_num: "",
      status: "",
      u_id: "",
      user_id: "",
    },
    keepAlive: false,
    firstOpen: true,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setFirstOpen(state, firstOpen) {
      state.firstOpen = firstOpen;
    },
    setMemberInfo(state, memberInfo) {
      state.memberInfo = memberInfo;
    },
    setKeepAlive(state, keepAlive) {
      state.keepAlive = keepAlive;
    },
  },
  actions: {},
  modules: {},
  getters: {
    getlanguageLoad(state) {
      return state.languageLoad;
    },
    getUser(state) {
      return state.user;
    },
    getMemberInfo(state) {
      return state.memberInfo;
    },
    getFirstOpen(state) {
      return state.firstOpen;
    },
    getKeepAlive(state) {
      return state.keepAlive;
    },
  },
});
