export class BluetoothSDK {
    constructor() {
        this.services = [];
        this.writeCharacteristicValue = ''
        this.noticeCharacteristicValue = ''
        if (!navigator.bluetooth) {
            console.error("Web Bluetooth is not available in this browser!");
            // throw new Error("Web Bluetooth is not available in this browser!");
        }
    }

    requestDevice(options) {
        return navigator.bluetooth.requestDevice(options).then((device) => {
            this.device = device;
            return device.gatt.connect();
        });
    }

    getServices() {
        if (!this.device || !this.device.gatt.connected) {
            return Promise.reject(
                "Device not connected. Call requestDevice() first."
            );
        }
        return this.device.gatt.getPrimaryServices();
    }

    async getCharacteristics(service) {
        const characteristics = await service.getCharacteristics();
        return characteristics;
    }

    async getServicesAndCharacteristics() {
        if (!this.device || !this.device.gatt.connected) {
            return Promise.reject(
                "Device not connected. Call requestDevice() first."
            );
        }
        this.services = await this.device.gatt.getPrimaryServices();
        for (let i = 0; i < this.services.length; i++) {
            this.services[i].characteristics = await this.getCharacteristics(this.services[i])
        }
        return this.services
    }

    async defaultServicesAndCharacteristics() {
        let services = await this.getServicesAndCharacteristics()
        let writeIndex = 0
        for (let i = 0; i < services.length; i++) {
            for (let j = 0; j < services[i].characteristics.length; j++) {
                if (services[i].characteristics[j].properties.write) {
                    writeIndex = i
                    this.writeCharacteristicValue = services[i].characteristics[j]
                    break
                }
            }
        }
        for (let i = 0; i < services[writeIndex].characteristics.length; i++) {
            if (services[writeIndex].characteristics[i].properties.notify) {
                this.noticeCharacteristicValue = services[writeIndex].characteristics[i]
                break
            }
        }
        this.noticeCharacteristicValue.startNotifications();
        this.noticeCharacteristicValue.addEventListener('characteristicvaluechanged', e => {
            console.log('e.target.value:', this.ab2hex(e.target.value.buffer));
        });
    }
    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    async conveyingInstruct(directiveTxt) {
        let directives = directiveTxt.split(" ");
        let mtu = 170
        let directiveBytes = []
        for(let i = 0; i < Math.ceil(directives.length / mtu); i++) {
            directiveBytes.push(directives.slice(i * mtu,(i+1) * mtu)) 
        }
        for(let i = 0; i < directiveBytes.length; i++) {
            let item = directiveBytes[i].join(' ');
            let directiveByteItem = new Uint8Array(item.match(/[\da-f]{2}/gi).map(h => parseInt(h, 16)));
            await this.writeCharacteristicValue.writeValueWithoutResponse(directiveByteItem)
        }
    }
    setupNotifications(characteristic) {
        return characteristic.startNotifications().then(() => {
            characteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleCharacteristicValueChanged
            );
            return characteristic;
        });
    }

    handleCharacteristicValueChanged(event) {
        const value = event.target.value;
        console.log(`Characteristic Value Changed: ${value}`);
        // Handle the value. This could be different depending on the characteristic's format.
    }

    writeCharacteristicValue(characteristic, value) {
        return characteristic.writeValue(value);
    }

    disconnect() {
        if (this.device) {
            return this.device.gatt.disconnect();
        }
    }
    hexToText(hex) {
        let text = "";
        for (let i = 0; i < hex.length; i += 2) {
            const byte = parseInt(hex.substr(i, 2), 16);
            if (byte !== 0) {
                text += String.fromCharCode(byte);
            }
        }
        return text;
    }
    //数据转文本
    ab2hex(buffer) {
        let hexArr = Array.prototype.map.call(new Uint8Array(buffer), function (bit) {
            return bit.toString(16).padStart(2, "0");
        });
        return this.hexToText(hexArr.join(""));
    }

    static isBluetoothAvailable() {
        return navigator.bluetooth !== undefined;
    }
}