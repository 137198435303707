import md5 from "js-md5";
import sha1 from "../../node_modules/js-sha1/src/sha1";

// 登录信息加签
function getSign(params) {
  let content;
  if (typeof params == "string") {
    content = params;
  } else if (typeof params == "object") {
    var arr = [];
    for (var i in params) {
      arr.push(i + "=" + params[i]);
    }
    content = arr.join("&");
  }
  var url = content.split("&").sort().join("&");
  // console.log(url, 888)
  return sha1(url);
}

//汉码数据加签
function hmSignature(time_stamp) {
  let appid = "764e02x4-d252-11eb-af05-6cdc5827";
  let appkey = "764e02x4-d252-11eb-af05-2f4a1694";

  var signature = sha1(md5(appid + time_stamp + appkey));

  return signature;
}

export { getSign, hmSignature };
