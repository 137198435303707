import { message } from "ant-design-vue";
import { Date } from "core-js";
const attribute = {
  data() {
    return {
      systemTimer: null, //系统时间定时器
      textTime: null, //输入框定时器
      closeAttrPanel: true, //用来判断是否关闭面板 （数字输入框聚焦和失焦）
      systemTimeArr: [],
      reference: [], //存储关联元素的下标
    };
  },
  methods: {
    //数据源切换
    dataSourceChange(e) {
      let data =
        this.subgroup[this.subIndex].DataSourceList[this.dataSourceIndex];
      data.SequenceType = "";
      data.ColumnName = "";
      data.QuerySql = "";
      data.ConnectionStr = "";
      data.AddedValue = "";
      data.Quantity = "";
      data.InitialValue = "";
      data.ReferenceId = "";
      if (e == 2) {
        data.SequenceType = "0";
        data.FirstValue = "0";
        data.AddedValue = 0;
        data.Quantity = 1;
        data.InitialValue = 0;
      } else if (e == 3) {
        //切换成元素值所有内容按普通文本处理
        let Id = this.subgroup[this.subIndex].Id;
        let isRefer = false;
        for (let i = 0; i < this.subgroup.length; i++) {
          let item = this.subgroup[i];
          if (
            item.Type == "WinText" ||
            item.Type == "GeneralBarcode" ||
            item.Type == "QRCode"
          ) {
            isRefer = item.DataSourceList.some((val) => val.ReferenceId == Id);
            if (isRefer) {
              break;
            }
          }
        }
        if (isRefer) {
          message.error(this.$t(`tips.tip72`));
          data.DataSourceType = "0";
        } else {
          this.$nextTick(() => {
            this.elementList = this.getElementList();
          });
        }
      }
      this.combinationData(this.subIndex);
      this.handleRecord();
    },
    //获取可选元素值
    getElementList() {
      let list = [];
      let Id = this.subgroup[this.subIndex].Id;
      this.subgroup.map((item) => {
        if (
          item.Type == "WinText" ||
          item.Type == "GeneralBarcode" ||
          item.Type == "QRCode"
        ) {
          let isRefer = item.DataSourceList.some(
            (data) => data.ReferenceId != ""
          );
          if (!isRefer && item.Id != Id) {
            list.push(item);
          }
        }
      });
      return list;
    },
    // 选择绑定的元素值
    elementChange(e) {
      let data =
        this.subgroup[this.subIndex].DataSourceList[this.dataSourceIndex];
      let ReferenceId = data.ReferenceId;

      let index = this.subgroup.findIndex((item) => item.Id == ReferenceId);
      data.FirstValue = this.subgroup[index].Data;
      this.combinationData(this.subIndex);
      this.handleRecord();
    },
    //普通文本、条形码、二维码输入框
    dataInput(e) {
      let type = this.subgroup[this.subIndex].Type;
      if (type == "WinText") {
        this.combinationData(this.subIndex);
        this.getAssociate();
        this.handleRecord();
      } else if (type == "QRCode") {
        clearTimeout(this.textTime);
        this.textTime = setTimeout(() => {
          this.combinationData(this.subIndex);
          this.getAssociate();
          this.handleRecord();
          this.textTime = null;
        }, 500);
      } else if (type == "GeneralBarcode") {
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        let value =
          this.subgroup[this.subIndex].DataSourceList[this.dataSourceIndex]
            .FirstValue;
        if (reg.test(value)) {
          value = value.replace(reg, "");
          this.subgroup[this.subIndex].DataSourceList[
            this.dataSourceIndex
          ].FirstValue = value;
          return;
        }
        clearTimeout(this.textTime);
        this.textTime = setTimeout(() => {
          this.combinationData(this.subIndex);
          this.getAssociate();
          this.handleRecord();
          this.textTime = null;
        }, 500);
      }
    },
    //时间输入框
    timeDataInput(e) {
      //重置时间输入框
      clearTimeout(this.textTime);
      this.textTime = setTimeout(() => {
        if (this.subgroup[this.subIndex].DataType == 1) {
          let data = this.subgroup[this.subIndex].Data;
          let NumberFormat = this.subgroup[this.subIndex].NumberFormat;
          if (NumberFormat == "123456789") {
            let newData = data.replace(/[^0-9]/gi, "");
            this.subgroup[this.subIndex].Data = newData;
          } else if (NumberFormat == "123,456,789") {
            let newStr = data.replace(/[^0-9]/gi, "");
            this.subgroup[this.subIndex].Data = this.dealNumber(newStr);
          }
        }
        this.handleRecord();
        this.textTime = null;
      }, 500);
    },
    //插入数据
    dataColumnChange(e) {
      let ConnectionData = [];
      let Type = this.subgroup[this.subIndex].Type;
      let index;
      if (Type == "Table") {
        index = this.cellIndex;
      } else {
        index = this.dataSourceIndex;
      }
      let data = this.subgroup[this.subIndex].DataSourceList[index];
      this.database.map((item) => {
        if (item.value == data.ColumnName) {
          ConnectionData = item.data;
        }
      });
      data.ConnectionData = ConnectionData;
      data.OriginalConnectionData = ConnectionData;
      this.dateBaseTypeChange();

      data.ConnectionStr = this.ConnectionStr;
      data.QuerySql = data.ConnectionStr + "," + data.ColumnName;
    },
    // 改变数据库数据类型
    dateBaseTypeChange(e) {
      let type = this.subgroup[this.subIndex].Type;
      let index;
      if (type == "Table") {
        index = this.cellIndex;
      } else {
        index = this.dataSourceIndex;
      }
      var data = this.subgroup[this.subIndex].DataSourceList[index];
      let DataType = data.DataType;
      let DataFormat = data.DataFormat;
      let NumberFormat = data.NumberFormat;
      let OriginalConnectionData = JSON.parse(
        JSON.stringify(data.OriginalConnectionData)
      );
      // 0 日期  1数字  2文本
      switch (DataType) {
        case "0":
          data.ConnectionData = OriginalConnectionData.map((val) => {
            if (isNaN(Number(val))) {
              let reg =
                /^(?=.*?年)(?=.*?月)|(?=.*?年)(?=.*?月)(?=.*?日)|(?=.*?月)(?=.*?日).+$/;
              let date = "";
              date = new Date(val).getTime();
              if (reg.test(val)) {
                let arr = val.split(/年|月|日/);
                arr.splice(arr.length - 1, 1);
                let string = arr.join("-");
                date = new Date(string).getTime();
              }
              if (!isNaN(date)) {
                let newDate = this.timeToDate(date, DataFormat);
                return newDate;
              } else {
                return val;
              }
            } else {
              return val;
            }
          });
          break;
        case "1":
          if (NumberFormat == "123456789") {
            data.ConnectionData = OriginalConnectionData.map((val) => {
              var newStr = val.replace(/,/g, "");
              return newStr;
            });
          } else if (NumberFormat == "123,456,789") {
            data.ConnectionData = OriginalConnectionData.map((val) => {
              if (!isNaN(Number(val))) {
                var newStr = this.dealNumber(val);
                return newStr;
              } else {
                return val;
              }
            });
          }
          break;
        case "2":
          data.ConnectionData = OriginalConnectionData;
          break;
      }
      if (type == "Table") {
        data.Data = data.ConnectionData[0];
      } else {
        data.FirstValue = data.ConnectionData[0];
        this.combinationData(this.subIndex);
        this.getAssociate();
      }

      this.handleRecord();
    },
    //序列化类型改变
    sequenceTypeChange(e) {
      let Type = this.subgroup[this.subIndex].Type;
      let DataSourceList = this.subgroup[this.subIndex].DataSourceList;
      let data =
        Type == "Table"
          ? DataSourceList[this.cellIndex]
          : DataSourceList[this.dataSourceIndex];

      if (e == 2) {
        data.InitialValue = "a";
        if (Type == "Table") {
          data.Data = "a";
        } else {
          data.FirstValue = "a";
        }
      } else {
        data.InitialValue = "0";
        if (Type == "Table") {
          data.Data = "0";
        } else {
          data.FirstValue = "0";
        }
      }
      if (Type != "Table") {
        this.combinationData(this.subIndex);
        // this.getEndValue();
        this.getAssociate();
      }
      this.handleRecord();
    },
    //递变数值改变
    changeInitialValue(e) {
      let Type = this.subgroup[this.subIndex].Type;
      let DataSourceList = this.subgroup[this.subIndex].DataSourceList;
      let data =
        Type == "Table"
          ? DataSourceList[this.cellIndex]
          : DataSourceList[this.dataSourceIndex];
      let SequenceType = data.SequenceType;
      let regex = /^[0-9]+$/;
      switch (SequenceType) {
        // 十进制
        case "0":
          regex = /^[0-9]+$/;
          if (regex.test(e) || e == "") {
            data[Type == "Table" ? "Data" : "FirstValue"] = data.InitialValue;
          } else {
            data.InitialValue = data.InitialValue.replace(/\D/g, "");
            data[Type == "Table" ? "Data" : "FirstValue"] = data.InitialValue;
            message.error(this.$t(`tips.tip73`));
          }
          break;
        // 十六进制
        case "1":
          regex = /^[A-Fa-f0-9]+$/;
          if (regex.test(e) || e == "") {
            data.InitialValue = this.dealInitialValue(data);
            data[Type == "Table" ? "Data" : "FirstValue"] = data.InitialValue;
          } else {
            data.InitialValue = data.InitialValue.replace(/[^A-Fa-f0-9]/g, "");
            data[Type == "Table" ? "Data" : "FirstValue"] = data.InitialValue;
            message.error(this.$t(`tips.tip74`));
          }
          break;
        // 二十六进制
        case "2":
          regex = /^[A-Za-z]+$/;
          if (regex.test(e) || e == "") {
            data.InitialValue = this.dealInitialValue(data);
            data[Type == "Table" ? "Data" : "FirstValue"] = data.InitialValue;
          } else {
            data.InitialValue = data.InitialValue.replace(/[^A-Za-z]/g, "");
            data[Type == "Table" ? "Data" : "FirstValue"] = data.InitialValue;
            message.error(this.$t(`tips.tip75`));
          }
          break;
        // 三十六进制
        case "3":
          regex = /^[A-Za-z0-9]+$/;
          if (regex.test(e) || e == "") {
            data.InitialValue = this.dealInitialValue(data);
            data[Type == "Table" ? "Data" : "FirstValue"] = data.InitialValue;
          } else {
            data.InitialValue = data.InitialValue.replace(/\W/g, "");
            data[Type == "Table" ? "Data" : "FirstValue"] = data.InitialValue;
            message.error(this.$t(`tips.tip76`));
          }
          break;
      }
      if (Type != "Table") {
        this.combinationData(this.subIndex);
        this.getAssociate();
      }
      this.handleRecord();
    },
    InitialValueBlur() {
      let Type = this.subgroup[this.subIndex].Type;
      let DataSourceList = this.subgroup[this.subIndex].DataSourceList;
      let data =
        Type == "Table"
          ? DataSourceList[this.cellIndex]
          : DataSourceList[this.dataSourceIndex];

      if (data.InitialValue == "") {
        let SequenceType = data.SequenceType;
        switch (SequenceType) {
          // 十进制
          case "0":
          // 十六进制
          case "1":
          // 三十六进制
          case "3":
            data.InitialValue = "0";
            data[Type == "Table" ? "Data" : "FirstValue"] = "0";
            break;
          // 二十六进制
          case "2":
            data.InitialValue = "a";
            data[Type == "Table" ? "Data" : "FirstValue"] = "a";
            break;
        }
        if (Type != "Table") {
          this.combinationData(this.subIndex);
          this.getAssociate();
        }
        this.handleRecord();
      }
      this.closeAttrPanel = true;
    },
    dealInitialValue(data) {
      let InitialValue = data.InitialValue;
      for (let i = 0; i < data.InitialValue.length; i++) {
        let strCode = data.InitialValue.charCodeAt(i);
        if (strCode >= 65 && strCode <= 90) {
          InitialValue = data.InitialValue.toUpperCase();
          break;
        } else if (strCode >= 97 && strCode <= 122) {
          InitialValue = data.InitialValue.toLowerCase();
          break;
        }
      }
      return InitialValue;
    },
    //递变量改变
    changeAddedValue(e) {
      // this.getEndValue();
      this.handleRecord();
    },
    //递变数量改变
    changeQuantity(e) {
      // this.getEndValue();
      this.handleRecord();
    },
    //重新计算递变数值的末值
    getEndValue() {
      let Type = this.subgroup[this.subIndex].Type;
      let DataSourceList = this.subgroup[this.subIndex].DataSourceList;
      let data =
        Type == "Table"
          ? DataSourceList[this.cellIndex]
          : DataSourceList[this.dataSourceIndex];
      let SequenceType = data.SequenceType;
      switch (SequenceType) {
        // 十进制
        case 0:
          data.EndValue =
            data.InitialValue * 1 + data.AddedValue * data.Quantity;
          break;
        // 十六进制
        case 1:
          data.EndValue = (
            parseInt(data.InitialValue, 16) +
            data.AddedValue * data.Quantity
          ).toString(16);
          break;
        // 二十六进制
        case 2:
          let toTen = this.convertToTen(data.InitialValue);
          let value = toTen + data.AddedValue * data.Quantity;
          data.EndValue = this.convertToTwoteenSix(value);
          break;
        // 三十六进制
        case 3:
          data.EndValue = (
            parseInt(data.InitialValue, 36) +
            data.AddedValue * data.Quantity
          ).toString(36);
          break;
      }
    },
    // 组合数据操作
    dealSourceData(val) {
      let list = JSON.parse(
        JSON.stringify(this.subgroup[this.subIndex].DataSourceList)
      );
      let type = this.subgroup[this.subIndex].Type;
      let firstValue = "";
      if (type == "WinText") {
        firstValue = "Label Designer";
      } else if (type == "GeneralBarcode") {
        firstValue = "12345678910";
      } else if (type == "QRCode") {
        firstValue = "PRT";
      }
      switch (val) {
        // 新增数据
        case 0:
          let obj = {
            DataSourceType: "0", //数据源 0普通文本 1数据库文本 2 序列化文本 3元素值
            FirstValue: "Label Designer", //显示文本值，连接数据库的时候，会获取绑定列的第一个值，用来显示
            ColumnName: "", //数据库列名
            QuerySql: "", //选择的数据库
            ConnectionStr: "", //插入字段名
            DataType: "2", //数据类型 0为日期 1为数字 2文本
            DataFormat: "yyyy-MM-dd HH:mm:ss", //日期格式
            NumberFormat: "123456789", //数字形式
            SequenceType: "0", //序列类型
            InitialValue: "", //初始递变数值
            AddedValue: "", //递变量
            Quantity: "", //递变数量
            ReferenceId: "", //上一级引用元素值的Id
          };
          this.subgroup[this.subIndex].DataSourceList.push(obj);
          this.dataSourceIndex = list.length;
          this.$nextTick(() => {
            let combinationBox = this.$refs.combinationBox;
            combinationBox.scrollTop = combinationBox.scrollHeight;
          });
          break;
        // 删除数据
        case 1:
          this.subgroup[this.subIndex].DataSourceList.splice(
            this.dataSourceIndex,
            1
          );
          if (this.dataSourceIndex > 0) {
            this.dataSourceIndex -= 1;
          }
          break;
        // 上移
        case 2:
          if (this.dataSourceIndex > 0) {
            let data = list[this.dataSourceIndex];
            this.subgroup[this.subIndex].DataSourceList.splice(
              this.dataSourceIndex,
              1
            );
            this.subgroup[this.subIndex].DataSourceList.splice(
              this.dataSourceIndex - 1,
              0,
              data
            );
            this.dataSourceIndex -= 1;
          }
          break;
        // 下移
        case 3:
          if (this.dataSourceIndex < list.length - 1) {
            let data = list[this.dataSourceIndex];
            this.subgroup[this.subIndex].DataSourceList.splice(
              this.dataSourceIndex,
              1
            );
            this.subgroup[this.subIndex].DataSourceList.splice(
              this.dataSourceIndex + 1,
              0,
              data
            );
            this.dataSourceIndex += 1;
          }
          break;
      }
      this.combinationData(this.subIndex);
      this.getAssociate();
      this.handleRecord();
    },
    //时间数据类型切换时修改输入框内容
    dataTypeChange(e) {
      if (this.subgroup[this.subIndex].Type == "TimeText") {
        let Id = this.subgroup[this.subIndex].Id;

        if (e == 0) {
          //将时间戳转成成指定格式的日期
          this.subgroup[this.subIndex].Data = this.timeToDate(
            this.dealTimeOffset(this.subgroup[this.subIndex].selectTime),
            this.subgroup[this.subIndex].DataFormat
          );
        } else if (e == 1) {
          this.subgroup[this.subIndex].Data = "1234120789";
        }
        this.setSystemTime();

        this.handleRecord();
      }
    },
    //时间类型切换重置输入框内容
    timeTypeChange(e) {
      this.subgroup[this.subIndex].selectTime = new Date(
        this.timeToDate(new Date().getTime(), "yyyy-MM-dd HH:mm:ss")
      );
      this.subgroup[this.subIndex].Data = this.timeToDate(
        this.dealTimeOffset(this.subgroup[this.subIndex].selectTime),
        this.subgroup[this.subIndex].DataFormat
      );
      this.setSystemTime();
      this.handleRecord();
    },
    //日期格式切换重置输入框内容
    dateFormatChange(e) {
      //将时间戳转成成指定格式的日期
      this.subgroup[this.subIndex].Data = this.timeToDate(
        this.dealTimeOffset(this.subgroup[this.subIndex].selectTime),
        this.subgroup[this.subIndex].DataFormat
      );
      this.handleRecord();
    },
    //日期选择重置输入框内容
    datePickerChange(e) {
      //将时间戳转成成指定格式的日期
      this.subgroup[this.subIndex].Data = this.timeToDate(
        this.dealTimeOffset(this.subgroup[this.subIndex].selectTime),
        this.subgroup[this.subIndex].DataFormat
      );
      this.handleRecord();
    },
    //天数分钟偏移
    DateOffsetChange(e, type) {
      this.$nextTick(() => {
        if (typeof e == "undefined") {
          if (type == 0) {
            this.subgroup[this.subIndex].DayOffset = 0;
          } else {
            this.subgroup[this.subIndex].MinuteOffset = 0;
          }
        }
        //将时间戳转成成指定格式的日期
        this.subgroup[this.subIndex].Data = this.timeToDate(
          this.dealTimeOffset(this.subgroup[this.subIndex].selectTime),
          this.subgroup[this.subIndex].DataFormat
        );
        this.handleRecord();
      });
    },
    //数字格式切换重置输入框内容
    numFormatChange(e) {
      let data = this.subgroup[this.subIndex].Data;
      let NumberFormat = this.subgroup[this.subIndex].NumberFormat;
      if (NumberFormat == "123456789") {
        let newData = data.replace(/,/g, "");
        this.subgroup[this.subIndex].Data = newData;
      } else if (NumberFormat == "123,456,789") {
        if (data.indexOf(",") == -1) {
          if (!isNaN(Number(data))) {
            this.subgroup[this.subIndex].Data = this.dealNumber(data);
          }
        }
      }
      this.handleRecord();
    },
    //设置字体样式 加粗 斜体 下划线
    handleFontStyle(e) {
      let fontStyle =
        this.subgroup[this.subIndex].Font["@FontStyle"].split(", ");
      if (e == 1) {
        //加粗
        let index = fontStyle.findIndex((item) => item == "Bold");
        if (index > -1) {
          fontStyle.splice(index, 1);
          if (fontStyle.length == 0) {
            fontStyle[0] = "Regular";
          }
        } else {
          let _index = fontStyle.findIndex((item) => item == "Regular");
          if (_index > -1) {
            fontStyle.splice(_index, 1);
          }
          fontStyle.unshift("Bold");
        }
      } else if (e == 2) {
        let index = fontStyle.findIndex((item) => item == "Italic");
        if (index > -1) {
          fontStyle.splice(index, 1);
          if (fontStyle.length == 0) {
            fontStyle[0] = "Regular";
          }
        } else {
          if (fontStyle[0] == "Bold") {
            fontStyle.splice(1, 0, "Italic");
          } else if (fontStyle[0] == "Regular") {
            fontStyle[0] = "Italic";
          } else {
            fontStyle.unshift("Italic");
          }
        }
      } else if (e == 3) {
        let index = fontStyle.findIndex((item) => item == "Underline");
        if (index > -1) {
          fontStyle.splice(index, 1);
          if (fontStyle.length == 0) {
            fontStyle[0] = "Regular";
          }
        } else {
          if (fontStyle[0] == "Regular") {
            fontStyle[0] = "Underline";
          } else {
            fontStyle.push("Underline");
          }
        }
      }
      this.subgroup[this.subIndex].Font["@FontStyle"] = fontStyle.join(", ");
      if (this.subgroup[this.subIndex].Type == "GeneralBarcode") {
        this.handleCreated("1", this.subIndex);
      }
      this.handleRecord();
    },
    //字号改变重新生成条形码
    fontSizeChange(e) {
      let index = this.subIndex;
      let type = this.subgroup[index].Type;
      if (type == "GeneralBarcode") {
        this.$nextTick(() => {
          if (typeof e == "") {
            this.subgroup[index].Font["@FontSize"] = 14;
          }
          this.handleCreated("1", index);
        });
      }
      this.handleRecord();
    },
    //条形码下方是否显示  重新生成图片
    datashowChange(e) {
      if (e == 0) {
        this.subgroup[this.subIndex].HasLabel = false;
      } else if (e == 1) {
        this.subgroup[this.subIndex].HasLabel = true;
      }
      this.handleCreated("1", this.subIndex);
      this.handleRecord();
    },
    //字体样式改变重新生成条形码
    fontFamilyChange(e) {
      let type = this.subgroup[this.subIndex].Type;
      if (type == "GeneralBarcode") {
        this.handleCreated("1", this.subIndex);
      }
      this.handleRecord();
    },
    //编码改变重新生成条形码、二维码
    codeChange(e) {
      let type = this.subgroup[this.subIndex].Type;
      if (type == "GeneralBarcode") {
        this.handleCreated("1", this.subIndex);
      } else if (type == "QRCode") {
        let BarcodeType = this.subgroup[this.subIndex].BarcodeType
        let ECLevelBits = this.subgroup[this.subIndex].ECLevelBits
        this.setEclevelList(BarcodeType);
        if(BarcodeType == 'PDF_417' && (ECLevelBits != 'L0' || ECLevelBits != 'L1' || ECLevelBits != 'L2' || ECLevelBits != 'L3' ||     ECLevelBits != 'L4' || ECLevelBits != 'L5' || ECLevelBits != 'L6' || ECLevelBits != 'L7' || ECLevelBits != 'L8')){
          this.subgroup[this.subIndex].ECLevelBits = 'L0'
        }
        if(BarcodeType == 'QR_CODE' && (ECLevelBits != 'Low' || ECLevelBits != 'Medium' || ECLevelBits != 'Medium High' || ECLevelBits != 'High')){
          this.subgroup[this.subIndex].ECLevelBits = 'Low'
        }
        this.handleCreated("2", this.subIndex);
      }
      this.handleRecord();
    },
    //缩放倍率改变重新生成条形码、二维码
    zoomChange(e) {
      this.$nextTick(() => {
        let type = this.subgroup[this.subIndex].Type;
        if (type == "GeneralBarcode") {
          if (typeof e == "undefined") {
            this.subgroup[this.subIndex].Zoom = 5;
          }
          this.handleCreated("1", this.subIndex);
        } else if (type == "QRCode") {
          if (typeof e == "undefined") {
            this.subgroup[this.subIndex].Zoom = 2;
          }
          this.handleCreated("2", this.subIndex);
        }
        this.handleRecord();
      });
    },
    //纠错率改变重新生成二维码
    LevelBitsChange(e) {
      this.handleCreated("2", this.subIndex);
      this.handleRecord();
    },
    //文本位置改变重新生成条形码
    locationClick(e) {
      this.locationIndex = e;
      if (e == 1) {
        this.subgroup[this.subIndex].Localtype = "BOTTOMLEFT";
        this.subgroup[this.subIndex].DisplaPosition = 1;
      } else if (e == 2) {
        this.subgroup[this.subIndex].Localtype = "BOTTOMCENTER";
        this.subgroup[this.subIndex].DisplaPosition = 2;
      } else if (e == 3) {
        this.subgroup[this.subIndex].Localtype = "BOTTOMRIGHT";
        this.subgroup[this.subIndex].DisplaPosition = 3;
      }
      this.handleCreated("1", this.subIndex);
      this.handleRecord();
    },
    //旋转
    handleRotateFixed(value) {
      let index = this.subIndex;
      this.subgroup[index].AngleRound = value;
      this.rotateIndex = value;
      this.handleRecord();
    },
    //适应画布
    AdaptCanvas() {
      //this.template.width值为mm
      this.subgroup[this.subIndex].Width = this.template.width;
      this.subgroup[this.subIndex].Height = this.template.height;
      this.subgroup[this.subIndex].StartX = 0;
      this.subgroup[this.subIndex].StartY = 0;
      this.handleRecord();
    },
    //判断当前选中元素的关联关系
    getAssociate() {
      let data = this.subgroup[this.subIndex].DataSourceList;
      //判断该条数据是否有绑定其他元素，若否则该条数据可能被其他数据绑定
      let isRefer = data.some((item) => item.ReferenceId != "");
      if (!isRefer) {
        this.dealAssociateElements(this.subIndex);
      }
    },
    // 处理绑定元素值间的数据重载
    dealAssociateElements(index) {
      let Id = this.subgroup[index].Id;
      let data = this.subgroup[index].Data;
      this.subgroup.map((item, i) => {
        if (
          item.Type == "WinText" ||
          item.Type == "GeneralBarcode" ||
          item.Type == "QRCode"
        ) {
          item.DataSourceList.map((val) => {
            if (val.ReferenceId == Id) {
              val.FirstValue = data;
            }
          });
          this.combinationData(i);
        }
      });
    },
    // 删除元素时解除关联关系 并修改为普通文本
    delAssociate(id) {
      let recordId = id;
      this.subgroup.map((item, i) => {
        if (
          item.Type == "WinText" ||
          item.Type == "GeneralBarcode" ||
          item.Type == "QRCode"
        ) {
          item.DataSourceList.map((val) => {
            let isFind = recordId.some((value) => value == val.ReferenceId);
            if (isFind) {
              val.ReferenceId = "";
              val.DataSourceType = "0";
            }
          });
          this.combinationData(i);
        }
      });
    },
    // 组合数据
    combinationData(index) {
      let value = "";
      this.subgroup[index].DataSourceList.map((item) => {
        value += item.FirstValue;
      });
      this.subgroup[index].Data = value;
      let type = this.subgroup[index].Type;
      if (type == "GeneralBarcode") {
        this.handleCreated("1", index);
      } else if (type == "QRCode") {
        this.handleCreated("2", index);
      }
    },
    //(a-z)26进制转10进制
    convertToTen(str) {
      var num = 0,
        index = 1;
      for (let i = str.length - 1; i >= 0; i--) {
        if (str.charCodeAt(i))
          num += ((str.toLowerCase().charCodeAt(i) - 97) % 26) * index;
        index = index * 26;
      }
      return num;
    },
    //10进制转(a-z)26进制
    convertToTwoteenSix(num) {
      var str = "";
      if(num == 0){
        str = 'a'
      }else{
        while (num !== 0) {
          var temp = num % 26;
          str += String.fromCharCode(temp + 97);
          num = parseInt(num / 26);
        }
      }
      //从后往前存储，进行反转
      return str.split("").reverse().join("");
    },
    // 位数不够时前面补0
    setBeforeZero(type, sequenceValue, data) {
      let value = sequenceValue;
      let upperCase = false;
      //判断要转成大写还是小写
      for (let i = 0; i < data.InitialValue.length; i++) {
        let strCode = data.InitialValue.charCodeAt(i);
        if (strCode >= 65 && strCode <= 90) {
          upperCase = true;
          break;
        } else if (strCode >= 97 && strCode <= 122) {
          upperCase = false;
          break;
        }
      }
      if (upperCase) {
        //大写
        if (value.length < data.InitialValue.length) {
          //前面补位
          var length = data.InitialValue.length - value.length;
          for (let k = 0; k < length; k++) {
            if (type == 26) {
              value = "A" + value.toUpperCase();
            } else {
              value = "0" + value.toUpperCase();
            }
          }
        } else {
          value = value.toUpperCase();
        }
      } else {
        //小写
        if (value.length < data.InitialValue.length) {
          //前面补位
          var length = data.InitialValue.length - value.length;
          for (let k = 0; k < length; k++) {
            if (type == 26) {
              value = "a" + value;
            } else {
              value = "0" + value;
            }
          }
        }
      }
      return value;
    },
    //处理数字格式
    dealNumber(num) {
      let index = num.indexOf(".");
      let int = num;
      let floar = "";

      if (index > -1) {
        int = num.substring(0, index);
        floar = num.substring(index, num.length);
      }
      if (int.length <= 3) {
        return int + floar;
      } else {
        var r = int.length % 3;
        if (r > 0) {
          return (
            int.slice(0, r) +
            "," +
            int.slice(r, int.length).match(/\d{3}/g).join(",") +
            floar
          );
        } else {
          return int.slice(r, int.length).match(/\d{3}/g).join(",") + floar;
        }
      }
    },
    //日期格式转换
    timeToDate(time, format) {
      var t = new Date(time);
      var tf = function (i) {
        return (i < 10 ? "0" : "") + i;
      };
      return format.replace(/yyyy|yy|MM|M|dd|d|HH|H|mm|m|ss|s/g, function (a) {
        switch (a) {
          case "yyyy":
            return tf(t.getFullYear());
            break;
          case "yy":
            let year = tf(t.getFullYear());
            return year.slice(year.length - 2, year.length);
            break;
          case "MM":
            return tf(t.getMonth() + 1);
            break;
          case "M":
            let month = tf(t.getMonth() + 1);
            if (month > 9) {
              return month;
            } else {
              return month.charAt(month.length - 1);
            }
            break;
          case "dd":
            return tf(t.getDate());
            break;
          case "d":
            let day = tf(t.getDate());
            if (day > 9) {
              return day;
            } else {
              return day.charAt(day.length - 1);
            }
            break;
          case "HH":
            return tf(t.getHours());
            break;
          case "H":
            return tf(t.getHours());
            break;
          case "mm":
            return tf(t.getMinutes());
            break;
          case "m":
            return tf(t.getMinutes());
            break;
          case "ss":
            return tf(t.getSeconds());
            break;
          case "s":
            return tf(t.getSeconds());
            break;
        }
      });
    },
    // 处理天数分钟偏移
    dealTimeOffset(date) {
      //将日期转换成时间戳进行添加加减
      let time1 = date.getTime();
      let time2 =
        time1 +
        this.subgroup[this.subIndex].DayOffset * 24 * 60 * 60 * 1000 +
        this.subgroup[this.subIndex].MinuteOffset * 60 * 1000;
      return time2;
    },
    //实时渲染系统时间
    setSystemTime() {
      clearInterval(this.systemTimer);
      let tempGroup = JSON.parse(JSON.stringify(this.subgroup));
      this.systemTimeArr = tempGroup.filter((item, index) => {
        if (
          item.Type == "TimeText" &&
          item.DataType == "0" &&
          item.TimeDate == "0"
        ) {
          item.Index = index;
          return item;
        }
      });
      if (this.systemTimeArr.length > 0) {
        this.systemTimer = setInterval(() => {
          this.systemTimeArr.map((val) => {
            this.subgroup[val.Index].Data = this.timeToDate(
              new Date().getTime() +
                this.subgroup[val.Index].DayOffset * 24 * 60 * 60 * 1000 +
                this.subgroup[val.Index].MinuteOffset * 60 * 1000,
              this.subgroup[val.Index].DataFormat
            );
            this.subgroup[val.Index].selectTime = new Date(
              this.timeToDate(new Date().getTime(), "yyyy-MM-dd HH:mm:ss")
            );
          });
        }, 1000);
      } else {
        clearInterval(this.systemTimer);
        this.systemTimer = null;
      }
    },
    //处理数据库序列化关联元素打印
    dealPrintData(Id) {
      this.reference = [];
      this.subgroup.map((item, i) => {
        if (
          item.Type == "WinText" ||
          item.Type == "GeneralBarcode" ||
          item.Type == "QRCode"
        ) {
          item.DataSourceList.map((val, j) => {
            if (val.ReferenceId == Id) {
              this.reference.push({ PIndex: i, CIndex: j });
            }
          });
        }
      });
    },
    //四舍五入保留两位小数
    dealPrecision(value) {
      return Number(Math.round((value * 100) / 100).toFixed(2));
    },
    //处理excel表的列字母
    transformLetters(l) {
      var ls = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        F: 6,
        G: 7,
        H: 8,
        I: 9,
        J: 10,
        K: 11,
        L: 12,
        M: 13,
        N: 14,
        O: 15,
        P: 16,
        Q: 17,
        R: 18,
        S: 19,
        T: 20,
        U: 21,
        V: 22,
        W: 23,
        X: 24,
        Y: 25,
        Z: 26,
      };
      var sum = 0;
      while (l.length) {
        sum += ls[l[0]] * Math.pow(26, l.length - 1);
        l = l.slice(1, l.length);
      }
      return sum;
    },

    //判断是否是base64
    isBase64(str){
      if (str.length % 4 !== 0) {
        return false;
      }
      const pattern = /^[A-Za-z0-9+/]*[=]{0,2}$/;
      return pattern.test(str);
    }
  },
};

export default attribute;
