import store from "../store/index";
const fontUrlArr = [
  {
    value: "AbrilFatface",
    label: "AbrilFatface",
    woff: "https://foreverfile.hprtcloud.com/fonts/Abril-fatface/AbrilFatface-Regular-2",
    type: "ttf",
  },
  {
    value: "Archivo",
    label: "Archivo",
    woff: "https://foreverfile.hprtcloud.com/fonts/Archivo-black/ArchivoBlack-Regular-2",
    type: "ttf",
  },
  {
    value: "Arvo",
    label: "Arvo",
    woff: "https://foreverfile.hprtcloud.com/fonts/Arvo/Arvo-Regular-5",
    type: "ttf",
  },
  {
    value: "BioRhymeExpanded",
    label: "BioRhymeExpanded",
    woff: "https://foreverfile.hprtcloud.com/fonts/Biorhyme-expanded/BioRhymeExpanded-Regular-6",
    type: "ttf",
  },
  {
    value: "Cairo",
    label: "Cairo",
    woff: "https://foreverfile.hprtcloud.com/fonts/Cairo/Cairo-1",
    type: "ttf",
  },
  {
    value: "Cardo",
    label: "Cardo",
    woff: "https://foreverfile.hprtcloud.com/fonts/Cardo/Cardo-Regular-4",
    type: "ttf",
  },
  {
    value: "Comic-Sans-MS",
    label: "Comic-Sans-MS",
    woff: "https://foreverfile.hprtcloud.com/fonts/Comic-Sans-MS-Regular/Comic-Sans-MS-Regular-2",
    type: "ttf",
  },
  {
    value: "CrimsonText",
    label: "CrimsonText",
    woff: "https://foreverfile.hprtcloud.com/fonts/Crimson-Text/CrimsonText-Regular-5",
    type: "ttf",
  },
  // {
  //     value: "FangZhengHeiTiFanTi",
  //     label: "FangZhengHeiTiFanTi",
  //     woff: "https://foreverfile.hprtcloud.com/fonts/FangZhengHeiTiFanTi/FangZhengHeiTiFanTi-1",
  // type: 'ttf'
  // },
  {
    value: "方正黑体",
    label: "方正黑体",
    woff: "https://foreverfile.hprtcloud.com/fonts/FangZhengHeiTiJianTi/FangZhengHeiTiJianTi-1",
    type: "ttf",
  },
  // {
  //     value: "FangZhengKaiTiFanTi",
  //     label: "FangZhengKaiTiFanTi",
  //     woff: "https://foreverfile.hprtcloud.com/fonts/FangZhengKaiTiFanTi/FangZhengKaiTiFanTi-1",
  // type: 'ttf'
  // },
  {
    value: "方正楷体",
    label: "方正楷体",
    woff: "https://foreverfile.hprtcloud.com/fonts/FangZhengKaiTiJianTi/FangZhengKaiTiJianTi-1",
    type: "ttf",
  },
  {
    value: "方正书宋",
    label: "方正书宋",
    woff: "https://foreverfile.hprtcloud.com/fonts/FangZhengShuSongJianTi/FangZhengShuSongJianTi-1",
    type: "ttf",
  },
  {
    value: "FjallaOne",
    label: "FjallaOne",
    woff: "https://foreverfile.hprtcloud.com/fonts/Fjalla-one/FjallaOne-Regular-2",
    type: "ttf",
  },
  {
    value: "Lato",
    label: "Lato",
    woff: "https://foreverfile.hprtcloud.com/fonts/Lato.zip.zip.zip/Lato-Regular-15",
    type: "ttf",
  },
  {
    value: "Lora",
    label: "Lora",
    woff: "https://foreverfile.hprtcloud.com/fonts/Lora/Lora-Regular-7",
    type: "ttf",
  },
  {
    value: "Merriweather",
    label: "Merriweather",
    woff: "https://foreverfile.hprtcloud.com/fonts/Merriweather/Merriweather-Regular-9",
    type: "ttf",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
    woff: "https://foreverfile.hprtcloud.com/fonts/Montserrat/Montserrat-Regular-8",
    type: "otf",
  },
  {
    value: "MontserratAlternates",
    label: "MontserratAlternates",
    woff: "https://foreverfile.hprtcloud.com/fonts/Montserrat/MontserratAlternates-Regular-17",
    type: "otf",
  },
  {
    value: "Open-Sans",
    label: "Open-Sans",
    woff: "https://foreverfile.hprtcloud.com/fonts/Open-Sans/Open-Sans-2",
    type: "ttf",
  },
  {
    value: "OPPOSans",
    label: "OPPOSans",
    woff: "https://foreverfile.hprtcloud.com/fonts/OPPOSans-B/OPPOSans-B-2",
    type: "ttf",
  },
  {
    value: "PlayfairDisplay",
    label: "PlayfairDisplay",
    woff: "https://foreverfile.hprtcloud.com/fonts/Playfair-display/PlayfairDisplay-Regular-7",
    type: "ttf",
  },
  {
    value: "Roboto",
    label: "Roboto",
    woff: "https://foreverfile.hprtcloud.com/fonts/Roboto/Roboto-Regular-14",
    type: "ttf",
  },
  {
    value: "Source-Sans",
    label: "Source-Sans",
    woff: "https://foreverfile.hprtcloud.com/fonts/Source-Sans-Pro-Regular/Source-Sans-Pro-Regular-2",
    type: "otf",
  },
  {
    value: "TitilliumWeb",
    label: "TitilliumWeb",
    woff: "https://foreverfile.hprtcloud.com/fonts/Titillium-web/TitilliumWeb-Regular-10",
    type: "ttf",
  },
  {
    value: "Ubuntu",
    label: "Ubuntu",
    woff: "https://foreverfile.hprtcloud.com/fonts/UbuntuMono-B/Ubuntu-L-5",
    type: "ttf",
  },
  {
    value: "UbuntuMono",
    label: "UbuntuMono",
    woff: "https://foreverfile.hprtcloud.com/fonts/UbuntuMono-B/UbuntuMono-R-12",
    type: "ttf",
  },
];

function getFontFamily() {
  return new Promise((resolve, reject) => {
    let lengths = 0;
    for (let i = 0; i < fontUrlArr.length; i++) {
      if (fontUrlArr[i].type == "ttf") {
        var url = "url(" + fontUrlArr[i].woff + ".ttf" + ")";
      } else if (fontUrlArr[i].type == "otf") {
        var url = "url(" + fontUrlArr[i].woff + ".otf" + ")";
      }
      let myFonts = new FontFace(fontUrlArr[i].label, url, { display: "swap" });
      myFonts
        .load()
        .then((loadFace) => {
          document.fonts.add(loadFace);
          lengths++;
          if (fontUrlArr.length == lengths) {
            store.state.languageLoad = true;
            resolve();
          }
        })
        .catch((err) => {
          lengths++;
          if (fontUrlArr.length == lengths) {
            store.state.languageLoad = true;
            resolve();
          }
        });
    }
  });
}
export { fontUrlArr, getFontFamily };

