const deformation = {
  data() {
    return {
      unitMove: false, //记录组件是否操作变形
      printSelectFlag: false, //选择区域
      unitHandle: false, //组件是否在操作
      selectUnit: [], //选中的组件
      showAttrPanel: false,
      tableMultCellMove: false,
      printSelect: {
        //选择区域
        width: 0,
        height: 0,
        x: 0,
        y: 0,
      },
      aa: true,
    };
  },
  methods: {
    //点击组合数据
    handleDataSourceList(index) {
      this.dataSourceIndex = index;
      let data =
        this.subgroup[this.subIndex].DataSourceList[this.dataSourceIndex];
      let DataSourceType = data.DataSourceType;
      if (DataSourceType == "1") {
        if (data.ColumnName == "") {
          this.subgroup[this.subIndex].DataSourceList[
            this.dataSourceIndex
          ].DataSourceType = "0";
        }
      } else if (DataSourceType == "3") {
        if (data.ReferenceId == "") {
          this.subgroup[this.subIndex].DataSourceList[
            this.dataSourceIndex
          ].DataSourceType = "0";
        }
      }
    },
    //选择当前
    handleSelect(index, event) {
      if (this.isMoreSelect) return;

      //鼠标点击位置与元件的偏移量，px转mm
      let offsetX = (event.offsetX * 25.4) / 96;
      let offsetY = (event.offsetY * 25.4) / 96;

      let cancelSelect = this.selectUnit.some((item) => item.index === index);
      if (!cancelSelect) {
        this.subIndex = index;
        this.dataSourceIndex = 0;
        this.multiple_cell = false;
        this.mulCellArr = [];

        // let data = JSON.parse(JSON.stringify(this.subgroup[this.subIndex]));
        let data = this.subgroup[this.subIndex];
        data.index = this.subIndex;

        if (data.Type == "Table") {
          // 首次选择表格元件获取鼠标点击位置与元件的偏移量，px转mm
          let table_box_attr =
            this.$refs["print" + index][0].getBoundingClientRect();

          offsetX =
            ((event.clientX - table_box_attr.left) * 25.4) / 96 / this.multiple;

          offsetY =
            ((event.clientY - table_box_attr.top) * 25.4) / 96 / this.multiple;
        }

        this.selectUnit = [];
        this.selectUnit.push(data);
      }
      this.Lock = this.subgroup[this.subIndex].Lock;
      this.rotateIndex = this.subgroup[this.subIndex].AngleRound;

      this.setDataSource(this.subgroup[index]);
      if(this.subgroup[this.subIndex].Type == "QRCode"){
        this.setEclevelList(this.subgroup[this.subIndex].BarcodeType);
      }

      if (
        this.subgroup[this.subIndex].Type == "WinText" ||
        this.subgroup[this.subIndex].Type == "GeneralBarcode" ||
        this.subgroup[this.subIndex].Type == "QRCode"
      ) {
        let data =
          this.subgroup[this.subIndex].DataSourceList[this.dataSourceIndex];
        let DataSourceType = data.DataSourceType;
        if (DataSourceType == "1") {
          if (data.ColumnName == "") {
            this.subgroup[this.subIndex].DataSourceList[
              this.dataSourceIndex
            ].DataSourceType = "0";
          }
        } else if (DataSourceType == "3") {
          if (data.ReferenceId == "") {
            this.subgroup[this.subIndex].DataSourceList[
              this.dataSourceIndex
            ].DataSourceType = "0";
          }
        }

        this.$nextTick(() => {
          //可选元素列表
          this.elementList = this.getElementList();
          this.showAttrPanel = true;
          this.$forceUpdate();
        });
      }

      if(this.subgroup[this.subIndex].Type == "Table"){
        let list = this.subgroup[this.subIndex].DataSourceList;
        if (!this.multiple_cell && !this.tableMultCellMove) {
          this.mulCellArr = [];
          for (let i = 0; i < list.length; i++) {
            let data = list[i];
            if (
              offsetX > data.StartX &&
              offsetX < data.StartX + data.Width &&
              offsetY > data.StartY &&
              offsetY < data.StartY + data.Height
            ) {
              this.cellIndex = i;
              break;
            }
            if (i == list.length - 1) {
              if (this.cellIndex < 0) {
                //防止点击在线框上的情况
                this.cellIndex = 0;
              }
            }
          }
        } else {
          //处理多选
          if(!this.tableMultCellMove){
            //防止多选状态下移动或变形表格时，单元格被取消选中或者选中
            this.dealMulCell(offsetX, offsetY);
          }
        }
      }
    },
    //操作组件变形
    handleSubgroup(event, index, type) {
      event.preventDefault();
      this.showAttrPanel = false;
      this.subIndex = index;
      if(this.selectUnit.length <= 1){
        this.isMoreSelect = false
      }
      this.$nextTick(() => {
        this.showAttrPanel = true;
      });
      let demo = this.$refs["print" + this.subIndex][0];
      var divW = demo.offsetWidth;
      var divH = demo.offsetHeight;
      // 获得当前boximg的offsetLeft 和 offsetTop
      var divL = demo.offsetLeft;
      var divT = demo.offsetTop;
      // 存储鼠标按下时的位置
      var clientx = event.clientX;
      var clienty = event.clientY;

      let indexType = this.subgroup[this.subIndex].Type;
      if (indexType == "Table") {
        let list = this.subgroup[this.subIndex].DataSourceList;
        for (let i = 0; i < list.length; i++) {
          let data = list[i];
          if (
            clientx > data.StartX &&
            clientx < data.StartX + data.Width &&
            clienty > data.StartY &&
            clienty < data.StartY + data.Height
          ) {
            this.cellIndex = i;
            return;
          }
          if (i == list.length - 1) {
            if (this.cellIndex < 0) {
              //防止点击在线框上的情况
              this.cellIndex = 0;
            }
          }
        }
      }
      this.dataSourceIndex = 0;
      this.unitHandle = true;
      let AngleRound = this.subgroup[index].AngleRound;
      if (this.subgroup[this.subIndex].Lock) {
        document.onmouseup = () => {
          this.unitHandle = false;
          document.onmousemove = null;
          document.onmouseup = null;
        };
        return;
      }

      if (this.selectUnit.length > 0) {
        this.selectUnit.forEach((e) => {
          let ebox = this.$refs["print" + e.index][0];
          let divL = ebox.offsetLeft;
          let divT = ebox.offsetTop;
          e.divL = divL;
          e.divT = divT;
        });
      }
      if (this.subgroup[this.subIndex].Font) {
        var FontSize = this.subgroup[this.subIndex].Font["@FontSize"] * 1;
      }
      document.onmousemove = async (event) => {
        this.unitMove = true;
        if (this.selectUnit.length == 1 && !this.multiple_cell) {
          this.selectUnit = [];
        }
        if (indexType == "Table") {
          this.tableMultCellMove = true
        }

        if (indexType == "Diamond") {
          var spaceHeiht =
            (this.subgroup[this.subIndex].Height * 4 -
              (this.subgroup[this.subIndex].Vertice[2]["@Y"] -
                this.subgroup[this.subIndex].Vertice[0]["@Y"])) /
            2;
          var spaceWidth =
            (this.subgroup[this.subIndex].Width * 4 -
              (this.subgroup[this.subIndex].Vertice[1]["@X"] -
                this.subgroup[this.subIndex].Vertice[3]["@X"])) /
            2;
        } else if (indexType == "Triangle") {
          var spaceHeiht =
            (this.subgroup[this.subIndex].Height * 4 -
              (this.subgroup[this.subIndex].Vertice[1]["@Y"] -
                this.subgroup[this.subIndex].Vertice[2]["@Y"])) /
            2;
        }
        //移动
        if (type == 1) {
          if (this.selectUnit.length > 1) {
            this.selectUnit.forEach((item) => {
              let _index = item.index;
              let divsTop =
                item.divT - (clienty - event.clientY) / this.multiple;
              let divsLeft =
                item.divL - (clientx - event.clientX) / this.multiple;
              switch (this.subgroup[_index].Type) {
                case "Diamond":
                  spaceHeiht =
                    (this.subgroup[_index].Height * 4 -
                      (this.subgroup[_index].Vertice[2]["@Y"] -
                        this.subgroup[_index].Vertice[0]["@Y"])) /
                    2;
                  spaceWidth =
                    (this.subgroup[_index].Width * 4 -
                      (this.subgroup[_index].Vertice[1]["@X"] -
                        this.subgroup[_index].Vertice[3]["@X"])) /
                    2;
                  this.subgroup[_index].StartY = (divsTop * 25.4) / 96;
                  this.subgroup[_index].StartX = (divsLeft * 25.4) / 96;
                  this.subgroup[_index].Vertice[0]["@X"] =
                    this.subgroup[_index].StartX * 4 +
                    (this.subgroup[_index].Width / 2) * 4;
                  this.subgroup[_index].Vertice[0]["@Y"] =
                    this.subgroup[_index].StartY * 4 + spaceHeiht;

                  this.subgroup[_index].Vertice[1]["@X"] =
                    this.subgroup[_index].StartX * 4 +
                    this.subgroup[_index].Width * 4 -
                    spaceWidth;
                  this.subgroup[_index].Vertice[1]["@Y"] =
                    this.subgroup[_index].StartY * 4 +
                    (this.subgroup[_index].Height / 2) * 4;

                  this.subgroup[_index].Vertice[2]["@X"] =
                    this.subgroup[_index].StartX * 4 +
                    (this.subgroup[_index].Width / 2) * 4;
                  this.subgroup[_index].Vertice[2]["@Y"] =
                    this.subgroup[_index].StartY * 4 +
                    this.subgroup[_index].Height * 4 -
                    spaceHeiht;

                  this.subgroup[_index].Vertice[3]["@X"] =
                    this.subgroup[_index].StartX * 4 + spaceWidth;
                  this.subgroup[_index].Vertice[3]["@Y"] =
                    this.subgroup[_index].StartY * 4 +
                    (this.subgroup[_index].Height / 2) * 4;
                  break;
                case "Triangle":
                  spaceHeiht =
                    (this.subgroup[_index].Height * 4 -
                      (this.subgroup[_index].Vertice[1]["@Y"] -
                        this.subgroup[_index].Vertice[2]["@Y"])) /
                    2;
                  this.subgroup[_index].StartY = (divsTop * 25.4) / 96;
                  this.subgroup[_index].StartX = (divsLeft * 25.4) / 96;
                  this.subgroup[_index].Vertice[2]["@X"] =
                    this.subgroup[_index].StartX * 4 +
                    (this.subgroup[_index].Width / 2) * 4;
                  this.subgroup[_index].Vertice[2]["@Y"] =
                    this.subgroup[_index].StartY * 4 + spaceHeiht;

                  this.subgroup[_index].Vertice[0]["@X"] =
                    this.subgroup[_index].StartX * 4 +
                    this.subgroup[_index].PenWidth * 2;
                  this.subgroup[_index].Vertice[0]["@Y"] =
                    this.subgroup[_index].StartY * 4 +
                    this.subgroup[_index].Height * 4 -
                    spaceHeiht;

                  this.subgroup[_index].Vertice[1]["@X"] =
                    this.subgroup[_index].StartX * 4 +
                    this.subgroup[_index].Width * 4 -
                    this.subgroup[_index].PenWidth * 2;
                  this.subgroup[_index].Vertice[1]["@Y"] =
                    this.subgroup[_index].StartY * 4 +
                    this.subgroup[_index].Height * 4 -
                    spaceHeiht;
                  break;
                case "Line":
                  if (
                    this.subgroup[_index].EndX < this.subgroup[_index].StartX
                  ) {
                    this.subgroup[_index].EndX = (divsLeft * 25.4) / 96;
                    this.subgroup[_index].StartX =
                      (divsLeft * 25.4) / 96 + this.subgroup[_index].Width;
                  } else {
                    this.subgroup[_index].StartX = (divsLeft * 25.4) / 96;
                    this.subgroup[_index].EndX =
                      (divsLeft * 25.4) / 96 + this.subgroup[_index].Width;
                  }
                  if (
                    this.subgroup[_index].EndY < this.subgroup[_index].StartY
                  ) {
                    this.subgroup[_index].EndY = (divsTop * 25.4) / 96;
                    this.subgroup[_index].StartY =
                      (divsTop * 25.4) / 96 + this.subgroup[_index].Height;
                  } else {
                    this.subgroup[_index].StartY = (divsTop * 25.4) / 96;
                    this.subgroup[_index].EndY =
                      (divsTop * 25.4) / 96 + this.subgroup[_index].Height;
                  }
                  break;
                default:
                  this.subgroup[_index].StartY = (divsTop * 25.4) / 96;
                  this.subgroup[_index].StartX = (divsLeft * 25.4) / 96;
                  break;
              }
            });
          } else {
            let divsTop = divT - (clienty - event.clientY) / this.multiple;
            let divsLeft = divL - (clientx - event.clientX) / this.multiple;
            switch (this.subgroup[this.subIndex].Type) {
              case "Diamond":
                this.subgroup[this.subIndex].StartY = (divsTop * 25.4) / 96;
                this.subgroup[this.subIndex].StartX = (divsLeft * 25.4) / 96;
                this.subgroup[this.subIndex].Vertice[0]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width / 2) * 4;
                this.subgroup[this.subIndex].Vertice[0]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 + spaceHeiht;

                this.subgroup[this.subIndex].Vertice[1]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  this.subgroup[this.subIndex].Width * 4 -
                  spaceWidth;
                this.subgroup[this.subIndex].Vertice[1]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  (this.subgroup[this.subIndex].Height / 2) * 4;

                this.subgroup[this.subIndex].Vertice[2]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width / 2) * 4;
                this.subgroup[this.subIndex].Vertice[2]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  this.subgroup[this.subIndex].Height * 4 -
                  spaceHeiht;

                this.subgroup[this.subIndex].Vertice[3]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 + spaceWidth;
                this.subgroup[this.subIndex].Vertice[3]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  (this.subgroup[this.subIndex].Height / 2) * 4;
                break;
              case "Triangle":
                this.subgroup[this.subIndex].StartY = (divsTop * 25.4) / 96;
                this.subgroup[this.subIndex].StartX = (divsLeft * 25.4) / 96;
                this.subgroup[this.subIndex].Vertice[2]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width / 2) * 4;
                this.subgroup[this.subIndex].Vertice[2]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 + spaceHeiht;

                this.subgroup[this.subIndex].Vertice[0]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  this.subgroup[this.subIndex].PenWidth * 2;
                this.subgroup[this.subIndex].Vertice[0]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  this.subgroup[this.subIndex].Height * 4 -
                  spaceHeiht;

                this.subgroup[this.subIndex].Vertice[1]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  this.subgroup[this.subIndex].Width * 4 -
                  this.subgroup[this.subIndex].PenWidth * 2;
                this.subgroup[this.subIndex].Vertice[1]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  this.subgroup[this.subIndex].Height * 4 -
                  spaceHeiht;
                break;
              case "Line":
                if (
                  this.subgroup[this.subIndex].EndX <
                  this.subgroup[this.subIndex].StartX
                ) {
                  this.subgroup[this.subIndex].EndX = (divsLeft * 25.4) / 96;
                  this.subgroup[this.subIndex].StartX =
                    (divsLeft * 25.4) / 96 + this.subgroup[this.subIndex].Width;
                } else {
                  this.subgroup[this.subIndex].StartX = (divsLeft * 25.4) / 96;
                  this.subgroup[this.subIndex].EndX =
                    (divsLeft * 25.4) / 96 + this.subgroup[this.subIndex].Width;
                }
                if (
                  this.subgroup[this.subIndex].EndY <
                  this.subgroup[this.subIndex].StartY
                ) {
                  this.subgroup[this.subIndex].EndY = (divsTop * 25.4) / 96;
                  this.subgroup[this.subIndex].StartY =
                    (divsTop * 25.4) / 96 + this.subgroup[this.subIndex].Height;
                } else {
                  this.subgroup[this.subIndex].StartY = (divsTop * 25.4) / 96;
                  this.subgroup[this.subIndex].EndY =
                    (divsTop * 25.4) / 96 + this.subgroup[this.subIndex].Height;
                }
                break;
              default:
                this.subgroup[this.subIndex].StartY = (divsTop * 25.4) / 96;
                this.subgroup[this.subIndex].StartX = (divsLeft * 25.4) / 96;
                break;
            }
            this.referenceObject(this.subIndex);
          }
        } else if (type == 2) {
          //等比例拉伸
          switch (AngleRound) {
            case 90:
              if (
                indexType == "GeneralBarcode" ||
                indexType == "WinText" ||
                indexType == "TimeText" ||
                indexType == "Image"
              ) {
                var width = divW - (clienty - event.clientY) / this.multiple;
              } else {
                var width = divW - (clientx - event.clientX) / this.multiple;
              }
              break;
            case 180:
              if (
                indexType == "GeneralBarcode" ||
                indexType == "WinText" ||
                indexType == "TimeText" ||
                indexType == "Image"
              ) {
                var width = divW - (event.clientX - clientx) / this.multiple;
              } else {
                var width = divW - (clientx - event.clientX) / this.multiple;
              }
              break;
            case 270:
              if (
                indexType == "GeneralBarcode" ||
                indexType == "WinText" ||
                indexType == "TimeText" ||
                indexType == "Image"
              ) {
                var width = divW - (event.clientY - clienty) / this.multiple;
              } else {
                var width = divW - (clientx - event.clientX) / this.multiple;
              }
              break;
            default:
              var width = divW - (clientx - event.clientX) / this.multiple;
              break;
          }
          var height = Math.floor(divH / (divW / parseInt(width)));

          if (
            indexType != "Line" &&
            indexType != "LineHorizontal" &&
            indexType != "LineVertical"
          ) {
            switch (indexType) {
              case "GeneralBarcode":
                this.subgroup[this.subIndex].Width = (width * 25.4) / 96;
                break;
              case "WinText":
              case "TimeText":
                this.subgroup[this.subIndex].Font["@FontSize"] = Number(
                  ((width / divW) * FontSize).toFixed(1)
                );
                break;
              case "Ellipse":
                if ((width * 25.4) / 96 > 5 && (height * 25.4) / 96 > 5) {
                  this.subgroup[this.subIndex].Width = (width * 25.4) / 96;
                  this.subgroup[this.subIndex].Height = (height * 25.4) / 96;
                }
                break;
              case "Diamond":
              case "Triangle":
                if ((width * 25.4) / 96 > 4.99 && (height * 25.4) / 96 > 3.99) {
                  this.subgroup[this.subIndex].Width = (width * 25.4) / 96;
                  this.subgroup[this.subIndex].Height = (height * 25.4) / 96;
                }
                break;
              default:
                this.subgroup[this.subIndex].Width = (width * 25.4) / 96;
                this.subgroup[this.subIndex].Height = (height * 25.4) / 96;
                break;
            }
          }
          //计算菱形三角形缩放后点的位置
          if ((width * 25.4) / 96 > 4.99 && (height * 25.4) / 96 > 3.99) {
            if (this.subgroup[this.subIndex].Type == "Diamond") {
              this.subgroup[this.subIndex].Vertice[0]["@X"] =
                this.subgroup[this.subIndex].StartX * 4 +
                (this.subgroup[this.subIndex].Width / 2) * 4;
              this.subgroup[this.subIndex].Vertice[0]["@Y"] =
                this.subgroup[this.subIndex].StartY * 4 + spaceHeiht;

              this.subgroup[this.subIndex].Vertice[1]["@X"] =
                this.subgroup[this.subIndex].StartX * 4 +
                this.subgroup[this.subIndex].Width * 4 -
                spaceWidth;
              this.subgroup[this.subIndex].Vertice[1]["@Y"] =
                this.subgroup[this.subIndex].StartY * 4 +
                (this.subgroup[this.subIndex].Height / 2) * 4;

              this.subgroup[this.subIndex].Vertice[2]["@X"] =
                this.subgroup[this.subIndex].StartX * 4 +
                (this.subgroup[this.subIndex].Width / 2) * 4;
              this.subgroup[this.subIndex].Vertice[2]["@Y"] =
                this.subgroup[this.subIndex].StartY * 4 +
                this.subgroup[this.subIndex].Height * 4 -
                spaceHeiht;

              this.subgroup[this.subIndex].Vertice[3]["@X"] =
                this.subgroup[this.subIndex].StartX * 4 + spaceWidth;
              this.subgroup[this.subIndex].Vertice[3]["@Y"] =
                this.subgroup[this.subIndex].StartY * 4 +
                (this.subgroup[this.subIndex].Height / 2) * 4;
            } else if (this.subgroup[this.subIndex].Type == "Triangle") {
              //三角形变形 0 1 左右边顶点 2顶点
              this.subgroup[this.subIndex].Vertice[2]["@X"] =
                this.subgroup[this.subIndex].StartX * 4 +
                (this.subgroup[this.subIndex].Width / 2) * 4;
              this.subgroup[this.subIndex].Vertice[2]["@Y"] =
                this.subgroup[this.subIndex].StartY * 4 + spaceHeiht;

              this.subgroup[this.subIndex].Vertice[0]["@X"] =
                this.subgroup[this.subIndex].StartX * 4 +
                this.subgroup[this.subIndex].PenWidth * 2;
              this.subgroup[this.subIndex].Vertice[0]["@Y"] =
                this.subgroup[this.subIndex].StartY * 4 +
                this.subgroup[this.subIndex].Height * 4 -
                spaceHeiht;

              this.subgroup[this.subIndex].Vertice[1]["@X"] =
                this.subgroup[this.subIndex].StartX * 4 +
                this.subgroup[this.subIndex].Width * 4 -
                this.subgroup[this.subIndex].PenWidth * 2;
              this.subgroup[this.subIndex].Vertice[1]["@Y"] =
                this.subgroup[this.subIndex].StartY * 4 +
                this.subgroup[this.subIndex].Height * 4 -
                spaceHeiht;
            }
          }
        } else if (type == 3) {
          //左拉伸
          if (
            indexType != "Line" &&
            indexType != "LineHorizontal" &&
            indexType != "LineVertical"
          ) {
            switch (indexType) {
              case "Diamond":
              case "Triangle":
                if (
                  ((divW + (clientx - event.clientX) / this.multiple) * 25.4) /
                    96 >
                  5
                ) {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (clientx - event.clientX) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartX =
                    ((divL - (clientx - event.clientX) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "Ellipse":
                if (
                  ((divW + (clientx - event.clientX) / this.multiple) * 25.4) /
                    96 >
                  3
                ) {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (clientx - event.clientX) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartX =
                    ((divL - (clientx - event.clientX) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "GeneralBarcode":
              case "Image":
                if (AngleRound == 90) {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (clienty - event.clientY) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartY =
                    ((divT - (clienty - event.clientY) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 180) {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartX =
                    ((divL + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 270) {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartY =
                    ((divT + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                } else {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (clientx - event.clientX) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartX =
                    ((divL - (clientx - event.clientX) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              default:
                this.subgroup[this.subIndex].Width =
                  ((divW + (clientx - event.clientX) / this.multiple) * 25.4) /
                  96;
                this.subgroup[this.subIndex].StartX =
                  ((divL - (clientx - event.clientX) / this.multiple) * 25.4) /
                  96;
                break;
            }
          } else {
            let width =
              ((divW + (clientx - event.clientX) / this.multiple) * 25.4) / 96;
            if (width > 1) {
              this.subgroup[this.subIndex].Width = width;
              this.subgroup[this.subIndex].StartX =
                ((divL - (clientx - event.clientX) / this.multiple) * 25.4) /
                96;
            }
          }
          if (
            ((divW + (clientx - event.clientX) / this.multiple) * 25.4) / 96 >
            5
          ) {
            switch (indexType) {
              case "Diamond":
                this.subgroup[this.subIndex].Vertice[3]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 + spaceWidth;
                this.subgroup[this.subIndex].Vertice[0]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width * 4) / 2;
                this.subgroup[this.subIndex].Vertice[2]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width * 4) / 2;
                break;
              case "Triangle":
                this.subgroup[this.subIndex].Vertice[0]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4;
                this.subgroup[this.subIndex].Vertice[2]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width * 4) / 2;
                break;
            }
          }
        } else if (type == 4) {
          //顶部拉伸
          if (
            indexType != "Line" &&
            indexType != "LineHorizontal" &&
            indexType != "LineVertical"
          ) {
            switch (indexType) {
              case "Diamond":
              case "Triangle":
                if (
                  ((divH + (clienty - event.clientY) / this.multiple) * 25.4) /
                    96 >
                  5
                ) {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (clienty - event.clientY) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartY =
                    ((divT - (clienty - event.clientY) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "Ellipse":
                if (
                  ((divH + (clienty - event.clientY) / this.multiple) * 25.4) /
                    96 >
                  3
                ) {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (clienty - event.clientY) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartY =
                    ((divT - (clienty - event.clientY) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "GeneralBarcode":
              case "Image":
                if (AngleRound == 90) {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartX =
                    ((divL + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 180) {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartY =
                    ((divT + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 270) {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (clientx - event.clientX) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartX =
                    ((divL - (clientx - event.clientX) / this.multiple) *
                      25.4) /
                    96;
                } else {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (clienty - event.clientY) / this.multiple) *
                      25.4) /
                    96;
                  this.subgroup[this.subIndex].StartY =
                    ((divT - (clienty - event.clientY) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              default:
                this.subgroup[this.subIndex].Height =
                  ((divH + (clienty - event.clientY) / this.multiple) * 25.4) /
                  96;
                this.subgroup[this.subIndex].StartY =
                  ((divT - (clienty - event.clientY) / this.multiple) * 25.4) /
                  96;
                break;
            }
          } else {
            let height =
              ((divH + (clienty - event.clientY) / this.multiple) * 25.4) / 96;
            if (height > 1) {
              this.subgroup[this.subIndex].Height = height;
              this.subgroup[this.subIndex].StartY =
                ((divT - (clienty - event.clientY) / this.multiple) * 25.4) /
                96;
            }
          }
          if (
            ((divH + (clienty - event.clientY) / this.multiple) * 25.4) / 96 >
            5
          ) {
            switch (indexType) {
              case "Diamond":
                this.subgroup[this.subIndex].Vertice[0]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 + spaceHeiht;
                this.subgroup[this.subIndex].Vertice[1]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  (this.subgroup[this.subIndex].Height * 4) / 2;
                this.subgroup[this.subIndex].Vertice[3]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  (this.subgroup[this.subIndex].Height * 4) / 2;
                break;
              case "Triangle":
                this.subgroup[this.subIndex].Vertice[2]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 + spaceHeiht;
                break;
            }
          }
        } else if (type == 5) {
          //右拉伸
          if (
            indexType != "Line" &&
            indexType != "LineHorizontal" &&
            indexType != "LineVertical"
          ) {
            switch (indexType) {
              case "Diamond":
              case "Triangle":
                if (
                  ((divW + (event.clientX - clientx) / this.multiple) * 25.4) /
                    96 >
                  5
                ) {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "Ellipse":
                if (
                  ((divW + (event.clientX - clientx) / this.multiple) * 25.4) /
                    96 >
                  3
                ) {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "GeneralBarcode":
              case "Image":
                if (AngleRound == 90) {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 180) {
                  this.subgroup[this.subIndex].Width =
                    ((divW - (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 270) {
                  this.subgroup[this.subIndex].Width =
                    ((divW - (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                } else {
                  this.subgroup[this.subIndex].Width =
                    ((divW + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "Table":
                let newWidth;
                if (AngleRound == 90) {
                  newWidth =
                    ((divW + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 180) {
                  newWidth =
                    ((divW - (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 270) {
                  newWidth =
                    ((divW - (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                } else {
                  newWidth =
                    ((divW + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                }
                if (newWidth < 6) return;
                let oldWidth = this.subgroup[this.subIndex].Width;
                let list = this.subgroup[this.subIndex].DataSourceList;
                let Cloumn = this.subgroup[this.subIndex].Cloumn;
                //新旧宽度的差值  均分给每一列
                let diff = (newWidth - oldWidth) / Cloumn;

                let DicLineLocW = this.subgroup[this.subIndex].DicLineLocW;
                for (let i = 0; i < DicLineLocW.length; i++) {
                  DicLineLocW[i] = DicLineLocW[i] + diff * (i + 1);
                }

                list.map((data) => {
                  if (data.Key[1] > 0) {
                    data.StartX = data.StartX + diff * data.Key[1];
                    if (data.hasOwnProperty("children")) {
                      let lastKey = data.children[data.children.length - 1];
                      data.Width =
                        lastKey[1] - data.Key[1] > 0
                          ? data.Width + diff * (lastKey[1] - data.Key[1] + 1)
                          : data.Width + diff;
                    } else {
                      data.Width = data.Width + diff;
                    }
                  } else {
                    if (data.hasOwnProperty("children")) {
                      let lastKey = data.children[data.children.length - 1];
                      data.Width =
                        lastKey[1] - data.Key[1] > 0
                          ? data.Width + diff * (lastKey[1] - data.Key[1] + 1)
                          : data.Width + diff;
                    } else {
                      data.Width = data.Width + diff;
                    }
                  }
                });
                this.subgroup[this.subIndex].Width = newWidth;
                break;
              default:
                this.subgroup[this.subIndex].Width =
                  ((divW + (event.clientX - clientx) / this.multiple) * 25.4) /
                  96;
                break;
            }
          } else {
            let width =
              ((divW + (event.clientX - clientx) / this.multiple) * 25.4) / 96;
            if (width > 1) {
              this.subgroup[this.subIndex].Width = width;
            }
          }
          if (
            ((divW + (event.clientX - clientx) / this.multiple) * 25.4) / 96 >
            5
          ) {
            switch (indexType) {
              case "Diamond":
                this.subgroup[this.subIndex].Vertice[1]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  this.subgroup[this.subIndex].Width * 4 -
                  spaceWidth;
                this.subgroup[this.subIndex].Vertice[0]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width * 4) / 2;
                this.subgroup[this.subIndex].Vertice[2]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width * 4) / 2;
                break;
              case "Triangle":
                this.subgroup[this.subIndex].Vertice[1]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  this.subgroup[this.subIndex].Width * 4;
                this.subgroup[this.subIndex].Vertice[2]["@X"] =
                  this.subgroup[this.subIndex].StartX * 4 +
                  (this.subgroup[this.subIndex].Width * 4) / 2;
                break;
            }
          }
        } else if (type == 6) {
          //底部拉伸
          if (
            indexType != "Line" &&
            indexType != "LineHorizontal" &&
            indexType != "LineVertical"
          ) {
            switch (indexType) {
              case "Diamond":
              case "Triangle":
                if (
                  ((divH + (event.clientY - clienty) / this.multiple) * 25.4) /
                    96 >
                  5
                ) {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "Ellipse":
                if (
                  ((divH + (event.clientY - clienty) / this.multiple) * 25.4) /
                    96 >
                  3
                ) {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "GeneralBarcode":
              case "Image":
                if (AngleRound == 90) {
                  this.subgroup[this.subIndex].Height =
                    ((divH - (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 180) {
                  this.subgroup[this.subIndex].Height =
                    ((divH - (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 270) {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                } else {
                  this.subgroup[this.subIndex].Height =
                    ((divH + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                }
                break;
              case "Table":
                let newHeight;
                if (AngleRound == 90) {
                  newHeight =
                    ((divH - (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 180) {
                  newHeight =
                    ((divH - (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                } else if (AngleRound == 270) {
                  newHeight =
                    ((divH + (event.clientX - clientx) / this.multiple) *
                      25.4) /
                    96;
                } else {
                  newHeight =
                    ((divH + (event.clientY - clienty) / this.multiple) *
                      25.4) /
                    96;
                }
                if (newHeight < 6) return;
                let oldHeight = this.subgroup[this.subIndex].Height;
                let list = this.subgroup[this.subIndex].DataSourceList;
                let Row = this.subgroup[this.subIndex].Row;
                //新旧高度的差值  均分给每一行
                let diff = (newHeight - oldHeight) / Row;

                let DicLineLocH = this.subgroup[this.subIndex].DicLineLocH;
                for (let i = 0; i < DicLineLocH.length; i++) {
                  DicLineLocH[i] = DicLineLocH[i] + diff * (i + 1);
                }
                list.map((data) => {
                  if (data.Key[0] > 0) {
                    data.StartY = data.StartY + diff * data.Key[0];
                    if (data.hasOwnProperty("children")) {
                      let lastKey = data.children[data.children.length - 1];
                      data.Height =
                        lastKey[0] - data.Key[0] > 0
                          ? data.Height + diff * (lastKey[0] - data.Key[0] + 1)
                          : data.Height + diff;
                    } else {
                      data.Height = data.Height + diff;
                    }
                  } else {
                    if (data.hasOwnProperty("children")) {
                      let lastKey = data.children[data.children.length - 1];
                      data.Height =
                        lastKey[0] - data.Key[0] > 0
                          ? data.Height + diff * (lastKey[0] - data.Key[0] + 1)
                          : data.Height + diff;
                    } else {
                      data.Height = data.Height + diff;
                    }
                  }
                });
                this.subgroup[this.subIndex].Height = newHeight;
                break;
              default:
                this.subgroup[this.subIndex].Height =
                  ((divH + (event.clientY - clienty) / this.multiple) * 25.4) /
                  96;
                break;
            }
          } else {
            height =
              ((divH + (event.clientY - clienty) / this.multiple) * 25.4) / 96;
            if (height > 1) {
              this.subgroup[this.subIndex].Height = height;
            }
          }
          if (
            ((divH + (event.clientY - clienty) / this.multiple) * 25.4) / 96 >
            5
          ) {
            switch (indexType) {
              case "Diamond":
                this.subgroup[this.subIndex].Vertice[2]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  this.subgroup[this.subIndex].Height * 4 -
                  spaceHeiht;
                this.subgroup[this.subIndex].Vertice[1]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  (this.subgroup[this.subIndex].Height * 4) / 2;
                this.subgroup[this.subIndex].Vertice[3]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  (this.subgroup[this.subIndex].Height * 4) / 2;
                break;
              case "Triangle":
                this.subgroup[this.subIndex].Vertice[1]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  this.subgroup[this.subIndex].Height * 4 -
                  spaceHeiht;
                this.subgroup[this.subIndex].Vertice[0]["@Y"] =
                  this.subgroup[this.subIndex].StartY * 4 +
                  this.subgroup[this.subIndex].Height * 4 -
                  spaceHeiht;
                break;
            }
          }
        } else if (type == 7) {
          //斜线拉伸
          this.subgroup[this.subIndex].EndX =
            (((event.clientX -
              this.$refs.imageDom.getBoundingClientRect().left) /
              this.multiple) *
              25.4) /
            96;
          this.subgroup[this.subIndex].EndY =
            (((event.clientY -
              this.$refs.imageDom.getBoundingClientRect().top) /
              this.multiple) *
              25.4) /
            96;
          this.subgroup[this.subIndex].Width = Math.abs(
            this.subgroup[this.subIndex].EndX -
              this.subgroup[this.subIndex].StartX
          );
          this.subgroup[this.subIndex].Height = Math.abs(
            this.subgroup[this.subIndex].EndY -
              this.subgroup[this.subIndex].StartY
          );
        }
      };
      document.onmouseup = (event) => {
        if (type == 2 || type == 3 || type == 5) {
          if (event.clientX != clientx) {
            if (this.subgroup[this.subIndex].Type == "QRCode") {
              if (this.isCreate) {
                let zoom = Math.round(
                  this.subgroup[this.subIndex].Width / this.subgroup[this.subIndex].QRCodeWidth
                );
                this.subgroup[this.subIndex].Zoom = zoom < 2 ? 2 : zoom
                this.isCreate = false;
                this.handleCreated("2", this.subIndex);
              }
            } else if (this.subgroup[this.subIndex].Type == "GeneralBarcode") {
              if (this.isCreate) {
                //zoomWidth 为条形码1倍的宽度
                let zoom = Math.round(
                  this.subgroup[this.subIndex].Width /
                    this.subgroup[this.subIndex].zoomWidth
                );
                this.subgroup[this.subIndex].Zoom = zoom < 2 ? 2 : zoom
                this.isCreate = false;
                this.handleCreated("1", this.subIndex);
              }
            }
          }
        }
        if (this.unitMove) {
          this.handleRecord();
          this.unitMove = false;
        }
        this.unitHandle = false;
        document.onmousemove = null;
        document.onmouseup = null;
        //移除对齐参考线
        this.removeLine();
        this.referenceArr = [];
        setTimeout(()=>{
          this.tableMultCellMove = false
        },100)
      };
    },
    //点击属性区域取消多选
    cancelMoreSelect() {
      if (this.isMoreSelect) {
        this.selectUnit = [];
        let data = this.subgroup[this.subIndex];
        data.index = this.subIndex;
        this.selectUnit.push(data);
        this.isMoreSelect = false;
      }
    },
    //设置全选/多选样式
    returnClass(index) {
      if (this.selectUnit.length == 1 && this.subIndex > -1) {
        return;
      }
      let classname = "";
      //多选时，选中的元件显示蓝色轮廓线
      for (let i = 0; i < this.selectUnit.length; i++) {
        if (this.selectUnit[i].index == index) {
          classname += " act";
        }
      }
      //元件对齐时相关元件显示淡橙色轮廓线
      for (let j = 0; j < this.referenceArr.length; j++) {
        if (this.referenceArr[j].id == this.subgroup[index].Id) {
          classname += " refer";
        }
      }
      return classname;
    },
    //全选
    handleAllGroup() {
      this.selectUnit = [];
      for (let i = 0; i < this.subgroup.length; i++) {
        let data = JSON.parse(JSON.stringify(this.subgroup[i]));
        data.index = i;
        this.selectUnit.push(data);
      }
      // console.log(this.selectUnit)
    },
    //选择多个组件 框选区域
    handleMoreSelect(evt) {
      if (evt.button != 0) {
        return;
      }
      if (this.unitHandle) {
        return false;
      }
      if (!this.closeAttrPanel) {
        return;
      }

      this.subIndex = -1;
      this.printSelectFlag = true;
      let event = evt;
      this.selectUnit = [];
      if (!event.ctrlKey) {
        this.isMoreSelect = false;
      }
      document.onmousemove = (e) => {
        this.isMoreSelect = true;
        //计算框选区域的宽高
        let width = Math.abs(e.clientX - event.clientX);
        let height = Math.abs(e.clientY - event.clientY);

        //绘制框选区
        this.printSelect = {
          width: width,
          height: height,
          x: event.clientX - this.$refs.printLeft.offsetWidth,
          y:
            event.clientY -
            this.$refs.printTop.offsetHeight -
            this.$refs.printTool.offsetHeight,
        };
        //计算当移动点的X和Y小于起始点时的值
        let x = e.clientX - this.$refs.printLeft.offsetWidth;
        let y =
          e.clientY -
          this.$refs.printTop.offsetHeight -
          this.$refs.printTool.offsetHeight;
        //移动点X大于起始点X，移动点Y小于起始点Y
        if (e.clientX - event.clientX > 0) {
          if (e.clientY - event.clientY < 0) {
            this.printSelect.y = y;
          }
        } else {
          //移动点X小于起始点X，移动点Y小于起始点Y
          if (e.clientY - event.clientY < 0) {
            this.printSelect.x = x;
            this.printSelect.y = y;
          } else {
            //移动点X小于起始点X，移动点Y大于起始点Y
            this.printSelect.x = x;
          }
        }
      };
      document.onmouseup = (e) => {
        this.printSelectFlag = false;
        document.onmousemove = null;
        document.onmousemove = null;
        document.onmouseup = null;
        this.coordinate();
      };
    },
    //计算坐标，是否组件被选中
    coordinate() {
      let design = document.querySelector("#design").getBoundingClientRect();
      //计算multiple == 1时多选框的起始点和宽高
      let left =
        ((this.printSelect.x -
          (design.left - this.$refs.printLeft.offsetWidth)) *
          25.4) /
        96 /
        this.multiple;
      let top =
        ((this.printSelect.y -
          (design.top -
            this.$refs.printTop.offsetHeight -
            this.$refs.printTool.offsetHeight)) *
          25.4) /
        96 /
        this.multiple;
      let width = (this.printSelect.width * 25.4) / 96 / this.multiple;
      let height = (this.printSelect.height * 25.4) / 96 / this.multiple;

      //遍历组件，判断是否在区域内
      for (let i = 0; i < this.subgroup.length; i++) {
        let item = this.subgroup[i];
        let itemLeft = item.StartX;
        let itemTop = item.StartY;
        let itemWidth = "";
        let itemHeight = "";
        let Lx = "";
        let Ly = "";
        switch (item.Type) {
          case "Line":
          case "LineVertical":
          case "LineHorizontal":
            itemWidth = item.Width;
            itemHeight = item.Height;
            //求组件中心点与多选框中心点水平方向的距离
            Lx = Math.abs(left + width / 2 - (itemLeft + itemWidth / 2));
            //求组件中心点与多选框中心点垂直方向的距离
            Ly = Math.abs(top + height / 2 - (itemTop + itemHeight / 2));
            if (
              Lx < (width + itemWidth) / 2 &&
              Ly < (height + itemHeight) / 2
            ) {
              let data = JSON.parse(JSON.stringify(item));
              data.index = i;
              this.selectUnit.push(data);
            }
            break;
          default:
            itemWidth = item.Width;
            itemHeight = item.Height;
            //求组件中心点与多选框中心点水平方向的距离
            Lx = Math.abs(left + width / 2 - (itemLeft + itemWidth / 2));
            //求组件中心点与多选框中心点垂直方向的距离
            Ly = Math.abs(top + height / 2 - (itemTop + itemHeight / 2));
            if (
              Lx < (width + itemWidth) / 2 &&
              Ly < (height + itemHeight) / 2
            ) {
              // let data = JSON.parse(JSON.stringify(item));
              let data = item;
              data.index = i;
              this.selectUnit.push(data);
            }
            break;
        }
      }
      this.printSelect = {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
      };
    },
  },
};
export default deformation;
