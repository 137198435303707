<template>
  <div>
    <img
      id="barcode"
      ref="barcode"
      style="position: fixed; left: -200%; top: -200%; z-index: -1"
    />
    <div
      v-if="newgroup.length == 1"
      style="width: 100%; height: 100%; margin: 0; padding: 0; overflow: hidden;transform-origin: 0 0;"
      ref="imageDom0"
      :style="{
        position: 'absolute',
        width:
          boxInfo.width * boxInfo.cloumn +
          boxInfo.paddingLeft +
          boxInfo.paddingRight +
          (boxInfo.cloumn - 1) * boxInfo.ColumnSpacing +
          'mm',
        height:
          boxInfo.height * boxInfo.row +
          boxInfo.paddingTop +
          boxInfo.paddingBottom +
          (boxInfo.row - 1) * boxInfo.RowSpacing -
          0.2 +
          'mm',
        background: '#fff',
        transform: explorer == 'Safari' ? 'scale(0.92)' : 'scale(1)'
      }"
    >
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          justify-content: flex-start;
          margin: 0px;
          padding: 0px;
        "
        :style="{
          paddingLeft: boxInfo.paddingLeft + 'mm',
          paddingRight: boxInfo.paddingRight + 'mm',
          paddingTop: boxInfo.paddingTop + 'mm',
          paddingBottom: boxInfo.paddingBottom + 'mm',
        }"
      >
        <template
          v-for="(item0, index0) in boxInfo.count"
          :key="index0"
        >
          <div
            style="
              position: relative;
              overflow: hidden;
              margin: 0px;
              padding: 0px;
            "
            id="design"
            v-for="(pArr, _index) in newgroup"
            :key="_index"
            :style="{
              width: template.width + 'mm',
              height: template.height + 'mm',
              background: showCanvas ? '#fff' : 'none',
              marginBottom:
                index0 < boxInfo.cloumn * boxInfo.row - boxInfo.cloumn
                  ? boxInfo.RowSpacing + 'mm'
                  : '0',
              marginRight:
                (index0 + 1) % boxInfo.cloumn != 0
                  ? boxInfo.ColumnSpacing + 'mm'
                  : '0',
            }"
          >
            <div>
              <!-- 背景图片 -->
              <img
                v-if="template.Background && isShowBackgound"
                :style="{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: template.width + 'mm',
                  height: template.height + 'mm',
                }"
                :src="imgHeader + template.Background"
                alt=""
              />
              <template
                v-for="(item, index) in pArr.group"
                :key="index"
              >
                <!-- 水平线 -->
                <div
                  v-if="item.Type == 'LineHorizontal'"
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.PenWidth / conversion + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <line
                      :x1="0"
                      :y1="item.PenWidth / conversion / 2 + 'mm'"
                      :x2="item.Width + 'mm'"
                      :y2="item.PenWidth / conversion / 2 + 'mm'"
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 垂直线 -->
                <div
                  v-if="item.Type == 'LineVertical'"
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.PenWidth / conversion + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <line
                      :x1="item.PenWidth / conversion / 2 + 'mm'"
                      :y1="0"
                      :x2="item.PenWidth / conversion / 2 + 'mm'"
                      :y2="item.Height + 'mm'"
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 斜线 -->
                <div
                  v-if="item.Type == 'Line'"
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left:
                      item.EndX < item.StartX
                        ? item.EndX + 'mm'
                        : item.StartX + 'mm',
                    top:
                      item.EndY < item.StartY
                        ? item.EndY + 'mm'
                        : item.StartY + 'mm',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <line
                      :x1="
                        item.EndX < item.StartX
                          ? item.Width - item.PenWidth / conversion / 2 + 'mm'
                          : item.PenWidth / conversion / 2 + 'mm'
                      "
                      :y1="
                        item.EndY < item.StartY
                          ? item.Height - item.PenWidth / conversion / 2 + 'mm'
                          : item.PenWidth / conversion / 2 + 'mm'
                      "
                      :x2="
                        item.EndX < item.StartX
                          ? item.PenWidth / conversion / 2 + 'mm'
                          : item.Width - item.PenWidth / conversion / 2 + 'mm'
                      "
                      :y2="
                        item.EndY < item.StartY
                          ? item.PenWidth / conversion / 2 + 'mm'
                          : item.Height - item.PenWidth / conversion / 2 + 'mm'
                      "
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 图片 -->
                <div
                  v-if="item.Type == 'Image'"
                  :ref="'print' + index"
                >
                  <img
                    style="position: absolute"
                    :style="{
                      zIndex: item.zOrder,
                      width: item.Width + 'mm',
                      height: item.Height + 'mm',
                      left: item.StartX + 'mm',
                      top: item.StartY + 'mm',
                      transform: 'rotate(' + item.AngleRound + 'deg)',
                      transformOrigin: '0 0',
                    }"
                    :src="imgHeader + item.OriginalImage"
                  />
                </div>
                <!-- 矩形、圆角矩形、实心矩形 -->
                <div
                  v-if="
                    item.Type == 'Rectangle' ||
                    item.Type == 'RoundRectangle' ||
                    item.Type == 'SolidRectangle'
                  "
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    transform: 'rotate(' + item.AngleRound + 'deg)',
                    transformOrigin: '0 0',
                  }"
                >
                  <!-- 矩形 -->
                  <svg
                    v-if="item.Type == 'Rectangle'"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <rect
                      :x="item.PenWidth / 2 / conversion + 'mm'"
                      :y="item.PenWidth / 2 / conversion + 'mm'"
                      :width="item.Width - item.PenWidth / conversion + 'mm'"
                      :height="item.Height - item.PenWidth / conversion + 'mm'"
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                  <!-- 圆角矩形 -->
                  <svg
                    v-if="item.Type == 'RoundRectangle'"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <rect
                      :x="item.PenWidth / 2 / conversion + 'mm'"
                      :y="item.PenWidth / 2 / conversion + 'mm'"
                      :width="item.Width - item.PenWidth / conversion + 'mm'"
                      :height="item.Height - item.PenWidth / conversion + 'mm'"
                      :rx="item.CornerRadius / conversion + 'mm'"
                      :ry="item.CornerRadius / conversion + 'mm'"
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                  <!-- 实心矩形 -->
                  <div
                    v-if="item.Type == 'SolidRectangle'"
                    :style="{
                      width:
                        'calc(100% - ' +
                        2 * (item.PenWidth / conversion) +
                        'mm)',
                      height:
                        'calc(100% - ' +
                        2 * (item.PenWidth / conversion) +
                        'mm)',
                      background: '#000',
                      borderWidth: item.PenWidth / conversion + 'mm',
                      borderStyle: item.DashStyle == 0 ? 'solid' : 'dashed',
                    }"
                  >
                    <img
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                      "
                      src="../assets/black-bg.png"
                      alt=""
                    />
                  </div>
                </div>
                <!-- 条形码 -->
                <div
                  v-if="item.Type == 'GeneralBarcode'"
                  :ref="'print' + index"
                >
                  <img
                    style="position: absolute"
                    :style="{
                      zIndex: item.zOrder,
                      width: item.Width + 'mm',
                      height: item.Height + 'mm',
                      left: item.StartX + 'mm',
                      top: item.StartY + 'mm',
                      transform: 'rotate(' + item.AngleRound + 'deg)',
                      transformOrigin: '0 0',
                    }"
                    v-if="showCanvas && item.BarcodeImage"
                    :src="imgHeader + item.BarcodeImage"
                  />
                </div>
                <!-- 二维码 -->
                <div
                  v-if="item.Type == 'QRCode'"
                  :ref="'print' + index"
                >
                  <img
                    style="position: absolute"
                    :style="{
                      zIndex: item.zOrder,
                      width: item.Width + 'mm',
                      height: item.Height + 'mm',
                      left: item.StartX + 'mm',
                      top: item.StartY + 'mm',
                      transform: 'rotate(' + item.AngleRound + 'deg)',
                      transformOrigin: '0 0',
                    }"
                    v-if="showCanvas"
                    :src="imgHeader + item.BarcodeImage"
                  />
                </div>
                <!-- 文本时间 -->
                <div
                  v-if="item.Type == 'WinText' || item.Type == 'TimeText'"
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.AutoWrap
                      ? item.BoxWidth + 'mm'
                      : item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    transform: 'rotate(' + item.AngleRound + 'deg)',
                    transformOrigin: '0 0',
                  }"
                >
                  <EditDiv
                    :data="item"
                    :index="index"
                    :pindex="pArr.groupIndex"
                    :multiple="1"
                    v-model:value="item.Data"
                    @getDiv="getDivWidth"
                  >
                  </EditDiv>
                </div>
                <!-- 椭圆 -->
                <div
                  v-if="item.Type == 'Ellipse'"
                  :ref="'print' + index"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    position: 'absolute',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <ellipse
                      :cx="item.Width / 2 + 'mm'"
                      :cy="item.Height / 2 + 'mm'"
                      :rx="
                        (item.Width - (2 * item.PenWidth) / conversion) / 2 +
                        'mm'
                      "
                      :ry="
                        (item.Height - (2 * item.PenWidth) / conversion) / 2 +
                        'mm'
                      "
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 2 + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 菱形-->
                <div
                  v-if="item.Type == 'Diamond'"
                  :ref="'print' + index"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    position: 'absolute',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <polygon
                      :points="
                        (
                          ((item.Vertice[3]['@X'] / conversion -
                            item.StartX +
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[3]['@Y'] / conversion - item.StartY) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[0]['@X'] / conversion - item.StartX) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[0]['@Y'] / conversion -
                            item.StartY +
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[1]['@X'] / conversion -
                            item.StartX -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[1]['@Y'] / conversion - item.StartY) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[2]['@X'] / conversion - item.StartX) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[2]['@Y'] / conversion -
                            item.StartY -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString()
                      "
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 2 + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 三角形 -->
                <div
                  v-if="item.Type == 'Triangle'"
                  :ref="'print' + index"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    position: 'absolute',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <polygon
                      :points="
                        (
                          ((item.Vertice[2]['@X'] / conversion - item.StartX) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[2]['@Y'] / conversion -
                            item.StartY +
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[1]['@X'] / conversion -
                            item.StartX -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[1]['@Y'] / conversion -
                            item.StartY -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[0]['@X'] / conversion -
                            item.StartX +
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[0]['@Y'] / conversion -
                            item.StartY -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString()
                      "
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 2 + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 表格 -->
                <div
                  v-if="item.Type == 'Table'"
                  :ref="'print' + index"
                  class="edit-print-li"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    transform: 'rotate(' + item.AngleRound + 'deg)',
                    transformOrigin: '0 0',
                    position: 'absolute',
                  }"
                >
                  <div style="
                      width: 100%;
                      height: 100%;
                      position: relative;
                      background: #000;
                    ">
                    <!-- 黑色背景层  底色 -->
                    <div style="
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                      ">
                      <img
                        style="width: 100%; height: 100%"
                        src="../assets/black-bg.png"
                        alt=""
                      />
                    </div>
                    <div
                      style="
                        position: absolute;
                        overflow: hidden;
                        background: #fff;
                      "
                      v-for="(cell, c_Index) in item.DataSourceList"
                      :key="c_Index"
                      :style="{
                        width: cell.Width + 'mm',
                        height: cell.Height + 'mm',
                        left: cell.StartX + 'mm',
                        top: cell.StartY + 'mm',
                      }"
                    >
                      <EditDiv
                        style="
                          text-shadow: none;
                          color: #000;
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                        "
                        :style="{
                          color: cell.AntiBlack ? '#fff' : '#000',
                          justifyContent:
                            cell.TextAlign == 1
                              ? 'center'
                              : cell.TextAlign == 2
                              ? 'flex-end'
                              : 'flex-start',
                        }"
                        :data="cell"
                        v-model:value="cell.Data"
                      >
                      </EditDiv>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- 数据库数据/序列化数据、多行多列 -->
    <template v-else>
      <div
        v-for="(item0, index0) in boxInfo.count"
        :key="index0"
        style="margin: 0; padding: 0;transform-origin: 0 0;"
        :style="{
          position: explorer != 'Firefox' ? 'relative' : '',
          overflow: explorer != 'Firefox' ? 'hidden' : '',
          width: explorer != 'Firefox' ? '100%' : '',
          height:
            explorer != 'Firefox'
              ? boxInfo.height * boxInfo.row +
                boxInfo.paddingTop +
                boxInfo.paddingBottom +
                (boxInfo.row - 1) * boxInfo.RowSpacing -
                0.45 +
                'mm'
              : '',
          transform: explorer == 'Safari' ? 'scale(0.92)' : 'scale(1)'
        }"
      >
        <div
          style="position: relative;"
          :ref="'imageDom' + index0"
          :style="{
            width:
              boxInfo.width * boxInfo.cloumn +
              boxInfo.paddingLeft +
              boxInfo.paddingRight +
              (boxInfo.cloumn - 1) * boxInfo.ColumnSpacing +
              'mm',
            height:
              boxInfo.height * boxInfo.row +
              boxInfo.paddingTop +
              boxInfo.paddingBottom +
              (boxInfo.row - 1) * boxInfo.RowSpacing -
              0.45 +
              'mm',
            background: '#fff',
          }"
        >
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              align-content: flex-start;
              justify-content: flex-start;
              margin: 0px;
              padding: 0px;
            "
            :style="{
              paddingLeft: boxInfo.paddingLeft + 'mm',
              paddingRight: boxInfo.paddingRight + 'mm',
              paddingTop: boxInfo.paddingTop + 'mm',
              paddingBottom: boxInfo.paddingBottom + 'mm',
            }"
          >
            <div
              style="
                position: relative;
                overflow: hidden;
                margin: 0px;
                padding: 0px;
              "
              id="design"
              v-for="(pArr, _index) in newgroup.slice(
                boxInfo.row * boxInfo.cloumn * index0,
                boxInfo.row * boxInfo.cloumn * (index0 + 1)
              )"
              :key="_index"
              :style="{
                width: template.width + 'mm',
                height: template.height + 'mm',
                background: showCanvas ? '#fff' : 'none',
                marginBottom:
                  _index < boxInfo.cloumn * boxInfo.row - boxInfo.cloumn
                    ? boxInfo.RowSpacing + 'mm'
                    : '0',
                marginRight:
                  (_index + 1) % boxInfo.cloumn != 0
                    ? boxInfo.ColumnSpacing + 'mm'
                    : '0',
              }"
            >
              <!-- 背景图片 -->
              <img
                v-if="template.Background && isShowBackgound"
                :style="{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: template.width + 'mm',
                  height: template.height + 'mm',
                }"
                :src="imgHeader + template.Background"
                alt=""
              />
              <template
                v-for="(item, index) in pArr.group"
                :key="index"
              >
                <!-- 水平线 -->
                <div
                  v-if="item.Type == 'LineHorizontal'"
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.PenWidth / conversion + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <line
                      :x1="0"
                      :y1="item.PenWidth / conversion / 2 + 'mm'"
                      :x2="item.Width + 'mm'"
                      :y2="item.PenWidth / conversion / 2 + 'mm'"
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 垂直线 -->
                <div
                  v-if="item.Type == 'LineVertical'"
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.PenWidth / conversion + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <line
                      :x1="item.PenWidth / conversion / 2 + 'mm'"
                      :y1="0"
                      :x2="item.PenWidth / conversion / 2 + 'mm'"
                      :y2="item.Height + 'mm'"
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 斜线 -->
                <div
                  v-if="item.Type == 'Line'"
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left:
                      item.EndX < item.StartX
                        ? item.EndX + 'mm'
                        : item.StartX + 'mm',
                    top:
                      item.EndY < item.StartY
                        ? item.EndY + 'mm'
                        : item.StartY + 'mm',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <line
                      :x1="
                        item.EndX < item.StartX
                          ? item.Width - item.PenWidth / conversion / 2 + 'mm'
                          : item.PenWidth / conversion / 2 + 'mm'
                      "
                      :y1="
                        item.EndY < item.StartY
                          ? item.Height - item.PenWidth / conversion / 2 + 'mm'
                          : item.PenWidth / conversion / 2 + 'mm'
                      "
                      :x2="
                        item.EndX < item.StartX
                          ? item.PenWidth / conversion / 2 + 'mm'
                          : item.Width - item.PenWidth / conversion / 2 + 'mm'
                      "
                      :y2="
                        item.EndY < item.StartY
                          ? item.PenWidth / conversion / 2 + 'mm'
                          : item.Height - item.PenWidth / conversion / 2 + 'mm'
                      "
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 图片 -->
                <div
                  v-if="item.Type == 'Image'"
                  :ref="'print' + index"
                >
                  <img
                    style="position: absolute"
                    :style="{
                      zIndex: item.zOrder,
                      width: item.Width + 'mm',
                      height: item.Height + 'mm',
                      left: item.StartX + 'mm',
                      top: item.StartY + 'mm',
                      transform: 'rotate(' + item.AngleRound + 'deg)',
                      transformOrigin: '0 0',
                    }"
                    :src="imgHeader + item.OriginalImage"
                  />
                </div>
                <!-- 矩形、圆角矩形、实心矩形 -->
                <div
                  v-if="
                    item.Type == 'Rectangle' ||
                    item.Type == 'RoundRectangle' ||
                    item.Type == 'SolidRectangle'
                  "
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    transform: 'rotate(' + item.AngleRound + 'deg)',
                    transformOrigin: '0 0',
                  }"
                >
                  <!-- 矩形 -->
                  <svg
                    v-if="item.Type == 'Rectangle'"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <rect
                      :x="item.PenWidth / 2 / conversion + 'mm'"
                      :y="item.PenWidth / 2 / conversion + 'mm'"
                      :width="item.Width - item.PenWidth / conversion + 'mm'"
                      :height="item.Height - item.PenWidth / conversion + 'mm'"
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                  <!-- 圆角矩形 -->
                  <svg
                    v-if="item.Type == 'RoundRectangle'"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <rect
                      :x="item.PenWidth / 2 / conversion + 'mm'"
                      :y="item.PenWidth / 2 / conversion + 'mm'"
                      :width="item.Width - item.PenWidth / conversion + 'mm'"
                      :height="item.Height - item.PenWidth / conversion + 'mm'"
                      :rx="item.CornerRadius / conversion + 'mm'"
                      :ry="item.CornerRadius / conversion + 'mm'"
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                  <!-- 实心矩形 -->
                  <div
                    v-if="item.Type == 'SolidRectangle'"
                    :style="{
                      width:
                        'calc(100% - ' +
                        2 * (item.PenWidth / conversion) +
                        'mm)',
                      height:
                        'calc(100% - ' +
                        2 * (item.PenWidth / conversion) +
                        'mm)',
                      background: '#000',
                      borderWidth: item.PenWidth / conversion + 'mm',
                      borderStyle: item.DashStyle == 0 ? 'solid' : 'dashed',
                    }"
                  >
                    <img
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                      "
                      src="../assets/black-bg.png"
                      alt=""
                    />
                  </div>
                </div>
                <!-- 条形码 -->
                <div
                  v-if="item.Type == 'GeneralBarcode'"
                  :ref="'print' + index"
                >
                  <img
                    style="position: absolute"
                    :style="{
                      zIndex: item.zOrder,
                      width: item.Width + 'mm',
                      height: item.Height + 'mm',
                      left: item.StartX + 'mm',
                      top: item.StartY + 'mm',
                      transform: 'rotate(' + item.AngleRound + 'deg)',
                      transformOrigin: '0 0',
                    }"
                    v-if="showCanvas && item.BarcodeImage"
                    :src="imgHeader + item.BarcodeImage"
                  />
                </div>
                <!-- 二维码 -->
                <div
                  v-if="item.Type == 'QRCode'"
                  :ref="'print' + index"
                >
                  <img
                    style="position: absolute"
                    :style="{
                      zIndex: item.zOrder,
                      width: item.Width + 'mm',
                      height: item.Height + 'mm',
                      left: item.StartX + 'mm',
                      top: item.StartY + 'mm',
                      transform: 'rotate(' + item.AngleRound + 'deg)',
                      transformOrigin: '0 0',
                    }"
                    v-if="showCanvas"
                    :src="imgHeader + item.BarcodeImage"
                  />
                </div>
                <!-- 文本时间 -->
                <div
                  v-if="item.Type == 'WinText' || item.Type == 'TimeText'"
                  :ref="'print' + index"
                  style="position: absolute"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.AutoWrap
                      ? item.BoxWidth + 'mm'
                      : item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    transform: 'rotate(' + item.AngleRound + 'deg)',
                    transformOrigin: '0 0',
                  }"
                >
                  <EditDiv
                    :data="item"
                    :pindex="pArr.groupIndex"
                    :index="index"
                    :multiple="1"
                    v-model:value="item.Data"
                    @getDiv="getDivWidth"
                  >
                  </EditDiv>
                </div>
                <!-- 椭圆 -->
                <div
                  v-if="item.Type == 'Ellipse'"
                  :ref="'print' + index"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    position: 'absolute',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <ellipse
                      :cx="item.Width / 2 + 'mm'"
                      :cy="item.Height / 2 + 'mm'"
                      :rx="
                        (item.Width - (2 * item.PenWidth) / conversion) / 2 +
                        'mm'
                      "
                      :ry="
                        (item.Height - (2 * item.PenWidth) / conversion) / 2 +
                        'mm'
                      "
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 2 + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 菱形-->
                <div
                  v-if="item.Type == 'Diamond'"
                  :ref="'print' + index"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    position: 'absolute',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <polygon
                      :points="
                        (
                          ((item.Vertice[3]['@X'] / conversion -
                            item.StartX +
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[3]['@Y'] / conversion - item.StartY) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[0]['@X'] / conversion - item.StartX) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[0]['@Y'] / conversion -
                            item.StartY +
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[1]['@X'] / conversion -
                            item.StartX -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[1]['@Y'] / conversion - item.StartY) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[2]['@X'] / conversion - item.StartX) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[2]['@Y'] / conversion -
                            item.StartY -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString()
                      "
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 2 + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 三角形 -->
                <div
                  v-if="item.Type == 'Triangle'"
                  :ref="'print' + index"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    position: 'absolute',
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    preserveAspectRatio="none"
                    style="width: 100%; height: 100%; position: absolute"
                  >
                    <polygon
                      :points="
                        (
                          ((item.Vertice[2]['@X'] / conversion - item.StartX) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[2]['@Y'] / conversion -
                            item.StartY +
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[1]['@X'] / conversion -
                            item.StartX -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[1]['@Y'] / conversion -
                            item.StartY -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ' ' +
                        (
                          ((item.Vertice[0]['@X'] / conversion -
                            item.StartX +
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString() +
                        ',' +
                        (
                          ((item.Vertice[0]['@Y'] / conversion -
                            item.StartY -
                            item.PenWidth / conversion) *
                            96) /
                          25.4
                        ).toString()
                      "
                      style="fill: none; stroke: black"
                      :stroke-dasharray="
                        item.DashStyle == 0
                          ? '0'
                          : item.PenWidth / conversion + 2 + 'mm'
                      "
                      :style="{
                        strokeWidth: item.PenWidth / conversion + 'mm',
                      }"
                    />
                  </svg>
                </div>
                <!-- 表格 -->
                <div
                  v-if="item.Type == 'Table'"
                  :ref="'print' + index"
                  class="edit-print-li"
                  :style="{
                    zIndex: item.zOrder,
                    width: item.Width + 'mm',
                    height: item.Height + 'mm',
                    left: item.StartX + 'mm',
                    top: item.StartY + 'mm',
                    transform: 'rotate(' + item.AngleRound + 'deg)',
                    transformOrigin: '0 0',
                    position: 'absolute',
                  }"
                >
                  <div style="
                      width: 100%;
                      height: 100%;
                      position: relative;
                      background: #000;
                    ">
                    <!-- 黑色背景层  底色 -->
                    <div style="
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                      ">
                      <img
                        style="width: 100%; height: 100%"
                        src="../assets/black-bg.png"
                        alt=""
                      />
                    </div>
                    <div
                      style="
                        position: absolute;
                        overflow: hidden;
                        background: #fff;
                      "
                      v-for="(cell, c_Index) in item.DataSourceList"
                      :key="c_Index"
                      :style="{
                        width: cell.Width + 'mm',
                        height: cell.Height + 'mm',
                        left: cell.StartX + 'mm',
                        top: cell.StartY + 'mm',
                      }"
                    >
                      <EditDiv
                        style="
                          text-shadow: none;
                          color: #000;
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                        "
                        :style="{
                          color: cell.AntiBlack ? '#fff' : '#000',
                          justifyContent:
                            cell.TextAlign == 1
                              ? 'center'
                              : cell.TextAlign == 2
                              ? 'flex-end'
                              : 'flex-start',
                        }"
                        :data="cell"
                        v-model:value="cell.Data"
                      >
                      </EditDiv>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <p style="page-break-after: always"></p>
      </div>
    </template>
  </div>
</template>
<script>
import { getExplorer } from "../utils/device";
import JsBarcode from "jsbarcode";
import EditDiv from "@/components/EditDiv";
import QRCode from "qrcodejs2";
import attribute from "../../src/views/editLabel/js/attribute.js";
import table_attr from "../../src/views/editLabel/js/table_attr.js";
import { propertyType } from "../../src/views/editLabel/js/propertyType.js";
import changedpi from "../../src/views/editLabel/js/changeDpi.js";
export default {
  mixins: [attribute, table_attr],
  components: {
    EditDiv,
  },
  props: {
    //标签数据
    data: {
      type: Object,
      default: () => { },
    },
    //用于上传生成模板 文件路径
    url: {
      type: String,
      default: "",
    },
    //类型
    type: {
      type: Number,
      default: 0,
    },
    printBackground: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showCanvas: false,
      labelList: [],
      template: {
        //模板信息
        width: 1000,
        height: 580,
        x: 0,
        y: 0,
        Background: "",
      },
      subgroup: [],
      subIndex: -1, //当前元素指针
      imgHeader: "data:image/png;base64,",
      jsonData: [],
      fileUrl: "",
      MeasureUnit: "",
      UnitConversion: 1, //单位毫米时为1，1英寸等于25.4毫米，英寸时为25.4, 将英寸换算成毫米
      conversion: 3.96, // 1mm等于3.96点,
      newgroup: [],
      isCreate: false,
      boxInfo: {
        width: 80,
        height: 100,
        count: 1,
        cloumn: 1,
        row: 1,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        ColumnSpacing: 0,
        RowSpacing: 0,
      },
      isShowBackgound: false,
      imgArr: [],
      originGroup: [], //存储处理完后的打印数据
      explorer: "", //浏览器类型
    };
  },

  mounted () {
    this.explorer = getExplorer();
    //type == 1用于上传时生成模板图片（只需第一条数据）
    //type == 2用于编辑器打印预览
    //type == 0 用于其他页面的立即打印预览
    if (this.type == 1) {
      this.writeFile(this.url);
    } else if (this.type == 2) {
      this.MeasureUnit = this.data.MeasureUnit;
      if (this.MeasureUnit.toLowerCase() == "inch") {
        this.UnitConversion = 25.4;
      } else {
        this.UnitConversion = 1;
      }
      this.boxInfo = {
        width: this.data.width * this.UnitConversion,
        height: this.data.height * this.UnitConversion,
        count: this.data.cloumn * this.data.row,
        cloumn: this.data.cloumn,
        row: this.data.row,
        paddingLeft: this.data.paddingLeft * this.UnitConversion,
        paddingRight: this.data.paddingRight * this.UnitConversion,
        paddingTop: this.data.paddingTop * this.UnitConversion,
        paddingBottom: this.data.paddingBottom * this.UnitConversion,
        ColumnSpacing: this.data.ColumnSpacing * this.UnitConversion,
        RowSpacing: this.data.RowSpacing * this.UnitConversion,
      };
      this.template.Background = this.data.Background;
      this.template.width = this.data.width * this.UnitConversion;
      this.template.height = this.data.height * this.UnitConversion;
      this.newgroup = this.data.newgroup;
      if (this.newgroup.length > 1) {
        this.boxInfo.count = Math.ceil(
          this.newgroup.length / (this.boxInfo.cloumn * this.boxInfo.row)
        );
      }

      this.$nextTick(() => {
        this.newgroup.map((item, index) => {
          var str1 = item.group.some(
            (_item) => _item.Type == "QRCode" || _item.Type == "GeneralBarcode"
          );
          if (str1) {
            item.group.map((e, _index) => {
              if (e.Type == "GeneralBarcode") {
                this.handleCreated("1", index, _index);
              } else if (e.Type == "QRCode") {
                this.handleCreated("2", index, _index);
              }
            });
          }
        });
        this.isCreate = true;
        this.originGroup = this.newgroup;
        // console.log(this.newgroup);
        this.$emit("return", this.newgroup);
      });
    } else {
      this.labelList = this.data;
      this.writeFile(this.labelList.file_pc);
    }
  },
  watch: {
    showCanvas (newVal) {
      if (newVal) {
        this.rebuildImgList();
      }
    },
    isCreate (newVal) {
      if (newVal) {
        this.showCanvas = true;
      }
    },
    printBackground (newVal) {
      this.isShowBackgound = newVal;
    },
  },
  methods: {
    //生成图片
    createHtml2canvas (e) {
      var that = this;
      return new Promise(function (resolve, reject) {
        that.$nextTick(() => {
          if (that.newgroup.length == 1) {
            var myBox = that.$refs["imageDom" + e];
          } else {
            var myBox = that.$refs["imageDom" + e][0];
          }
          html2canvas(myBox, {
            useCORS: true,
          }).then(function (canvas) {
            let data = canvas.toDataURL("image/png", 1);
            resolve(data);
          });
        });
      });
    },
    //设置打印背景图片重新初始化图片列表
    async rebuildImgList () {
      this.imgArr = [];
      let img = await this.createHtml2canvas(0);
      if (this.newgroup.length > 1) {
        var pageNum = this.boxInfo.count;
        let arr = [{ src: img }];
        this.imgArr = arr.concat(Array(pageNum - 1).fill({ src: "" }));
      } else {
        var pageNum = 1;
        let arr = [{ src: img }];
        this.imgArr = arr;
      }
      this.$emit("get", this.imgArr, pageNum);
    },
    //重选数据后 重新渲染并初始化图片列表
    resetPrintData (list) {
      this.$nextTick(() => {
        this.newgroup = list;
        this.boxInfo.count = Math.ceil(
          this.newgroup.length / (this.boxInfo.cloumn * this.boxInfo.row)
        );
        this.rebuildImgList();
      });
    },
    //页数改变重新生成图片
    async changePage (value) {
      let img = await this.createHtml2canvas(value - 1);
      let data = JSON.parse(JSON.stringify(this.imgArr[value - 1]));
      data.src = img;
      this.imgArr[value - 1] = data;
      this.$emit("change", this.imgArr);
    },

    //转Json
    writeFile (fileUrl) {
      var form = new FormData();
      form.append("url", fileUrl);
      this.$axiosnet("/LoadXml/writeFile", form, "post")
        .then(async (res) => {
          let regx = /^[\s\S]*\.tprts$/
          if (regx.test(fileUrl)) {
            let jsonString = decodeURIComponent(escape(atob(res)))
            this.jsonData = JSON.parse(jsonString);
          } else {
            this.jsonData = JSON.parse(res);
          }
          this.MeasureUnit =
            this.jsonData.PrtLable.PictureArea.LabelPage["@MeasureUnit"];
          this.template.Background =
            this.jsonData.PrtLable.PictureArea.LabelPage.Background;

          let printBackground = this.template.Background ? true : false;
          this.isShowBackgound = printBackground;
          this.$emit(
            "info",
            this.MeasureUnit,
            printBackground,
            this.template.Background
          );

          if (this.MeasureUnit.toLowerCase() == "inch") {
            this.UnitConversion = 25.4;
          } else {
            this.UnitConversion = 1;
          }

          this.template.width =
            this.jsonData.PrtLable.PictureArea.LabelPage.LabelWidth *
            this.UnitConversion;
          this.template.height =
            this.jsonData.PrtLable.PictureArea.LabelPage.LabelHeight *
            this.UnitConversion;

          this.dialogVisible = false;
          let DrawObject = this.jsonData.PrtLable.ObjectList.DrawObject;
          let Count = this.jsonData.PrtLable.ObjectList["@Count"];
          this.turnFormat(DrawObject, Count);
          this.boxInfo = {
            width:
              this.jsonData.PrtLable.PictureArea.LabelPage.LabelWidth *
              this.UnitConversion,
            height:
              this.jsonData.PrtLable.PictureArea.LabelPage.LabelHeight *
              this.UnitConversion,
            count:
              parseInt(this.jsonData.PrtLable.PictureArea.LabelPage.Columns) *
              parseInt(this.jsonData.PrtLable.PictureArea.LabelPage.Rows),
            cloumn: parseInt(
              this.jsonData.PrtLable.PictureArea.LabelPage.Columns
            ),
            row: parseInt(this.jsonData.PrtLable.PictureArea.LabelPage.Rows),
            paddingLeft:
              parseInt(
                this.jsonData.PrtLable.PictureArea.LabelPage.LeftMargin
              ) * this.UnitConversion,
            paddingRight:
              parseInt(
                this.jsonData.PrtLable.PictureArea.LabelPage.RightMargin
              ) * this.UnitConversion,
            paddingTop:
              parseInt(
                this.jsonData.PrtLable.PictureArea.LabelPage.UpperMargin
              ) * this.UnitConversion,
            paddingBottom:
              parseInt(
                this.jsonData.PrtLable.PictureArea.LabelPage.LowerMargin
              ) * this.UnitConversion,
            ColumnSpacing:
              parseInt(
                this.jsonData.PrtLable.PictureArea.LabelPage.ColumnSpacing
              ) * this.UnitConversion,
            RowSpacing:
              parseInt(
                this.jsonData.PrtLable.PictureArea.LabelPage.RowSpacing
              ) * this.UnitConversion,
          };
          console.log(this.boxInfo);
          //立即打印预览
          if (this.type == 0) {
            await this.dealData();
            this.$emit("return", this.newgroup, this.boxInfo);
            if (this.newgroup.length > 1) {
              this.boxInfo.count = Math.ceil(
                this.newgroup.length / (this.boxInfo.cloumn * this.boxInfo.row)
              );
            }
          } else if (this.type == 1) {
            //上传模板生成图片，只需第一条数据
            this.boxInfo.row = 1;
            this.boxInfo.cloumn = 1;
            this.boxInfo.count = 1;
            this.$nextTick(() => {
              this.subgroup.map((e, index) => {
                if (e.Type == "GeneralBarcode") {
                  this.handleCreated("1", 0, index);
                } else if (e.Type == "QRCode") {
                  this.handleCreated("2", 0, index);
                }
              });
              let isText = this.subgroup.some(
                (item) => item.Type === "WinText" && "TimeText"
              );
              if (!isText) {
                this.showCanvas = true;
              }
            });
          }
          console.log(this.newgroup);
        })
        .catch((err) => { });
    },
    //处理解析后的json数据
    turnFormat (data, Count) {
      let dataList = [];
      if (Count == 1) {
        dataList.push(data);
      } else {
        dataList = data;
      }
      this.subgroup = dataList.map((element) => {
        // 字号变成Number类型
        if (element.Font) {
          element.Font["@FontSize"] = element.Font["@FontSize"] * 1;
        }

        //AngleRound为空的元素默认旋转角度为0
        if (!element.AngleRound) {
          element.AngleRound = 0;
        }

        //判断旋转角度，重置顶点坐标
        element.StartX = element.StartX / this.conversion;
        element.StartY = element.StartY / this.conversion;
        let width = element.Width / this.conversion;
        let height = element.Height / this.conversion;
        let startX = element.StartX;
        let startY = element.StartY;
        if (element.Type != "WinText" && element.Type != "TimeText") {
          switch (element.AngleRound) {
            case "90":
              element.StartX = startX + height;
              element.StartY = startY;
              break;
            case "180":
              element.StartX = startX + width;
              element.StartY = startY + height;
              break;
            case "270":
              element.StartX = startX;
              element.StartY = startY + width;
              break;
            default:
              element.StartX = startX;
              element.StartY = startY;
              break;
          }
        }

        //处理元素宽高
        element.Width = element.Width / this.conversion;
        element.Height = element.Height / this.conversion;
        element.BoxWidth = element.BoxWidth / this.conversion;

        //处理表格
        if (element.Type == "Table") {
          element.PenWidth = element.PenWidth / this.conversion;

          element.DicLineLocW = element.DicLineLocW
            ? element.DicLineLocW.split(",")
            : [];
          for (let i = 0; i < element.DicLineLocW.length; i++) {
            element.DicLineLocW[i] = (
              Math.round((element.DicLineLocW[i] / this.conversion) * 100) / 100
            ).toFixed(2);
            element.DicLineLocW[i] = Number(element.DicLineLocW[i]);
          }

          element.DicLineLocH = element.DicLineLocH
            ? element.DicLineLocH.split(",")
            : [];
          for (let i = 0; i < element.DicLineLocH.length; i++) {
            element.DicLineLocH[i] = (
              Math.round((element.DicLineLocH[i] / this.conversion) * 100) / 100
            ).toFixed(2);
            element.DicLineLocH[i] = Number(element.DicLineLocH[i]);
          }

          let DataSourceList = Array.isArray(element.DataSourceList)
            ? element.DataSourceList
            : [element.DataSourceList];
          let { list, tableWidth, tableHeight, lineNumW, lineNumH } =
            this.dealTableData(
              DataSourceList,
              element.DicLineLocW,
              element.DicLineLocH
            );
          element.DataSourceList = list;
          element.Row = element.DicLineLocH.length + 1;
          element.Cloumn = element.DicLineLocW.length + 1;
          //重新计算表格的宽高，避免因转换过程中精度丢失导致误差
          element.Width = tableWidth + element.PenWidth * (1 + lineNumW);
          element.Height = tableHeight + element.PenWidth * (1 + lineNumH);
        }

        //处理线条
        if (
          element.Type == "LineHorizontal" ||
          element.Type == "LineVertical" ||
          element.Type == "Line"
        ) {
          element.EndX = element.EndX / this.conversion;
          element.EndY = element.EndY / this.conversion;
          switch (element.Type) {
            case "LineHorizontal":
              element.Width = Math.abs(element.EndX - element.StartX);
              element.Height = element.EndY - element.StartY + 1;
              if (element.EndX < element.StartX) {
                let temp = element.StartX;
                element.StartX = element.EndX;
                element.EndX = temp;
              }
              break;
            case "LineVertical":
              element.Width = element.EndX - element.StartX + 1;
              element.Height = Math.abs(element.EndY - element.StartY);
              if (element.EndY < element.StartY) {
                let temp = element.StartY;
                element.StartY = element.EndY;
                element.EndY = temp;
              }
              break;
            case "Line":
              if (element.EndX == element.StartX) {
                element.Width = 1;
              } else {
                element.Width = Math.abs(element.EndX - element.StartX);
              }
              if (element.EndY == element.StartY) {
                element.Height = 1;
              } else {
                element.Height = Math.abs(element.EndY - element.StartY);
              }
              break;
          }
        }

        // 处理文本、二维码、条形码的数据
        if (
          element.Type == "WinText" ||
          element.Type == "GeneralBarcode" ||
          element.Type == "QRCode"
        ) {
          element.DataSourceList = Array.isArray(element.DataSourceList)
            ? element.DataSourceList
            : [element.DataSourceList];
          let value = "";
          element.DataSourceList.map((list) => {
            if (list.DataSourceType == "2") {
              list.AddedValue = Number(list.AddedValue);
              list.Quantity = Number(list.Quantity);
              switch (list.SequenceType) {
                case "1":
                  list.InitialValue = Number(list.InitialValue).toString(16);
                  break;
                case "2":
                  list.InitialValue = this.convertToTwoteenSix(
                    Number(list.InitialValue)
                  );
                  break;
                case "3":
                  list.InitialValue = Number(list.InitialValue).toString(36);
                  break;
              }
              list.FirstValue = list.InitialValue;
            }
            value += list.FirstValue;
          });
          element.Data = value;
        }
        //对数据转类型
        element = propertyType(element);
        return element;
      });
      if (this.type == 1) {
        this.newgroup.push({
          groupIndex: 0,
          group: JSON.parse(JSON.stringify(this.subgroup)),
        });
      }
    },
    //处理序列化数据和数据库数据
    dealData () {
      var that = this;
      return new Promise(async (resolve, reject) => {
        await that.getAllDataBase();
        var minCount = 0; //记录数据长度
        var firstFind = true;
        let indexArr = []; //记录数据库数据对应的下标
        let sequence = []; //记录序列化数据所在的下标
        that.subgroup.forEach((item, index) => {
          //获取序列化数据的长度
          if (
            item.Type == "WinText" ||
            item.Type == "GeneralBarcode" ||
            item.Type == "QRCode" ||
            item.Type == "Table"
          ) {
            item.DataSourceList.map((val, i) => {
              // 序列化文本
              if (
                val.DataSourceType == "2" &&
                val.Quantity != "" &&
                val.Quantity > 0
              ) {
                let sub = {
                  parentIndex: index,
                  childIndex: i,
                };
                sequence.push(sub);
                if (firstFind) {
                  minCount = val.Quantity;
                  firstFind = false;
                } else {
                  if (val.Quantity < minCount) {
                    minCount = val.Quantity;
                  }
                  firstFind = false;
                }
              }
              // 数据库文本
              if (
                val.DataSourceType == "1" &&
                val.ConnectionData &&
                val.ConnectionData.length > 0
              ) {
                let sub = {
                  parentIndex: index,
                  childIndex: i,
                };
                indexArr.push(sub);
                if (firstFind) {
                  minCount = val.ConnectionData.length;
                  firstFind = false;
                } else {
                  if (val.ConnectionData.length < minCount) {
                    minCount = val.ConnectionData.length;
                  }
                  firstFind = false;
                }
              }
            });
          }
        });
        // console.log("minCount", minCount);
        // console.log("sequence", sequence);
        // console.log("indexArr", indexArr);
        // 整合数据
        if (minCount > 0) {
          for (let i = 0; i < minCount; i++) {
            let arr = JSON.parse(JSON.stringify(that.subgroup));
            if (sequence.length > 0) {
              for (let j = 0; j < sequence.length; j++) {
                let parentIndex = sequence[j].parentIndex;
                let childIndex = sequence[j].childIndex;
                let data = arr[parentIndex].DataSourceList[childIndex];
                //处理数据库序列化关联元素打印
                this.dealPrintData(arr[parentIndex].Id);
                let SequenceType = data.SequenceType;
                switch (SequenceType) {
                  case "0":
                    var sequenceValue = (
                      data.InitialValue * 1 +
                      data.AddedValue * i
                    ).toString();

                    data[
                      arr[parentIndex].Type == "Table" ? "Data" : "FirstValue"
                    ] = this.setBeforeZero(10, sequenceValue, data);
                    break;
                  case "1":
                    var sequenceValue = (
                      parseInt(data.InitialValue, 16) +
                      data.AddedValue * i
                    ).toString(16);
                    data[
                      arr[parentIndex].Type == "Table" ? "Data" : "FirstValue"
                    ] = this.setBeforeZero(16, sequenceValue, data);
                    break;
                  case "2":
                    var sequenceValue = this.convertToTwoteenSix(
                      this.convertToTen(data.InitialValue) + data.AddedValue * i
                    );
                    data[
                      arr[parentIndex].Type == "Table" ? "Data" : "FirstValue"
                    ] = this.setBeforeZero(26, sequenceValue, data);
                    break;
                  case "3":
                    var sequenceValue = (
                      parseInt(data.InitialValue, 36) +
                      data.AddedValue * i
                    ).toString(36);
                    data[
                      arr[parentIndex].Type == "Table" ? "Data" : "FirstValue"
                    ] = this.setBeforeZero(36, sequenceValue, data);
                    break;
                }
                if (arr[parentIndex].Type != "Table") {
                  let value = "";
                  arr[parentIndex].DataSourceList.map((val) => {
                    value += val.FirstValue;
                  });
                  arr[parentIndex].Data = value;

                  if (this.reference.length > 0) {
                    this.reference.map((ref) => {
                      arr[ref.PIndex].DataSourceList[ref.CIndex].FirstValue =
                        data.FirstValue;
                      arr[ref.PIndex].Data = "";
                      arr[ref.PIndex].DataSourceList.map((val) => {
                        arr[ref.PIndex].Data += val.FirstValue;
                      });
                    });
                  }
                }
              }
            }

            if (indexArr.length > 0) {
              for (let h = 0; h < indexArr.length; h++) {
                let parentIndex = indexArr[h].parentIndex;
                let childIndex = indexArr[h].childIndex;
                let data = arr[parentIndex].DataSourceList[childIndex];

                if (arr[parentIndex].Type == "Table") {
                  data.Data = data.ConnectionData[i];
                } else {
                  //处理数据库序列化关联元素打印
                  this.dealPrintData(arr[parentIndex].Id);
                  data.FirstValue = data.ConnectionData[i];
                  let value = "";
                  arr[parentIndex].DataSourceList.map((val) => {
                    value += val.FirstValue;
                  });
                  arr[parentIndex].Data = value;

                  if (this.reference.length > 0) {
                    this.reference.map((ref) => {
                      arr[ref.PIndex].DataSourceList[ref.CIndex].FirstValue =
                        data.FirstValue;
                      arr[ref.PIndex].Data = "";
                      arr[ref.PIndex].DataSourceList.map((val) => {
                        arr[ref.PIndex].Data += val.FirstValue;
                      });
                    });
                  }
                }
              }
            }
            that.newgroup.push({ groupIndex: i, group: arr });
          }
        } else {
          that.newgroup.push({
            groupIndex: 0,
            group: JSON.parse(JSON.stringify(this.subgroup)),
          });
        }
        //生成二维码、条形码
        that.$nextTick(() => {
          that.newgroup.map((item, index) => {
            var str1 = item.group.some(
              (_item) =>
                _item.Type == "QRCode" || _item.Type == "GeneralBarcode"
            );
            if (str1) {
              item.group.map((e, _index) => {
                if (e.Type == "GeneralBarcode") {
                  that.handleCreated("1", index, _index);
                } else if (e.Type == "QRCode") {
                  that.handleCreated("2", index, _index);
                }
              });
            }
          });
          that.isCreate = true;
          this.originGroup = this.newgroup;
          resolve();
        });
      });
    },
    //获取所有数据库链接
    getAllDataBase () {
      var that = this;
      return new Promise(async (resolve, reject) => {
        let dataBase = [];
        //获取所有需要请求的数据库链接
        that.subgroup.map((list, index) => {
          if (
            list.Type == "WinText" ||
            list.Type == "GeneralBarcode" ||
            list.Type == "QRCode" ||
            list.Type == "Table"
          ) {
            list.DataSourceList.map((item, key) => {
              if (item.DataSourceType == "1") {
                var re = new RegExp("^(http|https)://", "i");
                if (re.test(item.ConnectionStr)) {
                  dataBase.push({
                    url: item.ConnectionStr,
                    parentIndex: index,
                    childIndex: key,
                  });
                }
              }
            });
          }
        });
        // 请求数据
        if (dataBase.length > 0) {
          await that.loadExcel(dataBase);
          resolve();
        } else {
          resolve();
        }
      });
    },
    //请求接口解析表格
    loadExcel (dataBase) {
      var that = this;
      return new Promise(async (resolve, reject) => {
        for (let i = 0; i < dataBase.length; i++) {
          await that.requestData(dataBase[i]);
          if (i == dataBase.length - 1) {
            resolve();
          }
        }
      });
    },
    //请求接口解析表格
    requestData (database) {
      var that = this;
      return new Promise((resolve, reject) => {
        let parentIndex = database.parentIndex;
        let childIndex = database.childIndex;
        that.$axioshanma("/api/tem.new_class/import", {
          FileUrl: database.url,
          RowIndex: 0,
          TableIndex: 0,
        })
          .then(async (res) => {
            that.database = [];
            let data = res[0].data;
            let columnIndex = data.findIndex(
              (item) =>
                item[0] ==
                that.subgroup[parentIndex].DataSourceList[childIndex].ColumnName
            );
            if (columnIndex > -1) {
              that.subgroup[parentIndex].DataSourceList[
                childIndex
              ].ConnectionData = data[columnIndex].slice(1);
              that.subgroup[parentIndex].DataSourceList[
                childIndex
              ].OriginalConnectionData = data[columnIndex].slice(1);
              that.dealInitConnectionData(database);
            }
            resolve();
          })
          .catch((err) => {
            resolve();
          });
      });
    },
    //根据数据类型处理初始化后的数据库数据
    dealInitConnectionData (database) {
      let parentIndex = database.parentIndex;
      let childIndex = database.childIndex;
      let DataType =
        this.subgroup[parentIndex].DataSourceList[childIndex].DataType;
      let DataFormat =
        this.subgroup[parentIndex].DataSourceList[childIndex].DataFormat;
      let NumberFormat =
        this.subgroup[parentIndex].DataSourceList[childIndex].NumberFormat;
      let OriginalConnectionData = JSON.parse(
        JSON.stringify(
          this.subgroup[parentIndex].DataSourceList[childIndex]
            .OriginalConnectionData
        )
      );
      // 0 日期  1数字  2文本
      switch (DataType) {
        case "0":
          this.subgroup[parentIndex].DataSourceList[childIndex].ConnectionData =
            OriginalConnectionData.map((val) => {
              if (isNaN(Number(val))) {
                let reg =
                  /^(?=.*?年)(?=.*?月)|(?=.*?年)(?=.*?月)(?=.*?日)|(?=.*?月)(?=.*?日).+$/;
                let date = "";
                date = new Date(val).getTime();
                if (reg.test(val)) {
                  let arr = val.split(/年|月|日/);
                  arr.splice(arr.length - 1, 1);
                  let string = arr.join("-");
                  date = new Date(string).getTime();
                }
                if (!isNaN(date)) {
                  let newDate = this.timeToDate(date, DataFormat);
                  return newDate;
                } else {
                  return val;
                }
              } else {
                return val;
              }
            });

          break;
        case "1":
          if (NumberFormat == "123456789") {
            this.subgroup[parentIndex].DataSourceList[
              childIndex
            ].ConnectionData = OriginalConnectionData.map((val) => {
              var newStr = val.replace(/,/g, "");
              return newStr;
            });
          } else if (NumberFormat == "123,456,789") {
            this.subgroup[parentIndex].DataSourceList[
              childIndex
            ].ConnectionData = OriginalConnectionData.map((val) => {
              if (!isNaN(Number(val))) {
                var newStr = this.dealNumber(val);
                return newStr;
              } else {
                return val;
              }
            });
          }
          break;
        case "2":
          this.subgroup[parentIndex].DataSourceList[childIndex].ConnectionData =
            OriginalConnectionData;
          break;
      }
      let str =
        this.subgroup[parentIndex].DataSourceList[childIndex].ConnectionData[0];
      if (this.subgroup[parentIndex].Type == "Table") {
        this.subgroup[parentIndex].DataSourceList[childIndex].Data = str;
      } else {
        this.subgroup[parentIndex].DataSourceList[childIndex].FirstValue = str;
      }
    },
    //获取文本框宽高
    getDivWidth (width, height, index, type, pindex) {
      // console.log(width, height, pindex, index, type);
      let subgroup = this.newgroup[pindex].group;
      if (type == 0) {
        if (width) {
          subgroup[index].Width = width;
        }
        if (height) {
          subgroup[index].Height = height;
        }
        //判断旋转角度，重置顶点坐标    处理多端行间距位置对齐问题
        if (this.type == 0 || this.type == 1) {
          switch (subgroup[index].AngleRound) {
            case 90:
              subgroup[index].StartX =
                subgroup[index].StartX + subgroup[index].Height;
              break;
            case 180:
              subgroup[index].StartX =
                subgroup[index].StartX + subgroup[index].Width;
              subgroup[index].StartY =
                subgroup[index].StartY + subgroup[index].Height;
              break;
            case 270:
              subgroup[index].StartY =
                subgroup[index].StartY + subgroup[index].Width;
              subgroup[index].StartX = subgroup[index].StartX;
              break;
            default:
              subgroup[index].StartY = subgroup[index].StartY;
              break;
          }
        }
      } else {
        if (width) {
          subgroup[index].Width = width;
        }
        if (height) {
          subgroup[index].Height = height;
        }
      }
      this.$nextTick(() => {
        this.newgroup[pindex].group = subgroup;
        this.showCanvas = true;
      });
    },
    //生成条形码二维码
    handleCreated (e, index, _index) {
      var group = this.newgroup[index].group;
      var currentIndex = _index;
      if (e == 1) {
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if (reg.test(group[currentIndex].Data)) {
          group[currentIndex].BarcodeImage = "";
          return false;
        }
        if (group[currentIndex].DisplaPosition == 2) {
          var textAlign = "center";
        } else if (group[currentIndex].DisplaPosition == 1) {
          var textAlign = "left";
        } else if (group[currentIndex].DisplaPosition == 3) {
          var textAlign = "right";
        }
        let FontStyle = group[currentIndex].Font["@FontStyle"];
        var fontOptions = "";
        if (FontStyle.indexOf("Bold") != -1) {
          fontOptions = "Bold";
        }
        if (FontStyle.indexOf("Italic") != -1) {
          fontOptions = "Italic";
        }
        if (
          FontStyle.indexOf("Bold") != -1 &&
          FontStyle.indexOf("Italic") != -1
        ) {
          fontOptions = "Bold Italic";
        }
        // 4.16 是为了生成为300dip时放大图片足够清晰
        let barcode = {
          format: group[currentIndex].BarcodeType,
          width: 0.35 * group[currentIndex].Zoom * 4.16,
          height: group[currentIndex].BarCodeHight * 4.16,
          fontSize: group[currentIndex].Font["@FontSize"] * 4.16,
          font: group[currentIndex].Font["@FontFamily"],
          fontOptions: fontOptions,
          textAlign: textAlign,
          displayValue: group[currentIndex].HasLabel,
          margin: 10,
          textPosition: "bottom",
          background: "",
        };
        var data = group[currentIndex].Data;
        try {
          JsBarcode("#barcode", data, barcode);
          let base64Str = changedpi.changeDpiDataUrl(
            this.$refs.barcode.src,
            300
          );
          // let baseStrProduct = this.$refs.barcode.src.split("base64,")[1];
          let baseStrProduct = base64Str.split("base64,")[1];
          let img = baseStrProduct;
          this.newgroup[index].group[currentIndex].BarcodeImage = img;
          this.newgroup[index].group[currentIndex].badBarcode = false;
          let imgs = new Image();
          imgs.onload = () => {
            let w = imgs.width / 4.16;
            let h = imgs.height / 4.16;
            this.newgroup[index].group[currentIndex].Width = (w * 25.4) / 96;
            // this.newgroup[index][currentIndex].Height = (h * 25.4) / 96;
            this.isCreate = true;
          };
          imgs.src = this.imgHeader + img;
        } catch (err) {
          if (index != 0) {
            this.newgroup[index].group[currentIndex].BarcodeImage = "";
            this.newgroup[index].group[currentIndex].badBarcode = true;
          }
          this.isCreate = true;
        }
      } else if (e == 2) {
        let ECLevelBits = this.newgroup[index].group[currentIndex].ECLevelBits;
        let bcid;
        let eclevel = "";
        switch (this.newgroup[index].group[currentIndex].BarcodeType) {
          case "PDF_417":
            bcid = "pdf417";
            let value = ECLevelBits.replace(/[^\d]/g, " ");
            if (value) {
              eclevel = value;
            } else {
              eclevel = 0;
            }
            break;
          case "DATA_MATRIX":
            bcid = "datamatrix";
            break;
          case "AZTEC":
            bcid = "azteccode";
            eclevel = "23";
            break;
          default:
            bcid = "qrcode";
            if (ECLevelBits == "Medium") {
              eclevel = "M";
            } else if (ECLevelBits == "Medium High") {
              eclevel = "Q";
            } else if (ECLevelBits == "High") {
              eclevel = "H";
            } else {
              eclevel = "L";
            }
            break;
        }

        let canvas = document.createElement("canvas");
        let options = {
          bcid,
          text: this.newgroup[index].group[currentIndex].Data,
          scale: this.newgroup[index].group[currentIndex].Zoom,
          eclevel,
        };
        bwipjs.toCanvas(canvas, options);
        let baseStrProduct = canvas.toDataURL("image/png").split("base64,")[1];
        this.newgroup[index].group[currentIndex].BarcodeImage = baseStrProduct;
      } else {
        return;
      }
    },
  },
};
</script>
