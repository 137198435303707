const propertyType = (element) => {
  var attrList = Object.keys(element);
  for (let i = 0; i < attrList.length; i++) {
    let attr = attrList[i];
    switch (attr) {
      case "zOrder":
      case "DayOffset":
      case "MinuteOffset":
      case "RowSpacing1":
      case "WordSpacing":
      case "BoxWidth":
      case "StartX":
      case "StartY":
      case "Height":
      case "Width":
      case "AngleRound":
      case "DashStyle":
      case "TextAlign":
      case "PenWidth":
      case "CornerRadius":
      case "Zoom":
      case "OrgSize":
      case "DisplaPosition":
      case "BarCodeHight":
      case "QRCodeWidth":
      case "QRCodeHeight":
        if (element[attr] == "") break;
        element[attr] = Number(element[attr]);
        break;
      case "AutoWrap":
      case "Lock":
      case "AntiBlack":
      case "MirrorImage":
      case "Inverse":
      case "Vert":
      case "HasLabel":
      case "ISParticipating":
        if (element[attr] == "False") {
          element[attr] = false;
        } else if (element[attr] == "True") {
          element[attr] = true;
        }
        break;
      case "Font":
        element[attr]["@FontSize"] = Number(element[attr]["@FontSize"]);
        break;
      default:
        element[attr] = element[attr];
        break;
    }
    if (i == attrList.length - 1) {
      return element;
    }
  }
};

//保存时处理数据类型及精度
const dataTypeChange = (data, precision) => {
  for (var val in data) {
    if (typeof data[val] == "boolean") {
      if (data[val] == true) {
        data[val] = "True";
      } else if (data[val] == false) {
        data[val] = "False";
      }
    } else if (typeof data[val] == "number") {
      switch (val) {
        case "StartX":
        case "StartY":
        case "EndX":
        case "EndY":
        case "Width":
        case "Height":
        case "BoxWidth":
        case "PenWidth":
        case "BarCodeHight":
        case "QRCodeWidth":
        case "QRCodeHeight":
          data[val] = (
            Math.round(data[val] * Math.pow(10, precision)) /
            Math.pow(10, precision)
          ).toFixed(precision);
          break;
      }
    }
  }
  return data;
};
export { propertyType, dataTypeChange };

