export default {
  navbar: {
    login: "Iniciar sesión",
    home: "Hogar",
    register: "Inscribirse",
    shareTemplate: "Modelo da indústria",
    cloudTemplate: "Plantillas de nube",
    cloudData: "Datos de nube",
    printSetting: "Configuración de impresión",
    moreSetting: "Más ajustes",
    user: "Centro Personal",
    unit: "Unidad",
    logout: "Cerrar sesión",
    error404: "Error404",
    renamePassword: "Cambiar contraseña",
    editLabel: "Editar etiqueta",
    member: "Centro de Miembros",
    suggest: "Feedback",
    download: "Descargar Software",
    help: "Centro de ayuda",
    bind: "Vincular la cuenta",
    onlinetool: 'Utilidades',
    pdfPrint: 'Impresión de documentos',
  },
  button: {
    login: "Iniciar sesión",
    register: "Inscribirse",
    getCode: "Enviar",
    print: "Imprimir ahora",
    editLabel: "Editar la etiqueta",
    copyLabel: "Copiar la etiqueta",
    rename: "Rebautizar",
    delete: "Borrar",
    createLabel: "Crear una nueva etiqueta",
    move: "Mover",
    createFile: "Crear una nueva carpeta",
    upload: "Subir",
    cancel: "Cancelar",
    confirm: "Estás seguro",
    save: "Salvar",
    create: "Crear",
    saveToCloudTem: "Guardar en la plantilla de nube",
    download: "Descargar",
    updateAvatar: "Modifica tu avatar",
    forgetPass: "Olvidé mi contraseña",
    set: "Construir",
    goRegister: "Regístrese ahora",
    goLogin: "Iniciar sesión",
    backLogin: "Iniciar sesión",
    reset: "Restablecimiento",
    useEmail: "Regístrate por email",
    usePhone: "Regístrate con tu teléfono",
    labelset: "Configuración de etiquetas",
    printLabel: "Impresión",
    exitEdit: "Salida",
    choose: "Elegir",
    help: "Ayuda",
    downloadPC: "Descargar Software",
    Renewal: "Renovación",
    Upgrade: "Actualizar membresía",
    bingLogin: "Enlazar e iniciar sesión",
    upgradeMember: "Actualizar membresía",
    exchange: "Código de canje",
    exchangeNow: 'Canjear ahora',
    expansion: 'Expansión',
  },
  lang: {
    chinese: "Chinês simplificado",
    english: "Inglês",
    portuguese: "Português",
    Spanish: "Español",
    Russian: "Ruso",
  },
  tips: {
    tip1: "Inicio de sesión correcto",
    tip2: "¿Está seguro de que desea eliminar la carpeta y todos los datos que contiene?",
    tip3: "¿Está seguro de que desea eliminar estos datos?",
    tip4: "¿Está seguro de que desea eliminar esta etiqueta?",
    tip5: "Introduzca un nombre/tamaño de etiqueta",
    tip6: "Por favor, ingrese a una industria",
    tip7: "Por favor, introduzca su número de teléfono móvil/dirección de correo electrónico",
    tip8: "Introduzca el código de verificación",
    tip9: "Establezca una nueva contraseña",
    tip10: "Confirme la nueva contraseña",
    tip11: "Introduzca una contraseña",
    tip12: "Introduzca un nombre de usuario",
    tip13: "Por favor, introduzca su número de teléfono",
    tip14: "Por favor, establezca una contraseña",
    tip15: "Por favor, confirme la contraseña",
    tip16: "Seleccione una industria",
    tip17: "Por favor, introduzca su dirección de correo electrónico",
    tip18:
      "Introduzca el número de teléfono móvil/dirección de correo electrónico correcto",
    tip19: "Marque el acuerdo de lectura",
    tip20: "Introduzca el número de teléfono correcto",
    tip21: "Introduzca la dirección de correo electrónico correcta",
    tip22: "La contraseña introducida dos veces es incoherente",
    tip23: "Si no encuentras resultados, prueba otra cosa",
    tip24: "Aún no hay contenido",
    tip25: "Ven y crea tu etiqueta",
    tip26: "¡Seleccione el archivo de formato tprt/tprts!",
    tip27: "Error de carga",
    tip28: "Eliminar correctamente",
    tip29: "¡Seleccione png, jpg, jpeg, xlsx, xls, archivo de formato csv!",
    tip30:
      "El archivo es demasiado grande para cargarlo, por favor cargue menos de",
    tip31: "Archivo K!",
    tip32: "¿Aún no tienes una cuenta?",
    tip33: "¿Ya tienes una cuenta?",
    tip34: "Modificación exitosa",
    tip35: "Replicación correcta",
    tip36: "Seleccione una capa",
    tip37: "Pegar correctamente",
    tip38: "¡Los códigos de barras no pueden tener caracteres chinos!",
    tip39: "¡Seleccione la imagen en formato JPG, JPEG, PNG!",
    tip40: "No hay imágenes en la nube",
    tip41: "El nombre no puede estar vacío",
    tip42:
      "El nombre de archivo no puede contener ninguno de los caracteres siguientes:",
    tip43: "Los apodos no pueden estar vacíos",
    tip44:
      "La modificación es exitosa y será redirigido a la página de inicio de sesión",
    tip45: "Si el archivo ya existe se sobrescribe",
    tip46: "¡Error de red!",
    tip47:
      "El archivo es demasiado grande, ¡use un cliente de PC para abrirlo!",
    tip48: "Modificación exitosa",
    tip49: "Error de modificación",
    tip50: "El contenido está vacío y no se puede guardar",
    tip51: "Seleccione una sola capa",
    tip52: "Seleccione al menos un dato",
    tip53: "Introduzca palabras clave",
    tip54: "Código de verificación enviado con éxito",
    tip55: "Registro exitoso",
    tip56: "Agregado correctamente",
    tip57: "La mudanza fue exitosa",
    tip58: "Guardado con éxito",
    tip59: "Enviado correctamente",
    tip60: "Sube imágenes de menos de 2M",
    tip61: "El contenido no puede estar vacío",
    tip62: "La carpeta actual no tiene datos cargados",
    tip63:
      "Para la seguridad de su cuenta, por favor adquiera su número de teléfono móvil / dirección de correo electrónico!",
    tip64: "¡Conectado con éxito!",
    tip65: "¡La conexión falló!",
    tip66: "¡Encuadernación exitosa!",
    tip67:
      "¡Use el permiso para modificar el apodo después de actualizar la membresía!",
    tip68: "¡Autorización exitosa!",
    tip69:
      "El tamaño de fuente se muestra de manera anormal, ¡ajuste el tamaño de fuente mínimo del navegador!",
    tip70: "¿Cómo modificar?",
    tip71: "Puntas",
    tip72: "¡Se ha asociado con otros elementos, y no se puede asociar con un elemento!",
    tip73: "Introduzca un número decimal de 0 ~ 9",
    tip74:
      "Introduzca un número hexadecimal que conste de 0 a 9, de la A a la F",
    tip75: "Introduzca un número hexadecimal que conste de la A a la Z",
    tip76: "Por favor, introduzca 0~9, A~Z compuesto por treinta-hexadecimales",
    tip77:
      "Los datos actuales están referenciados por otro elemento, ¿es seguro eliminarlos?",
    tip78:
      "Existen los datos actuales a los que hacen referencia otros elementos, ¿es seguro eliminarlos?",
    tip79:
      "Se recomienda utilizar los navegadores Chrome y Microsoft Edge para editar o imprimir etiquetas.",
    tip80: "¡El formato de codificación es incorrecto!",
    tip81: "Verificación de seguridad",
    tip82: "¿No puedes ver con claridad? Cambiar uno",
    tip83: "¡Las impresiones no pueden estar vacías!",
    tip84: "Seleccione una impresora",
    tip85: "Haga clic ",
    tip86: "en Descargar para instalar e iniciar",
    tip87: " el Asistente de impresión",
    tip88: "Si está instalado e iniciado, haga clic aquí para ",
    tip89: "conectarse",
    tip90:
      "No se pudo obtener la impresora, vuelva a conectar el Asistente de impresión.",
    tip91: "¡Entregado con éxito!",
    tip92: "Error en la entrega, inténtalo de nuevo.",
    tip93: "¡Instale e inicie el Asistente de impresión!",
    tip94: 'Pago exitoso',
    tip95: 'Tiempo de espera de pago',
    tip96: 'Por favor, introduzca el código de canje',
    tip97: 'Instrucciones de uso',
    tip98: 'Los códigos de canje se pueden obtener participando en las actividades oficiales de operación realizadas por Hanma;',
    tip99: 'Proceso de canje: verifique la información de la cuenta, complete el código de canje y haga clic en Canjear ahora;',
    tip100: 'Si usted es miembro, el período de validez de la membresía se extenderá después del canje;',
    tip101: 'Por favor, preste atención al tiempo de canje y canjee dentro del período de validez de canje.',
    tip102: 'Redención exitosa',
    tip103: 'Error de análisis de plantilla',
    tip104: 'La función de formulario es un privilegio solo para miembros, ¡úsela después de actualizar su membresía!',
    tip105: '¡Ábralo con un navegador de computadora!'
  },
  label: {
    label1: "Pasta",
    label2: "Etiquetas usadas recientemente",
    label3: "Nome",
    label4: "Edite el nombre de la carpeta",
    label5: "Edite el nombre del archivo",
    label6: "Editar el nombre de la etiqueta",
    label7: "Modificar el apodo",
    label8: "Editar el apodo",
    label9: "Cambia la contraseña",
    label10: "Apodo",
    label11: "Cuenta",
    label12: "Contraseña",
    label13: "Industria",
    label14: "Seleccione la industria",
    label15: "Revisar",
    label16: "El nombre de la etiqueta",
    label17: "Guarde la etiqueta en",
    label18: "Mover la etiqueta a",
    label19: "Vista previa de impresión",
    label20: "Tamaño de la etiqueta",
    label21: "El tipo de plantilla",
    label23: "Ancho",
    label24: "Altura",
    label25: "Varias filas y columnas",
    label26: "Columnas",
    label27: "Filas",
    label28: "Espaciado",
    label29: "Espaciado",
    label30: "Margen",
    label31: "Izquierda",
    label32: "Derecha",
    label33: "Encima",
    label34: "Debajo",
    label35: "Creé",
    label36: "Nombre de datos",
    label37: "Agregar fecha",
    label38: "Defecto",
    label39: "Nombre A-Z",
    label40: "Nombre Z-A",
    label41: "Fecha ASC",
    label42: "Fecha DESC",
    label43: "Tamaño ASC",
    label44: "Tamaño DESC",
    label45: "Inicio de sesión en la cuenta",
    label46: "Cambiar contraseña",
    label47: "He leído y estoy de acuerdo ",
    label48: "Acuerdo de servicio ",
    label49: "y ",
    label50: "Política de privacidad",
    label51: "Nueva carpeta 1",
    label52:
      "Actualice a los miembros para disfrutar de privilegios exclusivos",
    label53: "WeChat Paga",
    label54: "WeChat",
    label55: "Actualizar miembros para disfrutar de ",
    label63: " privilegios de miembro",
    label56: "Recomendar",
    label57: "Expira en ",
    label58: " días",
    label59: "",
    label60: "Caduca hoy",
    label61: " meses",
    label62: "Inicio de sesión de terceros",
    label64: "Impresora",
    label65: "Copias",
    label66: "Preferencial",
  },
  privilege: {
    privilege1: "Aumentar el espacio en la nube",
    privilege2: "Código de escaneo nuevo",
    privilege3: "Carpetas ilimitadas",
    privilege4: "Escanear Imprimir",
    privilege5: "Comparte Plantillas",
    privilege6: "Impresión Rápida",
    privilege7: "Plantillas Variables",
    privilege8: "Fuentes Exclusivas",
    privilege9: "Función de Tabla",
    privilege10: "Imprimir Registro",
    privilege11: "Modificar Apodo",
    privilege12: "Estatus Distinguido",
    privilege13: "Espacio en la nube"
  },
  editAttr: {
    attrText1: "Propiedades del componente",
    attrText2: "Origen de datos",
    attrText3: "Texto normal",
    attrText4: "Texto de la base de datos",
    attrText5: "Serializar texto",
    attrText6: "Fuente",
    attrText7: "Tamaño",
    attrText8: "Estilo",
    attrText9: "Girar",
    attrText10: "Palabras",
    attrText11: "Línea",
    attrText12: "Ajuste de palabras",
    attrText13: "Espejo",
    attrText14: "Anti-Negro",
    attrText15: "Ubicación",
    attrText16: "Fecha",
    attrText17: "Cifra",
    attrText18: "Tipo de hora",
    attrText19: "Hora del sistema",
    attrText20: "Especifique la hora",
    attrText21: "Tipo de código de barras",
    attrText22: "Ubicación de los datos",
    attrText23: "No se muestra",
    attrText24: "Abajo",
    attrText25: "Multiplicador de zoom",
    attrText26: "Izquierda",
    attrText27: "Centro",
    attrText28: "Derecha",
    attrText29: "Tasa de corrección de errores",
    attrText30: "Fotos locales",
    attrText31: "Imágenes en la nube",
    attrText32: "Escala igual",
    attrText33: "El tipo de línea",
    attrText34: "Grosor de línea",
    attrText35: "Tipo de dato",
    attrText36: "Por favor, seleccione",
    attrText37: "Conectarse",
    attrText38: "Insertar datos",
    attrText39: "Valor inicial",
    attrText40: "Incremento",
    attrText41: "Cantidad",
    attrText42: "Formato de número",
    attrText43: "Formato de fecha",
    attrText44: "Seleccione una hora",
    attrText45: "Radio de esquina",
    attrText46: "Ajuste al lienzo",
    attrText47: "Por favor, introduzca el contenido",
    attrText48: "Líneas sólidas",
    attrText49: "Discontinua",
    attrText50: "Copiar",
    attrText51: "Asalto",
    attrText52: "Cerradura",
    attrText53: "Abrir",
    attrText54: "Alinear",
    attrText55: "Alineado a la izquierda",
    attrText56: "Centrar verticalmente",
    attrText57: "Alineado a la derecha",
    attrText58: "Centrar horizontalmente",
    attrText59: "Alineación superior",
    attrText60: "Alineación inferior",
    attrText61: "Distribuido horizontalmente",
    attrText62: "Distribuido verticalmente",
    attrText63: "Orden de capas",
    attrText64: "Arriba",
    attrText65: "De abajo hacia afuera",
    attrText66: "Borrar",
    attrText67: "Datos en la nube",
    attrText68: "Datos locales",
    attrText69: "Anular",
    attrText70: "Recuperar",
    attrText71: "Salvar",
    attrText72: "Guardar como",
    attrText73: "Borrar",
    attrText74: "Ascender",
    attrText75: "Mover hacia abajo",
    attrText76: "Mover a la izquierda",
    attrText77: "Mover a la derecha",
    attrText78: "Seleccionar todo",
    attrText79: "Impresión",
    attrText80: "Acercar el lienzo",
    attrText81: "Alejar el lienzo",
    attrText82: "Selección múltiple",
    attrText83: "La rueda está arriba",
    attrText84: "Desplácese hacia abajo",
    attrText85: "Click izquierdo",
    attrText86: "Guía para principiantes",
    attrText87: "Ver accesos directos",
    attrText88: "Descripciones de teclas de atajo",
    attrText89: "Ajuste a la ventana",
    attrText90: "Disminuir el zoom",
    attrText91: "Agrandar",
    attrText92: "Tipo serializado",
    attrText93: "Decimal",
    attrText94: "Alinear",
    attrText95: "Contrapesar(d)",
    attrText96: "Contrapesar(m)",
    attrText97: "Imagen de fondo",
    attrText98: "Datos",
    attrText99: "Imprime imagen de fondo",
    attrText100: "Seleccionar datos",
    attrText101: "Valor objeto",
    attrText102: "Agregar datos",
    attrText103: "Hexadecimal",
    attrText104: "Letras",
    attrText105: "Letras y numeros",
    attrText106: "Altura de línea",
    attrText107: "Ancho de columna",
    attrText108: "Contenido",
    attrText109: "Insertar filas",
    attrText110: "Insertar una columna",
    attrText111: "Eliminar la fila",
    attrText112: "Eliminar la columna",
    attrText113: "Fusionar",
    attrText114: "Partir",
  },
  module: {
    editText1: "Texto",
    editText2: "Hora",
    editText3: "Código de barras",
    editText4: "Código QR",
    editText5: "Imagen",
    editText6: "Forma",
    editText7: "Rectángulo",
    editText8: "rectángulo de filete",
    editText9: "Rombo",
    editText10: "Elíptico",
    editText11: "Triángulo",
    editText12: "Rectángulo sólido",
    editText13: "Icono",
    editText14: "Línea",
    editText15: "Horizonte",
    editText16: "Vertical",
    editText17: "Mesa",
  },
  guide: {
    guideText1: "Bienvenido a ",
    guideText2: "Simple y eficiente",
    guideText3: "Orientado a la escena",
    guideText4: "Recursos útiles",
    guideText5: "Interoperabilidad multiterminal",
    guideText6:
      "El software de diseño de etiquetas HereLabel permite que el diseño de etiquetas logre lo que ve, es lo que obtiene. Se puede completar simplemente arrastrando y soltando. El diseño de etiquetas nunca ha sido tan simple y eficiente. ¡Ven y experiméntalo!",
    guideText7: "Saltar",
    guideText8: "Empieza a aprender",
    guideText9: "Guía: Modelo da indústria",
    guideText10:
      "Las plantillas de etiquetas basadas en escenarios proporcionan etiquetas comunes en varias industrias, como la venta al por menor, la ropa y los códigos de barras.",
    guideText11: "Guía: Plantilla de nube",
    guideText12:
      "Las etiquetas personales se almacenan automáticamente en la nube, se pueden crear etiquetas de administración de carpetas y las etiquetas se pueden usar en PC y terminales móviles.",
    guideText13: "Guía: Datos en la nube",
    guideText14:
      "Los archivos como imágenes y tablas se pueden almacenar permanentemente en la nube y se pueden usar en cualquier momento y en cualquier lugar en la Web, PC y terminales móviles.",
    guideText15: "Guía: Centro personal",
    guideText16:
      "Haga clic para modificar el idioma, cambie la unidad o ingrese a la página del centro personal para modificar la información.",
    guideText17: "Guía: Nueva etiqueta",
    guideText18:
      "Establezca el nombre de la etiqueta, el tamaño, la cantidad de filas y la cantidad de columnas, ¡y comience a crear su propia etiqueta!",
    guideText19: "Entendido",
    guideText20: "Empezar a crear etiquetas",
    guideText21: "Bienvenido a la página de edición",
    guideText22:
      "Solo unos simples pasos para completar la edición de la etiqueta, ¡venga y aprenda juntos!",
    guideText23: "Bibliotecas de componentes",
    guideText24:
      "Se proporcionan varios componentes, como texto, hora, código de barras, etc., y los componentes correspondientes se pueden agregar haciendo clic o arrastrando con el mouse.",
    guideText25: "Sigue aprendiendo",
    guideText26: "Área de funciones",
    guideText27:
      "Las funciones de los componentes incluyen bloquear, deshacer, restaurar, alinear, capa en la parte superior, capa en la parte inferior, copiar, pegar y eliminar.",
    guideText28:
      "También puede hacer clic con el botón derecho en el componente en el lienzo para abrir el menú contextual para la operación.",
    guideText29: "Área de atributos",
    guideText30:
      "Seleccione el componente para abrir el área de atributos, modifique la fuente de datos, el estilo, el tamaño, la posición y otros atributos del componente correspondiente.",
    guideText31: "Guardar Imprimir",
    guideText32:
      "Haga clic en la configuración de la etiqueta para modificar el tamaño de la etiqueta actual. <br />Después de terminar de editar la etiqueta, puede intentar imprimir la etiqueta actual.",
    guideText33: "He aprendido",
    guideText34: "Próximo",
    guideText35:
      "El software de diseño de etiquetas HereLabel permite que el diseño de etiquetas logre lo que ve, es lo que obtiene. Se puede completar simplemente arrastrando y soltando. El diseño de etiquetas nunca ha sido tan simple y eficiente. ¡Ven y experiméntalo!",
  },
  suggest: {
    suggest1: "Tipo de pregunta",
    suggest2: "Función anómala",
    suggest3: "Consejo de producto",
    suggest4: "Descripción del problema",
    suggest5:
      "¡Describa el problema que encontró en detalle y lo resolveremos lo antes posible!",
    suggest6: "Detalles de contacto",
    suggest7: "Por favor ingrese su información de contacto",
    suggest8: "Captura de pantalla del problema",
    suggest9: "Enviar",
  },
  document: {
    text1: 'Abrir el documento',
    text2: 'Haga clic o arrastre y suelte el archivo directamente en esta área para cargarlo',
    text3: 'Corte automático',
    text4: 'Corte inteligente de etiquetas',
    text5: 'Corte personalizado',
    text6: 'Impresión directa',
    text7: 'Documentación',
    text8: 'Un clic para cortar el vacío excesivo del documento',
    text9: 'División de etiquetas inteligentes',
    text10: 'Cortar a varios archivos de etiqueta',
    text11: 'Líneas',
    text12: 'Columnas',
    text13: 'Ejemplo',
    text14: 'Por ejemplo: 2 * 2',
    text15: 'Siguiente paso',
    text16: 'Tamaño del papel',
    text17: 'Personalizado',
    text18: 'Ancho',
    text19: 'Altura',
    text20: 'Efecto de impresión',
    text21: 'Documentos',
    text22: 'Imagen',
    text23: 'Resolución',
    text24: 'Modo de disposición',
    text25: 'Extender',
    text26: 'Relleno',
    text27: 'Proporción',
    text28: 'Número de copias impresas',
    text29: 'Rango de página',
    text30: 'Todo',
    text31: 'Página actual',
    text32: 'Rango de página',
    text33: 'Rango de página (por ejemplo: 1-3,5)',
    text34: 'Paso anterior',
    text35: 'Corte',
    text36: 'Por favor, suba el archivo txt, pptx, ppt, pdf, doc, docx, xls, xlsx, bp, jpg, png',
    text37: 'El contenido del documento está vacío',
    text38: 'Entrada incorrecta del rango de página',
    text39: 'Error de análisis',
    text40: 'Aplicar todo el documento',
  }
};
